import React from 'react';
import PropTypes from 'prop-types';
import Text from './Text';
import { PropsPropType, RulesPropType } from '../propTypes';

const Paragraph = ({ preview, ...props }) => {
  return <Text disabled={preview} {...{ ...props, isParagraph: true }} />;
};

Paragraph.defaultProps = {
  preview: false
};

Paragraph.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  system_type: PropTypes.string.isRequired,
  props: PropsPropType,
  rules: RulesPropType,
  preview: PropTypes.bool
};

export default Paragraph;
