import React from 'react';
import PropTypes from 'prop-types';
import { connectHighlight } from 'react-instantsearch-dom';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { formatHitValue, isArrayAttribute, isDateAttribute, getSeeMoreLink } from './helpers';

const ARRAY_ITEMS_LIMIT = 5;

const Highlight = ({ highlight, attribute, hit }) => {
  const specialDateHighlighter = value => {
    return [
      {
        value,
        isHighlighted: false
      }
    ];
  };

  const renderHighlight = (parsedHit, attr) => {
    return parsedHit.map(part => {
      const parsedValue = formatHitValue(part.value, attr);

      if (_.isArray(parsedValue)) {
        return _.map(parsedValue, subvalue => {
          return (
            <li key={Math.random()}>
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: subvalue.replaceAll('<em>', '<mark>').replaceAll('</em>', '</mark>')
                }}
              />
            </li>
          );
        });
      }

      return part.isHighlighted ? (
        <mark key={Math.random()}>{parsedValue}</mark>
      ) : (
        <span key={Math.random()}>{parsedValue}</span>
      );
    });
  };

  const renderArrayHighlight = (parsedHit, attr) => {
    return (
      <span>
        <ul style={{ paddingLeft: '16px', marginBottom: '6px' }}>
          {parsedHit.slice(0, ARRAY_ITEMS_LIMIT).map(item => {
            return (
              <li>
                {item.map(part => {
                  const parsedValue = formatHitValue(part.value, attr);
                  return part.isHighlighted ? (
                    <mark key={Math.random()}>{parsedValue}</mark>
                  ) : (
                    <span key={Math.random()}>{parsedValue}</span>
                  );
                })}
              </li>
            );
          })}
        </ul>
        {parsedHit.length > ARRAY_ITEMS_LIMIT && getSeeMoreLink(attribute, hit) && (
          <Link to={getSeeMoreLink(attribute, hit)}>See More</Link>
        )}
      </span>
    );
  };

  const parsedHit = isDateAttribute(attribute, hit[attribute])
    ? specialDateHighlighter(hit[attribute])
    : highlight({
        highlightProperty: '_highlightResult',
        attribute,
        hit
      });

  if (isArrayAttribute(attribute, hit[attribute]) && parsedHit.length) {
    if (parsedHit.length === 1) {
      return <span>{renderHighlight(parsedHit[0], attribute)}</span>;
    }

    return <span>{renderArrayHighlight(parsedHit, attribute)}</span>;
  }

  return <span>{renderHighlight(parsedHit, attribute)}</span>;
};

const CustomHighlight = connectHighlight(Highlight);

Highlight.propTypes = {
  highlight: PropTypes.func.isRequired,
  attribute: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  hit: PropTypes.object.isRequired
};

export default CustomHighlight;
