import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { Typography as MuiTypography } from '@material-ui/core';
import { Table, Button } from 'antd';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { produce } from 'immer';
import { useSelector } from 'react-redux';
import NewEditableCell from './NewEditableCell';
import { isSuperAdminOrAdmin, isTechnician } from '../../helpers';
import { formatProductionTime } from '../../helpers/techWorkflow';

const useStyles = makeStyles(() => ({
  actionButton: {},
  table: {
    '& .ant-table': {
      fontSize: 12
    },
    '& .ant-table-cell': {
      padding: 2,
      wordBreak: 'break-all',
      '& .ant-input': {
        lineHeight: 0,
        fontSize: 12,
        padding: 0
      },
      '& .ant-picker': {
        lineHeight: 0,
        fontSize: 8,
        padding: 0,
        '& input': {
          fontSize: 12
        }
      }
    }
  },
  row: {},
  cell: {}
}));

const TechWorkflow = ({ title, workflowData, readOnly, save, start, finish }) => {
  const classes = useStyles();
  const [editingKey, setEditingKey] = useState('');
  const [editingData, setEditingData] = useState({});
  const [saving, setSaving] = useState(false);
  const [starting, setStarting] = useState(false);
  const [finishing, setFinishing] = useState(false);
  const isEditing = record => record.id === editingKey;
  const currentUser = useSelector(state => state.auth.user);
  const isAdmin = currentUser ? isSuperAdminOrAdmin(currentUser) : false;
  const isTech = currentUser ? isTechnician(currentUser) : false;

  const edit = record => {
    if (!isAdmin) {
      return;
    }
    setEditingData({ ...record });
    setEditingKey(record.id);
  };
  const cancel = () => {
    setEditingData({});
    setEditingKey('');
  };

  const handleSave = record => {
    setSaving(true);

    return save(editingData, record)
      .then(() => {
        setEditingData({});
        setEditingKey('');
      })
      .catch(() => {})
      .finally(() => {
        setSaving(false);
      });
  };

  const handleStart = record => {
    setStarting(true);
    return start(record)
      .catch(() => {})
      .finally(() => {
        setStarting(false);
      });
  };

  const handleFinish = record => {
    setFinishing(true);
    return finish(record)
      .catch(() => {})
      .finally(() => {
        setFinishing(false);
      });
  };

  const renderTechActions = record => {
    if (!record.has_started && record.is_prerequisite_finished) {
      return (
        <Button
          type="primary"
          size="small"
          className={classes.actionButton}
          disabled={starting}
          onClick={() => handleStart(record)}
          loading={starting}
        >
          Start
        </Button>
      );
    }

    if (record.has_started && !record.has_finished) {
      return (
        <Button
          type="primary"
          size="small"
          className={classes.actionButton}
          danger
          disabled={finishing}
          onClick={() => handleFinish(record)}
          loading={finishing}
        >
          Finish
        </Button>
      );
    }

    return null;
  };

  const renderAdminActions = record => {
    if (!isEditing(record) && (record.tech_name || record.started_at || record.finished_at)) {
      return (
        <Button
          type="primary"
          size="small"
          disabled={editingKey !== ''}
          onClick={() => edit(record)}
          className={classes.actionButton}
        >
          Edit
        </Button>
      );
    }

    if (isEditing(record)) {
      return (
        <span>
          <Button
            disabled={saving}
            type="primary"
            size="small"
            style={{
              marginRight: 2
            }}
            className={classes.actionButton}
            onClick={() => handleSave(record)}
            loading={saving}
          >
            Save
          </Button>
          {!saving && (
            <Button
              type="primary"
              size="small"
              danger
              className={classes.actionButton}
              disabled={saving}
              onClick={cancel}
            >
              Cancel
            </Button>
          )}
        </span>
      );
    }

    return null;
  };

  const columns = [
    { title: 'Nº', dataIndex: 'step', width: '3%' },
    { title: 'Process', dataIndex: 'process', width: '12%' },
    {
      title: 'Tech Name',
      dataIndex: 'tech_name',
      width: '20%',
      editable: true,
      type: 'user',
      deletable: true
    },
    {
      title: 'ST',
      dataIndex: 'started_at',
      width: '15%',
      type: 'datetime',
      editable: true
    },
    {
      title: 'ET',
      dataIndex: 'finished_at',
      width: '15%',
      type: 'datetime',
      editable: true
    },
    {
      title: 'Prod',
      dataIndex: 'production_time',
      width: '10%',
      align: 'center',
      render: (_, record) => {
        return formatProductionTime(record.production_time || '');
      }
    },
    {
      title: 'Lag',
      dataIndex: 'lag_time',
      width: '10%',
      align: 'center',
      render: (_, record) => {
        return formatProductionTime(record.lag_time || '');
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) => {
        if (isTech) {
          return renderTechActions(record);
        }

        if (isAdmin) {
          return renderAdminActions(record);
        }

        return null;
      }
    }
  ];

  return (
    <>
      <Box my={1}>
        <MuiTypography>{title}</MuiTypography>
      </Box>
      <Table
        components={{
          body: {
            cell: NewEditableCell
          }
        }}
        rowClassName={classes.row}
        cellClassName={classes.cell}
        bordered
        rowKey="id"
        dataSource={workflowData}
        pagination={false}
        columns={columns.map(col => {
          let { editable } = col;
          if (readOnly && !isAdmin) {
            editable = false;
          }

          return {
            ...col,
            onCell: record => ({
              record: editingData,
              editable,
              dataIndex: col.dataIndex,
              title: col.title,
              type: col.type,
              handleSave: row => {
                setEditingData(prevState =>
                  produce(prevState, () => {
                    return { ...prevState, ...row };
                  })
                );
              },
              deletable: col.deletable ? col.deletable : false,
              editing: isEditing(record),
              onClick: () => {
                if (
                  editingKey === '' &&
                  !isEditing(record) &&
                  editable &&
                  col.dataIndex !== 'actions'
                ) {
                  edit(record);
                }
              }
            })
          };
        })}
        className={classes.table}
        summary={data => {
          const lagTime = formatProductionTime(data.reduce((a, b) => a + b.lag_time, 0));
          const ProductionTime = formatProductionTime(
            data.reduce((a, b) => a + b.production_time, 0)
          );
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} />
              <Table.Summary.Cell index={1}>
                <strong>Total</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} />
              <Table.Summary.Cell index={3} />
              <Table.Summary.Cell index={4} />
              <Table.Summary.Cell index={5} align="center">
                <strong>{ProductionTime}</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} align="center">
                <strong>{lagTime}</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7} />
            </Table.Summary.Row>
          );
        }}
      />
    </>
  );
};

TechWorkflow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workflowData: PropTypes.array.isRequired,
  title: PropTypes.string,
  readOnly: PropTypes.bool,
  save: PropTypes.func.isRequired,
  start: PropTypes.func.isRequired,
  finish: PropTypes.func.isRequired
};

TechWorkflow.defaultProps = {
  title: '',
  readOnly: false
};

export default TechWorkflow;
