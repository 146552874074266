import React from 'react';
import { TextField } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { createLabel, getInjectedFields } from './helpers';
import { PropsPropType, RulesPropType } from '../propTypes';

const Text = ({ id, preview, field, props, rules, isParagraph }) => {
  const { name, onChange, value } = getInjectedFields(field);

  return (
    <TextField
      label={createLabel(props, rules, 'Field Label')}
      InputLabelProps={{ shrink: true }}
      variant="outlined"
      type={get(props, 'type', 'text')}
      minRows={isParagraph ? 4 : 1}
      multiline={isParagraph}
      value={value || ''}
      onChange={onChange}
      name={name}
      helperText={get(props, 'description')}
      fullWidth
      disabled={preview}
    />
  );
};

Text.defaultProps = {
  preview: false
};

Text.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  props: PropsPropType,
  rules: RulesPropType,
  preview: PropTypes.bool
};

export default Text;
