import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';

const CaseReversalInfoDialog = ({ open, setOpen, complianceResult, onReverse }) => {
  const reverseCase = useCallback(() => {
    onReverse();
    setOpen(false);
  }, [onReverse, setOpen]);

  const renderWarnings = warnings => {
    if (!warnings || !warnings.length) {
      return null;
    }

    return (
      <>
        <div>
          <strong>Warnings:</strong>{' '}
          <ul>
            {complianceResult.warnings.map(warning => (
              <li>{warning}</li>
            ))}
          </ul>
        </div>
      </>
    );
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Are you sure you want to reverse this case?</DialogTitle>
      <DialogContent>
        {complianceResult && (
          <DialogContentText>{renderWarnings(complianceResult.warnings)}</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={reverseCase}
          color="primary"
          disabled={!complianceResult || complianceResult.is_compliant !== true}
        >
          Reverse
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CaseReversalInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  complianceResult: PropTypes.shape({
    is_compliant: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    warnings: PropTypes.arrayOf(PropTypes.string).isRequired
  }),
  onReverse: PropTypes.func.isRequired
};

CaseReversalInfoDialog.defaultProps = {
  complianceResult: null
};

export default CaseReversalInfoDialog;
