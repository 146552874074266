import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormHelperText, Typography } from '@material-ui/core';
import { Field } from 'formik';
import red from '@material-ui/core/colors/red';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroupField from './RadioGroupField';
import withDependency from './hooks/withDependency';
import { ATTRIBUTES, QUESTIONS, HELPER_TEXT, SUB_LABEL } from '../constants';
import MoneyFormat from '../../MoneyFormat';
import NameField from '../../Formik/NameField';

const useStyles = makeStyles(theme => ({
  rush_case_fee: {
    color: red[800],
    textAlign: 'center'
  },
  rush_case_fee_container: {
    paddingTop: '0.2em'
  },
  rush_case_helper_text: {
    paddingBottom: theme.spacing(2)
  }
}));

const RushCaseField = ({ name, currentValue, rushCaseFee, ...props }) => {
  const classes = useStyles();

  let appendAfterOptions = '';

  if (currentValue && rushCaseFee > 0) {
    appendAfterOptions = (
      <div className={classes.rush_case_fee_container}>
        <Typography variant="h6" display="inline" className={classes.rush_case_fee}>
          <span>(</span>
          <MoneyFormat value={rushCaseFee} fixedDecimalScale={false} />
          <span>)</span>
        </Typography>
      </div>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <RadioGroupField
          name={name}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
          appendAfterOptions={appendAfterOptions}
          appendLabel={SUB_LABEL[ATTRIBUTES.RUSH_CASE]}
          {...props}
        />
      </Grid>
      {currentValue && (
        <>
          <Grid item xs={12} md={5}>
            <FormHelperText className={classes.rush_case_helper_text}>
              {HELPER_TEXT[ATTRIBUTES.RUSH_CASE]}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <Field
              style={{ alignSelf: 'end', width: 400 }}
              variant="outlined"
              id={ATTRIBUTES.RUSH_CASE_AUTHORIZED_BY_NAME}
              label={QUESTIONS[ATTRIBUTES.RUSH_CASE_AUTHORIZED_BY_NAME]}
              name={ATTRIBUTES.RUSH_CASE_AUTHORIZED_BY_NAME}
              component={NameField}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

RushCaseField.propTypes = {
  name: PropTypes.string.isRequired,
  currentValue: PropTypes.bool.isRequired,
  rushCaseFee: PropTypes.number
};

RushCaseField.defaultProps = {
  rushCaseFee: 0
};

export default withDependency(RushCaseField);
