import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Badge, Hidden, IconButton, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Popper from '@material-ui/core/Popper';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import { useDispatch, useSelector } from 'react-redux';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import {
  clearAllUserNotificationsAction,
  logoutAction,
  markAllUserNotificationsAsReadAction
} from '../../../../../reducers/auth';
import logo from '../../../../../assets/images/logo-173x44.png';
import { sortByDate } from '../../../../../helpers';
import OrganizationSelector from '../../../../OrganizationSelector';
import MakeHidden from '../../../../MakeHidden';
import OrganizationsSelector from './OrganizationsSelector';
import Can from '../../../../Can';
import { ORGANIZATIONS_SELECTOR_AVAILABLE_PATHS } from '../../../../../constants/organizations';
import OnboardingButton from '../../../../Onboarding/OnboardingButton';
import { createDesktopURI } from '../../../../../helpers/urls';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    // must above onboarding popup
    zIndex: `${theme.zIndex.appBar + 2} !important`
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  logoLink: {
    width: '192px',
    textAlign: 'center'
  },
  logo: {
    height: '48px'
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, hideButtons, ...rest } = props;
  const dispatch = useDispatch();
  const userNotifications = useSelector(state => state.auth.userNotifications);
  const classes = useStyles();
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [openNotification, setOpenNotification] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const showOrganizationSelector =
    ORGANIZATIONS_SELECTOR_AVAILABLE_PATHS.indexOf(location.pathname) > -1;

  const handleClickNotification = event => {
    if (!userNotifications) {
      return;
    }

    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
      dispatch(markAllUserNotificationsAsReadAction());
    }
  };

  const handleCloseNotification = link => {
    if (link) {
      history.push(link);
    }
    setOpenNotification(null);
    dispatch(clearAllUserNotificationsAction());
  };

  const handleAutoLogin = () => {
    window.location.href = createDesktopURI('login');
  };

  useEffect(() => {
    setUnreadNotifications(userNotifications.filter(item => !item.read_at));
    setNotifications(sortByDate(userNotifications.slice(), 'created_at'));
  }, [userNotifications]);

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/" className={classes.logoLink}>
          <img alt="Logo" src={logo} className={classes.logo} />
        </RouterLink>
        {showOrganizationSelector && (
          <MakeHidden profile="normal">
            <Can
              permissions={['organizations.manage', 'organizations.view-any']}
              yes={() => <OrganizationsSelector />}
            />
          </MakeHidden>
        )}
        <div className={classes.flexGrow} />
        <OrganizationSelector />
        <OnboardingButton />
        {!hideButtons.autoLogin && (
          <IconButton color="inherit" title="Auto-Login" onClick={handleAutoLogin}>
            <DesktopWindowsIcon />
          </IconButton>
        )}
        <IconButton color="inherit" onClick={handleClickNotification} title="Notifications">
          <Badge badgeContent={unreadNotifications.length} color="secondary" overlap="rectangular">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton
          className={classes.signOutButton}
          color="inherit"
          title="Log Out"
          onClick={() => dispatch(logoutAction())}
        >
          <InputIcon />
        </IconButton>
        <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={`${clsx({ [classes.popperClose]: !openNotification })} ${classes.popperNav}`}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => handleCloseNotification()}>
                  <MenuList role="menu">
                    {notifications.map(userNotification => (
                      <MenuItem
                        onClick={() => handleCloseNotification(userNotification.link)}
                        className={classes.dropdownItem}
                        key={userNotification.id}
                      >
                        {userNotification.message}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  hideButtons: PropTypes.shape({
    autoLogin: PropTypes.bool
  })
};

Topbar.defaultProps = {
  className: '',
  hideButtons: { autoLogin: false },
  onSidebarOpen: () => {}
};

export default Topbar;
