import React from "react";
import PropTypes from "prop-types";
import { PropsPropType, RulesPropType } from "../propTypes";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { createLabel, getInjectedFields } from "./helpers";
import { get } from "lodash";

const Radios = ({ id, field, preview, props, rules, isLive, form }) => {
    const { name, onChange, value } = getInjectedFields(field);

    if (!props) return <></>;

    const options = get(props, 'options', []);

    return (
        <FormControl>
            <FormLabel id={id}>{ createLabel(props, rules) }</FormLabel>
            <RadioGroup
                labelid={id}
                name={ name }
                value={ value }
                disabled={preview}
                onChange={ onChange }>
                    { options.map((option, index) => {
                        const checked = option.id?.toString() === value?.toString();

                        return (
                            <FormControlLabel
                                key={index}
                                value={option.id}
                                control={<Radio color="primary" />}
                                label={option.name}
                                checked={checked} />
                        );
                    })}
            </RadioGroup>
        </FormControl>
    );
}

Radios.defaultProps = {
  preview: false
};

Radios.propTypes = {
  id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
  ]).isRequired,
  props: PropsPropType,
  rules: RulesPropType,
  preview: PropTypes.bool
};

export default Radios;