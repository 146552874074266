import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class RequestsApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  get({
    perPage = 5,
    page = 1,
    order,
    orderBy,
    userId = '',
    searchFullNameQuery = '',
    includes = [],
    searchDoctorFullNameQuery = '',
    statusId = '',
    organizationId = '',
    locations = []
  }) {
    const orderChar = order === 'desc' ? '-' : '+';
    const orderByField = orderBy || 'id';
    return this.request({
      method: 'get',
      url: this.query
        .for('customer_requests')
        .include(...includes)
        .where('patient.user_id', userId)
        .where('patient_full_name_like', searchFullNameQuery)
        .where('doctor_full_name_like', searchDoctorFullNameQuery)
        .where('patient.organization_id', organizationId)
        .where('status_id', statusId)
        .whereIn('wi_patient.location_id', locations)
        .sort(orderChar + orderByField)
        .params({ perPage, page })
        .get()
    });
  }

  show(id) {
    return this.request({
      method: 'get',
      url: `/customer_requests/${id}`
    });
  }

  complete(id, data) {
    return this.request({
      method: 'post',
      url: `/customer_requests/${id}/complete`,
      data
    });
  }

  cancel(id, data = {}) {
    return this.request({
      method: 'post',
      url: `/customer_requests/${id}/cancel`,
      data
    });
  }

  updateManagementData(id, data) {
    return this.request({
      method: 'put',
      url: `/customer_requests/${id}/management_data`,
      data
    });
  }

  markPatientLabelAsPrinted(id) {
    return this.request({
      method: 'post',
      url: `/customer_requests/${id}/patient_label_printed`
    });
  }

  printShippingLabel(bundledShipmentId) {
    return this.request({
      method: 'post',
      url: `/shipment/print-shipping-label`,
      data: {
        bundled_shipment_id: bundledShipmentId
      }
    });
  }

  completeInBundle(ids, trackingNumber, customShippingFee = null, internalShippingCost = null) {
    return this.request({
      method: 'post',
      url: `/customer_requests/bundled_completion`,
      data: {
        ids,
        tracking_number: trackingNumber,
        custom_shipping_fee: customShippingFee,
        internal_shipping_cost: internalShippingCost
      }
    });
  }
}
