import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormDialog from '../../components/FormDialog';
import Rating from './Rating';

const useStyles = makeStyles(() => ({
  optOut: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20
  }
}));

const Popup = ({ name, showOptionToTurnOff, rate }) => {
  const classes = useStyles();
  const [turnedOff, setTurnedOff] = useState(!showOptionToTurnOff);

  const turnOff = e => {
    setTurnedOff(e.target.checked);
  };

  const handleRate = score => {
    rate(score, {
      disable: turnedOff
    });
  };

  return (
    <FormDialog
      open={!!name}
      titleText={`${name}'s case was recently completed. How did it go?`}
      showButtons={false}
      handleClose={() => {}}
      setOpen={() => {}}
    >
      <Rating submit={handleRate} />
      {showOptionToTurnOff && (
        <div className={classes.optOut}>
          <Checkbox checked={turnedOff} onChange={turnOff} />
          These pop ups are annoying, please turn them off
        </div>
      )}
    </FormDialog>
  );
};

Popup.propTypes = {
  name: PropTypes.string.isRequired,
  showOptionToTurnOff: PropTypes.bool.isRequired,
  rate: PropTypes.func.isRequired
};

export default Popup;
