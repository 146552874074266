import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const Section = ({ title, children, ...props }) => {
  return (
    <Grid item {...props}>
      <Grid container spacing={2}>
        {title && (
          <Grid item xs={12}>
            <Typography className="customize-fees-section-label" variant="h6">
              {title}
            </Typography>
          </Grid>
        )}
        {children}
      </Grid>
    </Grid>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired
};

export default Section;
