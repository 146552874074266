import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class FeedbacksApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  getCase(organizationId) {
    return this.request({
      method: 'get',
      url: `/organizations/${organizationId}/feedback-suggestion`
    });
  }

  submit(patientId, score, options = {}) {
    return this.request({
      method: 'post',
      url: `/patients/${patientId}/submit-feedback`,
      data: { score, options }
    });
  }
}
