import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { PRINT_ASSIGNED_TO_ID } from '../../../constants';
import ButtonLink from '../../../components/ButtonLink';

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(1, 0),
    fontSize: theme.typography.pxToRem(12)
  }
}));

const TrackingNumber = ({ printingRequest, onClickTrackingNumber }) => {
  const classes = useStyles();

  const trackingNumber = useMemo(() => {
    if (!printingRequest) {
      return null;
    }

    if (printingRequest.tracking_number) {
      return (
        <ButtonLink onClick={() => onClickTrackingNumber(printingRequest.tracking_number)}>
          {printingRequest.tracking_number}
        </ButtonLink>
      );
    }

    if (printingRequest.assigned_to === PRINT_ASSIGNED_TO_ID.orthoselect) {
      return 'Pending';
    }

    return 'N/A';
  }, [printingRequest, onClickTrackingNumber]);

  if (!printingRequest) {
    return null;
  }

  return (
    <div className={classes.container}>
      <strong>Tracking # </strong> {trackingNumber}
    </div>
  );
};

TrackingNumber.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  printingRequest: PropTypes.object,
  onClickTrackingNumber: PropTypes.func.isRequired
};

TrackingNumber.defaultProps = {
  printingRequest: null
};

export default TrackingNumber;
