import React from "react";
import { useDrop } from 'react-dnd'
import { TYPE_LAYOUT } from "../helpers";

export default function BuilderPage({ addLayout, children, moveSection }) {
    const [{ isOver }, drop] = useDrop({
        accept: TYPE_LAYOUT,
        drop: (item, monitor) => {
            if (monitor.didDrop()) {
                return;
            }

            if (item.id) {
                moveSection({
                    src: {
                        id: item.id
                    },
                    dst: null,
                });
            } else {
                if (addLayout) {
                    addLayout(item);
                }
            }
        },
        collect: monitor => ({
            isOver: !!monitor.isOver({ shallow: true }),
        }),
    }, [addLayout]);

    return (
        <div ref={drop} className={'fb-builder-content ' + ( isOver ? 'highlight' : '')}>
            <div className="fb-builder-content-children">{ children }</div>
        </div>
    );
};