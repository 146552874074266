export const NOTIFICATION_TYPES = {
  PATIENT_SETUP_COMPLETED: 'patient-setup-completed',
  RUSH_CASE: 'rush-case',
  UPDATED_NOTIFICATION: 'updated-notification',
  LOW_BRACKET_INVENTORY: 'low-bracket-inventory'
};

export const NOTIFICATION_ALERTS = [
  NOTIFICATION_TYPES.RUSH_CASE,
  NOTIFICATION_TYPES.LOW_BRACKET_INVENTORY
];
