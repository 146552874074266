import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Alert } from '@material-ui/lab';
import { fetchOrganizationLocationsAction } from '../../reducers/organizations';
import LoadingIndicator from '../LoadingIndicator';

const LoadAvailableLocations = ({ children, organization }) => {
  const dispatch = useDispatch();
  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const isManagement = useSelector(state => state.auth.isManagement);
  const [assignedLocations, setAssignedLocations] = useState([]);
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);
  const hasMount = useRef(false);

  useEffect(() => {
    hasMount.current = true;
    if (!isManagement) {
      setAssignedLocations(currentOrganization.current_user_assigned_locations);
      return;
    }

    if (organization.locations) {
      setAssignedLocations(organization.locations);
      return;
    }

    setIsLoadingLocal(true);
    dispatch(fetchOrganizationLocationsAction(organization.id)).then(response => {
      setAssignedLocations(response);
      setIsLoadingLocal(false);
    });
  }, [dispatch, organization, currentOrganization, isManagement]);

  if (isLoadingLocal) {
    return <LoadingIndicator />;
  }

  if (!hasMount.current) {
    return null;
  }

  if (!assignedLocations.length) {
    return <Alert severity="warning">No found Locations</Alert>;
  }

  return children(assignedLocations);
};

LoadAvailableLocations.propTypes = {
  organization: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.func.isRequired
};

LoadAvailableLocations.defaultProps = {};

export default LoadAvailableLocations;
