import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { TextField } from '@material-ui/core';
import LoadingButton from '../LoadingButton';

const EnterPromotionCode = ({ onApply }) => {
  const [code, setCode] = useState('');

  const applyPromoCode = () => {
    onApply(code);
    setCode('');
  };

  const onChange = e => {
    setCode(e.target.value);
  };

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
      <TextField label="Add Promo Code" type="text" value={code} onChange={onChange} />
      <LoadingButton
        type="button"
        size="small"
        variant="contained"
        color="primary"
        disabled={!code}
        onClick={() => applyPromoCode()}
      >
        Apply
      </LoadingButton>
    </Box>
  );
};

EnterPromotionCode.propTypes = {
  onApply: PropTypes.func.isRequired
};

EnterPromotionCode.defaultProps = {};

export default EnterPromotionCode;
