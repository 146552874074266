import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FETCH_DASHBOARD_DATA_PREFIX, fetchDashboardDataAction } from '../../../reducers/dashboard';
import Filters from './Filters';
import Content from './Content';
import createLoadingSelector from '../../../selectors/loading';
import ThirdPartyContent from './ThirdParty/Content';
import { isLabPartner } from '../../../helpers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    background: '#f5f5f5'
  }
}));

export default function AdminDashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.user);
  const globalParams = useSelector(state => state.dashboard.globalParams);
  const resetAfterSync = useSelector(state => state.dashboard.globalParams.resetPage);
  const isLoading = useSelector(state =>
    createLoadingSelector([FETCH_DASHBOARD_DATA_PREFIX])(state)
  );
  const data = useSelector(state => state.dashboard.items);
  const [additionalParams, setAdditionalParams] = useState({
    params: {
      dateFilterFrom: globalParams.dateFilterFrom,
      dateFilterTo: globalParams.dateFilterTo,
      timeFilter: globalParams.timeFilter,
      invoiceType: globalParams.invoiceType
    },
    resetPage: false
  });

  useEffect(() => {
    const { params } = additionalParams;
    dispatch(fetchDashboardDataAction(params));
  }, [dispatch, additionalParams, resetAfterSync]);

  if (!data || !data.statistics || !currentUser) {
    return null;
  }

  return (
    <Grid item xs={12} className={classes.root}>
      <Filters additionalParams={additionalParams} setAdditionalParams={setAdditionalParams} />
      {isLabPartner(currentUser) ? (
        <ThirdPartyContent data={data} isLoading={isLoading} />
      ) : (
        <Content data={data} isLoading={isLoading} />
      )}
    </Grid>
  );
}
