import React, { useCallback, useEffect, useRef, useState } from 'react';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Checkbox } from '@material-ui/core';
import { get } from 'lodash';
import createLoadingSelector from '../../selectors/loading';
import createErrorMessageSelector from '../../selectors/error';
import { CREATE_RX_FORM_PREFIX, createRxFormAction } from '../../reducers/rxForms';
import OrganizationInfo from '../../components/OrganizationInfo';
import LoadAvailableLocations from '../../components/LoadAvailableLocations';
import LoadingIndicator from '../../components/LoadingIndicator';
import useOrganizationPermissions from '../../hooks/useOrganizationPermissions';
import OrganizationsApiService from '../../services/api/organizations';
import Form from '../../components/RxForm/Form';
import {
  getDefaultAssignment,
  getDefaultTrayDesignAssignment
} from '../../components/RxForm/helpers';
import LoadingButton from '../../components/LoadingButton';
import createGetItemSelector from '../../selectors/getItem';
import { enqueueNotification } from '../../reducers/notifications';
import { ATTRIBUTES, SCAN_SUBMISSION_METHODS } from '../../components/RxForm/constants';
import hasPermission from '../../selectors/hasPermission';
import { fetchOrganizationUsers } from '../../reducers/organizationUsers';
import withOrganizationPricing from '../../hooks/withOrganizationPricing';
import InfoDialog from '../../components/InfoDialog';
import PatientApiService from '../../services/api/patients';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { fetchFormBuilderAction } from '../../reducers/formBuilder';
import {
  CONTROL_CHECKBOXES,
  CONTROL_DROPDOWN,
  CONTROL_EXISTING,
  CONTROL_FILE,
  CONTROL_RADIOS
} from '../Account/components/RxFormCustomization/FormBuilder/helpers';

const useStyles = makeStyles(() => ({
  form: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& .MuiCardHeader-action': {
      flex: '0.5 0 auto'
    }
  }
}));

const getDoctors = members => {
  return members.map(user => {
    return {
      id: user.id,
      full_name: user.full_name,
      role: user.pivot.role.name
    };
  });
};

const AddRxForm = ({ organizationMembers, match: { params } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const hasMount = useRef(false);
  const organizationId = parseInt(params.organizationId, 10);
  const isManagement = useSelector(state => state.auth.isManagement);
  const { isLoading: isLoadingOrganizationPermissions } = useOrganizationPermissions({
    organizationId
  });
  const organizationFromState = useSelector(state =>
    createGetItemSelector(state.organizations, organizationId)
  );
  const currentUserOrganization = useSelector(state => state.auth.currentOrganization);
  const currentUser = useSelector(state => state.auth.user);

  const [preference, setPreference] = useState();
  const [hiddenFields, setHiddenFields] = useState([]);
  const [thirdPartyPartnerName, setThirdPartyPartnerName] = useState();
  const canSelectDoctor = useSelector(state =>
    hasPermission(state, {
      permissions: [
        'rx-forms.manage',
        'rx-forms.select-doctor',
        'organization.rx-forms.select-doctor'
      ],
      organizationId
    })
  );
  const [doctors, setDoctors] = useState({
    list: [],
    enabled: canSelectDoctor,
    fetching: false
  });
  const [bondingDateInfoShown, setBondingDateInfoShown] = useState(null);
  const [similarPatient, setSimilarPatient] = useState(null);
  const [valuesToSubmit, setValuesToSubmit] = useState(null);
  const [doNotShowBondingDateInfoNote, setDoNotShowBondingDateInfoNote] = useState(false);

  const isCreatingRxForm = useSelector(state =>
    createLoadingSelector([CREATE_RX_FORM_PREFIX])(state)
  );
  const errorOnCreating = useSelector(state =>
    createErrorMessageSelector([CREATE_RX_FORM_PREFIX])(state)
  );
  const [organization, setOrganization] = useState(null);

  const [fieldsToInclude, setFieldsToInclude] = useState([]);
  const [formBuilderFields, setFormBuilderFields] = useState({});
  const formBuilderForm = useSelector(state => state.formBuilder.form);
  const formStructure = useSelector(state => state.formBuilder.layout);
  const newControls = Object.values(get(formBuilderForm, 'controls', {})).filter(
    control => control.system_type !== CONTROL_EXISTING
  );

  useEffect(() => {
    const builderParams = {
      organization_id: organizationId
    };
    dispatch(fetchFormBuilderAction(builderParams));
  }, [organizationId, dispatch]);

  useEffect(() => {
    const navigate = () => {
      if (isManagement) {
        history.push('/patients-page');
      } else {
        history.push('/patients');
      }
    };

    if (
      hasMount.current &&
      !isCreatingRxForm &&
      !errorOnCreating &&
      bondingDateInfoShown === false
    ) {
      navigate();
    } else if (
      hasMount.current &&
      !isCreatingRxForm &&
      !errorOnCreating &&
      bondingDateInfoShown === null
    ) {
      const showBondingDateNote = localStorage.getItem('doNotShowBondingDateInfoNote') === '1';

      if (showBondingDateNote) {
        navigate();
      } else {
        setBondingDateInfoShown(true);
      }
    }
    hasMount.current = true;
  }, [dispatch, isCreatingRxForm, errorOnCreating, history, isManagement, bondingDateInfoShown]);

  useEffect(() => {
    const organizationApiService = new OrganizationsApiService();
    organizationApiService.getRxFormPreference(organizationId).then(({ data, metadata }) => {
      setPreference(data);
      setThirdPartyPartnerName(metadata.third_party_partner.name);
      setHiddenFields(metadata.hidden_fields);
    });
  }, [organizationId]);

  useEffect(() => {
    if (organization && organization.id === organizationId) {
      return () => {};
    }
    if (isManagement && organizationFromState) {
      setOrganization(organizationFromState);
      return () => {};
    }
    if (currentUserOrganization) {
      setOrganization(currentUserOrganization);
      return () => {};
    }

    let setFetchOrganization = data => {
      setOrganization(data);
    };
    const api = new OrganizationsApiService();
    api
      .show(organizationId, ['locations'])
      .then(data => {
        setFetchOrganization(data.organization);
      })
      .catch(() => {
        setOrganization(null);
        dispatch(enqueueNotification('error', 'Failed to fetch organization.'));
      });

    return () => {
      setFetchOrganization = () => {};
    };
  }, [
    organization,
    setOrganization,
    organizationId,
    isManagement,
    organizationFromState,
    currentUserOrganization,
    dispatch
  ]);

  useEffect(() => {
    if (!canSelectDoctor) {
      return;
    }
    if (organization) {
      if (organization.users) {
        setDoctors(current => ({
          ...current,
          list: getDoctors(organization.users)
        }));
        return;
      }

      if (organizationMembers[organization.id]) {
        if (organizationMembers[organization.id].fetching) {
          setDoctors(current => ({
            ...current,
            fetching: true,
            list: []
          }));
        } else if (organizationMembers[organization.id].users) {
          setDoctors(current => ({
            ...current,
            fetching: false,
            list: organizationMembers[organization.id].users
          }));
        }
      } else if (
        !organizationMembers[organization.id] ||
        !organizationMembers[organization.id].fetching
      ) {
        dispatch(fetchOrganizationUsers(organization.id));
      }
    }
  }, [organizationMembers, organization, setDoctors, dispatch, canSelectDoctor]);

  const cleanValues = useCallback(
    values => {
      const controls = {};
      const newVals = { ...values };
      Object.values(get(formBuilderForm, 'controls', {})).forEach(con => {
        controls[con.system_name] = con;
      });

      if (!controls.tray_design_assignment) {
        newVals.tray_design_assignment = '';
      }
      return newVals;
    },
    [formBuilderForm]
  );

  const createRxForm = useCallback(
    values => {
      dispatch(
        createRxFormAction({
          ...cleanValues(values),
          organization_id: organization.id
        })
      );
      return true;
    },
    [dispatch, organization, cleanValues]
  );

  const notifyNameAdjustmentAndCreate = async values => {
    const controls = Object.values(get(formBuilderForm, 'controls', {}));
    const hasFirstName = controls.find(control => control.system_name === 'first_name');
    const hasLastName = controls.find(control => control.system_name === 'last_name');
    // check if first_name and last_name are present in the form controls
    // if yes, check if there is a similar patient
    if (hasFirstName && hasLastName) {
      const patientApiService = new PatientApiService();
      try {
        const response = await patientApiService.checkSimilarPatient({
          first_name: values.first_name,
          last_name: values.last_name,
          case_number_text: values.case_number_text,
          organization_id: organization.id,
          user_id: values.user_id
        });

        if (response.data.has_similar_patient) {
          setSimilarPatient(response.data);
          setValuesToSubmit(values);
        } else {
          createRxForm(values);
        }
      } catch (e) {
        enqueueNotification('error', 'Failed to fetch similar patient data');
      }
    } else {
      createRxForm(values);
    }
  };

  useEffect(() => {
    if (!similarPatient && valuesToSubmit) {
      createRxForm(valuesToSubmit);
      setValuesToSubmit(null);
    }
  }, [similarPatient, valuesToSubmit, createRxForm]);

  useEffect(() => {
    const MULTIPLE_OPTION_TYPES = [CONTROL_DROPDOWN, CONTROL_CHECKBOXES, CONTROL_RADIOS];

    for (const control of newControls) {
      if (formBuilderFields[control.system_name] === undefined) {
        let value = control.system_type === CONTROL_FILE ? [] : '';

        const defaultValueString = get(control, 'props.default', null);
        if (defaultValueString !== null) {
          if (MULTIPLE_OPTION_TYPES.includes(control.system_type)) {
            const option = get(control, 'props.options', []).find(
              opt => opt.name.toLowerCase() === defaultValueString.toLowerCase()
            );

            if (option) value = option.id;
          } else {
            value = defaultValueString;
          }
        }

        setFormBuilderFields(prevState => {
          return {
            ...prevState,
            [control.system_name]: value
          };
        });
      }
    }
  }, [formBuilderFields, formBuilderForm, newControls]);

  useEffect(() => {
    setFormBuilderFields(prevState => {
      return {
        ...prevState,
        form_builder_layout_id: get(formBuilderForm, 'form_builder_layout_id', '')
      };
    });
  }, [formBuilderForm]);

  useEffect(() => {
    const allControls = Object.values(get(formBuilderForm, 'controls', {}));
    setFieldsToInclude(() => allControls.map(control => control.system_name));
  }, [setFieldsToInclude, formBuilderForm]);

  if (!organization || !preference || isLoadingOrganizationPermissions) {
    return <LoadingIndicator />;
  }

  const rxForm = {
    first_name: '',
    last_name: '',
    case_number_text: '',
    intraoral_scan_date: moment().format('YYYY-MM-DD'),
    submission_completed_by: getDefaultAssignment(preference.initial_case_setup_assignment_options),
    rush_case: preference.rush_case,
    rush_case_authorized_by: '',
    scan_submission_type: SCAN_SUBMISSION_METHODS.UPLOAD,
    scanner_type: '1',
    custom_scanner_type_name: '',
    stl_files: [],
    rx_intraoral_photos: [],
    special_instructions: '',
    jaws_id: preference.arch_type,
    export_type: preference.export_type,
    print_assignment: getDefaultAssignment(preference.print_assignment_options),
    tray_design_assignment: getDefaultTrayDesignAssignment(preference),
    insert_brackets_assignment: preference.insert_brackets_assignment,
    location_id: preference.treatment_location_id,
    shipping_location_id: preference.shipping_location_id,
    shipping_method: null, // let the ShippingMethodField take care setting the correct method
    root_integration: false,
    [ATTRIBUTES.BRACKET_LIBRARIES]: [],
    bonding_date: null,
    [ATTRIBUTES.DOCTOR]: canSelectDoctor ? preference.default_doctor_id : null,
    [ATTRIBUTES.DICOM_FILES]: [],
    [ATTRIBUTES.ARCH_WIRES]: [],
    teeth: {
      u00: 0,
      u01: 0,
      u02: 0,
      u03: 0,
      u04: 0,
      u05: 0,
      u06: 0,
      u07: 0,
      u08: 0,
      u09: 0,
      u10: 0,
      u11: 0,
      u12: 0,
      u13: 0,
      u14: 0,
      u15: 0,
      l00: 0,
      l01: 0,
      l02: 0,
      l03: 0,
      l04: 0,
      l05: 0,
      l06: 0,
      l07: 0,
      l08: 0,
      l09: 0,
      l10: 0,
      l11: 0,
      l12: 0,
      l13: 0,
      l14: 0,
      l15: 0
    },
    [ATTRIBUTES.CUSTOM_SETUP]: 0,
    ...formBuilderFields
  };

  if (canSelectDoctor) {
    rxForm[ATTRIBUTES.DOCTOR] = preference.default_doctor_id;
  } else if (currentUser) {
    rxForm[ATTRIBUTES.DOCTOR] = currentUser.id;
  }

  const confirmationDialogHandler = response => {
    if (response) {
      createRxForm(valuesToSubmit);
    }
    setValuesToSubmit(null);
    setSimilarPatient(null);
  };

  const handleDoNotShowBondingDateInfoNote = () => {
    setDoNotShowBondingDateInfoNote(!doNotShowBondingDateInfoNote);

    if (doNotShowBondingDateInfoNote) {
      // remove from local storage
      localStorage.setItem('doNotShowBondingDateInfoNote', 0);
    } else {
      // set to local storage
      localStorage.setItem('doNotShowBondingDateInfoNote', 1);
    }
  };

  const children = pricing => (
    <Card className={classes.form}>
      <LoadAvailableLocations organization={organization}>
        {availableLocations => (
          <Form
            organization={organization}
            title="Start A Case"
            description={<OrganizationInfo organization={organization} />}
            rxForm={rxForm}
            formStructure={formStructure}
            newControls={newControls}
            only={fieldsToInclude}
            onSubmit={notifyNameAdjustmentAndCreate}
            thirdPartyPartnerName={thirdPartyPartnerName}
            preference={preference}
            isLoading={isCreatingRxForm}
            availableLocations={availableLocations}
            doctors={doctors}
            hiddenFields={hiddenFields}
            actions={({ dirty, isValid, submitForm, status }) => {
              return (
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={isCreatingRxForm}
                  disabled={!dirty || !isValid || isCreatingRxForm || !!status}
                  onClick={submitForm}
                >
                  Submit
                </LoadingButton>
              );
            }}
            pricing={pricing}
          />
        )}
      </LoadAvailableLocations>
      <InfoDialog
        open={!!bondingDateInfoShown}
        onClose={() => setBondingDateInfoShown(false)}
        title="Note"
        content={
          <>
            <p>
              For maximum bracket accuracy, cases should be bonded no later than 30 days from this
              submission date.
            </p>
            <p>
              <Checkbox
                checked={doNotShowBondingDateInfoNote}
                onChange={handleDoNotShowBondingDateInfoNote}
              />
              Please don't show this message again
            </p>
          </>
        }
      />
      <ConfirmationDialog
        open={!!similarPatient && similarPatient.has_similar_patient}
        handleClose={confirmationDialogHandler}
        title="Duplicate Patient"
        confirmText="Yes"
        bodyText={`${
          similarPatient && similarPatient.numeric_suffix > 1 ? 'Patients' : 'A patient'
        } with this exact name was already submitted on ${moment(
          similarPatient && similarPatient.created_at
        ).format('DD-MM-YYYY')}. Click YES to proceed, but note a '(${similarPatient &&
          similarPatient.numeric_suffix})' will be placed in the name to differentiate this patient from the first.`}
      />
    </Card>
  );

  return withOrganizationPricing({ organizationId: organization.id, children });
};

AddRxForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  organizationMembers: PropTypes.object.isRequired
};

AddRxForm.defaultProps = {
  match: { params: {} }
};

// export default AddRxForm;

export default connect(state => {
  const organizationMembers = state.organizationUsers.organizations;
  return {
    organizationMembers
  };
})(AddRxForm);
