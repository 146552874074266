import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class PaymentsApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  createFattmerchantCustomer(organizationId) {
    return this.request({
      method: 'post',
      url: `f_m_payment_infos`,
      data: {
        organization_id: organizationId
      }
    });
  }

  createFattmerchantPaymentMethod(id, organizationId) {
    return this.request({
      method: 'post',
      url: `f_m_payment_methods`,
      data: {
        payment_method_id: id,
        organization_id: organizationId
      }
    });
  }

  deleteMyPaymentMethod(id) {
    return this.request({
      method: 'delete',
      url: `f_m_payment_methods/${id}`
    });
  }

  updateMyPaymentMethod(id, data) {
    return this.request({
      method: 'put',
      url: `f_m_payment_methods/${id}`,
      data
    });
  }

  updatePaymentMethodExpirationDate(id, data) {
    return this.request({
      method: 'put',
      url: `f_m_payment_methods/update_exp_date/${id}`,
      data
    });
  }

  calculateInitialOrder(organizationId, promotionCode) {
    return this.request({
      method: 'post',
      url: `/organizations/${organizationId}/calculate_initial_order`,
      data: {
        promo_code: promotionCode
      }
    });
  }

  initialPurchase(promoCode, organizationId) {
    return this.request({
      method: 'post',
      url: `/organizations/${organizationId}/process_initial_purchase`,
      data: {
        promo_code: promoCode
      }
    });
  }
}
