import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { Field } from 'formik';
import { QUESTIONS } from '../constants';
import { ORGANIZATION_ROLE_OPTIONS } from '../../../constants';
import FormikAutocomplete from '../../FormikAutocomplete';

const DoctorSelectionField = ({ name, doctors, fetching }) => {
  const sortedDoctors = [...doctors].sort((a, b) => (a.full_name > b.full_name ? 1 : -1));

  const [items, setItems] = useState([]);

  const getLabel = doctor => {
    return `${doctor.full_name} ${
      ORGANIZATION_ROLE_OPTIONS[doctor.role]
        ? `(${ORGANIZATION_ROLE_OPTIONS[doctor.role].shortLabel})`
        : ''
    }`;
  };

  const handleOnChangeSearchQuery = value => {
    const lowerCaseValue = value.toLowerCase();
    const valueIsSelected = value && !!sortedDoctors.find(doctor => getLabel(doctor) === value);
    if (valueIsSelected) {
      setItems(sortedDoctors.map(doctor => doctor.id));
    } else {
      const newItems = sortedDoctors.filter(
        doctor =>
          doctor.full_name.toLowerCase().indexOf(lowerCaseValue) !== -1 ||
          (ORGANIZATION_ROLE_OPTIONS[doctor.role] &&
            ORGANIZATION_ROLE_OPTIONS[doctor.role].shortLabel
              .toLowerCase()
              .indexOf(lowerCaseValue) !== -1)
      );
      setItems(newItems.map(doctor => doctor.id));
    }
  };

  useEffect(() => {
    if (items.length !== sortedDoctors.length) {
      setItems(sortedDoctors.map(doctor => doctor.id));
    }
  }, [items, sortedDoctors]);

  return (
    <FormControl variant="outlined" fullWidth>
      <Field
        options={items}
        getOptionLabel={option => {
          if (option) {
            const doctor = doctors.find(doc => doc.id === option);
            return doctor ? getLabel(doctor) : '';
          }
          return '';
        }}
        autoComplete
        getOptionSelected={(option, value) => {
          if (!value) {
            return null;
          }
          return option.id === value;
        }}
        disabled={fetching}
        textFieldProps={{
          label: fetching ? 'Loading Doctors' : QUESTIONS[name],
          variant: 'outlined',
          fullWidth: true,
          required: true
        }}
        name={name}
        onInputChange={(event, newInputValue) => handleOnChangeSearchQuery(newInputValue)}
        component={FormikAutocomplete}
      />
    </FormControl>
  );
};

DoctorSelectionField.propTypes = {
  name: PropTypes.string.isRequired,
  doctors: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string.isRequired,
      full_name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    })
  ).isRequired,
  fetching: PropTypes.bool.isRequired
};

export default DoctorSelectionField;
