// eslint-disable-next-line no-unused-vars
import { autoEnableShipmentBundling } from '../components/ShipmentBundling/functions';
import { extractRequestType } from '../../../reducers/patients';

function correctRequestStep(parameters) {
  if (parameters.requestStep) {
    const request = extractRequestType(parameters);
    const [requestFromStep] = parameters.requestStep.split('.');
    if (request !== requestFromStep) {
      // eslint-disable-next-line no-param-reassign
      parameters.requestStep = null;
    }
  }
}

// eslint-disable-next-line import/prefer-default-export
export function refineParameters(parameters, { is3PP = false }) {
  if (!is3PP) {
    autoEnableShipmentBundling(parameters);
  }
  correctRequestStep(parameters);
}
