import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Collapse } from '../../assets/icons/collapse.svg';

const useStyles = makeStyles(() => ({
  icon: {
    '& path': {
      stroke: 'currentColor'
    }
  }
}));

const CollapseIcon = props => {
  const classes = useStyles();

  return (
    <SvgIcon
      {...props}
      className={classes.icon}
      component={Collapse}
      viewBox="0.00 0.00 256.00 256.00"
    />
  );
};

export default CollapseIcon;
