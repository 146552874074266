import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class ArchWiresApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  getBuiltInArchWires() {
    return this.request({
      method: 'get',
      url: `/arch-wires`
    });
  }

  getUserArchWires(organizationId, userId) {
    return this.request({
      method: 'get',
      url: `/organizations/${organizationId}/arch-wires`,
      params: {
        member_id: userId
      }
    });
  }

  getOrganizationArchWires(organizationId) {
    return this.request({
      method: 'get',
      url: `/organizations/${organizationId}/arch-wires/get-uploaded-items`
    });
  }

  getOrganizationMembersAssignableForArchWires(organizationId) {
    return this.request({
      method: 'get',
      url: `/organizations/${organizationId}/arch-wires/assignable-members`
    });
  }

  getOrganizationArchWireUploadUrl(organizationId, filename) {
    return this.request({
      method: 'post',
      url: `/organizations/${organizationId}/arch-wires/request-upload-url`,
      data: { file_name: filename }
    });
  }

  uploadOrganizationArchWire(organizationId, data) {
    return this.request({
      method: 'post',
      url: `/organizations/${organizationId}/arch-wires`,
      data
    });
  }

  updateOrganizationArchWire(organizationId, bracketLibraryId, data) {
    return this.request({
      method: 'put',
      url: `/organizations/${organizationId}/arch-wires/${bracketLibraryId}`,
      data
    });
  }

  deleteOrganizationArchWire(organizationId, itemId) {
    return this.request({
      method: 'delete',
      url: `/organizations/${organizationId}/arch-wires/${itemId}`
    });
  }

  getOrganizationArchWireDownloadUrl(organizationId, itemId) {
    return this.request({
      method: 'get',
      url: `/organizations/${organizationId}/arch-wires/${itemId}/request-download-url`
    });
  }
}
