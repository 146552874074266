import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { get } from 'lodash';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import {
  INITIAL_SETUP_ASSIGNED_TO,
  INITIAL_SETUP_ASSIGNED_TO_ID,
  RX_FORM_STATUSES_ID
} from '../../../constants';
import Can from '../../../components/Can';
import SimpleMenu from '../../../components/SimpleMenu';
import PatientWorkflowContext from '../context/PatientWorkflowContext';
import {
  ASSIGN_RX_FORM_PATIENT_PREFIX,
  assignRxFormPatientAction
} from '../../../reducers/patients';
import createLoadingSelector from '../../../selectors/loading';
import { isTechnicianOrAdmin } from '../../../helpers';

const InitialSetupAssignment = ({ rxForm }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.user);
  const isAssigningRxForm = useSelector(state =>
    createLoadingSelector([ASSIGN_RX_FORM_PATIENT_PREFIX])(state)
  );
  const patientWorkflowContext = useContext(PatientWorkflowContext);

  const defaultAssignmentOptions = [
    { label: 'Assign To OrthoSelect', id: 1 },
    { label: 'Assign to 3rd Party Partner', id: 3 }
  ];

  const availableAssignmentOptions = defaultAssignmentOptions.filter(
    option => option.id !== rxForm.submission_completed_by
  );

  const organizationManager = get(
    patientWorkflowContext,
    'patient.organization.organization_manager'
  );

  const organizationId = get(patientWorkflowContext, 'patient.organization_id');

  const isCurrentUserTheOrganizationManager = get(organizationManager, 'id') === currentUser.id;

  const isAssignedToCurrentUser =
    (isCurrentUserTheOrganizationManager &&
      rxForm.submission_completed_by === INITIAL_SETUP_ASSIGNED_TO_ID.thirdParty) ||
    (isTechnicianOrAdmin(currentUser) &&
      rxForm.submission_completed_by === INITIAL_SETUP_ASSIGNED_TO_ID.orthoselect);

  const onAssignOptionIsSelected = option => {
    dispatch(assignRxFormPatientAction(rxForm.patient_id, rxForm.id, option.id));
  };

  const isNewInitialSetup = rxForm.status_id === RX_FORM_STATUSES_ID.new;

  const hideReassignButton =
    !isNewInitialSetup ||
    !organizationManager ||
    (isCurrentUserTheOrganizationManager && !isAssignedToCurrentUser);

  return (
    <>
      {`Assigned To: ${INITIAL_SETUP_ASSIGNED_TO[rxForm.submission_completed_by || 1].name}`}
      {!hideReassignButton && (
        <Can
          permissions={['rx-forms.assign', 'rx-forms.manage', 'organization.rx-forms.assign']}
          organizationId={organizationId}
          yes={() => {
            return (
              <Box mx={2} component="span">
                <SimpleMenu
                  buttonTitle="Change"
                  onOptionSelected={onAssignOptionIsSelected}
                  isLoading={isAssigningRxForm}
                  options={availableAssignmentOptions}
                />
              </Box>
            );
          }}
        />
      )}
    </>
  );
};

InitialSetupAssignment.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rxForm: PropTypes.object.isRequired
};

InitialSetupAssignment.defaultProps = {};

export default InitialSetupAssignment;
