import React from 'react';
import {
  FormControlLabel,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch
} from '@material-ui/core';
import List from '@material-ui/core/List';

const Webhooks = ({ enabled, setEnable }) => {
  const handleChange = event => {
    setEnable(event.target.checked);
  };

  return (
    <List>
      <ListItem>
        <ListItemText primary="Enable" secondary="Turn on/off webhook events." />
        <ListItemSecondaryAction>
          <FormControlLabel
            edge="end"
            control={<Switch color="primary" />}
            onChange={handleChange}
            labelPlacement="start"
            checked={enabled}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
};

Webhooks.propTypes = {};

export default Webhooks;
