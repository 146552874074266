import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import RadioGroupField from './RadioGroupField';
import withDependency from './hooks/withDependency';
import { REMOVED_SCANNERS, SCANNER_OPTIONS, SCANNERS } from '../constants';
import TextField from './Formik/TextField';

const ScannerField = ({ currentValue, setValue, ...props }) => {
  useEffect(() => {
    if (!currentValue) {
      setValue(SCANNERS.ITERO);
    }
  }, [currentValue, setValue]);
  const availableOptions = SCANNER_OPTIONS.filter(scanner => {
    // eslint-disable-next-line radix
    return scanner.value === currentValue || !REMOVED_SCANNERS.includes(parseInt(scanner.value));
  });
  const radioProps = { ...props, options: availableOptions };
  return (
    <Grid container>
      <Grid item xs={12}>
        <RadioGroupField {...radioProps} />
      </Grid>
      {currentValue === 7 && (
        <Grid item xs={12}>
          <Field
            name="custom_scanner_type_name"
            variant="outlined"
            fullWidth
            id="custom_scanner_type_name"
            label="Scanner Name"
            component={TextField}
          />
        </Grid>
      )}
    </Grid>
  );
};

ScannerField.defaultProps = {
  currentValue: null
};

ScannerField.propTypes = {
  currentValue: PropTypes.number,
  setValue: PropTypes.func.isRequired
};

export default withDependency(ScannerField);
