import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CardActions, CardContent, Divider } from '@material-ui/core';
import LoadingButton from '../../../components/LoadingButton';
import { updateInstallerAction } from '../../../reducers/installers';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  cardActions: {
    justifyContent: 'flex-end'
  }
}));

const EditReleaseNotesForm = ({ installer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialValues = {
    release_notes: installer.release_notes || ''
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={Yup.object().shape({
        release_notes: Yup.string().required('Required')
      })}
      onSubmit={values => {
        const releaseNotes = values.release_notes.trim().replace(/(?:\r\n|\r|\n)/g, '<br>');

        dispatch(updateInstallerAction(installer.id, { release_notes: releaseNotes }));
      }}
    >
      {({ values, isValid, dirty }) => (
        <Box width="100%">
          <Divider />
          <Form className={classes.form} noValidate>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Field
                    variant="outlined"
                    id={`installer_${installer.id}_release_notes`}
                    required
                    fullWidth
                    label="Release Notes"
                    name="release_notes"
                    value={values.release_notes}
                    multiline
                    minRows={8}
                    maxRows={8}
                    component={TextField}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions className={classes.cardActions}>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={false}
                disabled={!dirty || !isValid}
                className={classes.submit}
              >
                Save
              </LoadingButton>
            </CardActions>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

EditReleaseNotesForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  installer: PropTypes.object.isRequired
};

export default EditReleaseNotesForm;
