import React from 'react';
import PropTypes from 'prop-types';
import SubSectionWrapper from '../../Account/components/SubSectionWrapper';
import InstallersManagement from '../../Settings/Installer/InstallersManagement';

const OrganizationInstallersManagement = ({ organizationId }) => {
  return (
    <SubSectionWrapper title="Installers Management">
      <InstallersManagement organizationId={organizationId} />
    </SubSectionWrapper>
  );
};

OrganizationInstallersManagement.propTypes = {
  organizationId: PropTypes.number.isRequired
};

export default OrganizationInstallersManagement;
