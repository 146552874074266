import { extractRequestType } from '../../../../reducers/patients';
import { REQUEST, REQUEST_STATUS } from '../../../../constants';
import { ASSIGNMENTS } from '../../../../components/RxForm/constants';
import { SORT_VALUE } from './constants';

function isApplicable(parameters) {
  const isPrint = extractRequestType(parameters) === REQUEST.PRINT;
  const { requestAssignments, shipping, requestTypeStatus, trackingNumber } = parameters;
  const isAssignedToOrthoSelectOnly =
    requestAssignments.length === 1 && requestAssignments[0] === ASSIGNMENTS.ORTHOSELECT;
  const isCompletedOnly =
    requestTypeStatus.length === 1 &&
    requestTypeStatus[0] === `${REQUEST.PRINT}.${REQUEST_STATUS.COMPLETED}`;

  return isPrint && isAssignedToOrthoSelectOnly && (shipping || isCompletedOnly || trackingNumber);
}

export function autoEnableShipmentBundling(parameters) {
  // enabled
  if (isApplicable(parameters)) {
    // eslint-disable-next-line no-param-reassign
    parameters.orderBy = SORT_VALUE;
  }

  // disable
  if (!isApplicable(parameters) && parameters.orderBy === SORT_VALUE) {
    // eslint-disable-next-line no-param-reassign
    parameters.orderBy = 'ship_date_sort';
    // eslint-disable-next-line no-param-reassign
    parameters.order = 'asc';
  }
}

export const isShipmentBundlingEnabled = params => {
  return params.orderBy === SORT_VALUE;
};
