import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Can from '../../../components/Can';
import LoadingButton from '../../../components/LoadingButton';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import ActionOwnerExtraInfo from './ActionOwnerExtraInfo';
import InfoDialog from '../../../components/InfoDialog';

const PatientDeleteAction = ({ patient, isLoading, isDeleting, onDelete }) => {
  const isManagement = useSelector(state => state.auth.isManagement);
  const [extraDataRequesting, setExtraDataRequesting] = useState(false);
  const [infoDialog, setInfoDialog] = useState(false);

  const { allow_delete: { allow = true, message = '' } = {} } = useSelector(state => {
    return state.patients.metadata[patient.id || -1] || {};
  });

  const deletePatient = extraInfo => {
    onDelete({
      deleted_by_name: extraInfo.action_owner_name,
      deleted_reason: extraInfo.reason
    });
  };

  const confirmDelete = () => {
    if (patient.rx_form && patient.rx_form.completed_at && !isManagement) {
      setInfoDialog(true);
    } else {
      setExtraDataRequesting(true);
    }
  };

  if (patient && patient.deleted_at) {
    return null;
  }

  return (
    <>
      <Can
        permissions={['patients.manage', 'patients.delete', 'organization.patients.delete']}
        yes={() => (
          <ConfirmationDialogOnClickWrapper
            confirmationBody="Are you sure you want to delete the current patient?"
            confirmationTitle="Delete Patient"
          >
            <LoadingButton
              loading={isDeleting}
              color="secondary"
              variant="contained"
              disabled={isLoading || isDeleting || !allow}
              title={message}
              style={{
                pointerEvents: 'auto'
              }}
              onClick={confirmDelete}
            >
              {patient.deleted_at ? 'Permanently Delete' : 'Delete'}
            </LoadingButton>
          </ConfirmationDialogOnClickWrapper>
        )}
      />
      <ActionOwnerExtraInfo
        open={extraDataRequesting}
        setOpen={open => setExtraDataRequesting(open)}
        onAddedExtraInfo={deletePatient}
        title="Delete Patient"
        hasNotifyField={false}
        infoMessage="Please Note: Deleting this case does not remove fees if the work has been completed."
      />
      <InfoDialog
        title="Delete Patient"
        content="Patient has completed initial setup. Please call and request a cancellation for cases after setup."
        open={infoDialog}
        onClose={setInfoDialog}
      />
    </>
  );
};

PatientDeleteAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default PatientDeleteAction;
