import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import formBuilderApiService from '../services/api/formBuilder';
import { get } from 'lodash';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_FORM_BUILDER_PREFIX = 'FETCH_FORM_BUILDER';
export const FETCH_FORM_BUILDER_REQUEST_ACTION = `${FETCH_FORM_BUILDER_PREFIX}_REQUEST_ACTION`;
export const FETCH_FORM_BUILDER_SUCCESS_ACTION = `${FETCH_FORM_BUILDER_PREFIX}_SUCCESS_ACTION`;
export const FETCH_FORM_BUILDER_FAILURE_ACTION = `${FETCH_FORM_BUILDER_PREFIX}_FAILURE_ACTION`;

export const CREATE_FORM_BUILDER_PREFIX = 'CREATE_FORM_BUILDER';
export const CREATE_FORM_BUILDER_REQUEST_ACTION = `${CREATE_FORM_BUILDER_PREFIX}_REQUEST_ACTION`;
export const CREATE_FORM_BUILDER_SUCCESS_ACTION = `${CREATE_FORM_BUILDER_PREFIX}_SUCCESS_ACTION`;
export const CREATE_FORM_BUILDER_FAILURE_ACTION = `${CREATE_FORM_BUILDER_PREFIX}_FAILURE_ACTION`;

export const UPDATE_FORM_BUILDER_PREFIX = 'UPDATE_FORM_BUILDER';
export const UPDATE_FORM_BUILDER_REQUEST_ACTION = `${UPDATE_FORM_BUILDER_PREFIX}_REQUEST_ACTION`;
export const UPDATE_FORM_BUILDER_SUCCESS_ACTION = `${UPDATE_FORM_BUILDER_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_FORM_BUILDER_FAILURE_ACTION = `${UPDATE_FORM_BUILDER_PREFIX}_FAILURE_ACTION`;

export const RESET_FORM_BUILDER = 'RESET_FORM_BUILDER';

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  form: null,
  layout: [],
  defaultControls: [],
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FORM_BUILDER_SUCCESS_ACTION:
    case CREATE_FORM_BUILDER_SUCCESS_ACTION:
    case UPDATE_FORM_BUILDER_SUCCESS_ACTION:
        return produce(state, draft => {
          draft.form = get(action, 'payload.form', state.form);

          const controls = {};
          draft.form.controls.forEach(control => {
            controls[control.id] = control;
          });

          draft.form.controls = controls;

          draft.layout = get(action, 'payload.layout', state.layout);
          draft.defaultControls = get(action, 'payload.default_controls', state.defaultControls);
        });

    case RESET_FORM_BUILDER:
      return initialState;

    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchFormBuilderAction(params) {
  return dispatch => {
    dispatch({ type: FETCH_FORM_BUILDER_REQUEST_ACTION });

    const formBuilder = new formBuilderApiService();

    return formBuilder
      .getForm(params)
      .then(response => {
        dispatch({ type: FETCH_FORM_BUILDER_SUCCESS_ACTION, payload: {...response.data} });
      })
      .catch(error => {
        dispatch({ type: FETCH_FORM_BUILDER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function createFormBuilderAction(params) {
  return dispatch => {
    dispatch({ type: CREATE_FORM_BUILDER_REQUEST_ACTION });

    const formBuilder = new formBuilderApiService();

    return formBuilder
      .create(params)
      .then(response => {
        dispatch({ type: CREATE_FORM_BUILDER_SUCCESS_ACTION, payload: {...response.data}});

        dispatch(enqueueNotification('success', response.message));
        return response.form;
      })
      .catch(error => {
        dispatch({ type: CREATE_FORM_BUILDER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateFormBuilderAction(id, params) {
  return dispatch => {
    dispatch({ type: UPDATE_FORM_BUILDER_REQUEST_ACTION });

    const formBuilder = new formBuilderApiService();

    return formBuilder
      .update(id, params)
      .then(response => {
        dispatch({ type: UPDATE_FORM_BUILDER_SUCCESS_ACTION, payload: {...response.data} });
        dispatch(enqueueNotification('success', response.message));
        return response.form;
      })
      .catch(error => {
        dispatch({ type: UPDATE_FORM_BUILDER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}