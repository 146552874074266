import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputAdornment, TextField } from '@material-ui/core';
import { groupedInputs } from './fields';
import Section from './Section';
import FieldWrapper from './FieldWrapper';

const CommonField = ({ label, inputProps, adornment, error, fieldWrapperProps, ...props }) => {
  const fieldProps = {
    error: !!error,
    helperText: error,
    ...props
  };

  return (
    <FieldWrapper label={label} {...fieldWrapperProps}>
      <FormControl fullWidth>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          required
          InputProps={{
            min: 0,
            step: 1,
            endAdornment: <InputAdornment position="end">{adornment}</InputAdornment>,
            ...inputProps
          }}
          {...fieldProps}
        />
      </FormControl>
    </FieldWrapper>
  );
};

CommonField.defaultProps = {
  fieldWrapperProps: {},
  adornment: '$',
  inputProps: {},
  error: null
};

CommonField.propTypes = {
  label: PropTypes.string.isRequired,
  adornment: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  fieldWrapperProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  error: PropTypes.string
};

const useStyles = makeStyles(theme => ({
  customizedField: {
    '& fieldset': {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.1
    }
  }
}));

const InputGroup = ({
  values,
  defaultValues,
  name,
  setFieldValue,
  exclude,
  label,
  fieldWrapperProps,
  errors,
  ...props
}) => {
  const classes = useStyles();
  const group = groupedInputs[name];
  return (
    <Section title={label} {...props}>
      {Object.keys(group).map(key => {
        if (exclude.indexOf(key) !== -1) {
          return '';
        }

        const { name: originalName, label: fieldLabel, adornment, disableFn } = group[key];
        const fieldName = originalName || `${name}.${key}`;
        const value = get(values, fieldName);
        const defaultValue = get(defaultValues, fieldName);
        const className =
          defaultValue !== undefined && defaultValue !== value ? classes.customizedField : '';

        const fieldProps = {
          name: fieldName,
          onChange: e => {
            setFieldValue(fieldName, Number(e.currentTarget.value));
          },
          className,
          value,
          label: fieldLabel,
          adornment,
          error: errors && get(errors, fieldName),
          disabled: disableFn && disableFn(values)
        };
        if (fieldProps.disabled) {
          fieldProps.value = 0;
        }

        return (
          <CommonField
            key={key}
            {...fieldProps}
            fieldWrapperProps={{ key, ...fieldWrapperProps }}
          />
        );
      })}
    </Section>
  );
};

InputGroup.defaultProps = {
  fieldWrapperProps: {},
  exclude: [],
  defaultValues: {},
  errors: null,
  label: ''
};

InputGroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValues: PropTypes.object,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  exclude: PropTypes.array,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  fieldWrapperProps: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object
};

export default InputGroup;
