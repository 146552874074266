import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core';
import React from 'react';

const EditValidations = ({ system_type, form, setForm }) => {
    const validations = [
        'required:Required',
    ];

    const onChange = (e) => {
        const newForm = { ...form };
        newForm.rules[e.target.name] = e.target.checked;
        setForm(curr => newForm);
    }

    return (
        <FormControl variant="standard">
            <FormLabel>Select Which Rules Apply:</FormLabel>
            <FormGroup>
                { validations.map((validation, index) => {
                    const [id, label] = validation.split(':');

                    return <FormControlLabel
                        key={index}
                        control={ <Checkbox checked={form?.rules?.[id]} color="primary" onChange={onChange} name={ id } /> }
                        label={ label }
                        color="primary"
                    />
                })
                }
            </FormGroup>
        </FormControl>
    );
};

export default EditValidations;