import React, { useEffect, useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  accordionSummary: {
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0
    },
    '& .MuiAccordionSummary-content': {
      margin: 0
    },
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  accordionDetail: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  actionWrapper: {
    marginBottom: theme.spacing(-3)
  }
}));

/**
 * Todo:
 * - Proptypes
 * - Style header title
 */

const Wrapper = ({ title, subTitle, children, ...props }) => {
  const location = useLocation();
  let defaultExpanded = false;
  const ref = useRef();
  const history = useHistory();

  if (location.hash) {
    const section = location.hash.slice(1);
    if (title.replace(' ', '-').toLowerCase() === section) {
      defaultExpanded = true;
    }
  }
  const [opened, setOpened] = useState(defaultExpanded);

  useEffect(() => {
    if (defaultExpanded && location.hash) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
      history.replace(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultExpanded]);

  const changeHandler = (event, isExpanded) => {
    if (!opened && isExpanded) {
      setOpened(true);
    }
  };

  const classes = useStyles();
  return (
    <Accordion ref={ref} defaultExpanded={defaultExpanded} onChange={changeHandler} {...props}>
      <AccordionSummary
        variant="outlined"
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon fontSize="large" />}
      >
        <CardHeader title={title} subheader={subTitle} />
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetail}>{opened && children}</AccordionDetails>
    </Accordion>
  );
};

Wrapper.defaultProps = {
  subTitle: null
};

Wrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export const SubSectionActionWrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.actionWrapper}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};
SubSectionActionWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default Wrapper;
