import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Select, TextField } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '../LoadingButton';
import { fetchSignUpQuestionAction } from '../../reducers/questions';
import CountryList from '../CountryList';
import PhoneNumber from '../PhoneNumber';
import ValidationSchema from './ValidationSchema';
import InitialValuesFactory from './InitialValuesFactory';
import { updateOrganizationAccountInfo } from '../../reducers/organizations';
import LoadingIndicator from '../LoadingIndicator';
import { replaceNullToEmpty } from '../../helpers';
import { fetchGlobalSettingsAction } from '../../reducers/settings';
import NameField from '../Formik/NameField';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 1)
  }
}));

function OrganizationInformation({ handleNext, onSuccess }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const surveyResponse = useSelector(state => state.auth.user.survey_response);
  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const mainLocation = useSelector(state => state.auth.currentOrganization.main_location);
  const signUpQuestion = useSelector(state => state.questions.signUpQuestion);
  const showSignUpQuestion = useSelector(state => state.questions.hasQuestion);

  const signUpQuestionLabel = useRef(null);

  const [signUpQuestionLabelWidth, setSignUpQuestionLabelWidth] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);

  const submitForm = async values => {
    setIsSubmitting(true);

    const data = {
      ...values,
      userId: user.id
    };

    dispatch(updateOrganizationAccountInfo(currentOrganization.id, data))
      .then(() => dispatch(fetchGlobalSettingsAction()).then(() => setSubmitStatus(true)))
      .catch(() => {
        setSubmitStatus(false);
        setIsSubmitting(false);
      });

    return true;
  };

  useEffect(() => {
    if (signUpQuestionLabel.current && showSignUpQuestion) {
      setSignUpQuestionLabelWidth(signUpQuestionLabel.current.offsetWidth);
    }
  }, [signUpQuestion, showSignUpQuestion]);

  useEffect(() => {
    if (!signUpQuestion && showSignUpQuestion) {
      dispatch(fetchSignUpQuestionAction());
    }
  }, [dispatch, signUpQuestion, showSignUpQuestion]);

  useEffect(() => {
    if (submitStatus) {
      setIsSubmitting(false);
      onSuccess(submitStatus);
      handleNext();
    }
  }, [onSuccess, handleNext, submitStatus]);

  if (!signUpQuestion && showSignUpQuestion) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <small>* Required fields</small>

      <Formik
        enableReinitialize
        validateOnMount
        validationSchema={ValidationSchema(showSignUpQuestion)}
        onSubmit={submitForm}
        initialValues={InitialValuesFactory(
          replaceNullToEmpty(user),
          replaceNullToEmpty(currentOrganization),
          replaceNullToEmpty(signUpQuestion),
          replaceNullToEmpty(mainLocation),
          replaceNullToEmpty(surveyResponse)
        )}
      >
        {({ isValid, values }) => (
          <Form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  fullWidth
                  id="organization"
                  required
                  label="Organization / Practice Name"
                  name="organization"
                  autoComplete="organization"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  disabled
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  autoComplete="off"
                  name="first_name"
                  variant="outlined"
                  required
                  fullWidth
                  id="first_name"
                  label="First Name"
                  component={NameField}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  name="last_name"
                  component={NameField}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  required
                  id="country"
                  label="Country"
                  name="country"
                  component={CountryList}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="phone_number"
                  label="Phone Number"
                  name="phone_number"
                  component={PhoneNumber}
                  selectedCountry={values.country}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  name="city"
                  variant="outlined"
                  required
                  fullWidth
                  id="city"
                  label="City"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="state"
                  label="State / Province"
                  name="state"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="zip_code"
                  label="Postal / Zip Code"
                  name="zip_code"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  name="street_address"
                  label="Street Address"
                  id="street_address"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  fullWidth
                  name="street_address_line_2"
                  label="Street Address Line 2"
                  id="street_address_line_2"
                  component={TextField}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                {signUpQuestion && showSignUpQuestion && (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel ref={signUpQuestionLabel}>{signUpQuestion.question} *</InputLabel>
                    <Field
                      required
                      name="survey_answer_id"
                      component={Select}
                      labelWidth={signUpQuestionLabelWidth}
                    >
                      {signUpQuestion.survey_answers.map(answer => {
                        return (
                          <MenuItem key={answer.id} value={answer.id}>
                            {answer.answer}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                {values.survey_answer_id === 7 && showSignUpQuestion && (
                  <Field
                    variant="outlined"
                    required
                    fullWidth
                    id="survey_response_other"
                    label="Custom Source"
                    name="survey_response_other"
                    component={TextField}
                  />
                )}
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end" alignItems="center">
              {!submitStatus && (
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  disabled={isSubmitting || !isValid}
                  className={classes.submit}
                >
                  Save
                </LoadingButton>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

OrganizationInformation.propTypes = {
  handleNext: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default OrganizationInformation;
