import { produce } from 'immer';
import { ASSIGNMENTS, ASSIGNMENT_LABELS, ATTRIBUTES, EXPORTS } from './constants';
import tags from '../../constants/tags';

const { ORTHOSELECT, THIRD_PARTY } = ASSIGNMENTS;

export function assignmentOptions({
  assignments = Object.values(ASSIGNMENTS),
  thirdPartyName = 'Organization'
}) {
  return assignments.map(value => {
    const label = ASSIGNMENT_LABELS[value];
    return { value, label: value === THIRD_PARTY ? `${thirdPartyName}` : label };
  });
}

export function getDefaultAssignment(options) {
  return options.indexOf(ORTHOSELECT) !== -1 ? ORTHOSELECT : THIRD_PARTY;
}

export function getDefaultTrayDesignAssignment(preference) {
  const value =
    Array.isArray(preference.tray_design_assignment_options) &&
    preference.tray_design_assignment_options.length > 0
      ? preference.tray_design_assignment_options[
          preference.tray_design_assignment_options.length - 1
        ].toString()
      : null;

  if (
    preference.export_type !== EXPORTS.TRAYS ||
    getDefaultAssignment(preference.print_assignment_options) !== ASSIGNMENTS.IN_OFFICE
  ) {
    return null;
  }
  return value;
}

export function getDefaultPreferenceTrayDesignAssignment(preference) {
  return Array.isArray(preference.tray_design_assignment_options) &&
    preference.tray_design_assignment_options.length > 0
    ? preference.tray_design_assignment_options[
        preference.tray_design_assignment_options.length - 1
      ].toString()
    : null;
}

const attributeTypes = {
  [ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT]: Number,
  [ATTRIBUTES.RUSH_CASE]: Boolean,
  [ATTRIBUTES.SCAN_SUBMISSION_METHOD]: Number,
  [ATTRIBUTES.SCANNER]: Number,
  [ATTRIBUTES.ARCH_TYPE]: Number,
  [ATTRIBUTES.EXPORT_TYPE]: Number,
  [ATTRIBUTES.PRINT_ASSIGNMENT]: Number,
  [ATTRIBUTES.TRAY_DESIGN_ASSIGNMENT]: Number,
  [ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT]: Number,
  [ATTRIBUTES.ROOT_INTEGRATION]: Boolean,
  [ATTRIBUTES.CUSTOM_SETUP]: Boolean
};

export function encodeFormikValues(values) {
  return Object.fromEntries(
    Object.entries(values).map(([attr, value]) => {
      let encodeValue = value;
      if (attributeTypes[attr]) {
        if (attributeTypes[attr] === Boolean) {
          encodeValue = Number(value);
        }
        encodeValue = value === null ? null : String(encodeValue);
      }
      return [attr, encodeValue];
    })
  );
}

export function decodeFormikValues(encodedValues) {
  return Object.fromEntries(
    Object.entries(encodedValues).map(([attr, value]) => {
      let decodedValue = value;
      if (decodedValue !== null && attributeTypes[attr]) {
        if (decodedValue === '' && attributeTypes[attr] === Number) {
          decodedValue = null;
        } else {
          decodedValue = Number(decodedValue);
        }

        if (attributeTypes[attr] === Boolean) {
          decodedValue = Boolean(decodedValue);
        }
      }
      return [attr, decodedValue];
    })
  );
}

export const overridePreferenceWithModificationRules = (preference, modificationRules) => {
  const override = {};
  if (modificationRules.submission_completed_by) {
    override.initial_case_setup_assignment_options =
      modificationRules.submission_completed_by.allowed_values;
  }

  if (modificationRules.print_assignment) {
    override.print_assignment_options = modificationRules.print_assignment.allowed_values;
  }
  return { ...preference, ...override };
};

export const validateDicomUpload = async file => {
  const minSizeBytes = 2465792;

  if (file.size < minSizeBytes) {
    return 'The zip file should be larger than 2MB.';
  }

  return null;
};

const autoDetectStlType = (file, files) => {
  const manPattern = /^.*(mand.*|mandibular.*|mandible.*|lower.*|[_\-\s]l)\.stl$/i;

  if (manPattern.test(file.name)) {
    return tags.STL_MANDIBULAR;
  }

  const maxPattern = /^.*(max.*|maxillary.*|maxilla.*|upper.*|[_\-\s]u)\.stl$/i;

  if (maxPattern.test(file.name)) {
    return tags.STL_MAXILLARY;
  }

  return files.some(f => f.tag === tags.STL_MANDIBULAR) ? tags.STL_MAXILLARY : tags.STL_MANDIBULAR;
};

export const autoDetectStlTypes = files => {
  return produce(files || [], draft => {
    // set initial stl type
    let firstDetection = '';
    for (let i = 0; i < draft.length; i += 1) {
      // set only first 2
      if (i > 1) {
        draft[i].tag = null;
        // eslint-disable-next-line no-continue
        continue;
      }
      if (!firstDetection && draft[i].tag) {
        firstDetection = draft[i].tag;
      }

      if (!draft[i].tag) {
        if (firstDetection) {
          draft[i].tag =
            firstDetection === tags.STL_MAXILLARY ? tags.STL_MANDIBULAR : tags.STL_MAXILLARY;
        } else {
          firstDetection = draft[i].tag = autoDetectStlType(draft[i], draft);
        }
      }
    }
  });
};
