import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import PropTypes from 'prop-types';

import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';

const useStyles = makeStyles(theme => ({
  toothContainer: {
    width: 'auto',
    height: '60px',
    [theme.breakpoints.down('md')]: {
      height: '50px',
      fontSize: '0.8rem'
    },
    [theme.breakpoints.down('sm')]: {
      height: '40px',
      fontSize: '0.6rem'
    },
    position: 'relative',
    textAlign: 'center',
    '& > span': {
      position: 'absolute'
    }
  },
  tooth: {
    height: '100%'
  },
  toothBlue: {
    '& path': {
      fill: blue[800]
    }
  },
  toothGreen: {
    '& path': {
      fill: green[800]
    }
  },
  toothContainerBlue: {
    color: '#fff'
  },
  toothContainerGreen: {
    color: '#fff'
  }
}));

// eslint-disable-next-line react/prop-types
const Tooth = ({ image, onChange, value, disabled, label }) => {
  const Image = image;
  const classes = useStyles();

  const [mode, setMode] = useState(value);

  const modeToothClass = useMemo(() => {
    switch (mode) {
      case 1:
        return classes.toothBlue;
      case 2:
        return classes.toothGreen;
      default:
        return '';
    }
  }, [mode, classes]);

  const modeToothContainerClass = useMemo(() => {
    switch (mode) {
      case 1:
        return classes.toothContainerBlue;
      case 2:
        return classes.toothContainerGreen;
      default:
        return '';
    }
  }, [mode, classes]);

  const handleOnClick = e => {
    e.preventDefault();
    setMode(prev => {
      let m = +prev + 1;

      if (m > 2) {
        m = 0;
      }

      onChange(m);

      return m;
    });
  };

  return (
    <ButtonBase
      disabled={disabled}
      className={`${classes.toothContainer} ${modeToothContainerClass}`}
      onClick={handleOnClick}
    >
      <Image className={`${classes.tooth} ${modeToothClass}`} />
      {label && <span>{label}</span>}
    </ButtonBase>
  );
};

Tooth.propTypes = {
  value: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string
};

Tooth.defaultProps = {
  value: 0,
  onChange: () => {},
  disabled: false,
  label: ''
};

export default Tooth;
