import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CardActions, CardContent } from '@material-ui/core';
import { replaceNullToEmpty } from '../../helpers';
import LoadingButton from '../LoadingButton';
import SubSectionWrapper from '../../pages/Account/components/SubSectionWrapper';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  actionButton: {
    marginLeft: 'auto'
  }
}));

const EasyRxSetup = ({
  onSubmit,
  isSaving,
  organization,
  mode,
  ContentWrapper,
  ActionsWrapper
}) => {
  const classes = useStyles();

  const validationSchema = {
    easy_rx_practice_id: Yup.number()
  };

  return (
    <SubSectionWrapper title="EasyRx Setup">
      <Formik
        initialValues={replaceNullToEmpty(organization)}
        enableReinitialize
        validationSchema={Yup.object().shape(validationSchema)}
        onSubmit={values => {
          return onSubmit(values);
        }}
      >
        {({ isValid, dirty }) => (
          <Form className={classes.form} noValidate>
            <ContentWrapper>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field
                    variant="outlined"
                    fullWidth
                    id="easy_rx_practice_id"
                    label="Practice ID"
                    name="easy_rx_practice_id"
                    disabled={mode === 'show'}
                    component={TextField}
                  />
                </Grid>
              </Grid>
            </ContentWrapper>
            {mode !== 'show' && (
              <ActionsWrapper>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={isSaving}
                  disabled={!dirty || !isValid || isSaving}
                  className={classes.actionButton}
                >
                  Save
                </LoadingButton>
              </ActionsWrapper>
            )}
          </Form>
        )}
      </Formik>
    </SubSectionWrapper>
  );
};

EasyRxSetup.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object.isRequired,
  mode: PropTypes.string,
  ContentWrapper: PropTypes.elementType,
  ActionsWrapper: PropTypes.elementType
};

EasyRxSetup.defaultProps = {
  mode: '',
  ContentWrapper: CardContent,
  ActionsWrapper: CardActions
};

export default EasyRxSetup;
