export default {
  lab_fees: {
    label: 'Lab Fees',
    filter: 'lab_fees'
  },
  flat_monthly: {
    label: 'Flat Monthly Fee',
    filter: 'flat_monthly'
  },
  arch_volume_discounts: {
    label: 'Arch Volume Discounts',
    filter: 'arch_volume_discounts'
  },
  shipping_fees: {
    label: 'Shipping Fees',
    filter: 'shipping_fees'
  },
  delay_case_charging: {
    label: 'Delay Case Charging',
    filter: 'delay_case_charging'
  },
  delay_payment_processing: {
    label: 'Delay Payment Processing',
    filter: 'delay_payment_processing'
  }
};
