import React, { useCallback, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Button } from '@material-ui/core';
import { produce } from 'immer';
import DataTable from '../../components/DataTable';
import SearchInput from '../../components/SearchInput';
import createLoadingSelector from '../../selectors/loading';
import { formatCurrency } from '../../helpers';
import StatusTag from '../../components/StatusTag';
import {
  FETCH_PROMOTION_CODES_PREFIX,
  fetchPromotionCodesAction
} from '../../reducers/promotionCodes';
import { PROMOTION_CODE_TYPES } from '../../constants';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  toolbar: {
    width: '100%'
  },
  searchInput: {
    marginBottom: theme.spacing(2)
  }
}));

export const STATUSES = {
  1: { name: 'ACTIVE', color: 'green' },
  2: { name: 'INACTIVE', color: 'red' }
};

const PromotionCodes = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const globalParams = useSelector(state => state.promotionCodes.globalParams);
  const isLoading = useSelector(state =>
    createLoadingSelector([FETCH_PROMOTION_CODES_PREFIX])(state)
  );
  const [additionalParams, setAdditionalParams] = useState({
    params: globalParams,
    resetPage: false
  });

  const rows = useSelector(state => state.promotionCodes.items);
  const total = useSelector(state => state.promotionCodes.total);
  const [selectedStatus, setSelectedStatus] = React.useState(globalParams.selectedStatus);

  const handleChangeStatus = (event, status) => {
    setSelectedStatus(status);
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params.selectedStatus = status ? parseInt(status, 10) === 1 : '';
        draft.resetPage = true;
      })
    );
  };

  const handleUpdateData = useCallback(
    params => {
      const includes = [];
      dispatch(fetchPromotionCodesAction({ ...params, includes }));
    },
    [dispatch]
  );

  const handleOnChangeSearchInput = debounce((value, additionalParameterKey) => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params[additionalParameterKey] = value;
        draft.resetPage = true;
      })
    );
  }, 500);

  const handleShowItem = id => {
    history.push(`/promotion-codes/${id}`);
  };

  const openCreatePromotionCode = () => {
    history.push('/promotion-codes/add');
  };

  const columns = [
    {
      id: 'code',
      numeric: false,
      disablePadding: false,
      label: 'Code'
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description'
    },
    {
      id: 'value',
      numeric: false,
      disablePadding: false,
      label: 'Amount',
      disabledSorting: true,
      formatMethod: (value, row) => (row.is_fixed ? formatCurrency(value) : `${value}%`)
    },
    {
      id: 'promotion_code_type_id',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      customSortId: 'promotion_code_type_id',
      formatMethod: value => {
        if (value) {
          return (
            <StatusTag
              label={PROMOTION_CODE_TYPES[value].name}
              color={PROMOTION_CODE_TYPES[value].color}
            />
          );
        }
        return '';
      }
    },
    {
      id: 'starts_at_date',
      numeric: false,
      disablePadding: false,
      customSortId: 'starts_at',
      label: 'Start Date',
      default: '-'
    },
    {
      id: 'expires_at_date',
      numeric: false,
      disablePadding: false,
      customSortId: 'expires_at',
      label: 'Expiration Date',
      default: '-'
    },
    {
      id: 'is_active',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      formatMethod: value => {
        const statusId = value ? 1 : 2;
        return <StatusTag label={STATUSES[statusId].name} color={STATUSES[statusId].color} />;
      }
    }
  ];

  return (
    <Container component="main">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.toolbar}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <SearchInput
                placeholder="Search by Code"
                className={classes.searchInput}
                defaultValue={globalParams.code}
                onChange={e => handleOnChangeSearchInput(e.target.value, 'code')}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button color="primary" variant="contained" onClick={openCreatePromotionCode}>
                Create Promotion Code
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container spacing={3} justifyContent="flex-end" direction="row">
                <Grid item>
                  <ToggleButtonGroup value={selectedStatus} exclusive onChange={handleChangeStatus}>
                    {Object.keys(STATUSES).map(item => (
                      <ToggleButton key={item} value={item}>
                        {STATUSES[item].name}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <DataTable
          isLoading={isLoading}
          columns={columns}
          rows={rows}
          total={total}
          updateData={handleUpdateData}
          globalParams={globalParams}
          defaultOrderBy={globalParams.orderBy}
          customActions={[
            {
              name: 'show',
              handleOnAction: handleShowItem,
              text: 'Show'
            }
          ]}
          additionalParams={additionalParams}
        />
      </div>
    </Container>
  );
};

PromotionCodes.propTypes = {};

PromotionCodes.defaultProps = {};

export default PromotionCodes;
