import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useDispatch } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import GetAppIcon from '@material-ui/icons/GetApp';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, Container } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Box from '@material-ui/core/Box';
import InstallersApiService from '../../../services/api/installers';
import { fetchInstallersAction, organizationInitialState } from '../../../reducers/installers';
import { enqueueNotification } from '../../../reducers/notifications';
import LoadingIndicator from '../../../components/LoadingIndicator';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(5)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  list: {
    width: '100%'
  }
}));

const Installer = ({ organizationId, fetching, fetched, installers, fetchInstallers }) => {
  const classes = useStyles();
  const installersApiService = new InstallersApiService();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!fetched && !fetching) {
      fetchInstallers(organizationId);
    }
  }, [fetched, fetching, fetchInstallers, organizationId]);

  const downloadFile = async fileId => {
    try {
      const { url } = await installersApiService.getDownloadLink(fileId);
      window.open(url);
    } catch (error) {
      dispatch(enqueueNotification('error', error.message));
    }
  };

  if (fetching) {
    return <LoadingIndicator />;
  }

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <GetAppIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Installer
      </Typography>
      <Box my={2} width={1}>
        <Container maxWidth="sm">
          <Card>
            <CardContent>
              {installers.length > 0 ? (
                <List>
                  {installers.map(item => (
                    <ListItem key={item.file_name}>
                      <ListItemIcon>
                        <AttachmentIcon />
                      </ListItemIcon>
                      <ListItemText primary={item.version} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="comments"
                          onClick={() => downloadFile(item.id)}
                        >
                          <CloudDownloadIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              ) : (
                'No installers'
              )}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </div>
  );
};

Installer.propTypes = {
  organizationId: PropTypes.number.isRequired,
  fetching: PropTypes.bool.isRequired,
  fetched: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  installers: PropTypes.array.isRequired,
  fetchInstallers: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { isManagement } = state.auth;
    const { currentOrganization } = state.auth;

    const installerState = currentOrganization
      ? state.installers.organizations[currentOrganization.id] || organizationInitialState
      : {
          fetching: false,
          fetched: false,
          items: []
        };

    return {
      fetching: installerState.fetching,
      fetched: installerState.fetched,
      organizationId: !isManagement && currentOrganization ? currentOrganization.id : null,
      installers: installerState.items
    };
  },
  dispatch => {
    return {
      fetchInstallers: organizationId => {
        dispatch(fetchInstallersAction({ perPage: 1 }, organizationId));
      }
    };
  }
)(Installer);
