import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import { BRACKET_FORMATS } from '../../../../constants';

const FormatSelectionField = ({ value, onChange }) => {
  const handleChange = e => {
    onChange(Number(e.target.value));
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="position"
          name="position"
          defaultValue="top"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value={BRACKET_FORMATS.ORIGINAL}
            control={<Radio color="primary" />}
            label="Original"
            labelPlacement="start"
          />
          <FormControlLabel
            value={BRACKET_FORMATS.VTK}
            control={<Radio color="primary" />}
            label="VTK"
            labelPlacement="start"
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

FormatSelectionField.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default FormatSelectionField;
