import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import WifiTetheringIcon from '@material-ui/icons/WifiTethering';
import { makeStyles } from '@material-ui/core/styles';
import { RadioGroup } from 'formik-material-ui';
import { FormControlLabel, Radio, Typography } from '@material-ui/core';
import Charts from './Charts';
import { setBoard } from '../../../reducers/dashboard';
import Statuses, { ArchiveStatusKey } from '../../GlobalSearch/Data/Statuses';
import hasPermission from '../../../selectors/hasPermission';

const useStyles = makeStyles(() => ({
  test: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  radioTitle: {
    fontSize: '1.1rem',
    fontWeight: 'bold'
  }
}));

const Board = ({ statistics }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const board = useSelector(state => state.dashboard.board);

  const organizationActiveTotal =
    statistics.onboarding_organizations.live_data +
    statistics.new_organizations.live_data +
    statistics.active_organizations.live_data;

  const doctorActiveTotal =
    statistics.onboarding_doctors.live_data +
    statistics.new_doctors.live_data +
    statistics.active_doctors.live_data;

  const canViewArchived = useSelector(state =>
    hasPermission(state, { permissions: ['organization.view-archived'] })
  );

  return (
    <>
      <CardHeader
        title={
          <RadioGroup
            row={false}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={board}
            onChange={event => dispatch(setBoard(event.target.value))}
          >
            <FormControlLabel
              value="organization"
              control={<Radio />}
              label={<Typography className={classes.radioTitle}>Organizations</Typography>}
            />
            <FormControlLabel
              value="user"
              control={<Radio />}
              label={<Typography className={classes.radioTitle}>Users</Typography>}
            />
          </RadioGroup>
        }
        classes={{
          content: classes.test
        }}
        titleTypographyProps={{ variant: 'h6', color: 'primary' }}
        subheader={<WifiTetheringIcon color="error" />}
      />
      <Charts
        numbers={
          board === 'organization'
            ? [
                statistics.registering_organizations.live_data,
                statistics.onboarding_organizations.live_data,
                statistics.new_organizations.live_data,
                statistics.active_organizations.live_data,
                statistics.inactive_organizations.live_data,
                statistics.archived_organizations.live_data
              ]
            : [
                statistics.registering_doctors.live_data,
                statistics.onboarding_doctors.live_data,
                statistics.new_doctors.live_data,
                statistics.active_doctors.live_data,
                statistics.inactive_doctors.live_data,
                statistics.archived_doctors.live_data
              ]
        }
        totals={
          board === 'organization'
            ? [
                statistics.total_organizations.live_data,
                organizationActiveTotal,
                organizationActiveTotal,
                organizationActiveTotal,
                statistics.total_organizations.live_data,
                statistics.total_organizations.live_data
              ]
            : [
                statistics.total_doctors.live_data,
                doctorActiveTotal,
                doctorActiveTotal,
                doctorActiveTotal,
                statistics.total_doctors.live_data,
                statistics.total_doctors.live_data
              ]
        }
        titles={['Leads', 'Onboarding', 'New', 'Active', 'Passive', 'Archived']}
        subtitles={
          board === 'organization'
            ? ['', '', '', '', '', '']
            : ['Doctors', 'Doctors', 'Doctors', 'Doctors', 'Doctors', 'Doctors']
        }
        filters={Object.keys(Statuses)}
        disabledFilters={canViewArchived ? [] : [ArchiveStatusKey]}
        paramString={
          board === 'organization'
            ? 'filter[roles]=organization&filter[status]='
            : 'filter[roles]=doctor&filter[status]='
        }
        clickable
      />
    </>
  );
};

Board.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  statistics: PropTypes.object.isRequired
};

export default Board;
