import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import LoadingButton from '../../../../../components/LoadingButton';
import FormBuilderForm from './Form';
import {
  createFormBuilderAction,
  updateFormBuilderAction
} from '../../../../../reducers/formBuilder';
import { fetchOrganizationPricing } from '../../../../../reducers/organizationPricing';

const FormBuilderDialog = ({
  open,
  onClose,
  organization,
  formBuilder,
  layout,
  defaultControls,
  hiddenFields
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    setLoading(false);
  }, [formBuilder]);

  const onSubmit = () => {
    setLoading(true);

    const data = {
      organization_id: get(organization, 'id', null),
      form_builder_id: get(formBuilder, 'id', null),
      form_data: [...formData]
    };

    if (organization && formBuilder.is_default) {
      dispatch(createFormBuilderAction(data)).then(() => {
        dispatch(fetchOrganizationPricing(organization.id));
      });
    } else {
      dispatch(updateFormBuilderAction(formBuilder.id, data)).then(() => {
        if (organization) {
          dispatch(fetchOrganizationPricing(organization.id));
        }
      });
    }
  };

  return (
    <>
      <Dialog fullWidth fullScreen open={open} onClose={e => e.preventDefault()}>
        <DialogContent>
          <FormBuilderForm
            formBuilder={formBuilder}
            hiddenFields={hiddenFields}
            layout={layout}
            defaultControls={defaultControls}
            onChange={setFormData}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Close</Button>

          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={loading}
            onClick={onSubmit}
          >
            Save Changes
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FormBuilderDialog;
