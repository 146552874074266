import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import { Divider } from '@material-ui/core';
import moment from 'moment/moment';
import ExportReportApiService from '../../services/api/exportReport';
import ExportReportSelector from './ExportReportSelector';
import LoadingSnackbar from '../LoadingSnackbar';
import DateFilter from './Filters/DateFilter';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1)
  },
  downloadButton: {
    margin: theme.spacing(1)
  },
  secondContainer: {
    marginTop: theme.spacing(1)
  }
}));

const Reports = () => {
  const classes = useStyles();

  const [selectedReport, setSelectedReport] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [filters, setFilters] = useState({
    dateFilterFrom: moment().startOf('month'),
    dateFilterTo: moment().endOf('month'),
    timeFilter: 'this_month'
  });

  const handleSelectedReportChange = value => {
    setSelectedReport(value);
  };

  const handleFilterChange = filter => {
    setFilters(filter);
  };

  const handleDownload = async () => {
    if (!selectedReport || isGenerating) {
      return;
    }

    setIsGenerating(true);
    try {
      const service = new ExportReportApiService();
      await service.download({
        url: `export-reports/${selectedReport}`,
        data: {
          date_from: filters.dateFilterFrom.format(),
          date_to: filters.dateFilterTo.format()
        }
      });
      // eslint-disable-next-line no-empty
    } catch (e) {
    } finally {
      setIsGenerating(false);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const handleTabClose = event => {
      if (isGenerating) {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
        return '';
      }
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [isGenerating]);

  return (
    <>
      <LoadingSnackbar show={isGenerating} message="Generating CSV..." />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.formControl}>
            <ExportReportSelector
              value={selectedReport}
              onChange={handleSelectedReportChange}
              disabled={isGenerating}
            />
          </FormControl>
        </Grid>
      </Grid>
      {!!selectedReport && (
        <Grid container className={classes.secondContainer}>
          <Divider />
          <DateFilter filters={filters} onFilterChange={handleFilterChange} />
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              className={classes.downloadButton}
              onClick={handleDownload}
              disabled={isGenerating}
            >
              Download CSV
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Reports;
