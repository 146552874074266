/* eslint-disable no-use-before-define */
import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import makeStyles from '@material-ui/core/styles/makeStyles';
import countries from '../CountryList/countries.json';

const useStyles = makeStyles(theme => ({
  disabled: {
    '& .special-label': {
      color: 'rgba(0, 0, 0, 0.38)'
    }
  }
}));

export default function PhoneNumber({
  field,
  form,
  selectedCountry,
  required,
  disabled,
  label,
  ...props
}) {
  const classes = useStyles();

  const [value, setValue] = useState(field.value);

  const handleChange = (value, countryData, event, formatted) => {
    const e164PhoneNumber = `+${value}`;

    setValue(e164PhoneNumber);

    form.setFieldValue('phone_number', e164PhoneNumber);
  };

  const mappedSelectedCountry = useMemo(() => {
    const foundCountry = countries.find(
      country => country.iso_3.toLowerCase() === selectedCountry.toLowerCase()
    );

    const found = _.get(foundCountry, ['iso_2'], null);
    return found ? found.toLowerCase() : null;
  }, [selectedCountry]);

  return (
    <PhoneInput
      className={disabled ? classes.disabled : ''}
      country={mappedSelectedCountry}
      value={value}
      onChange={handleChange}
      countryCodeEditable={!!mappedSelectedCountry}
      disableDropdown
      required
      specialLabel={`${label}${required ? ' *' : ''}`}
      inputStyle={{
        width: '100%',
        padding: '16px 14px 16px 58px',
        color: disabled ? 'rgba(0, 0, 0, 0.38)' : ''
      }}
    />
  );
}
