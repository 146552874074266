import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  getColorByStatus,
  getDateDifferenceFromNow,
  UTCDateTimeToLocalDateTimeReadable
} from '../../../helpers';
import {
  addPatientFileAction,
  deletePatientFileAction,
  finishTechWorkflowInitialCaseSetupAction,
  startTechWorkflowInitialCaseSetupAction,
  updatePatientRxFormAction,
  updateTechWorkflowInitialCaseSetupAction
} from '../../../reducers/patients';
import {
  FILE_TYPE_IDS,
  INITIAL_SETUP_ASSIGNED_TO_ID,
  JAWS_TYPES_IDS,
  PRINT_REQUEST_TABLE_CONFIG,
  RX_FORM_STATUSES,
  RX_FORM_STATUSES_ID
} from '../../../constants';
import StatusTag from '../../../components/StatusTag';
import UploadFilesDialog from '../../../components/UploadFilesDialog';
import { MARK_AS_READY_RX_FORM_PREFIX, markAsReadyRxFormAction } from '../../../reducers/rxForms';
import createLoadingSelector from '../../../selectors/loading';
import LoadingButton from '../../../components/LoadingButton';
import hasPermission from '../../../selectors/hasPermission';
import PatientWorkflowContext from '../context/PatientWorkflowContext';
import InitialSetupAssignment from './InitialSetupAssignmentChanger';
import { formatProcesses } from '../../../helpers/techWorkflow';
import TechWorkflow from '../../../components/Workflow/TechWorkflow';
import iosFileNameValidator from '../../../helpers/iosFileNameValidator';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';

const useStyles = makeStyles(theme => ({
  requestHeader: {
    display: 'flex',
    padding: '8px 16px',
    alignItems: 'center'
  },
  initialSetupHeaderLeft: {
    display: 'flex',
    alignItems: 'center'
  },
  initialSetupHeaderRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  initialSetupContent: {
    marginTop: theme.spacing(2)
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
    height: 150
  },
  centeredColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: 150
  }
}));

const InitialSetupWorkflow = ({ rxForm, iosFile, disable }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const techWorkflow = get(rxForm, 'tech_workflow_initial_case_setup');
  const [isIosUploaderOpen, setIsIosUploaderOpen] = useState(false);
  const [isRemovingIosFile, setIsRemovingIosFile] = useState(false);
  const isNotifying = useSelector(state =>
    createLoadingSelector([MARK_AS_READY_RX_FORM_PREFIX])(state)
  );
  const isInactive =
    [RX_FORM_STATUSES_ID.completed, RX_FORM_STATUSES_ID.canceled].indexOf(rxForm.status_id) !==
      -1 || disable;
  const initialWorkflow = useMemo(() => formatProcesses(get(techWorkflow, 'processes', [])), [
    techWorkflow
  ]);

  const patientWorkflowContext = useContext(PatientWorkflowContext);

  const canUpdateRxFormManagementData = useSelector(state =>
    hasPermission(state, {
      permissions: ['rx-forms.manage', 'rx-forms.update-management-data']
    })
  );

  const canNotifyOnReadyRxForm = useSelector(state =>
    hasPermission(state, {
      permissions: ['rx-forms.manage', 'rx-forms.mark-as-ready']
    })
  );

  const canNotifyOnReadyOrganizationRxForm = useSelector(state =>
    hasPermission(state, {
      permissions: ['organization.rx-forms.mark-as-ready'],
      organizationId: patientWorkflowContext.patient.organization_id
    })
  );

  const isAbleToNotifyOnReadyRxForm =
    canNotifyOnReadyRxForm ||
    (canNotifyOnReadyOrganizationRxForm &&
      Number(get(rxForm, 'submission_completed_by')) === INITIAL_SETUP_ASSIGNED_TO_ID.thirdParty);

  const handleUploadDialogClose = uploadedFiles => {
    setIsIosUploaderOpen(false);
    if (isEmpty(uploadedFiles)) {
      return null;
    }
    const uploadedFile = uploadedFiles[Object.keys(uploadedFiles)[0]];
    return dispatch(addPatientFileAction(rxForm.patient_id, FILE_TYPE_IDS.ios, uploadedFile));
  };

  const handleRemoveIos = () => {
    setIsRemovingIosFile(true);
    return dispatch(
      deletePatientFileAction(rxForm.patient_id, FILE_TYPE_IDS.ios, iosFile.id)
    ).then(() => setIsRemovingIosFile(false));
  };

  const handleNotify = () => {
    return dispatch(markAsReadyRxFormAction(rxForm.id)).then(updatedRxForm => {
      if (updatedRxForm) {
        return dispatch(updatePatientRxFormAction(rxForm.patient_id, updatedRxForm));
      }

      return null;
    });
  };

  const renderUploadDialog = (initialDialogParams, open) => {
    return (
      <UploadFilesDialog
        {...initialDialogParams}
        handleClose={handleUploadDialogClose}
        updatePatient
        open={open}
        nameValidator={name => iosFileNameValidator(name, [], patientWorkflowContext.patient)}
      />
    );
  };

  const renderUploadIosButton = () => (
    <Button
      className={classes.actionButton}
      variant="contained"
      size="small"
      color="primary"
      onClick={() => setIsIosUploaderOpen(true)}
    >
      UPLOAD IOS
    </Button>
  );

  const renderRemoveIosButton = () => (
    <LoadingButton
      className={clsx(classes.actionButton, classes.removeFileButton)}
      variant="contained"
      size="small"
      color="secondary"
      disabled={isRemovingIosFile}
      loading={isRemovingIosFile}
      onClick={handleRemoveIos}
    >
      REMOVE IOS
    </LoadingButton>
  );

  const renderNotifyButton = () => {
    if (rxForm.ready_at || rxForm.cancelled_at) {
      return null;
    }

    return (
      <ConfirmationDialogOnClickWrapper
        confirmationBody="Are you sure you want to notify the doctor now?"
        confirmationTitle="Notify Doctor"
      >
        <LoadingButton
          className={classes.actionButton}
          variant="contained"
          size="small"
          color="primary"
          onClick={handleNotify}
          disabled={isNotifying}
          loading={isNotifying}
        >
          NOTIFY
        </LoadingButton>
      </ConfirmationDialogOnClickWrapper>
    );
  };

  return (
    <Grid container>
      <Box mt={2} width="100%">
        <Card>
          <Grid item xs={12} className={classes.requestHeader}>
            <Grid container spacing={3}>
              <Grid item xs={9} className={classes.initialSetupHeaderLeft}>
                <Box>
                  <Typography component="h1" variant="h5">
                    Initial Setup
                  </Typography>
                </Box>
                <Box mx={2}>
                  <StatusTag
                    label={RX_FORM_STATUSES[rxForm.status_id]}
                    color={getColorByStatus(RX_FORM_STATUSES[rxForm.status_id])}
                  />
                </Box>
                <Box>
                  <Typography component="h1" variant="subtitle1">
                    {`Submitted at ${UTCDateTimeToLocalDateTimeReadable(rxForm.created_at)}`}
                  </Typography>
                </Box>
                <Box mx={2}>
                  <Typography component="h1" variant="subtitle2">
                    <InitialSetupAssignment rxForm={rxForm} />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3} className={classes.initialSetupHeaderRight}>
                <Typography component="h1" variant="subtitle1">
                  {rxForm.status.toLowerCase() === 'pending' &&
                    `${getDateDifferenceFromNow(rxForm.status_updated_at)} ago`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <CardContent>
            <Grid item xs={12} className={classes.initialSetupContent}>
              <Grid container spacing={3}>
                <Grid item xs={2} className={classes.centeredColumn}>
                  {(rxForm.jaws_id === JAWS_TYPES_IDS.maxillary ||
                    rxForm.jaws_id === JAWS_TYPES_IDS.both) && (
                    <Typography component="h1" variant="subtitle1">
                      Maxillary
                    </Typography>
                  )}
                  {(rxForm.jaws_id === JAWS_TYPES_IDS.mandibular ||
                    rxForm.jaws_id === JAWS_TYPES_IDS.both) && (
                    <Typography component="h1" variant="subtitle1">
                      Mandibular
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={8}>
                  {canUpdateRxFormManagementData && (
                    <TechWorkflow
                      save={(data, old) =>
                        dispatch(
                          updateTechWorkflowInitialCaseSetupAction(rxForm.patient_id, old.id, data)
                        )
                      }
                      start={record =>
                        dispatch(
                          startTechWorkflowInitialCaseSetupAction(rxForm.patient_id, record.id)
                        )
                      }
                      finish={record =>
                        dispatch(
                          finishTechWorkflowInitialCaseSetupAction(rxForm.patient_id, record.id)
                        )
                      }
                      field="tech_workflow"
                      title="Tech Workflow Table"
                      workflowData={initialWorkflow}
                      columnsData={PRINT_REQUEST_TABLE_CONFIG}
                      readOnly={isInactive}
                    />
                  )}
                </Grid>
                {!isInactive && isAbleToNotifyOnReadyRxForm && (
                  <Grid item xs={2} className={classes.actions}>
                    {!iosFile && renderUploadIosButton()}
                    {iosFile && renderRemoveIosButton()}
                    {renderNotifyButton()}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      {renderUploadDialog(
        {
          title: 'IOS Files',
          patientId: rxForm.patient_id,
          patientFileTypeId: FILE_TYPE_IDS.ios,
          isImageUploader: true
        },
        isIosUploaderOpen
      )}
    </Grid>
  );
};

InitialSetupWorkflow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rxForm: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  iosFile: PropTypes.object,
  disable: PropTypes.bool,
  showWorkflowTable: PropTypes.bool
};

InitialSetupWorkflow.defaultProps = {
  rxForm: { params: {} },
  iosFile: null,
  disable: false,
  showWorkflowTable: true
};

export default InitialSetupWorkflow;
