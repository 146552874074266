import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, FormControl, Grid, InputAdornment, TextField } from '@material-ui/core';
import { produce } from 'immer';
import FieldWrapper from './FieldWrapper';
import Section from './Section';

const CommonField = ({ label, inputProps, adornment, error, fieldWrapperProps, ...props }) => {
  const fieldProps = {
    error: !!error,
    helperText: error,
    ...props
  };

  return (
    <FieldWrapper label={label} {...fieldWrapperProps}>
      <FormControl fullWidth>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          required
          InputProps={{
            min: 0,
            step: 1,
            endAdornment: <InputAdornment position="end">{adornment}</InputAdornment>,
            ...inputProps
          }}
          {...fieldProps}
        />
      </FormControl>
    </FieldWrapper>
  );
};

CommonField.defaultProps = {
  fieldWrapperProps: {},
  adornment: '$',
  inputProps: {},
  error: null
};

CommonField.propTypes = {
  label: PropTypes.string.isRequired,
  adornment: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  fieldWrapperProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  error: PropTypes.string
};

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main
  }
}));

const CustomFieldFees = ({ values, setFieldValue, errors }) => {
  const classes = useStyles();

  const handleChange = (fieldId, optionId, value) => {
    const customRxForm = values.custom_rxform;
    const updated = produce(customRxForm, draft => {
      const fieldIndex = draft.fields.findIndex(item => item.id === fieldId);
      const optionIndex = draft.fields[fieldIndex].options.findIndex(item => item.id === optionId);
      const option = draft.fields[fieldIndex].options[optionIndex];
      option.price = value;
      option.done = true;
    });
    setFieldValue('custom_rxform', updated);
  };

  if (!values.custom_rxform || !values.custom_rxform.fields) {
    return '';
  }

  return (
    <div>
      <Divider className={classes.divider} />
      <h3 className={classes.title}>Additional RxForm Fields</h3>
      <Section title="">
        {values.custom_rxform.fields.map(customField => {
          return (
            <Grid item container spacing={2} key={customField.label}>
              <Grid item xs={12}>
                <h3>{customField.label}</h3>
              </Grid>

              {customField.options.map(optionFee => {
                const fieldName = `option_${optionFee.id}`;
                const { label } = optionFee;
                const value = optionFee.price;

                const fieldProps = {
                  name: fieldName,
                  onChange: e => handleChange(customField.id, optionFee.id, e.target.value),
                  adornment: '$',
                  value,
                  label,
                  error: errors && get(errors, fieldName)
                };

                return (
                  <CommonField
                    key={fieldName}
                    {...fieldProps}
                    fieldWrapperProps={{ fieldName, ...{ columns: { sm: 1, md: 2 } } }}
                  />
                );
              })}
            </Grid>
          );
        })}
      </Section>
    </div>
  );
};

CustomFieldFees.defaultProps = {
  fieldWrapperProps: {},
  exclude: [],
  defaultValues: {}
};

CustomFieldFees.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValues: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object
};

export default CustomFieldFees;
