import React from 'react';
import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/AddCircleRounded';
import FieldWrapper from './FieldWrapper';
import Section from './Section';

const DiscountField = ({
  readOnly,
  allowDelete,
  error,
  discount,
  onChange,
  volume,
  onDelete,
  ...props
}) => {
  const onChangeHandler = name => {
    return e => {
      onChange({ discount, volume, [name]: Number(e.currentTarget.value) });
    };
  };

  return (
    <FieldWrapper columns={{ sm: 1, md: 1 }} labelWidth={{ xs: 12, sm: 12, md: 3 }} {...props}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="baseline" justifyContent="space-between">
          <TextField
            fullWidth
            value={discount}
            variant="outlined"
            error={error && !!error.discount}
            size="small"
            helperText={error && error.discount}
            type="number"
            onChange={onChangeHandler('discount')}
            disabled={readOnly}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: {
                min: 0,
                max: 100,
                step: 1
              }
            }}
          />
          <Box mx={1}>@</Box>
          <TextField
            value={volume}
            fullWidth
            variant="outlined"
            error={error && !!error.volume}
            helperText={error && error.volume}
            size="small"
            type="number"
            onChange={onChangeHandler('volume')}
            disabled={readOnly}
            InputProps={{
              endAdornment: <InputAdornment position="end">Qty</InputAdornment>,
              inputProps: {
                min: 0,
                max: 100,
                step: 1
              }
            }}
          />
          <Box>
            <IconButton
              onClick={onDelete}
              disabled={readOnly || !allowDelete}
              aria-label="delete"
              size="small"
              color="secondary"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      </Grid>
    </FieldWrapper>
  );
};

DiscountField.defaultProps = {
  readOnly: false,
  allowDelete: false,
  error: null
};

DiscountField.propTypes = {
  label: PropTypes.string.isRequired,
  discount: PropTypes.number.isRequired,
  volume: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  allowDelete: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object
};

const Discounts = ({ discounts, defaultDiscounts, onChange, global, limit, errors, ...props }) => {
  const globalDiscountCount = discounts.filter(({ is_global: isGlobal }) => isGlobal === true)
    .length;
  const hideGlobal = !global && globalDiscountCount < discounts.length;
  const actualCount = global ? discounts.length : discounts.length - globalDiscountCount;

  const handleDelete = index => {
    const newDiscounts = discounts.filter((value, discountIndex) => {
      return index !== discountIndex;
    });
    onChange(!newDiscounts.length && defaultDiscounts ? defaultDiscounts : newDiscounts);
  };

  const handleChange = (updatedIndex, updatedValue) => {
    const newDiscounts = discounts.map((value, index) => {
      return updatedIndex === index ? { ...updatedValue, is_global: global } : value;
    });
    onChange(newDiscounts);
  };

  const handleAdd = () => {
    onChange([...discounts, { discount: 0, volume: 0, is_global: global }]);
  };

  return (
    <Section title="Arch Volume Discounts" {...props}>
      {discounts.map(({ discount, volume, is_global: isGlobal }, index) => {
        if (hideGlobal && isGlobal) {
          return '';
        }
        const item = hideGlobal ? index + 1 - globalDiscountCount : index + 1;
        const label = `${isGlobal ? 'Global' : 'Custom'} ${item}`;
        const error = errors && errors.arch_volume_discounts && errors.arch_volume_discounts[index];

        return (
          <DiscountField
            error={error}
            readOnly={!global && isGlobal}
            allowDelete={!global && !isGlobal}
            onChange={value => handleChange(index, value)}
            key={`discount-${index + 1}`}
            label={label}
            discount={discount}
            volume={volume}
            onDelete={() => handleDelete(index)}
          />
        );
      })}
      {!global && actualCount < limit && (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <IconButton onClick={handleAdd} aria-label="add" size="small" color="primary">
              <AddIcon />
            </IconButton>
          </Box>
        </Grid>
      )}
    </Section>
  );
};

Discounts.defaultProps = {
  limit: 4,
  global: true,
  errors: null,
  defaultDiscounts: []
};

Discounts.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  discounts: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  limit: PropTypes.number,
  global: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  defaultDiscounts: PropTypes.array
};

export default Discounts;
