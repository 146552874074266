import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Can from '../../../components/Can';
import LoadingButton from '../../../components/LoadingButton';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import ActionOwnerExtraInfo from './ActionOwnerExtraInfo';
import InfoDialog from '../../../components/InfoDialog';

const PatientArchiveAction = ({ patient, isLoading, isArchiving, isDeleting, onArchive }) => {
  const isManagement = useSelector(state => state.auth.isManagement);
  const [extraDataRequesting, setExtraDataRequesting] = useState(false);
  const [infoDialog, setInfoDialog] = useState(false);

  const archivePatient = extraInfo => {
    onArchive({
      archived_by_name: extraInfo.action_owner_name,
      archived_reason: extraInfo.reason
    });
  };

  const confirmArchive = () => {
    if (patient.rx_form && patient.rx_form.completed_at && !isManagement) {
      setInfoDialog(true);
    } else {
      setExtraDataRequesting(true);
    }
  };

  if (patient && patient.deleted_at) {
    return null;
  }

  return (
    <>
      <Can
        permissions={['patients.archive']}
        yes={() => (
          <ConfirmationDialogOnClickWrapper
            confirmationBody="Are you sure you want to archive the current patient?"
            confirmationTitle="Archive Patient"
          >
            <LoadingButton
              loading={isArchiving}
              color="secondary"
              variant="contained"
              disabled={isLoading || isArchiving || isDeleting}
              onClick={confirmArchive}
            >
              Archive
            </LoadingButton>
          </ConfirmationDialogOnClickWrapper>
        )}
      />
      <ActionOwnerExtraInfo
        open={extraDataRequesting}
        setOpen={open => setExtraDataRequesting(open)}
        onAddedExtraInfo={archivePatient}
        title="Archive"
        hasNotifyField={false}
        infoMessage="Please Note: Archiving this case does not remove fees if the work has been completed."
      />
      <InfoDialog
        title="Archive Patient"
        content="Patient has completed initial setup. Please call and request a cancellation for cases after setup."
        open={infoDialog}
        onClose={setInfoDialog}
      />
    </>
  );
};

PatientArchiveAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isArchiving: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  onArchive: PropTypes.func.isRequired
};

export default PatientArchiveAction;
