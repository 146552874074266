const SUBMISSION_DATA_KEY_MAP = {
  OrthoSelect: 'Ortho Select',
  '3rd Party': '3rd Party',
  ON_HOLD: 'On Hold',
  'In-house': 'In Office'
};

const calcPercentage = (from, to, noOfDecimalPlaces = 1) => {
  if (to === 0) {
    return '';
  }
  const percentage = (from / to) * 100;
  return percentage.toFixed(noOfDecimalPlaces);
};

export const formatSubmissionData = data => {
  if (typeof data.live_data === 'object') {
    const liveData = { ...data.live_data };
    Object.keys(data.live_data).forEach(key => {
      liveData[key] = {
        [SUBMISSION_DATA_KEY_MAP[key]]: {
          count: data.live_data[key][SUBMISSION_DATA_KEY_MAP[key]],
          percentage: calcPercentage(data.live_data[key][SUBMISSION_DATA_KEY_MAP[key]], data.total)
        },
        'Full Sets': {
          count: data.live_data[key]['Full Sets'],
          percentage: calcPercentage(
            data.live_data[key]['Full Sets'],
            data.live_data[key][SUBMISSION_DATA_KEY_MAP[key]]
          )
        },
        'Single Arch': {
          count: data.live_data[key]['Single Arch'],
          percentage: calcPercentage(
            data.live_data[key]['Single Arch'],
            data.live_data[key][SUBMISSION_DATA_KEY_MAP[key]]
          )
        }
      };
    });
    liveData.total =
      data.live_data.OrthoSelect[SUBMISSION_DATA_KEY_MAP.OrthoSelect] +
      data.live_data['3rd Party'][SUBMISSION_DATA_KEY_MAP['3rd Party']] +
      data.live_data['In-house'][SUBMISSION_DATA_KEY_MAP['In-house']];

    const combinedCount =
      data.live_data.OrthoSelect[SUBMISSION_DATA_KEY_MAP.OrthoSelect] +
      data.live_data['In-house'][SUBMISSION_DATA_KEY_MAP['In-house']];
    const percentage = calcPercentage(combinedCount, data.total, 2);
    const description = percentage
      ? `${combinedCount} (${percentage}% OS & In-Office Combined)`
      : '';
    return { ...data, live_data: liveData, description };
  }
  return data;
};

export const formatInitialSetupData = (data, submissionData) => {
  if (typeof data.live_data === 'object' && typeof submissionData.live_data === 'object') {
    const percentage = calcPercentage(submissionData.live_data.OrthoSelect['Ortho Select'], submissionData.total, 2);
    const description = percentage ? `${percentage}% of Submissions` : '';
    return { ...data, total_description: description };
  }
  return data;
};

export const includeDescriptionOfTotal = (data, dependentTotal, suffix) => {
  if (typeof data.live_data === 'object') {
    const percentage = calcPercentage(data.total, dependentTotal, 2);
    const description = percentage ? `${percentage}% ${suffix}` : '';
    return { ...data, total_description: description };
  }
  return data;
};
