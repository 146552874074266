import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Filters from '../../../pages/Dashboard/components/Filters';

const useStyles = makeStyles(theme => ({
  filter: {
    padding: theme.spacing(1)
  }
}));

const DateFilter = ({ filters, onFilterChange }) => {
  const classes = useStyles();

  const filterParams = {
    params: filters,
    resetPage: false
  };
  const handleFilterChange = params => {
    onFilterChange({
      dateFilterFrom: !params.params.dateFilterFrom
        ? null
        : params.params.dateFilterFrom.clone().startOf('day'),
      dateFilterTo: !params.params.dateFilterTo
        ? null
        : params.params.dateFilterTo.clone().endOf('day'),
      timeFilter: params.params.timeFilter
    });
  };

  return (
    <Grid item xs={12}>
      <div className={classes.filter}>
        <Filters
          additionalParams={filterParams}
          setAdditionalParams={handleFilterChange}
          excludeFilters={['all_time']}
        />
      </div>
    </Grid>
  );
};

DateFilter.propTypes = {
  onFilterChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object.isRequired
};

DateFilter.defaultProps = {
  onFilterChange: () => {}
};

export default DateFilter;
