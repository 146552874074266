import React, { useEffect } from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import { Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import PromotionCodeForm from './components/PromotionCodeForm';
import createLoadingSelector from '../../selectors/loading';
import {
  DELETE_PROMOTION_CODE_PREFIX,
  deletePromotionCodeAction,
  FETCH_PROMOTION_CODE_PREFIX,
  fetchPromotionCodeAction,
  UPDATE_PROMOTION_CODE_PREFIX,
  updatePromotionCodeAction
} from '../../reducers/promotionCodes';
import LoadingIndicator from '../../components/LoadingIndicator';

const useStyles = makeStyles(() => ({
  form: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

const UpdatePromotionCode = ({ match: { params } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const promotionCodeId = parseInt(params.id, 10);
  const promotionCode = useSelector(state => {
    return state.promotionCodes.items[
      state.promotionCodes.items.findIndex(item => item.id === promotionCodeId)
    ];
  });
  const isUpdatingPromotionCode = useSelector(state =>
    createLoadingSelector([UPDATE_PROMOTION_CODE_PREFIX])(state)
  );

  const isDeletingPromotionCode = useSelector(state =>
    createLoadingSelector([DELETE_PROMOTION_CODE_PREFIX])(state)
  );

  const isLoading = useSelector(state =>
    createLoadingSelector([FETCH_PROMOTION_CODE_PREFIX])(state)
  );

  useEffect(() => {
    dispatch(fetchPromotionCodeAction(promotionCodeId));
  }, [dispatch, promotionCodeId]);

  const updatePromotionCode = values => {
    dispatch(updatePromotionCodeAction(values, promotionCodeId)).then(response => {
      if (response) {
        history.push('/promotion-codes');
      }
    });
    return true;
  };

  const deletePromotionCode = () => {
    dispatch(deletePromotionCodeAction(promotionCodeId)).then(response => {
      if (response) {
        history.push('/promotion-codes');
      }
    });
    return true;
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Card className={classes.form}>
      <CardHeader title="Update Promotion Code" />
      <Divider />
      <PromotionCodeForm
        onSubmit={updatePromotionCode}
        isLoading={isUpdatingPromotionCode}
        promotionCode={promotionCode}
        isDeleting={isDeletingPromotionCode}
        onDelete={deletePromotionCode}
      />
    </Card>
  );
};

UpdatePromotionCode.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object
};

UpdatePromotionCode.defaultProps = {
  match: { params: {} }
};

export default UpdatePromotionCode;
