import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import RxFormsApiService from '../services/api/rxForms';
import ServiceManager from '../services/api/serviceManager';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_RX_FORMS_PREFIX = 'FETCH_RX_FORMS';
export const FETCH_RX_FORMS_REQUEST_ACTION = `${FETCH_RX_FORMS_PREFIX}_REQUEST_ACTION`;
export const FETCH_RX_FORMS_SUCCESS_ACTION = `${FETCH_RX_FORMS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_RX_FORMS_FAILURE_ACTION = `${FETCH_RX_FORMS_PREFIX}_FAILURE_ACTION`;

export const FETCH_RX_FORM_PREFIX = 'FETCH_RX_FORM';
export const FETCH_RX_FORM_REQUEST_ACTION = `${FETCH_RX_FORM_PREFIX}_REQUEST_ACTION`;
export const FETCH_RX_FORM_SUCCESS_ACTION = `${FETCH_RX_FORM_PREFIX}_SUCCESS_ACTION`;
export const FETCH_RX_FORM_FAILURE_ACTION = `${FETCH_RX_FORM_PREFIX}_FAILURE_ACTION`;

export const CREATE_RX_FORM_PREFIX = 'CREATE_RX_FORM';
export const CREATE_RX_FORM_REQUEST_ACTION = `${CREATE_RX_FORM_PREFIX}_REQUEST_ACTION`;
export const CREATE_RX_FORM_SUCCESS_ACTION = `${CREATE_RX_FORM_PREFIX}_SUCCESS_ACTION`;
export const CREATE_RX_FORM_FAILURE_ACTION = `${CREATE_RX_FORM_PREFIX}_FAILURE_ACTION`;

export const UPDATE_RX_FORM_PREFIX = 'UPDATE_RX_FORM';
export const UPDATE_RX_FORM_REQUEST_ACTION = `${UPDATE_RX_FORM_PREFIX}_REQUEST_ACTION`;
export const UPDATE_RX_FORM_SUCCESS_ACTION = `${UPDATE_RX_FORM_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_RX_FORM_FAILURE_ACTION = `${UPDATE_RX_FORM_PREFIX}_FAILURE_ACTION`;

export const MARK_AS_READY_RX_FORM_PREFIX = 'MARK_AS_READY_RX_FORM';
export const MARK_AS_READY_RX_FORM_REQUEST_ACTION = `${MARK_AS_READY_RX_FORM_PREFIX}_REQUEST_ACTION`;
export const MARK_AS_READY_RX_FORM_SUCCESS_ACTION = `${MARK_AS_READY_RX_FORM_PREFIX}_SUCCESS_ACTION`;
export const MARK_AS_READY_RX_FORM_FAILURE_ACTION = `${MARK_AS_READY_RX_FORM_PREFIX}_FAILURE_ACTION`;

export const CANCEL_RX_FORM_PREFIX = 'CANCEL_RX_FORM';
export const CANCEL_RX_FORM_REQUEST_ACTION = `${CANCEL_RX_FORM_PREFIX}_REQUEST_ACTION`;
export const CANCEL_RX_FORM_SUCCESS_ACTION = `${CANCEL_RX_FORM_PREFIX}_SUCCESS_ACTION`;
export const CANCEL_RX_FORM_FAILURE_ACTION = `${CANCEL_RX_FORM_PREFIX}_FAILURE_ACTION`;

export const DELETE_RX_FORM_PREFIX = 'DELETE_RX_FORM';
export const DELETE_RX_FORM_REQUEST_ACTION = `${DELETE_RX_FORM_PREFIX}_REQUEST_ACTION`;
export const DELETE_RX_FORM_SUCCESS_ACTION = `${DELETE_RX_FORM_PREFIX}_SUCCESS_ACTION`;
export const DELETE_RX_FORM_FAILURE_ACTION = `${DELETE_RX_FORM_PREFIX}_FAILURE_ACTION`;

export const ASSIGN_RX_FORM_PREFIX = 'ASSIGN_RX_FORM';
export const ASSIGN_RX_FORM_REQUEST_ACTION = `${ASSIGN_RX_FORM_PREFIX}_REQUEST_ACTION`;
export const ASSIGN_RX_FORM_SUCCESS_ACTION = `${ASSIGN_RX_FORM_PREFIX}_SUCCESS_ACTION`;
export const ASSIGN_RX_FORM_FAILURE_ACTION = `${ASSIGN_RX_FORM_PREFIX}_FAILURE_ACTION`;

export const CREATE_PATIENT_RX_FORM_PREFIX = 'CREATE_PATIENT_RX_FORM';
export const CREATE_PATIENT_RX_FORM_REQUEST_ACTION = `${CREATE_PATIENT_RX_FORM_PREFIX}_REQUEST_ACTION`;
export const CREATE_PATIENT_RX_FORM_SUCCESS_ACTION = `${CREATE_PATIENT_RX_FORM_PREFIX}_SUCCESS_ACTION`;
export const CREATE_PATIENT_RX_FORM_FAILURE_ACTION = `${CREATE_PATIENT_RX_FORM_PREFIX}_FAILURE_ACTION`;

export const RESET_RX_FORMS = 'RESET_RX_FORMS';

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  items: [],
  total: 0,
  globalParams: {
    order: 'desc',
    orderBy: 'created_at',
    status: 'all',
    page: 1,
    perPage: 25,
    searchFullNameQuery: '',
    searchDoctorFullNameQuery: '',
    locations: []
  }
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RX_FORMS_SUCCESS_ACTION:
      return {
        ...state,
        items: action.payload.rx_forms.data,
        total: action.payload.rx_forms.total,
        globalParams: action.payload.params
      };
    case FETCH_RX_FORM_SUCCESS_ACTION:
      return produce(state, draft => {
        const rxFormIndex = state.items.findIndex(item => item.id === action.payload.id);
        if (rxFormIndex > -1) {
          draft.items[state.items.findIndex(item => item.id === action.payload.id)] =
            action.payload;
        } else {
          draft.items.push(action.payload);
        }
      });
    case CREATE_RX_FORM_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items.unshift(action.payload);
      });
    case UPDATE_RX_FORM_SUCCESS_ACTION:
    case MARK_AS_READY_RX_FORM_SUCCESS_ACTION:
    case CANCEL_RX_FORM_SUCCESS_ACTION:
    case CREATE_PATIENT_RX_FORM_SUCCESS_ACTION:
      return produce(state, draft => {
        const rxFormId = state.items.findIndex(item => item.id === action.payload.id);
        if (rxFormId > -1) {
          draft.items[rxFormId] = action.payload;
        }
      });
    case DELETE_RX_FORM_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items.splice(
          state.items.findIndex(item => item.id === action.payload.id),
          1
        );
      });
    case RESET_RX_FORMS:
      return initialState;
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchRxFormsAction(params = {}) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_RX_FORMS_REQUEST_ACTION });
    const rxFormsService = new ServiceManager(getState).getRxFormServiceInstance();
    return rxFormsService
      .get(params)
      .then(response => {
        dispatch({
          type: FETCH_RX_FORMS_SUCCESS_ACTION,
          payload: { rx_forms: response.rx_forms, params }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_RX_FORMS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchRxFormAction(id) {
  return dispatch => {
    dispatch({ type: FETCH_RX_FORM_REQUEST_ACTION });
    const rxFormsService = new RxFormsApiService();
    return rxFormsService
      .show(id)
      .then(response => {
        dispatch({ type: FETCH_RX_FORM_SUCCESS_ACTION, payload: response.rx_form });
      })
      .catch(error => {
        dispatch({ type: FETCH_RX_FORM_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function createRxFormAction(params) {
  return dispatch => {
    dispatch({ type: CREATE_RX_FORM_REQUEST_ACTION });
    const rxFormsService = new RxFormsApiService();
    return rxFormsService
      .create(params)
      .then(response => {
        dispatch({ type: CREATE_RX_FORM_SUCCESS_ACTION, payload: response.rx_form });
        dispatch(enqueueNotification('success', response.message));
        return response.rx_form;
      })
      .catch(error => {
        dispatch({ type: CREATE_RX_FORM_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateRxFormAction(id, params) {
  return dispatch => {
    dispatch({ type: UPDATE_RX_FORM_REQUEST_ACTION });
    const rxFormsService = new RxFormsApiService();
    return rxFormsService
      .update(id, params)
      .then(response => {
        dispatch({ type: UPDATE_RX_FORM_SUCCESS_ACTION, payload: response.rx_form });
        dispatch(enqueueNotification('success', response.message));
        return response.rx_form;
      })
      .catch(error => {
        dispatch({ type: UPDATE_RX_FORM_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function markAsReadyRxFormAction(id) {
  return dispatch => {
    dispatch({ type: MARK_AS_READY_RX_FORM_REQUEST_ACTION });
    const rxFormsService = new RxFormsApiService();
    return rxFormsService
      .markAsReady(id)
      .then(response => {
        dispatch({ type: MARK_AS_READY_RX_FORM_SUCCESS_ACTION, payload: response.rx_form });
        dispatch(enqueueNotification('success', response.message));
        return response.rx_form;
      })
      .catch(error => {
        dispatch({ type: MARK_AS_READY_RX_FORM_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function cancelRxFormAction(id) {
  return dispatch => {
    dispatch({ type: CANCEL_RX_FORM_REQUEST_ACTION });
    const rxFormsService = new RxFormsApiService();
    return rxFormsService
      .cancel(id)
      .then(response => {
        dispatch({ type: CANCEL_RX_FORM_SUCCESS_ACTION, payload: response.rx_form });
        dispatch(enqueueNotification('success', response.message));
        return response.rx_form;
      })
      .catch(error => {
        dispatch({ type: CANCEL_RX_FORM_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function assignRxFormAction(id, assignToId) {
  return dispatch => {
    dispatch({ type: ASSIGN_RX_FORM_REQUEST_ACTION });
    const rxFormsService = new RxFormsApiService();
    return rxFormsService
      .assign(id, assignToId)
      .then(response => {
        dispatch({ type: ASSIGN_RX_FORM_SUCCESS_ACTION, payload: { id, assignToId } });
        dispatch(enqueueNotification('success', response.message));
        return response;
      })
      .catch(error => {
        dispatch({ type: ASSIGN_RX_FORM_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function deleteRxFormAction(id) {
  return dispatch => {
    dispatch({ type: DELETE_RX_FORM_REQUEST_ACTION });
    const rxFormsService = new RxFormsApiService();
    return rxFormsService
      .delete(id)
      .then(response => {
        dispatch({ type: DELETE_RX_FORM_SUCCESS_ACTION, payload: { id } });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: DELETE_RX_FORM_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function resetRxForms() {
  return dispatch => {
    dispatch({
      type: RESET_RX_FORMS
    });
  };
}
