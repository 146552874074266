import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Select
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { QUESTIONS } from '../../../../components/RxForm/constants';
import config from './config';

const useStyles = makeStyles(() => ({
  questionsWrapper: {
    fontSize: '1rem'
  },
  question: {
    '&:hover > .question-label': {
      fontWeight: 600
    }
  },
  label: {
    fontWeight: 500
  }
}));

const Questions = ({ data, metadata, onChange }) => {
  const classes = useStyles();
  const onChangeHooks = [];
  const overrideValues = {};

  const handleChange = (name, value) => {
    let changes = { [name]: value };
    onChangeHooks.forEach(onChangeHook => {
      changes = onChangeHook(data, changes);
    });
    onChange({ ...data, ...changes });
  };

  useEffect(() => {
    if (Object.keys(overrideValues).length === 0) {
      return;
    }
    onChange({ ...data, ...overrideValues });
  }, [data, overrideValues, onChange]);

  return (
    <div className={classes.questionsWrapper}>
      {config.map(
        ({
          label,
          name,
          options,
          encodeValue = false,
          overrideValueFn = null,
          onChangeHook,
          isDisabled,
          when = null,
          multiple = false,
          placeholder = '',
          placeholderFn = null,
          formatValue = null,
          emptyOptionsLabel = 'No available option'
        }) => {
          if (onChangeHook) {
            onChangeHooks.push(onChangeHook);
          }

          const generatedOptions = (options && options({ data, metadata })) || [];
          const overridePlaceholder = placeholderFn ? placeholderFn(metadata) : null;

          let value = data[name];
          if (overrideValueFn) {
            const overrideValue = overrideValueFn({
              currentValue: value,
              options: generatedOptions
            });
            if (value !== overrideValue) {
              overrideValues[name] = overrideValue;
              value = overrideValue;
            }
          }

          if (when && !when(data, metadata)) {
            return '';
          }

          let selectValue = encodeValue ? JSON.stringify(value) : value;

          if (multiple && !selectValue) {
            selectValue = [];
          }

          if (formatValue) {
            selectValue = formatValue({ value: selectValue, data, metadata });
          }

          const hideable = !!metadata.hideable_single_option_fields[name];
          const hideAttributeKey = metadata.hideable_single_option_fields[name];

          return (
            <Grid container spacing={2} alignItems="center" key={name} className={classes.question}>
              <Grid item xs={12} sm={6} className={`${classes.label} question-label`}>
                {label || QUESTIONS[name]}
              </Grid>
              <Grid item xs={12} sm={4} className="question-input">
                {generatedOptions.length > 0 && (
                  <FormControl size="small" fullWidth variant="outlined">
                    <Select
                      variant="outlined"
                      name={name}
                      required
                      disabled={isDisabled && isDisabled(data, metadata)}
                      value={selectValue}
                      onChange={e => {
                        handleChange(
                          name,
                          encodeValue ? JSON.parse(e.target.value) : e.target.value
                        );
                      }}
                      multiple={multiple}
                      displayEmpty={multiple}
                      renderValue={
                        !multiple
                          ? undefined
                          : selected => {
                              if (selected.length === 0 && placeholder) {
                                return overridePlaceholder || placeholder;
                              }

                              return generatedOptions
                                .filter(p => selected.includes(p.value))
                                .map(p => p.label)
                                .join(', ');
                            }
                      }
                    >
                      {!multiple && placeholder && (
                        <MenuItem value="" disabled>
                          {overridePlaceholder || placeholder}
                        </MenuItem>
                      )}
                      {generatedOptions.map(
                        ({ label: optionLabel, value: optionValue, formattedLabel }) => (
                          <MenuItem
                            key={optionLabel}
                            value={encodeValue ? JSON.stringify(optionValue) : optionValue}
                          >
                            {multiple && (
                              <Checkbox checked={selectValue.indexOf(optionValue) > -1} />
                            )}
                            <ListItemText primary={formattedLabel || optionLabel} />
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                )}
                {generatedOptions.length === 0 && emptyOptionsLabel}
              </Grid>
              <Grid item xs={12} sm={2}>
                {hideable && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={data[name].length > 1}
                        checked={data.single_option_hidden_fields.indexOf(hideAttributeKey) !== -1}
                        onChange={e => {
                          const { checked } = e.target;
                          if (checked) {
                            handleChange('single_option_hidden_fields', [
                              ...data.single_option_hidden_fields,
                              hideAttributeKey
                            ]);
                          } else {
                            handleChange(
                              'single_option_hidden_fields',
                              data.single_option_hidden_fields.filter(
                                item => item !== hideAttributeKey
                              )
                            );
                          }
                        }}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label="Hide"
                  />
                )}
              </Grid>
            </Grid>
          );
        }
      )}
    </div>
  );
};

Questions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  metadata: PropTypes.shape({
    third_party_partner: PropTypes.shape({
      name: PropTypes.string
    }),
    // eslint-disable-next-line react/forbid-prop-types
    shipping_methods: PropTypes.array
  }).isRequired
};

export default Questions;
