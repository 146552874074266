import { useEffect } from 'react';
import throttle from 'lodash/throttle';
import { useDispatch, useSelector } from 'react-redux';
import { updateLastActivityTime } from '../../reducers/auth';

const UserActivityHandler = () => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const user = useSelector(state => state.auth.user);

  useEffect(() => {

    if (!user || !isAuthenticated) {
      return null;
    }

    let throttleTime = +process.env.REACT_APP_THROTTLE_UPDATE_ACTIVITY_TIME_IN_SECONDS;

    if (Number.isNaN(throttleTime) || !throttleTime) {
      throttleTime = 600; // 10 minutes
    }

    const update = throttle(() => {
      dispatch(updateLastActivityTime());
    }, 1000 * throttleTime);

    document.addEventListener('mousemove', update);
    document.addEventListener('scroll', update);
    document.addEventListener('keydown', update);

    return () => {
      document.removeEventListener('scroll', update);
      document.removeEventListener('mousemove', update);
      document.removeEventListener('keydown', update);
    };
  }, [user, dispatch, isAuthenticated]);

  return null;
};
UserActivityHandler.propTypes = {};

UserActivityHandler.defaultProps = {};

export default UserActivityHandler;
