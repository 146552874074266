import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { CardActions, Divider, Accordion, AccordionDetails, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { Link as RouterLink } from 'react-router-dom';
import LaunchIcon from '@material-ui/icons/Launch';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LoadingIndicator from '../../components/LoadingIndicator';
import {
  ATTEMPT_CURRENT_PAYMENT_AGAIN_PREFIX,
  attemptCurrentPaymentAgainAction,
  FETCH_CONTRACT_PREFIX,
  fetchContractAction,
  RESUME_CONTRACT_PREFIX,
  resumeContractAction,
  SUSPEND_CONTRACT_PREFIX,
  suspendContractAction
} from '../../reducers/contracts';
import createLoadingSelector from '../../selectors/loading';
import { UTCDateTimeToLocalTime } from '../../helpers';
import PaymentStatusTag from '../../components/PaymentStatusTag';
import LoadingButton from '../../components/LoadingButton';
import ConfirmationDialogOnClickWrapper from '../../components/ConfirmationDialogOnClickWrapper';
import OrdersListHandler from '../../components/OrdersListHandler';
import ListPaymentAttempts from '../../components/ListPaymentAttempts';
import CurrentOrderHandler from '../../components/OrderSummary/CurrentOrderHandler';
import Can from '../../components/Can';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  orderDetails: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  attemptAgainButton: {
    marginLeft: 'auto'
  },
  paymentAttempts: {
    marginTop: theme.spacing(3),
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  licenseLink: {
    marginLeft: '5px'
  }
}));

const RecurringPaymentDetails = ({ match: { params } }) => {
  const classes = useStyles();
  const contractId = parseInt(params.id, 10);
  const dispatch = useDispatch();
  const contract = useSelector(state => {
    return state.contracts.items[state.contracts.items.findIndex(item => item.id === contractId)];
  });
  const [reloadCurrentOrder, setReloadCurrentOrder] = useState(false);
  const [isFetchedContract, setIsFetchedContract] = useState(false);
  const isLoadingContract = useSelector(state =>
    createLoadingSelector([FETCH_CONTRACT_PREFIX])(state)
  );

  const isClosingSubscription = useSelector(state =>
    createLoadingSelector([SUSPEND_CONTRACT_PREFIX, RESUME_CONTRACT_PREFIX])(state)
  );

  const isAttempting = useSelector(state =>
    createLoadingSelector([ATTEMPT_CURRENT_PAYMENT_AGAIN_PREFIX])(state)
  );

  const attemptPaymentAgain = () => {
    dispatch(attemptCurrentPaymentAgainAction(contractId));
  };

  const suspendSubscription = () => {
    dispatch(suspendContractAction(contractId));
  };

  const resumeSubscription = () => {
    dispatch(resumeContractAction(contractId));
  };

  useEffect(() => {
    dispatch(fetchContractAction(contractId)).then(() => setIsFetchedContract(true));
  }, [dispatch, contractId]);

  useEffect(() => {
    if (contract) {
      setReloadCurrentOrder(!reloadCurrentOrder);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, get(contract, 'license.license_type.id', null)]);

  const isSuspended = () => {
    return contract.suspension_type === 1;
  };

  const renderResumeButton = () => {
    if (contract.organization.disabled_at) {
      return null;
    }

    return (
      <Can
        permissions={['contracts.manage']}
        yes={() => (
          <ConfirmationDialogOnClickWrapper
            confirmationTitle="Resume Subscription"
            confirmationBody="Are you sure you want to resume the subscription? If the order is not paid, this action will attempt a payment and the subscription will resume after successful payment."
          >
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={resumeSubscription}
              loading={isClosingSubscription}
              disabled={isClosingSubscription}
              className={classes.attemptAgainButton}
            >
              Resume Subscription
            </LoadingButton>
          </ConfirmationDialogOnClickWrapper>
        )}
      />
    );
  };

  if (isLoadingContract) {
    return <LoadingIndicator />;
  }

  if (!contract || !isFetchedContract) {
    return null;
  }

  return (
    <>
      <Card className={classes.orderDetails}>
        <CardHeader title="Subscription Details" />
        <Divider />
        <CardContent>
          <List>
            <ListItem>
              <ListItemText primary="Organization" />
              <Typography variant="body2">{contract.organization.name}</Typography>
            </ListItem>
            <ListItem>
              <ListItemText primary="Initial Date" />
              <Typography variant="body2">
                {UTCDateTimeToLocalTime(contract.initial_payment_date)}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemText primary="Last Payment Date" />
              <Typography variant="body2">
                {isSuspended()
                  ? UTCDateTimeToLocalTime(contract.suspended_at)
                  : UTCDateTimeToLocalTime(contract.last_payment_date)}
              </Typography>
            </ListItem>
            {!isSuspended() && (
              <ListItem>
                <ListItemText primary="Next Payment Date" />
                <Typography variant="body2">
                  {UTCDateTimeToLocalTime(contract.next_payment_date)}
                </Typography>
              </ListItem>
            )}
            <ListItem>
              <ListItemText primary="Payment Status" />
              <PaymentStatusTag paymentStatus={get(contract, 'payment_status', null)} />
            </ListItem>
            <ListItem>
              <ListItemText primary="License Type" />
              <Typography variant="body2">Custom</Typography>
              <RouterLink
                className={classes.licenseLink}
                to={`/edit-license/${get(contract, 'license.id')}`}
              >
                <LaunchIcon color="primary" fontSize="small" />
              </RouterLink>
            </ListItem>
            <ListItem>
              <ListItemText primary="Created Arches (Current Period)" />
              <Typography variant="body2">
                {get(contract, 'number_created_arches', 0)}{' '}
                {!!get(contract, 'license.monthly_available_arches', false) &&
                  `/${get(contract, 'license.monthly_available_arches', 0)}`}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemText primary="Completed Printing Requests (Current Period)" />
              <Typography variant="body2">
                {get(contract, 'number_completed_printing_requests', 0)}
              </Typography>
            </ListItem>
            {contract.suspended_at && (
              <ListItem>
                <ListItemText
                  primary="Suspended At"
                  primaryTypographyProps={{ color: 'secondary' }}
                />
                <Typography variant="body2" color="secondary">
                  {UTCDateTimeToLocalTime(contract.suspended_at)}
                </Typography>
              </ListItem>
            )}
          </List>
        </CardContent>
        <Divider />
        <CardActions>
          {!contract.suspended_at ? (
            <>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={attemptPaymentAgain}
                loading={isAttempting}
                disabled={isAttempting}
                className={classes.attemptAgainButton}
              >
                Attempt Payment
              </LoadingButton>
              <Can
                permissions={['contracts.manage']}
                yes={() => (
                  <ConfirmationDialogOnClickWrapper
                    confirmationTitle="Suspend Subscription"
                    confirmationBody="Are you sure you want to suspend the subscription?"
                  >
                    <LoadingButton
                      variant="contained"
                      color="secondary"
                      onClick={suspendSubscription}
                      loading={isClosingSubscription}
                      disabled={isClosingSubscription}
                      className={classes.attemptAgainButton}
                    >
                      Suspend Subscription
                    </LoadingButton>
                  </ConfirmationDialogOnClickWrapper>
                )}
              />
            </>
          ) : (
            renderResumeButton()
          )}
        </CardActions>
      </Card>
      <div className={classes.paymentAttempts}>
        <ListPaymentAttempts
          paymentAttempts={get(contract, 'current_monthly_order.payment_attempts', [])}
        />
      </div>
      <Paper className={classes.paper}>
        <CurrentOrderHandler
          organizationId={get(contract, 'organization.id', null)}
          title="Current Invoice"
          reload={reloadCurrentOrder}
          showMoreOptions
        />
      </Paper>
      <div className={classes.orderDetails}>
        <Accordion TransitionProps={{ mountOnEnter: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">All Invoices</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <OrdersListHandler
              organization={get(contract, 'organization', null)}
              contractId={contract.id}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

RecurringPaymentDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired
};

RecurringPaymentDetails.defaultProps = {};

export default RecurringPaymentDetails;
