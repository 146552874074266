import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel
} from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { createLabel, getInjectedFields } from './helpers';
import { PropsPropType, RulesPropType } from '../propTypes';

const Checkboxes = ({ id, preview, field, props, rules, isLive, form }) => {
  const [values, setValues] = useState([]);

  const { name, value, onChange } = getInjectedFields(field);

  const onCheck = e => {
    const { checked, value: val } = e.target;

    let newVals = [];

    if (checked) {
      newVals = [...values, val];
    } else {
      newVals = values.filter(v => v !== val);
    }

    const target = { name, value: newVals.join(',') };

    onChange({ target });
  };

  useEffect(() => {
    if (value) {
      setValues(value.toString().split(','));
    }
  }, [value]);

  if (!props) return <></>;

  return (
    <FormControl variant="standard">
      <FormLabel>{createLabel(props, rules)}</FormLabel>
      <FormGroup>
        {get(props, 'options', []).map((option, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                disabled={preview}
                color="primary"
                onChange={onCheck}
                value={option.id}
                name={`check_${option.id}`}
              />
            }
            label={option.name}
            color="primary"
            checked={values.includes(option.id.toString())}
          />
        ))}
      </FormGroup>
      <FormHelperText>{get(props, 'description')}</FormHelperText>
    </FormControl>
  );
};

Checkboxes.defaultProps = {
  preview: false
};

Checkboxes.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  props: PropsPropType,
  rules: RulesPropType,
  preview: PropTypes.bool
};

export default Checkboxes;
