import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import EditIcon from '@material-ui/icons/Edit';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { UTCDateTimeToLocalTime } from '../../helpers';
import {
  deleteOwnDeviceAction,
  fetchCurrentUserAction,
  updateOwnDeviceAction
} from '../../reducers/auth';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import FormDialog from '../../components/FormDialog';

const useStyles = makeStyles(theme => ({
  availableSeats: {
    marginLeft: theme.spacing(2)
  },
  deviceTable: {
    marginTop: 20,
    marginBottom: 20
  }
}));

const Devices = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openEditDevice, setOpenEditDevice] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const handleOnDelete = device => {
    setSelectedDevice(device);
    setOpenDeleteConfirmation(true);
  };

  const handleOnEdit = device => {
    setSelectedDevice(device);
    setOpenEditDevice(true);
  };

  const handleChangeSelectedDeviceName = ({ target }) => {
    setSelectedDevice({ ...selectedDevice, [target.name]: target.value });
  };

  const handleOnCloseDeleteConfirmation = confirmed => {
    if (confirmed) {
      dispatch(deleteOwnDeviceAction(selectedDevice.id, selectedDevice.license_id));
    }

    setOpenDeleteConfirmation(false);
    setSelectedDevice(null);
  };

  const handleOnCloseEditForm = confirmed => {
    if (confirmed) {
      dispatch(
        updateOwnDeviceAction(selectedDevice.id, selectedDevice.license_id, {
          name: selectedDevice.name
        })
      );
    }

    setSelectedDevice(null);
  };

  useEffect(() => {
    dispatch(fetchCurrentUserAction(currentOrganization.id));
  }, [dispatch, currentOrganization.id]);

  const renderDevicesTable = license => {
    return (
      <Paper key={license.id} className={classes.deviceTable}>
        <Toolbar>
          <Typography variant="h6">{license.license_type.name}</Typography>
          <Chip
            size="small"
            className={classes.availableSeats}
            label={`Available Seats: ${license.available_seats}`}
          />
        </Toolbar>
        {license.devices.length ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Last Login</TableCell>
                  <TableCell align="right">Registration Date</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {license.devices.map(device => (
                  <TableRow key={device.id}>
                    <TableCell component="th" scope="row">
                      {device.name}
                    </TableCell>
                    <TableCell align="right">{UTCDateTimeToLocalTime(device.last_login)}</TableCell>
                    <TableCell align="right">{UTCDateTimeToLocalTime(device.created_at)}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleOnEdit(device)}>
                        <EditIcon color="primary" />
                      </IconButton>
                      <IconButton onClick={() => handleOnDelete(device)}>
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <List>
            <ListItem>No registered devices</ListItem>
          </List>
        )}
      </Paper>
    );
  };

  return (
    <>
      <Container component="main">
        {currentOrganization.licenses
          ? currentOrganization.licenses.map(license => {
              return renderDevicesTable(license);
            })
          : 'No Licenses'}
      </Container>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        handleClose={handleOnCloseDeleteConfirmation}
        title="Delete Device"
        bodyText={`Are you sure that you want to remove the device ${
          selectedDevice ? selectedDevice.name : ''
        }?`}
      />
      <FormDialog
        setOpen={setOpenEditDevice}
        open={openEditDevice}
        titleText="Edit Device Name"
        handleClose={handleOnCloseEditForm}
      >
        <TextField
          autoFocus
          margin="dense"
          id="name"
          name="name"
          label="Device Name"
          type="text"
          value={selectedDevice ? selectedDevice.name : ''}
          onChange={handleChangeSelectedDeviceName}
          fullWidth
        />
      </FormDialog>
    </>
  );
};

Devices.propTypes = {};

Devices.defaultProps = {};

export default Devices;
