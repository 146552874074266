import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import React from 'react';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import CardContent from '@material-ui/core/CardContent';
import { CardActions, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import { replaceNullToEmpty } from '../../../helpers';
import LoadingButton from '../../../components/LoadingButton';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import Can from '../../../components/Can';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  submit: {
    marginLeft: 'auto'
  }
}));

const PromotionCodeForm = ({ onSubmit, isLoading, promotionCode, isDeleting, onDelete }) => {
  const classes = useStyles();
  const emptyPromotionCode = {
    code: '',
    description: '',
    value: '',
    max_uses: '',
    promotion_code_type_id: 1,
    starts_at: null,
    expires_at: null
  };

  const allowOnlyDisable = () => {
    return promotionCode && promotionCode.id === 1;
  };

  const formatPromotionCode = currentPromotionCode => {
    return {
      ...currentPromotionCode,
      starts_at: currentPromotionCode.starts_at_date,
      expires_at: currentPromotionCode.expires_at_date
    };
  };

  return (
    <Formik
      initialValues={
        promotionCode
          ? replaceNullToEmpty(formatPromotionCode(promotionCode), ['starts_at', 'expires_at'])
          : emptyPromotionCode
      }
      enableReinitialize
      validationSchema={Yup.object().shape({
        code: Yup.string()
          .required('Required')
          .matches(/^[A-Z0-9]*$/, 'Must contain only capital letters and numbers'),
        description: Yup.string().required('Required'),
        value: Yup.number()
          .required('Required')
          .min(0, 'Value cannot be negative.'),
        promotion_code_type_id: Yup.number().required('Required'),
        starts_at: Yup.date().nullable(),
        expires_at: Yup.date().nullable()
      })}
      onSubmit={values => {
        return onSubmit(values);
      }}
    >
      {({ isValid, dirty, values, setFieldValue }) => (
        <Form className={classes.form} noValidate>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  fullWidth
                  required
                  disabled={!!promotionCode}
                  id="code"
                  label="Code"
                  name="code"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="description"
                  variant="outlined"
                  disabled={allowOnlyDisable()}
                  required
                  fullWidth
                  id="description"
                  label="Description"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  disabled={allowOnlyDisable()}
                  id="value"
                  label="Value"
                  type="number"
                  min="0"
                  name="value"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  component={TextField}
                />
              </Grid>
              {promotionCode && (
                <Grid item xs={12} sm={6}>
                  <Field
                    variant="outlined"
                    disabled
                    fullWidth
                    id="uses"
                    label="Uses"
                    name="uses"
                    component={TextField}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  fullWidth
                  id="max_uses"
                  label="Max Uses"
                  name="max_uses"
                  disabled={allowOnlyDisable()}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          variant="inline"
                          autoOk
                          fullWidth
                          id="starts_at"
                          disableToolbar
                          label="Start Date"
                          inputVariant="outlined"
                          format="YYYY-MM-DD"
                          value={values.starts_at}
                          onChange={value =>
                            setFieldValue(
                              'starts_at',
                              moment.isMoment(value) ? value.format('YYYY-MM-DD') : value
                            )
                          }
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          variant="inline"
                          autoOk
                          fullWidth
                          id="expires_at"
                          disableToolbar
                          label="Expiration Date"
                          inputVariant="outlined"
                          format="YYYY-MM-DD"
                          value={values.expires_at}
                          onChange={value =>
                            setFieldValue(
                              'expires_at',
                              moment.isMoment(value) ? value.format('YYYY-MM-DD') : value
                            )
                          }
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={isLoading}
              disabled={!dirty || !isValid || isLoading}
              className={classes.submit}
            >
              {promotionCode ? 'Update' : 'Create'}
            </LoadingButton>
            {promotionCode && (
              <Can
                permissions={['promotion-codes.manage']}
                yes={() => (
                  <ConfirmationDialogOnClickWrapper
                    confirmationBody="Are you sure you want to delete the current promotion code?"
                    confirmationTitle="Delete Promotion Code"
                  >
                    <LoadingButton
                      loading={isDeleting}
                      color="secondary"
                      variant="contained"
                      disabled={isLoading || isDeleting || allowOnlyDisable()}
                      onClick={onDelete}
                    >
                      Delete
                    </LoadingButton>
                  </ConfirmationDialogOnClickWrapper>
                )}
              />
            )}
          </CardActions>
        </Form>
      )}
    </Formik>
  );
};

PromotionCodeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  promotionCode: PropTypes.object,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool
};

PromotionCodeForm.defaultProps = {
  promotionCode: null,
  onDelete: () => {},
  isDeleting: false
};

export default PromotionCodeForm;
