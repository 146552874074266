import React, { useMemo } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 70
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
}));

const Controls = ({ activeStep, lastStep, steps, handleNext, handleBack }) => {
  const classes = useStyles();

  const currentStep = useMemo(() => get(steps, [activeStep], null), [activeStep, steps]);

  const isButtonDisabled = useMemo(() => currentStep.disabled, [currentStep]);

  if (!currentStep.showControls) return null;

  return (
    <div className={classes.buttons}>
      {currentStep.showBack && (
        <Button onClick={handleBack} className={classes.button}>
          Back
        </Button>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleNext}
        className={classes.button}
        disabled={isButtonDisabled}
      >
        {activeStep === lastStep - 1 ? 'Place order' : 'Next'}
      </Button>
    </div>
  );
};

Controls.propTypes = {
  activeStep: PropTypes.number.isRequired,
  lastStep: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  steps: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired
};

export default Controls;
