import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class FormBuilderApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  getForm(params = {}) {
    return this.request({
      method: 'get',
      url: `/form_builders`,
      params
    });
  }

  update(id, data) {
    return this.request({
      method: 'put',
      data: data,
      url: `/form_builders/${id}`
    });
  }

  create(data) {
    return this.request({
      method: 'post',
      url: `/form_builders`,
      data
    });
  }

  getControlAuditHistory(id, type) {
    return this.request({
      method: 'get',
      url: `/form_builders/controls/histories/${id}/${type}`
    });
  }
}
