import FeedbacksApiService from '../services/api/feedbacks';

const initialState = {
  fetched: false,
  organizationId: null,
  patient: null,
  showDisableOption: false,
  disabled: false,
  submitting: false
};

const RETRIEVED_SUGGESTED_CASE_FOR_FEEDBACK = 'RETRIEVED_SUGGESTED_CASE_FOR_FEEDBACK';
const SUBMITTING_CASE_FEEDBACK = 'SUBMITTING_CASE_FEEDBACK';
const SUBMITTED_CASE_FEEDBACK = 'SUBMITTED_CASE_FEEDBACK';

export default (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVED_SUGGESTED_CASE_FOR_FEEDBACK: {
      return {
        fetched: true,
        ...action.payload
      };
    }
    case SUBMITTING_CASE_FEEDBACK: {
      return {
        ...state,
        fetched: false,
        patient: null,
        submitting: true
      };
    }
    case SUBMITTED_CASE_FEEDBACK: {
      return {
        ...state,
        submitting: false
      };
    }
    default:
      return state;
  }
};

export const getCase = organizationId => {
  return dispatch => {
    const api = new FeedbacksApiService();
    api.getCase(organizationId).then(response => {
      dispatch({
        type: RETRIEVED_SUGGESTED_CASE_FOR_FEEDBACK,
        payload: {
          organizationId,
          patient: response.data,
          showDisableOption: response.metadata.allow_disable,
          disabled: response.metadata.disabled
        }
      });
    });
  };
};

export const refreshCase = () => {
  return (dispatch, getState) => {
    const { organizationId } = getState().caseFeedback;
    if (!organizationId) {
      return;
    }
    dispatch(getCase(organizationId));
  };
};

export const rate = (score, options) => {
  return (dispatch, getState) => {
    const {
      patient: { id },
      submitting
    } = getState().caseFeedback;
    if (submitting) {
      return;
    }
    dispatch({ type: SUBMITTING_CASE_FEEDBACK });
    const api = new FeedbacksApiService();
    api.submit(id, score, options).then(() => {
      dispatch({ type: SUBMITTED_CASE_FEEDBACK });
    });
  };
};
