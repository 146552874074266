import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { CardActions, CardContent, CardHeader, Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import LoadingButton from '../../../components/LoadingButton';
import CustomFileInput from './CustomFileInput';
import LinearProgressBar from '../../../components/LinearProgressBar';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  cardActions: {
    justifyContent: 'flex-end'
  }
}));

const UploadInstaller = ({ uploadProgress, installers, onSubmit, isSaving, installer }) => {
  const classes = useStyles();
  const initialValues = installer || {
    v1: '',
    v2: '',
    v3: '',
    version: '',
    release_notes: '',
    installer_file: null
  };

  const prepareDataForSubmission = values => {
    const versionSplitted = values.version.split('.');
    const releaseNotes = values.release_notes.trim().replace(/(?:\r\n|\r|\n)/g, '<br>');
    const formData = new FormData();

    formData.append('release_notes', releaseNotes);
    formData.append('version', values.version);
    formData.append('installer_file', values.installer_file);
    formData.append('v1', versionSplitted[0]);
    formData.append('v2', versionSplitted[1]);
    formData.append('v3', versionSplitted[2]);

    return formData;
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={Yup.object().shape({
        release_notes: Yup.string().required('Required'),
        version: Yup.string()
          .required('Required')
          .test('Version Test', 'Invalid format e.g. 2.12.8', value => {
            if (value) {
              return /^\d{1,2}\.\d{1,2}\.\d{1,2}$/.test(value.replace(/\s/g, ''));
            }
            return false;
          })
          .test('Version Uniqueness Test', 'Version already exists', value => {
            return !installers.find(item => item.version === value);
          }),
        installer_file: Yup.mixed()
          .required('A file is required')
          .test('fileFormat', 'Unsupported Format! Allowed formats: .exe and .msi', value => {
            if (value) {
              return /^.*\.(msi|exe)$/.test(value.name.replace(/\s/g, ''));
            }
            return false;
          })
      })}
      onSubmit={(values, formikHelpers) => {
        const formData = prepareDataForSubmission(values);
        return onSubmit(formData, formikHelpers);
      }}
    >
      {({ values, setFieldValue, handleBlur, errors, touched, isValid, dirty }) => (
        <Card>
          <CardHeader title="Add Installer" titleTypographyProps={{ variant: 'h6' }} />
          <Divider />
          <Form className={classes.form} noValidate>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field
                    variant="outlined"
                    id="version"
                    required
                    fullWidth
                    label="Version"
                    name="version"
                    helperText="e.g. 1.3.2"
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="installer_file"
                    component={CustomFileInput}
                    title="Select installer file"
                    value={values.installer_file ? values.installer_file.name : ''}
                    setFieldValue={setFieldValue}
                    errorMessage={errors.installer_file ? errors.installer_file : undefined}
                    touched={touched.installer_file}
                    style={{ display: 'flex' }}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Field
                    variant="outlined"
                    id="release_notes"
                    required
                    fullWidth
                    label="Release Notes"
                    name="release_notes"
                    multiline
                    minRows={8}
                    maxRows={8}
                    component={TextField}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions className={classes.cardActions}>
              <LinearProgressBar uploadProgress={uploadProgress} showProgress={isSaving} />
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={isSaving}
                disabled={!dirty || !isValid || isSaving}
                className={classes.submit}
              >
                Save
              </LoadingButton>
            </CardActions>
          </Form>
        </Card>
      )}
    </Formik>
  );
};

UploadInstaller.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  uploadProgress: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  installer: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  installers: PropTypes.array.isRequired
};

UploadInstaller.defaultProps = {
  installer: null
};

export default UploadInstaller;
