import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import { UTCDateTimeToLocalTime } from '../../../../helpers';
import MoneyFormat from '../../../MoneyFormat';

const useStyles = makeStyles(theme => {
  return {
    listItem: {
      padding: theme.spacing(1, 0)
    },
    total: {
      fontWeight: 700
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      '& > th, & > td': {
        textAlign: 'center'
      },
      '& td': {
        paddingTop: 3,
        paddingBottom: 3,
        '&:first-child': {
          textAlign: 'left'
        },
        '&:last-child': {
          textAlign: 'right'
        }
      }
    },
    patientDetails: {
      '&:not(.compact) > td': {
        fontWeight: 500
      },
      '&:not(:first-child) td': {
        paddingTop: 15
      },
      '&.compact td': {
        paddingTop: 5
      }
    },
    patientSubDetails: {
      '& td': {
        paddingLeft: 5
      }
    },
    patientSubItem: {
      color: '#999999'
    },
    patientTotal: {
      borderBottom: '#001028 1px solid',
      '& > td': {
        paddingTop: 5,
        paddingBottom: 10
      },
      '& > td:not(:first-child)': {
        color: 'black'
      }
    }
  };
});

const V2 = ({ order, detailed }) => {
  const { patients = [] } = order.template_data || {};
  const classes = useStyles();

  if (!detailed) {
    if (patients.length === 0) {
      return null;
    }
    const total = patients.reduce((partialSum, patient) => partialSum + patient.total, 0);
    return (
      <>
        <ListItem className={classes.listItem}>
          <ListItemText primary={`${patients.length}x Patients`} />
          <Typography variant="subtitle1" className={classes.total}>
            <MoneyFormat value={total} />
          </Typography>
        </ListItem>
        <Divider />
      </>
    );
  }

  return (
    <table className={classes.table}>
      <tbody>
        {patients.map(patient => {
          const hasSubItems = patient.deductions.length !== 0 || patient.add_ons.length !== 0;

          let doctorName = `Dr. ${patient.doctor_name}`;

          if (patient.doctor_account_number) {
            doctorName += ` - ${patient.doctor_account_number}`;
          }

          return (
            <React.Fragment key={patient.patient_name + patient.case_number_text}>
              <tr className={[classes.patientDetails, 'compact'].join(' ')}>
                <td>
                  {patient.patient_name} | {doctorName}
                </td>
                <td />
              </tr>
              <tr
                className={[
                  classes.patientSubItem,
                  classes.patientSubDetails,
                  !hasSubItems ? classes.patientTotal : ''
                ].join(' ')}
              >
                <td>
                  {UTCDateTimeToLocalTime(patient.creation_date)}{' '}
                  {patient.case_number_text && ` | Case #${patient.case_number_text}`}
                </td>
                <td>
                  <MoneyFormat value={patient.base_fee} />
                </td>
              </tr>
              {patient.deductions.map(({ label, value }) => {
                return (
                  <tr key={label} className={classes.patientSubItem}>
                    <td>{label}</td>
                    <td>
                      <MoneyFormat value={value * -1} />
                    </td>
                  </tr>
                );
              })}
              {patient.add_ons.map(({ label, value, details }) => {
                return (
                  <tr key={label} className={classes.patientSubItem}>
                    <td>
                      {label} {details && `(${details})`}
                    </td>
                    <td>
                      <MoneyFormat value={value} />
                    </td>
                  </tr>
                );
              })}
              {hasSubItems && (
                <tr className={classes.patientTotal}>
                  <td>Total:</td>
                  <td>
                    <MoneyFormat value={patient.total} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

V2.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.object.isRequired,
  detailed: PropTypes.bool.isRequired
};

export default V2;
