import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, IconButton, InputAdornment, TextField, Grid } from '@material-ui/core';
import IncrementIcon from '@material-ui/icons/KeyboardArrowUp';
import DecrementIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import { isNumber } from 'lodash';

const useStyles = makeStyles(() => ({
  inputContainer: {
    '& .MuiInputBase-input': {
      width: '30px',
      fontSize: '0.8rem',
      padding: '10px 3px',
      textAlign: 'center !important'
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0
    },
    '& .MuiInputAdornment-root': {
      marginLeft: 0
    }
  },
  inventoryButton: {
    padding: 0
  },
  fontGreen: {
    color: green[400]
  },
  fontRed: {
    color: red[400]
  },
  additionalQtyIndicatorContainer: {
    maxWidth: '20px'
  }
}));

const InputQty = ({
  qty: initialQty,
  onChange,
  additionalQty: initialAdditionalQty,
  className,
  disabled,
  readOnly
}) => {
  const classes = useStyles();

  const [additionalQty, setAdditionalQty] = useState(initialAdditionalQty || null);
  const prevAdditionalQty = useRef(initialAdditionalQty || null);

  const calculateTotal = useCallback((qty, additional) => {
    return +(qty || 0) + +(additional || 0);
  }, []);

  const totalQty = useMemo(() => calculateTotal(initialQty, additionalQty), [
    initialQty,
    additionalQty,
    calculateTotal
  ]);

  useEffect(() => {
    if (initialAdditionalQty !== null && isNumber(initialAdditionalQty)) {
      setAdditionalQty(initialAdditionalQty);
    }
  }, [initialAdditionalQty]);

  useEffect(() => {
    if (
      additionalQty !== null &&
      isNumber(additionalQty) &&
      prevAdditionalQty.current !== additionalQty
    ) {
      onChange({
        additionalQty,
        qty: initialQty,
        totalQty: calculateTotal(initialQty, additionalQty)
      });
      prevAdditionalQty.current = additionalQty;
    }
  }, [additionalQty, initialQty, totalQty, calculateTotal, onChange]);

  const isValid = additional => {
    const total = calculateTotal(initialQty, additional);

    return total >= 0;
  };

  const handleIncrement = () => {
    setAdditionalQty(prev => {
      const additional = +prev + 1;

      if (!isValid(additional)) {
        return prev;
      }

      return additional;
    });
  };

  const handleDecrement = () => {
    setAdditionalQty(prev => {
      const additional = +prev - 1;

      if (!isValid(additional)) {
        return prev;
      }

      return additional;
    });
  };

  const handleInputChange = e => {

    if (readOnly) {
      return;
    }

    const total = e.target.value === '' ? 0 : e.target.value;

    const regex = /^[0-9]*$/;
    if (!regex.test(e.target.value)) {
      return;
    }

    setAdditionalQty(prev => {
      const additional = total - +initialQty;

      if (!isValid(additional)) {
        return prev;
      }

      return additional;
    });
  };

  const renderAdditionalQtyIndicator = useMemo(() => {
    if (additionalQty > 0) {
      return <span className={classes.fontGreen}>+{additionalQty}</span>;
    }
    if (additionalQty < 0) {
      return <span className={classes.fontRed}>{additionalQty}</span>;
    }
    return <span>&nbsp;</span>;
  }, [additionalQty, classes.fontGreen, classes.fontRed]);

  return (
    <Grid container direction="column" alignItems="center" spacing={0} className={className}>
      <Grid item className={classes.additionalQtyIndicatorContainer}>
        {renderAdditionalQtyIndicator}
      </Grid>
      <Grid item>
        <TextField
          className={classes.inputContainer}
          size="small"
          variant="outlined"
          value={totalQty}
          onInput={handleInputChange}
          disabled={disabled}
          InputProps={{
            readOnly: readOnly,
            endAdornment: readOnly ? (
              undefined
            ) : (
              <InputAdornment position="end">
                <Box
                  display="flex"
                  flexDirection="column"
                  className="MuiOutlinedInput-notchedOutline"
                >
                  <IconButton
                    className={classes.inventoryButton}
                    variant="outlined"
                    onClick={handleIncrement}
                    size="small"
                  >
                    <IncrementIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    className={classes.inventoryButton}
                    variant="outlined"
                    onClick={handleDecrement}
                    size="small"
                  >
                    <DecrementIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              </InputAdornment>
            )
          }}
        />
      </Grid>
    </Grid>
  );
};

InputQty.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  qty: PropTypes.number,
  additionalQty: PropTypes.number,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool
};

InputQty.defaultProps = {
  onChange: () => {},
  qty: null,
  additionalQty: null,
  className: undefined,
  disabled: false,
  readOnly: false
};

export default InputQty;
