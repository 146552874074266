import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LoadingButton from '../../../components/LoadingButton';
import Can from '../../../components/Can';
import CaseReversalInfoDialog from './CaseReversalInfoDialog';
import PatientsApiService from '../../../services/api/patients';

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: 'auto'
  }
}));

const ReverseCaseAction = ({ patient, isLoading, isReversing, onReverse }) => {
  const [infoDialog, setInfoDialog] = useState(false);
  const classes = useStyles();
  const [complianceResult, setComplianceResult] = useState(null);
  const [isComplianceLoading, setIsComplianceLoading] = useState(false);

  useEffect(() => {
    setIsComplianceLoading(true);
    const patientsApiService = new PatientsApiService();
    patientsApiService.checkCaseReversalCompliance(patient.id).then(response => {
      setIsComplianceLoading(false);
      setComplianceResult(response.data);
    });
    return () => {
      setComplianceResult(null);
      setIsComplianceLoading(false);
      setInfoDialog(false);
    };
  }, [patient.id]);

  const onReverseClick = useCallback(() => {
    setInfoDialog(true);
  }, [setInfoDialog]);

  if (!complianceResult || !complianceResult.is_compliant) {
    return null;
  }

  return (
    <>
      <Can
        permissions={[
          'patients.manage',
          'patients.case-reversal',
          'organization.patients.case-reversal'
        ]}
        yes={() => (
          <LoadingButton
            loading={isReversing || isComplianceLoading}
            color="primary"
            variant="contained"
            disabled={isLoading || isReversing || isComplianceLoading}
            className={classes.button}
            onClick={onReverseClick}
          >
            Reverse Case
          </LoadingButton>
        )}
      />
      <CaseReversalInfoDialog
        setOpen={setInfoDialog}
        open={infoDialog}
        onReverse={onReverse}
        complianceResult={complianceResult}
      />
    </>
  );
};

ReverseCaseAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isReversing: PropTypes.bool.isRequired,
  onReverse: PropTypes.func.isRequired
};

export default ReverseCaseAction;
