import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import LoadingButton from '../../LoadingButton';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    width: '400px',
    maxWidth: '400px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    '& label': {
      position: 'relative',
      color: theme.palette.text.primary,
      fontWeight: '300',
      height: '40px',
      lineHeight: '40px',
      marginLeft: '20px',
      display: 'flex',
      flexDirection: 'row',
      '& > span': {
        width: '100px',
        textAlign: 'right',
        marginRight: '30px'
      }
    }
  },
  field: {
    border: 0,
    outline: 'none',
    flex: 1,
    paddingRight: '10px',
    paddingLeft: '10px',
    cursor: 'text',
    color: '#31325f',
    fontSize: '15px',
    fontWeight: '300'
  },
  group: {
    background: 'white',
    boxShadow: '0 7px 14px 0 rgba(49, 49, 93, 0.1) 0 3px 6px 0 rgba(0, 0, 0, 0.08)',
    borderRadius: '4px',
    marginBottom: '20px'
  },
  expiryMonth: {
    width: '40px',
    height: '100%',
    display: 'inline-block'
  },
  expiryYear: {
    width: '55px',
    height: '100%',
    display: 'inline-block'
  },
  error: {
    textAlign: 'right',
    display: 'block',
    color: 'red',
    fontSize: 'small',
    margin: '0'
  }
}));

const UpdateExpirationDateForm = ({ onSubmit }) => {
  const setFieldValueFormik = useRef(null);
  const classes = useStyles();

  // eslint-disable-next-line react/prop-types
  const ErrorMessageText = ({ children }) => {
    return <p className={classes.error}>{children}</p>;
  };

  return (
    <Paper className={classes.paper}>
      <CssBaseline />
      <Formik
        initialValues={{
          expiry_month: '',
          expiry_year: ''
        }}
        validationSchema={Yup.object().shape({
          expiry_month: Yup.string()
            .length(2)
            .required('Required'),
          expiry_year: Yup.string()
            .length(4)
            .required('Required')
            // eslint-disable-next-line func-names
            .test('valid_expiry', 'Invalid Expiration Date', function(value) {
              // eslint-disable-next-line camelcase,react/no-this-in-sfc
              const { expiry_month } = this.parent;
              const now = new Date();
              const year = now.getFullYear();
              const month = now.getMonth() + 1;

              // eslint-disable-next-line camelcase
              if (expiry_month > 12) {
                return false;
              }

              if (parseInt(value, 10) > year) {
                return true;
              }

              return !!(year === parseInt(value, 10) && month <= parseInt(expiry_month, 10));
            })
        })}
        onSubmit={async values => {
          await onSubmit(values);
        }}
      >
        {({ setFieldValue, errors, touched, isSubmitting }) => {
          setFieldValueFormik.current = setFieldValue;

          return (
            <Form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={classes.group}>
                    <label>
                      <span>Expiration Date</span>
                      <div className={classes.expiryMonth}>
                        <Field
                          className={classes.field}
                          name="expiry_month"
                          maxLength="2"
                          id="expiry-month"
                          placeholder="MM"
                          required
                          onKeyUp={e => {
                            const keyCode = e.which || e.keyCode;

                            const { target } = e;

                            // Tab = 9
                            // Shift + Tab = 16
                            if (keyCode === 9 || keyCode === 16 || target.value.length !== 2)
                              return;

                            target.blur();

                            target
                              .closest('form')
                              .querySelector('input[name=expiry_year]')
                              .focus();
                          }}
                        />
                      </div>
                      <div>/</div>
                      <div className={classes.expiryYear}>
                        <Field
                          className={classes.field}
                          name="expiry_year"
                          maxLength="4"
                          required
                          placeholder="YYYY"
                        />
                      </div>
                    </label>
                    {(touched.expiry_month || touched.expiry_year) &&
                      (errors.expiry_month || errors.expiry_year) && (
                        <ErrorMessageText>Invalid Expiration Date</ErrorMessageText>
                      )}
                  </div>
                </Grid>
              </Grid>
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                loading={isSubmitting}
                disabled={isSubmitting}
                className={classes.submit}
              >
                Save
              </LoadingButton>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
};

UpdateExpirationDateForm.propTypes = {
  onSubmit: PropTypes.func
};

UpdateExpirationDateForm.defaultProps = {
  onSubmit: () => {}
};

export default UpdateExpirationDateForm;
