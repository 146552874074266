import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { get } from 'lodash';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import Grid from '@material-ui/core/Grid';
import ImageList from '@material-ui/core/ImageList';
import GridListTile from '@material-ui/core/ImageListItem';
import GridListTileBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Img from 'react-image';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  DELETE_PATIENT_FILE_PREFIX,
  deletePatientFileAction,
  fetchImageUrls
} from '../../../reducers/patients';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import createLoadingSelector from '../../../selectors/loading';
import CustomFullScreenImagesView from '../../../components/CustomFullScreenImagesView';
import Can from '../../../components/Can';
import { fetchDownloadUrlPrintingFileAction } from '../../../reducers/printingFiles';
import { isImage } from '../../../helpers';

const useStyles = makeStyles(theme => ({
  noFoundChip: {
    marginLeft: theme.spacing(2)
  },
  addButton: {
    marginLeft: theme.spacing(2)
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  icon: {
    color: 'white'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    color: theme.palette.primary.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -15,
    marginLeft: -15
  },
  image: {
    cursor: 'zoom-in'
  },
  imageFullHeight: {
    height: '100%',
    cursor: 'zoom-in'
  },
  gridList: {
    width: '100%'
  },
  gridListFullHeight: {
    '& > .MuiImageListItem-item': {
      justifyContent: 'center',
      display: 'flex',
      background:
        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 20%, rgba(0,0,0,0) 100%)'
    }
  },
  AccordionSummary: {
    '& > .MuiExpansionPanelSummary-content': {
      justifyContent: 'space-between'
    }
  },
  titleContainer: {
    alignItems: 'center'
  },
  mainTitle: {
    display: 'flex'
  },
  extraTitle: {
    textAlign: 'right'
  },
  fileContainer: {
    '& > .MuiImageListItem-item': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}));

const modalCustomStyles = {
  blanket: base => ({
    ...base,
    zIndex: 1200
  }),
  positioner: base => ({
    ...base,
    zIndex: 1201
  })
};

const PatientFilesGallery = ({
  patient,
  patientFiles,
  title,
  fileTypeId,
  openUploadDialog,
  expandedAndLoadImages,
  showNumberOfImages,
  fullHeightImage
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [lightBoxModalIsOpen, setLightBoxModalIsOpen] = useState(false);
  const isDeleting = useSelector(state =>
    createLoadingSelector([DELETE_PATIENT_FILE_PREFIX])(state)
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const fetchImages = useCallback(() => {
    if (isLoaded) {
      return;
    }
    dispatch(
      fetchImageUrls(
        patient.id,
        fileTypeId,
        patientFiles.map(file => file.id)
      )
    ).then(() => {
      setIsLoaded(true);
    });
  }, [patient, fileTypeId, patientFiles, isLoaded, setIsLoaded, dispatch]);

  useEffect(() => {
    if (expandedAndLoadImages) {
      fetchImages();
    }
  }, [expandedAndLoadImages, fetchImages]);

  const openRemoveConfirmation = id => {
    setSelectedItem(id);
    setOpenDeleteConfirmation(true);
  };

  const handleCloseConfirmation = answer => {
    setOpenDeleteConfirmation(false);
    if (answer) {
      dispatch(deletePatientFileAction(patient.id, fileTypeId, selectedItem));
    }
  };

  const toggleLightBoxModal = () => {
    setLightBoxModalIsOpen(false);
  };

  const openLightBoxModel = id => {
    setSelectedItem(id);
    setLightBoxModalIsOpen(true);
  };

  const downloadFile = id => {
    dispatch(fetchDownloadUrlPrintingFileAction(id));
  };

  const renderAddButton = () => {
    if (!openUploadDialog) {
      return '';
    }
    return (
      <Button
        className={classes.addButton}
        variant="contained"
        size="small"
        startIcon={<AddIcon />}
        color="primary"
        onClick={event => {
          event.stopPropagation();
          openUploadDialog({ title, fileTypeId });
        }}
      >
        Add
      </Button>
    );
  };

  const renderImagesCount = count => <Typography>({count})</Typography>;

  if (!patientFiles.length) {
    return (
      <Paper>
        <Toolbar>
          <Typography variant="subtitle1">{title}</Typography>
          <Chip size="small" className={classes.noFoundChip} label={`No ${title} found`} />
          {!patient.deleted_at && renderAddButton()}
        </Toolbar>
      </Paper>
    );
  }

  let imageClassName = clsx(classes.image, 'MuiGridListTile-imgFullWidth');
  const imageFiles = patientFiles.filter(file => isImage(file.name));

  if (fullHeightImage) {
    imageClassName = clsx(classes.imageFullHeight, 'MuiGridListTile-imgFullWidth');
  }

  const handleExpand = (event, isExpanded) => {
    if (isExpanded) {
      fetchImages();
    }
  };

  const fileList = get(patient, `files[${fileTypeId}]`, []).filter(file => {
    return patientFiles.findIndex(patientFile => patientFile.id === file.id) !== -1;
  });

  return (
    <>
      <Accordion defaultExpanded={expandedAndLoadImages} onChange={handleExpand}>
        <AccordionSummary className={classes.AccordionSummary} expandIcon={<ExpandMoreIcon />}>
          <Grid container className={classes.titleContainer}>
            <Grid item xs={11} className={classes.mainTitle}>
              <Typography className={classes.heading}>{title}</Typography>
              {!patient.deleted_at && renderAddButton()}
            </Grid>
            <Grid item xs={1} className={classes.extraTitle}>
              {showNumberOfImages && renderImagesCount(imageFiles.length)}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <ImageList rowHeight={160} className={classes.gridList} cols={3}>
            {fileList.map(tile => {
              return (
                <GridListTile
                  rows={fullHeightImage ? 2 : 1}
                  className={`${
                    // eslint-disable-next-line no-nested-ternary
                    !isImage(tile.name)
                      ? classes.fileContainer
                      : fullHeightImage
                      ? classes.gridListFullHeight
                      : ''
                  }`}
                  key={tile.id}
                  cols={tile.cols || 1}
                  title={tile.updated_at}
                >
                  {!isImage(tile.name) &&
                    (tile.name.endsWith('.pdf') ? <PictureAsPdf /> : <InsertDriveFile />)}
                  {isImage(tile.name) && (
                    <Img
                      width="100%"
                      title={tile.created_at}
                      src={[tile.thumbnail_url, tile.url]}
                      alt={tile.caption}
                      className={`${imageClassName} test-12345`}
                      onClick={() => openLightBoxModel(tile.id)}
                      loader={<LinearProgress />}
                    />
                  )}
                  <GridListTileBar
                    actionIcon={
                      <div className={classes.wrapper}>
                        {!patient.deleted_at && !isImage(tile.name) && (
                          <IconButton
                            title="Download"
                            className={classes.icon}
                            onClick={() => downloadFile(tile.id)}
                            disabled={isDeleting}
                          >
                            <CloudDownloadIcon />
                          </IconButton>
                        )}
                        {!patient.deleted_at && (
                          <Can
                            permissions={[
                              'patient-files.manage',
                              'organization.patient-files.delete',
                              'patient-files.delete'
                            ]}
                            organizationId={patient.organization ? patient.organization.id : null}
                            yes={() => (
                              <IconButton
                                title="Remove"
                                className={classes.icon}
                                onClick={() => openRemoveConfirmation(tile.id)}
                                disabled={isDeleting}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          />
                        )}
                        {isDeleting && tile.id === selectedItem && (
                          <CircularProgress size={30} className={classes.buttonProgress} />
                        )}
                      </div>
                    }
                    className={classes.titleBar}
                  />
                </GridListTile>
              );
            })}
          </ImageList>
        </AccordionDetails>
      </Accordion>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        handleClose={handleCloseConfirmation}
        title="Delete Confirmation"
        bodyText="Are you sure that you want to remove this image?"
      />
      <ModalGateway>
        {lightBoxModalIsOpen ? (
          <Modal onClose={toggleLightBoxModal} styles={modalCustomStyles}>
            <Carousel
              currentIndex={fileList.findIndex(file => file.id === selectedItem)}
              views={fileList.map(image => ({
                src: image.url
              }))}
              components={{ View: CustomFullScreenImagesView }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

PatientFilesGallery.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  patientFiles: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  fileTypeId: PropTypes.number.isRequired,
  openUploadDialog: PropTypes.func,
  showNumberOfImages: PropTypes.bool,
  expandedAndLoadImages: PropTypes.bool,
  fullHeightImage: PropTypes.bool
};

PatientFilesGallery.defaultProps = {
  patient: {},
  showNumberOfImages: false,
  expandedAndLoadImages: false,
  fullHeightImage: false,
  openUploadDialog: null
};

export default PatientFilesGallery;
