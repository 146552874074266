import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Collapse } from '@material-ui/core';
import HitCardDetails from '../Common/HitCardDetails';
import HitCardHeader from '../Common/HitCardHeader';
import HitCardActions from '../Common/HitCardActions';
import OrganizationNotes from '../../OrganizationNotes';

const visibleAttributes = [
  'organization_type',
  'account_number',
  'number_of_users',
  'number_of_patients',
  'manager',
  'main_location',
  'joined_at',
  'first_case_submission',
  'newest_case_submission',
  'average_monthly_submissions',
  'average_monthly_submissions_diff_percentage'
];

const attributeTitles = {
  average_monthly_submissions_diff_percentage: 'Average Monthly Submissions Diff Percentage'
};

const customAttributeRenderers = {
  average_monthly_submissions_diff_percentage: value => {
    const percentage = Number(value);
    const color = percentage > 0 ? 'green' : 'red';
    const symbol = percentage > 0 ? '↑' : '↓';
    return (
      <>
        {Math.abs(percentage)}% {percentage !== 0 && <span style={{ color }}>{symbol}</span>}
      </>
    );
  }
};

const HitCard = ({ hit, showDetails, showHeader, exclude = [] }) => {
  const history = useHistory();

  const [expanded, setExpanded] = React.useState(false);
  const [expandedNotes, setExpandedNotes] = useState(false);

  const handleStartACase = () => {
    history.push(`/organizations/${hit.model_id}/rx-forms/add`);
  };

  let isArchived = false;

  if (hit.model === 'organization' && hit.is_archived) {
    isArchived = true;
  }

  const handleExpand = expand => {
    setExpanded(expand);
    setExpandedNotes(expand);
  };

  const toggleNotes = e => {
    e.preventDefault();
    setExpandedNotes(!expandedNotes);
  };

  let actions = [
    {
      label: 'Subscription',
      url: `/subscriptions/${hit.contract_id}`
    },
    {
      label: 'Locations',
      url: `/organizations/${hit.model_id}/locations`
    },
    {
      label: 'Users',
      url: `/users?queryOrganization=${hit.model_id}`
    },
    {
      label: 'Patients',
      url: `/patients-page?queryOrganization=${hit.model_id}&queryName=${hit.full_name}`
    },
    {
      label: 'Onboardings',
      url: `/onboardings/${hit.onboarding_id}`
    },
    {
      label: 'Start Case',
      onclick: handleStartACase
    },
    {
      label: 'Notes',
      onclick: toggleNotes
    },
    {
      label: 'Bracket Libraries',
      onclick: e => {
        e.preventDefault();
        history.push(`/organizations/${hit.model_id}#bracket-libraries`);
      }
    }
  ];
  if (exclude.length) {
    actions = actions.filter(link => !exclude.includes(link.label));
  }

  return (
    <>
      {showHeader && <HitCardHeader hit={hit} expanded={expanded} setExpanded={handleExpand} />}

      {!isArchived && <HitCardActions hit={hit} actions={actions} />}

      {isArchived && (
        <HitCardActions
          hit={hit}
          actions={[
            {
              label: 'Patients',
              url: `/patients-page?queryOrganization=${hit.model_id}&queryName=${hit.full_name}`
            }
          ]}
        />
      )}

      {showDetails && (
        <HitCardDetails
          hit={hit}
          visibleAttributes={visibleAttributes}
          expanded={expanded}
          attributeTitles={attributeTitles}
          customAttributeRenderers={customAttributeRenderers}
        />
      )}

      <Collapse in={expandedNotes} timeout="auto" unmountOnExit>
        <OrganizationNotes notes={hit.management_notes} organizationId={hit.model_id} />
      </Collapse>
    </>
  );
};

HitCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hit: PropTypes.object.isRequired,
  showHeader: PropTypes.bool,
  showDetails: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  exclude: PropTypes.array
};

HitCard.defaultProps = {
  showHeader: true,
  showDetails: true
};

export default HitCard;
