import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function LinearProgressBar({ uploadProgress, showProgress }) {
  if (!showProgress) {
    return null;
  }
  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={uploadProgress} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${uploadProgress}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressBar.propTypes = {
  uploadProgress: PropTypes.number.isRequired,
  showProgress: PropTypes.bool.isRequired
};
