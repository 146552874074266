import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomizeFees from '../../../../components/CustomizeFees';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import {
  fetchOrganizationPricing,
  saveOrganizationPricing
} from '../../../../reducers/organizationPricing';
import { fetchFormBuilderAction } from '../../../../reducers/formBuilder';
import { fetchGlobalPricing } from '../../../../reducers/pricing';

const Main = ({
  defaultPricing,
  fetchingDefaultPricing,
  fetchDefaultPricing,
  fetchingPricing,
  pricing,
  fetchPricing,
  updatePricing
}) => {
  const [state, setState] = useState({
    original: null,
    fees: null
  });
  const { original, fees } = state;

  useEffect(() => {
    if (!pricing && !fetchingPricing) {
      fetchPricing();
    }
    if (!defaultPricing && !fetchingDefaultPricing) {
      fetchDefaultPricing();
    }

    if (state.original && !pricing) {
      setState({
        original: null,
        fees: null
      }); // reset if it's fetching
    }
  }, [
    pricing,
    fetchPricing,
    setState,
    state,
    fetchDefaultPricing,
    defaultPricing,
    fetchingDefaultPricing,
    fetchingPricing
  ]);

  useEffect(() => {
    // update state once fetching is finished to avoid using existing data
    if (!state.original && pricing) {
      setState({
        original: cloneDeep(pricing),
        fees: cloneDeep(pricing)
      });
    }
  }, [state, pricing, setState]);

  const handleOnSave = async () => {
    const data = {
      ...fees,
      arch_volume_discounts: fees.arch_volume_discounts.filter(
        ({ is_global: isGlobal }) => isGlobal === false
      )
    };
    updatePricing(data).then(() => {
      setState({
        original: null,
        fees: null
      }); // reset state
    });
  };

  if (!fees || !defaultPricing) {
    return <LoadingIndicator />;
  }

  return (
    <CustomizeFees
      fees={fees}
      defaultPricing={{ ...original, ...defaultPricing, custom_rxform: original.custom_rxform }}
      originalFees={original}
      discountProps={{
        global: false
      }}
      onSave={handleOnSave}
      onChange={updatedFees => setState({ ...state, fees: updatedFees })}
    />
  );
};

Main.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  defaultPricing: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pricing: PropTypes.object.isRequired,
  fetchingDefaultPricing: PropTypes.bool.isRequired,
  fetchDefaultPricing: PropTypes.func.isRequired,
  fetchingPricing: PropTypes.func.isRequired,
  fetchPricing: PropTypes.func.isRequired,
  updatePricing: PropTypes.func.isRequired
};

export default connect(
  (state, ownProps) => {
    return {
      defaultPricing: state.pricing.global,
      fetchingDefaultPricing: state.pricing.fetching,
      pricing: state.organizationPricing.organizations[ownProps.organization.id]
        ? state.organizationPricing.organizations[ownProps.organization.id].pricing
        : null,
      fetchingPricing: state.organizationPricing.organizations[ownProps.organization.id]
        ? state.organizationPricing.organizations[ownProps.organization.id].fetching
        : false
    };
  },
  (dispatch, ownProps) => {
    return {
      fetchPricing: () => dispatch(fetchOrganizationPricing(ownProps.organization.id)),
      fetchDefaultPricing: () => dispatch(fetchGlobalPricing()),
      updatePricing: pricing =>
        dispatch(saveOrganizationPricing(ownProps.organization.id, pricing)).then(() =>
          dispatch(fetchFormBuilderAction({ organization_id: ownProps.organization.id }))
        )
    };
  }
)(Main);
