import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { ReactComponent as UR7 } from '../../../assets/teeth/U-01.svg';
import { ReactComponent as UR6 } from '../../../assets/teeth/U-02.svg';
import { ReactComponent as UR5 } from '../../../assets/teeth/U-03.svg';
import { ReactComponent as UR4 } from '../../../assets/teeth/U-04.svg';
import { ReactComponent as UR3 } from '../../../assets/teeth/U-05.svg';
import { ReactComponent as UR2 } from '../../../assets/teeth/U-06.svg';
import { ReactComponent as UR1 } from '../../../assets/teeth/U-07.svg';
import { ReactComponent as UL1 } from '../../../assets/teeth/U-08.svg';
import { ReactComponent as UL2 } from '../../../assets/teeth/U-09.svg';
import { ReactComponent as UL3 } from '../../../assets/teeth/U-10.svg';
import { ReactComponent as UL4 } from '../../../assets/teeth/U-11.svg';
import { ReactComponent as UL5 } from '../../../assets/teeth/U-12.svg';
import { ReactComponent as UL6 } from '../../../assets/teeth/U-13.svg';
import { ReactComponent as UL7 } from '../../../assets/teeth/U-14.svg';
import { ReactComponent as LR7 } from '../../../assets/teeth/L-01.svg';
import { ReactComponent as LR6 } from '../../../assets/teeth/L-02.svg';
import { ReactComponent as LR5 } from '../../../assets/teeth/L-03.svg';
import { ReactComponent as LR4 } from '../../../assets/teeth/L-04.svg';
import { ReactComponent as LR3 } from '../../../assets/teeth/L-05.svg';
import { ReactComponent as LR2 } from '../../../assets/teeth/L-06.svg';
import { ReactComponent as LR1 } from '../../../assets/teeth/L-07.svg';
import { ReactComponent as LL1 } from '../../../assets/teeth/L-08.svg';
import { ReactComponent as LL2 } from '../../../assets/teeth/L-09.svg';
import { ReactComponent as LL3 } from '../../../assets/teeth/L-10.svg';
import { ReactComponent as LL4 } from '../../../assets/teeth/L-11.svg';
import { ReactComponent as LL5 } from '../../../assets/teeth/L-12.svg';
import { ReactComponent as LL6 } from '../../../assets/teeth/L-13.svg';
import { ReactComponent as LL7 } from '../../../assets/teeth/L-14.svg';
import Tooth from './Tooth';

const useStyles = makeStyles(() => ({
  toothGrid: {
    // width: '7.14%'
  }
}));

const Teeth = ({ data, onChange, viewOnly }) => {
  const classes = useStyles();

  const teeth = useMemo(() => {
    const getValue = field => _get(data, field, 0);

    return {
      u00: {
        component: UR7,
        value: getValue('u00'),
        label: 'UR8'
      },
      u01: {
        component: UR7,
        value: getValue('u01'),
        label: 'UR7'
      },
      u02: {
        component: UR6,
        value: getValue('u02'),
        label: 'UR6'
      },
      u03: {
        component: UR5,
        value: getValue('u03'),
        label: 'UR5'
      },
      u04: {
        component: UR4,
        value: getValue('u04'),
        label: 'UR4'
      },
      u05: {
        component: UR3,
        value: getValue('u05'),
        label: 'UR3'
      },
      u06: {
        component: UR2,
        value: getValue('u06'),
        label: 'UR2'
      },
      u07: {
        component: UR1,
        value: getValue('u07'),
        label: 'UR1'
      },
      u08: {
        component: UL1,
        value: getValue('u08'),
        label: 'UL1'
      },
      u09: {
        component: UL2,
        value: getValue('u09'),
        label: 'UL2'
      },
      u10: {
        component: UL3,
        value: getValue('u10'),
        label: 'UL3'
      },
      u11: {
        component: UL4,
        value: getValue('u11'),
        label: 'UL4'
      },
      u12: {
        component: UL5,
        value: getValue('u12'),
        label: 'UL5'
      },
      u13: {
        component: UL6,
        value: getValue('u13'),
        label: 'UL6'
      },
      u14: {
        component: UL7,
        value: getValue('u14'),
        label: 'UL7'
      },
      u15: {
        component: UL7,
        value: getValue('u15'),
        label: 'UL8'
      },
      l00: {
        component: LR7,
        value: getValue('l00'),
        label: 'LR8'
      },
      l01: {
        component: LR7,
        value: getValue('l01'),
        label: 'LR7'
      },
      l02: {
        component: LR6,
        value: getValue('l02'),
        label: 'LR6'
      },
      l03: {
        component: LR5,
        value: getValue('l03'),
        label: 'LR5'
      },
      l04: {
        component: LR4,
        value: getValue('l04'),
        label: 'LR4'
      },
      l05: {
        component: LR3,
        value: getValue('l05'),
        label: 'LR3'
      },
      l06: {
        component: LR2,
        value: getValue('l06'),
        label: 'LR2'
      },
      l07: {
        component: LR1,
        value: getValue('l07'),
        label: 'LR1'
      },
      l08: {
        component: LL1,
        value: getValue('l08'),
        label: 'LL1'
      },
      l09: {
        component: LL2,
        value: getValue('l09'),
        label: 'LL2'
      },
      l10: {
        component: LL3,
        value: getValue('l10'),
        label: 'LL3'
      },
      l11: {
        component: LL4,
        value: getValue('l11'),
        label: 'LL4'
      },
      l12: {
        component: LL5,
        value: getValue('l12'),
        label: 'LL5'
      },
      l13: {
        component: LL6,
        value: getValue('l13'),
        label: 'LL6'
      },
      l14: {
        component: LL7,
        value: getValue('l14'),
        label: 'LL7'
      },
      l15: {
        component: LL7,
        value: getValue('l15'),
        label: 'LL8'
      }
    };
  }, [data]);

  const handleToothChange = (value, key) => {
    onChange({
      ...data,
      [key]: value
    });
  };

  const generateTeeth = part =>
    Object.keys(teeth)
      .filter(t => t.startsWith(part))
      .sort()
      .map(key => {
        const tooth = teeth[key];

        return (
          <Grid key={key} className={classes.toothGrid} item>
            <Tooth
              disabled={viewOnly}
              image={tooth.component}
              value={tooth.value}
              label={tooth.label || ''}
              onChange={v => handleToothChange(v, key)}
            />
          </Grid>
        );
      });

  return (
    <div>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs>
          <Typography variant="subtitle1" gutterBottom align="left">
            Upper Right
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="subtitle1" gutterBottom align="center">
            Maxillary
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="subtitle1" gutterBottom align="right">
            Upper Left
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" spacing={0}>
        {generateTeeth('u')}
      </Grid>
      <Grid container justifyContent="center" spacing={0}>
        {generateTeeth('l')}
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs>
          <Typography variant="subtitle1" gutterBottom align="left">
            Lower Right
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="subtitle1" gutterBottom align="center">
            Mandibular
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="subtitle1" gutterBottom align="right">
            Lower Left
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

Teeth.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  viewOnly: PropTypes.bool
};

Teeth.defaultProps = {
  onChange: () => {},
  viewOnly: false
};

export default Teeth;
