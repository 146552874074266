import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Container,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Switch
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import FileList from '../Account/components/BracketLibraries/FileList';
import { fetchBracketLibraries, resetBracketLibraries } from '../../reducers/bracketLibraries';
import LoadingIndicator from '../../components/LoadingIndicator';
import InventoryDialog from '../Account/components/BracketLibraries/InventoryDialog';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(1)
  },
  filterContainer: {
    marginBottom: theme.spacing(2)
  }
}));

const Brackets = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const currentOrganization = useSelector(state => state.auth.currentOrganization);

  const [selectedBracketInventory, setSelectedBracketInventory] = useState(null);
  const [isLowStocksOnly, setIsLowStocksOnly] = useState(false);
  const { fetched = false, fetching = false, items = [], removing = [] } = useSelector(state => {
    return state.bracketLibraries.organizations[currentOrganization.id] || {};
  });

  const brackets = useMemo(() => {
    if (!isLowStocksOnly) {
      return items;
    }

    return items.filter(i => i.low_stock);
  }, [items, isLowStocksOnly]);

  useEffect(() => {
    if (fetched || fetching) {
      return;
    }
    dispatch(fetchBracketLibraries(currentOrganization.id));
  }, [fetched, currentOrganization, dispatch, fetching]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('id') && fetched) {
      const id = Number(queryParams.get('id'));

      queryParams.delete('id');
      history.replace({
        search: queryParams.toString()
      });

      const bracketIndex = items.findIndex(f => +f.id === id);

      if (bracketIndex !== -1) {
        setSelectedBracketInventory(items[bracketIndex]);
      }
    }
  }, [fetched, history, items, location.search]);

  const handleSwitchChange = event => {
    setIsLowStocksOnly(event.target.checked);
    dispatch(resetBracketLibraries(currentOrganization.id));
  };

  const handleRefresh = () => {
    dispatch(resetBracketLibraries(currentOrganization.id));
  };

  if (!currentOrganization) {
    return null;
  }

  return (
    <Container component="main" className={classes.container}>
      <CssBaseline />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.filterContainer}
      >
        <FormControlLabel
          control={
            <Switch
              checked={isLowStocksOnly}
              onChange={handleSwitchChange}
              name="checkedB"
              color="primary"
              disabled={fetching}
            />
          }
          label="Low Inventory Only"
        />
        <Button variant="contained" color="primary" disabled={fetching} onClick={handleRefresh}>
          REFRESH
        </Button>
      </Grid>
      <Paper>
        {selectedBracketInventory && (
          <InventoryDialog
            organization={currentOrganization}
            bracket={selectedBracketInventory}
            open={!!selectedBracketInventory}
            setOpen={() => {
              setSelectedBracketInventory(null);
            }}
            readOnly
          />
        )}
        <Grid container>
          <Grid item xs={12}>
            {fetching && <LoadingIndicator />}
            {items && (
              <FileList
                data={brackets}
                onDelete={() => {}}
                onDownload={() => {}}
                onEdit={() => {}}
                removingItems={removing}
                organizationId={currentOrganization.id}
                onOpenInventory={bracket => {
                  setSelectedBracketInventory(bracket);
                }}
              />
            )}
            {!fetching && items && items.length === 0 && (
              <List>
                <ListItem>
                  <ListItemText primary="No Data" />
                </ListItem>
              </List>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Brackets;
