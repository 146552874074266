import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button } from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Can from '../../../components/Can';
import { restoreBackupIosAction } from '../../../reducers/patients';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import LoadingSpinner from '../../../components/LoadingSpinner';

const useStyles = makeStyles(theme => ({
  restoreButton: {
    marginLeft: theme.spacing(1)
  }
}));

const RestoreBackupIos = ({ patient, hidden }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isRestoring, setIsRestoring] = useState(false);

  const handleRestore = () => {
    const { id } = patient;
    setIsRestoring(true);
    dispatch(restoreBackupIosAction(id)).finally(() => {
      setIsRestoring(false);
    });
  };

  if (hidden) {
    return null;
  }

  return (
    <Can
      permissions={[
        'patients.ios.restore-tech-backup',
        'organization.patients.ios.restore-tech-backup'
      ]}
      organizationId={patient.organization_id}
      yes={() => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div onClick={event => event.stopPropagation()}>
          <ConfirmationDialogOnClickWrapper
            confirmationBody="Are you sure you want to restore the backup IOS file?"
            confirmationTitle="Restore"
          >
            <Button
              variant="contained"
              size="small"
              startIcon={<RestoreIcon />}
              color="secondary"
              className={classes.restoreButton}
              disabled={isRestoring}
              onClick={handleRestore}
            >
              Restore Backup IOS
              <LoadingSpinner show={isRestoring} />
            </Button>
          </ConfirmationDialogOnClickWrapper>
        </div>
      )}
    />
  );
};
RestoreBackupIos.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired,
  hidden: PropTypes.bool
};

RestoreBackupIos.defaultProps = {
  hidden: false
};
export default RestoreBackupIos;
