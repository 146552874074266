import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import { get } from 'lodash';
import CommonPropTypes from '../../../common/PropTypes';
import TrackingNumber from '../../PatientsPage/components/TrackingNumber';

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column'
    }
  };
});

const PatientColumn = ({ patient, collapsed }) => {
  const classes = useStyles();
  const doctor = patient.user;

  const renderPatientName = () => {
    let statusBadge = null;

    if (patient.deleted_at) {
      statusBadge = <Chip color="default" size="small" label="Archived" />;
    } else if (patient.held_at) {
      statusBadge = <Chip color="secondary" size="small" label="On Hold" />;
    }

    return (
      <>
        {patient.formatted_name} {statusBadge}
      </>
    );
  };

  return (
    <>
      <div className={classes.container}>
        <div>
          {renderPatientName()}
          {collapsed && (
            <>
              <Typography variant="caption" component="div" color="textSecondary">
                Dr. {doctor.full_name}
              </Typography>
              <TrackingNumber
                printingRequest={get(patient, 'customer_request.printing_request')}
                onClickTrackingNumber={() => {}}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

PatientColumn.defaultProps = {
  collapsed: false
};

PatientColumn.propTypes = {
  collapsed: PropTypes.bool,
  patient: CommonPropTypes.patient.isRequired
};

export default PatientColumn;
