import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import StarIcon from '@material-ui/icons/Star';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Chip from '@material-ui/core/Chip';
import { get } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Button from '@material-ui/core/Button';
import { Box, CardActions, colors, Divider } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import clsx from 'clsx';
import moment from 'moment';
import {
  deleteMyPaymentMethodAction,
  updateMyPaymentMethodAction,
  updateMyPaymentMethodExpirationDateAction
} from '../../reducers/auth';
import PaymentMethodFormSelector from './components/PaymentMethodFormSelector';
import ConfirmationDialogOnClickWrapper from '../ConfirmationDialogOnClickWrapper';
import UpdateExpirationDateForm from './components/UpdateExpirationDateForm';

const useStyles = makeStyles(() => ({
  addButton: {
    marginLeft: 'auto'
  },
  showAddForm: {
    display: 'block'
  },
  expiringChip: {
    backgroundColor: colors.yellow[200],
    marginLeft: '5px'
  },
  expiredChip: {
    backgroundColor: colors.red[300],
    marginLeft: '5px'
  }
}));

const ManagePaymentMethods = () => {
  const [addPaymentMethod, setAddPaymentMethod] = useState(false);
  const [paymentMethodBeingEdited, setPaymentMethodBeingEdited] = useState(null);
  const [itemBeingDeleted, setItemBeingDeleted] = useState(null);
  const classes = useStyles();
  const currentOrganization = useSelector(state => state.auth.currentOrganization);

  const dispatch = useDispatch();

  const onPaymentMethodSaved = () => {
    setAddPaymentMethod(false);
  };

  const handleDelete = item => {
    setItemBeingDeleted(item);
    dispatch(deleteMyPaymentMethodAction(item.id)).finally(() => setItemBeingDeleted(null));
  };

  const handleMarkAsPrimary = item => {
    dispatch(updateMyPaymentMethodAction(item.id, { is_primary: true }));
  };

  const handleUpdateExpirationDate = async (item, values) => {
    const date = moment(`${values.expiry_year}-${values.expiry_month}`, 'YYYY-MM');
    const lastDayOfMonth = date.endOf('month');
    const datetimeString = lastDayOfMonth.format('YYYY-MM-DD HH:mm:ss');

    await dispatch(
      updateMyPaymentMethodExpirationDateAction(item.id, {
        card_exp_datetime: datetimeString
      })
    );
  };

  const renderExpirationChip = expirationDate => {
    if (moment(expirationDate).isBefore(moment(), 'month')) {
      return <Chip label="Expired" color="inherit" className={classes.expiredChip} size="small" />;
    }

    if (moment(expirationDate).isBefore(moment().add(2, 'months'), 'month')) {
      return (
        <Chip label="Expiring" color="inherit" className={classes.expiringChip} size="small" />
      );
    }

    return null;
  };

  return (
    <Card>
      <CardHeader title="Payment Methods" />
      <Divider />
      <CardContent>
        <List disablePadding className={classes.list}>
          {currentOrganization.payment_info.payment_methods.map(paymentMethod => (
            <ListItem className={classes.listItem} key={paymentMethod.id}>
              <ListItemText
                primary={
                  <>
                    <Chip icon={<CreditCardIcon />} label={paymentMethod.payment_method_nickname} />
                    {paymentMethod.payment_method_type === 'card' &&
                      renderExpirationChip(paymentMethod.card_exp_datetime)}
                  </>
                }
              />
              <ListItemSecondaryAction>
                <>
                  <ConfirmationDialogOnClickWrapper
                    confirmationBody="Are you sure you want to remove this payment method?"
                    confirmationTitle="Remove Payment Method"
                  >
                    <IconButton
                      edge="end"
                      onClick={() => handleDelete(paymentMethod)}
                      color="secondary"
                      disabled={get(itemBeingDeleted, 'id') === paymentMethod.id}
                    >
                      <DeleteIcon />
                      {get(itemBeingDeleted, 'id') === paymentMethod.id && (
                        <CircularProgress size={15} />
                      )}
                    </IconButton>
                  </ConfirmationDialogOnClickWrapper>
                  <IconButton
                    edge="end"
                    color="primary"
                    onClick={
                      !paymentMethod.is_primary ? () => handleMarkAsPrimary(paymentMethod) : null
                    }
                    title={paymentMethod.is_primary ? 'Primary' : 'Secondary'}
                  >
                    {paymentMethod.is_primary ? <StarIcon /> : <StarBorderIcon />}
                  </IconButton>
                  {paymentMethod.payment_method_type === 'card' && (
                    <IconButton
                      edge="end"
                      color="primary"
                      title="Update expiration date"
                      onClick={() => setPaymentMethodBeingEdited(paymentMethod)}
                    >
                      <EventAvailableIcon />
                    </IconButton>
                  )}
                </>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </CardContent>
      <Divider />
      {paymentMethodBeingEdited && (
        <>
          <div>
            <Box display="flex" justifyContent="center" pt={2} pb={1}>
              <UpdateExpirationDateForm
                onSubmit={async values => {
                  await handleUpdateExpirationDate(paymentMethodBeingEdited, values);
                  setPaymentMethodBeingEdited(null);
                }}
              />
            </Box>
          </div>
          <div>
            <Box display="flex" justifyContent="center" pb={1} width={1}>
              <Button
                onClick={() => {
                  setPaymentMethodBeingEdited(null);
                }}
                color="secondary"
                variant="contained"
              >
                Cancel
              </Button>
            </Box>
          </div>
        </>
      )}
      <CardActions className={clsx({ [classes.showAddForm]: addPaymentMethod })}>
        {addPaymentMethod ? (
          <>
            <div>
              <Box display="flex" justifyContent="center" pt={2} pb={1}>
                <PaymentMethodFormSelector onPaymentMethodSaved={onPaymentMethodSaved} />
              </Box>
            </div>
            <div>
              <Box display="flex" justifyContent="center" pb={1} width={1}>
                <Button
                  onClick={() => setAddPaymentMethod(false)}
                  color="secondary"
                  variant="contained"
                >
                  Cancel
                </Button>
              </Box>
            </div>
          </>
        ) : (
          <Button
            onClick={() => setAddPaymentMethod(true)}
            color="primary"
            variant="contained"
            className={classes.addButton}
          >
            Add Payment Method
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

ManagePaymentMethods.propTypes = {};

ManagePaymentMethods.defaultProps = {};

export default ManagePaymentMethods;
