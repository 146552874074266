import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { QUESTIONS } from '../constants';
import TextField from '../../Form/TextField';
import FormikAutocomplete from '../../FormikAutocomplete';
import withDependency from './hooks/withDependency';

const useStyles = makeStyles(theme => ({
  addressField: {
    marginTop: 10
  },
  addressFieldInput: {
    color: theme.palette.primary.dark
  }
}));

const LocationField = ({ name, locations }) => {
  const {
    values: { [name]: currentValue }
  } = useFormikContext();
  const classes = useStyles();
  const sortedLocations = useMemo(() => {
    if (Array.isArray(locations)) {
      return [...locations].sort((a, b) => (a.location_name > b.location_name ? 1 : -1));
    }
    return [];
  }, [locations]);

  const [items, setItems] = useState(sortedLocations.map(location => location.id));

  const handleOnChangeSearchQuery = value => {
    const lowerCaseValue = value.toLowerCase();
    const selectedValue =
      value && sortedLocations.find(location => location.location_name === value);
    if (selectedValue) {
      setItems(sortedLocations.map(location => location.id));
    } else {
      const newItems = sortedLocations.filter(
        location => location.location_name.toLowerCase().indexOf(lowerCaseValue) !== -1
      );
      setItems(newItems.map(doctor => doctor.id));
    }
  };

  if (!locations.length) {
    return '';
  }
  const currentLocation = locations.find(el => el.id === currentValue);

  return (
    <FormControl variant="outlined" fullWidth>
      <Field
        options={items}
        getOptionLabel={option => {
          if (option) {
            const location = locations.find(loc => loc.id === option);
            return location ? location.location_name : '';
          }
          return '';
        }}
        autoComplete
        getOptionSelected={(option, value) => {
          if (!value) {
            return null;
          }
          return option.id === value;
        }}
        textFieldProps={{
          label: QUESTIONS[name],
          variant: 'outlined',
          fullWidth: true,
          required: true
        }}
        name={name}
        onInputChange={(event, newInputValue) => handleOnChangeSearchQuery(newInputValue)}
        component={FormikAutocomplete}
        required
      />
      {currentLocation && (
        <TextField
          className={classes.addressField}
          disabled
          fullWidth
          label="Address"
          multiline
          value={currentLocation.full_address}
          variant="outlined"
          InputProps={{
            classes: { input: classes.addressFieldInput }
          }}
        />
      )}
    </FormControl>
  );
};

LocationField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  locations: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired
};

export default withDependency(LocationField);
