import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import { Checkbox, TextField } from 'formik-material-ui';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CardActions, CardContent, Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import LoadingIndicator from '../../../components/LoadingIndicator';
import {
  fetchWhitehatUserIntegrationConfig,
  saveWhitehatUserIntegrationConfig
} from '../../../reducers/whitehatUserIntegrations';
import LoadingButton from '../../../components/LoadingButton';
import { replaceNullToEmpty } from '../../../helpers';

const WhitehatIntegration = ({ data, fetching, fetch, saving, save }) => {
  const validationSchema = Yup.object().shape({
    enabled: Yup.bool().required('Required'),
    username: Yup.string().when('enabled', {
      is: val => val,
      then: Yup.string().required('Please provide username.')
    }),
    password: Yup.string().when('enabled', {
      is: val => val,
      then: Yup.string().required('Please provide password.')
    })
  });

  useEffect(() => {
    if (!data && !fetching) {
      fetch();
    }
  }, [data, fetching, fetch]);

  if (!data && !fetching) {
    return <LoadingIndicator />;
  }

  if (fetching) {
    return <LoadingIndicator />;
  }

  return (
    <Formik
      initialValues={replaceNullToEmpty(data)}
      validationSchema={validationSchema}
      onSubmit={save}
    >
      {({ values, isValid, submitForm }) => {
        return (
          <form>
            <Card>
              <CardHeader title="Whitehat Integration" />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <FormControlLabel
                      control={<Field name="enabled" color="primary" component={Checkbox} />}
                      label="Enable Integration"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="username"
                      label="Username"
                      variant="outlined"
                      fullWidth
                      disabled={!values.enabled}
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="password"
                      label="Password"
                      variant="outlined"
                      fullWidth
                      disabled={!values.enabled}
                      type="password"
                      component={TextField}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />

              <CardActions style={{ justifyContent: 'flex-end' }}>
                <LoadingButton
                  type="button"
                  variant="contained"
                  color="primary"
                  loading={saving}
                  disabled={saving || !isValid}
                  onClick={submitForm}
                >
                  Save
                </LoadingButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

WhitehatIntegration.propTypes = {
  data: PropTypes.shape({
    enabled: PropTypes.bool,
    token: PropTypes.string,
    username: PropTypes.string,
    password: PropTypes.string
  }),
  fetching: PropTypes.bool.isRequired,
  fetch: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired
};

WhitehatIntegration.defaultProps = {
  data: null
};

export default connect(
  (state, { userId }) => {
    const itemState = state.whitehatUserIntegrations.users[userId];
    return {
      fetching: itemState ? !!itemState.fetching : false,
      saving: itemState ? !!itemState.updating : false,
      data: itemState ? itemState.data : null
    };
  },
  (dispatch, { userId }) => {
    return {
      save: data => dispatch(saveWhitehatUserIntegrationConfig(userId, data)),
      fetch: () => dispatch(fetchWhitehatUserIntegrationConfig(userId))
    };
  }
)(WhitehatIntegration);
