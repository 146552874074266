import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core';
import FormDialog from '../../../components/FormDialog';
import CustomTextField from '../../../components/Form/TextField';

const useStyles = makeStyles(() => ({
  note: {
    color: '#FF3231',
    fontWeight: 400,
    marginTop: 20
  }
}));

const ActionOwnerExtraInfo = ({
  open,
  setOpen,
  onAddedExtraInfo,
  title,
  hasNotifyField,
  infoMessage
}) => {
  const classes = useStyles();
  const currentUser = useSelector(state => state.auth.user);
  const isManagement = useSelector(state => state.auth.isManagement);
  const actionOwnerName =
    isManagement && currentUser.first_name && currentUser.last_name
      ? `${currentUser.first_name} ${currentUser.last_name}`
      : '';

  const [data, setData] = useState({
    action_owner_name: actionOwnerName,
    reason: '',
    should_notify_office: false
  });

  const handleOnCloseFormDialog = response => {
    if (response === true) {
      onAddedExtraInfo(data);
    }
    setData(oldData => ({
      ...oldData,
      reason: '',
      should_notify_office: false
    }));
    setOpen(false);
  };

  const handleChangeFormDialogElement = ({ target }) => {
    setData({ ...data, [target.name]: target.type === 'checkbox' ? target.checked : target.value });
  };

  return (
    <FormDialog
      setOpen={setOpen}
      open={open}
      titleText={title}
      acceptButtonText="Continue"
      handleClose={handleOnCloseFormDialog}
      isAcceptButtonDisabled={data.action_owner_name === '' || data.reason === ''}
    >
      <TextField
        autoFocus
        margin="dense"
        id="action_owner_name"
        name="action_owner_name"
        label="Your Name"
        type="text"
        required
        value={data.action_owner_name}
        disabled={!!actionOwnerName}
        onChange={handleChangeFormDialogElement}
        fullWidth
      />
      <CustomTextField
        margin="dense"
        id="reason"
        name="reason"
        label="Reason"
        type="text"
        required
        removeUnnecessaryWhitespaces
        value={data.reason}
        onChange={handleChangeFormDialogElement}
        fullWidth
        multiline
      />
      {hasNotifyField && isManagement && (
        <FormControlLabel
          control={
            <Checkbox
              id="should_notify_office"
              name="should_notify_office"
              onChange={handleChangeFormDialogElement}
              checked={data.should_notify_office}
            />
          }
          label="Notify Office"
        />
      )}
      {infoMessage && <div className={classes.note}>{infoMessage}</div>}
    </FormDialog>
  );
};

ActionOwnerExtraInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onAddedExtraInfo: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  hasNotifyField: PropTypes.bool,
  infoMessage: PropTypes.string
};

ActionOwnerExtraInfo.defaultProps = {
  hasNotifyField: true,
  infoMessage: ''
};

export default ActionOwnerExtraInfo;
