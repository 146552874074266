import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardContent, Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import { useHistory } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import LoadingIndicator from '../../components/LoadingIndicator';
import createLoadingSelector from '../../selectors/loading';
import {
  DELETE_USER_PREFIX,
  deleteUserAction,
  archiveUserAction,
  restoreUserAction,
  FETCH_USER_PREFIX,
  fetchUserAction,
  UPDATE_USER_PREFIX,
  ARCHIVE_USER_PREFIX,
  RESTORE_USER_PREFIX,
  updateUserAction
} from '../../reducers/users';
import UserForm from '../../components/UserForm';
import { UI_SETTINGS } from '../../constants';
import CustomUserPermissionManager from './components/CustomUserPermissionManager';
import Can from '../../components/Can';
import WhitehatIntegration from './components/WhitehatIntegration';
import NotificationSettings from './components/NotificationSettings';

const UserDetails = ({ match: { params } }) => {
  const userId = parseInt(params.id, 10);
  const dispatch = useDispatch();
  const user = useSelector(state => {
    return state.users.items[state.users.items.findIndex(item => item.id === userId)];
  });
  const history = useHistory();
  const isLoadingUser = useSelector(state => createLoadingSelector([FETCH_USER_PREFIX])(state));
  const isUpdatingUser = useSelector(state => createLoadingSelector([UPDATE_USER_PREFIX])(state));
  const isArchivingUser = useSelector(state => createLoadingSelector([ARCHIVE_USER_PREFIX])(state));
  const isRestoringUser = useSelector(state => createLoadingSelector([RESTORE_USER_PREFIX])(state));
  const isDeleting = useSelector(state => createLoadingSelector([DELETE_USER_PREFIX])(state));

  useEffect(() => {
    dispatch(fetchUserAction(userId));
  }, [dispatch, userId]);

  const updateUser = values => {
    dispatch(updateUserAction(userId, values));
    return true;
  };

  const remove = id => {
    dispatch(deleteUserAction(id)).then(response => {
      if (response) {
        history.push('/organizations-users');
      }
    });
  };

  const archive = id => {
    dispatch(archiveUserAction(id)).then(() => {
      dispatch(fetchUserAction(userId));
    });
  };

  const restore = id => {
    dispatch(restoreUserAction(id)).then(() => {
      dispatch(fetchUserAction(userId));
    });
  };

  const subTitle = () => {
    const tags = [];

    if (user.deleted_at) {
      tags.push(<Chip key="Archived" size="small" label="Archived" color="secondary" />);
    }

    return <>{tags}</>;
  };

  if (isLoadingUser) {
    return <LoadingIndicator />;
  }

  if (!user) {
    return null;
  }

  return (
    <Box width={UI_SETTINGS.formWidth} mx="auto">
      <Box my={2}>
        <Card>
          <CardHeader
            title={user.deleted_at ? 'User Information' : 'Update User'}
            subheader={subTitle()}
          />
          <Divider />
          <UserForm
            onSubmit={updateUser}
            isSaving={isUpdatingUser}
            isArchiving={isArchivingUser}
            isRestoring={isRestoringUser}
            user={user}
            isDeleting={isDeleting}
            onDelete={remove}
            onArchive={!user.deleted_at ? archive : null}
            onRestore={user.deleted_at ? restore : null}
            mode={!user.deleted_at ? '' : 'show'}
          />
        </Card>
      </Box>
      {!user.deleted_at && (
        <Can
          permissions={['permissions.manage']}
          yes={() => (
            <Box my={2}>
              <Card>
                <CardHeader title="Additional User Permissions" />
                <Divider />
                <CardContent>
                  <CustomUserPermissionManager user={user} />
                </CardContent>
              </Card>
            </Box>
          )}
        />
      )}
      {!user.deleted_at && (
        <Can
          permissions={['users.manage', 'users.update-whitehat-integration']}
          yes={() => (
            <Box my={2}>
              <WhitehatIntegration userId={user.id} />
            </Box>
          )}
        />
      )}
      {!user.deleted_at && (
        <Can
          permissions={['users.manage', 'users.manage-notification-settings']}
          yes={() => (
            <Box my={2}>
              <NotificationSettings user={user} />
            </Box>
          )}
        />
      )}
    </Box>
  );
};

UserDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired
};

UserDetails.defaultProps = {};

export default UserDetails;
