import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useRxForm } from '../../../components/RxForm/hooks';
import { ATTRIBUTES } from '../../../components/RxForm/constants';
import RxFormsApiService from '../../../services/api/rxForms';
import { enqueueNotification } from '../../../reducers/notifications';
import RxFormProvider from '../../../components/RxForm/RxFormProvider';
import OldInitialSetupAssignment from './OldInitialSetupAssignment';
import AssignmentChanger from './AssignmentChanger';
import { updatePatientRxFormAction } from '../../../reducers/patients';

const { INITIAL_CASE_SETUP_ASSIGNMENT } = ATTRIBUTES;

const InitialSetupAssignmentChanger = () => {
  const { rxForm } = useRxForm();
  const dispatch = useDispatch();
  const [value, setValue] = useState(rxForm[INITIAL_CASE_SETUP_ASSIGNMENT]);

  const save = selectedValue => {
    if (selectedValue === value) {
      return;
    }
    const api = new RxFormsApiService();
    api
      .patch(rxForm.id, { [INITIAL_CASE_SETUP_ASSIGNMENT]: selectedValue })
      .then(response => {
        setValue(selectedValue);
        dispatch(
          enqueueNotification('success', 'Successfully changed initial case setup assignment.')
        );
        dispatch(updatePatientRxFormAction(response.rx_form.patient_id, response.rx_form));
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error));
      });
  };

  return <AssignmentChanger onSave={save} value={value} serviceName="submission_completed_by" />;
};

const Wrapper = ({ rxForm }) => {
  if (rxForm.version !== 2) {
    return <OldInitialSetupAssignment rxForm={rxForm} />;
  }
  return (
    <RxFormProvider id={rxForm.id} withPreference loaderProps={{ size: 20, withSpacing: false }}>
      <InitialSetupAssignmentChanger />
    </RxFormProvider>
  );
};

Wrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rxForm: PropTypes.object.isRequired
};

export default Wrapper;
