import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { Grid, Box, Typography } from '@material-ui/core';
import { stepsMap } from '../../../constants/signup';
import CustomInput from './CustomInput';
import { objectHasProperty } from '../../../helpers';
import NameField from '../../../components/Formik/NameField';

function FormContent({ currentStep }) {
  const currentStepOptions = useMemo(() => {
    if (!objectHasProperty(stepsMap, currentStep)) return null;

    return stepsMap[currentStep];
  }, [currentStep]);

  const isActive = useCallback(
    key => {
      if (isNil(currentStepOptions)) return false;

      return currentStepOptions.inputs.includes(key);
    },
    [currentStepOptions]
  );

  if (isNil(currentStepOptions)) return null;

  return (
    <>
      <Box mb={5}>
        <Typography variant="h6" color="primary" align="center">
          {currentStepOptions.title}
        </Typography>
        {currentStepOptions.subtitle && (
          <Typography variant="subtitle1" color="primary" align="center">
            {currentStepOptions.subtitle}
          </Typography>
        )}
      </Box>

      <Grid container spacing={2}>
        <CustomInput
          id="first_name"
          label="First Name"
          show={isActive('first_name')}
          required
          xs={12}
          md={6}
          component={NameField}
        />

        <CustomInput
          id="last_name"
          label="Last Name"
          show={isActive('last_name')}
          required
          xs={12}
          md={6}
          component={NameField}
        />

        <CustomInput id="email" label="Email Address" show={isActive('email')} required xs={12} />

        <CustomInput
          id="password"
          label="Password"
          type="password"
          show={isActive('password')}
          xs={12}
          required
        />

        <CustomInput
          id="password_confirmation"
          label="Confirm Password"
          type="password"
          show={isActive('password_confirmation')}
          xs={12}
          required
        />
      </Grid>
    </>
  );
}

FormContent.propTypes = {
  currentStep: PropTypes.number.isRequired
};

export default FormContent;
