import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { DoneAll, LocalShipping, Print } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import { useDispatch } from 'react-redux';
import { PRINTING_REQUESTS_STATUSES_ID } from '../../../../constants';
import { selectPatientsForBundling, showShippingLabel } from '../../../../reducers/patients';
import ShippingLabel from '../../../../components/Shipment/Labels/ShippingLabel';
import { ASSIGNMENTS } from '../../../../components/RxForm/constants';

const styles = makeStyles(theme => ({
  tableBody: {
    '&.hovered': {
      backgroundColor: theme.palette.action.hover
    },
    '&.rush': {
      backgroundColor: '#fff0db'
    },
    '&.rush.hovered': {
      backgroundColor: '#ffe2b7'
    }
  }
}));

const GroupedItems = ({
  rows,
  keyValue,
  location,
  shippingMethod,
  completed,
  shippingLabelPrinted,
  patientsLabelPrinted,
  rowRenderer,
  assignment,
  hasRushCase,
  courier
}) => {
  const [hovered, setHovered] = useState(false);
  const dispatch = useDispatch();
  const classesNew = styles();
  const classNames = [classesNew.tableBody];
  if (hovered) {
    classNames.push('hovered');
  }
  if (hasRushCase && !completed) {
    classNames.push('rush');
  }

  const handlePrintShippingLabel = () => {
    dispatch(showShippingLabel(rows));
  };

  const handleBundleCompletion = () => {
    dispatch(selectPatientsForBundling(rows));
  };

  return (
    <TableBody key={keyValue} className={classNames.join(' ')}>
      {rows.map((item, index) => rowRenderer(item, index))}
      <TableRow
        key={`shipping-label-${keyValue}`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <TableCell colSpan={6}>
          {assignment === ASSIGNMENTS.THIRD_PARTY && (
            <Typography variant="body1">This is assigned to third party partner.</Typography>
          )}
          {assignment === ASSIGNMENTS.ORTHOSELECT && !location && (
            <Typography variant="body1">Shipping location is missing.</Typography>
          )}
          {assignment === ASSIGNMENTS.ORTHOSELECT && location && (
            <div
              style={{
                marginTop: 8,
                marginBottom: 32
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: 16
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <LocalShipping color="primary" />
                      &nbsp;
                      <Typography variant="body1" component="span">
                        Ship To:
                      </Typography>
                    </div>
                    <Button
                      size="small"
                      variant={shippingLabelPrinted ? 'outlined' : 'contained'}
                      color="primary"
                      disabled={shippingLabelPrinted}
                      startIcon={<Print />}
                      onClick={handlePrintShippingLabel}
                    >
                      {shippingLabelPrinted ? 'Printed' : 'Print'}
                    </Button>
                  </div>
                </div>
                <Typography
                  variant="body2"
                  component="div"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start'
                  }}
                >
                  {!!location && <ShippingLabel location={location} />}
                </Typography>
                <div
                  style={{
                    marginLeft: 'auto',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    display: 'flex'
                  }}
                >
                  <Typography variant="body1" component="span">
                    {shippingMethod}
                  </Typography>
                  <Button
                    size="small"
                    variant={completed ? 'outlined' : 'contained'}
                    color="primary"
                    disabled={completed || !shippingLabelPrinted || !patientsLabelPrinted}
                    startIcon={<DoneAll />}
                    onClick={handleBundleCompletion}
                  >
                    {completed ? 'Completed' : 'Complete'}
                  </Button>
                  {courier !== null && (
                    <div>
                      Track:{' '}
                      <a href={courier.link} rel="noopener noreferrer" target="_blank">
                        {courier.name}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

GroupedItems.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.array.isRequired,
  keyValue: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  shippingMethod: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  shippingLabelPrinted: PropTypes.bool.isRequired,
  patientsLabelPrinted: PropTypes.bool.isRequired,
  rowRenderer: PropTypes.func.isRequired,
  assignment: PropTypes.number.isRequired,
  hasRushCase: PropTypes.bool.isRequired,
  courier: PropTypes.shape({
    name: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  })
};

GroupedItems.defaultProps = {
  location: null,
  courier: null
};

const otherGroupProps = rows => {
  return {
    completed:
      rows.filter(
        patient =>
          patient.customer_request.printing_request.printing_request_status_id ===
          PRINTING_REQUESTS_STATUSES_ID.completed
      ).length === rows.length,
    shippingLabelPrinted:
      rows.filter(
        patient => patient.customer_request.printing_request.shipping_label_printed === true
      ).length === rows.length,
    patientsLabelPrinted:
      rows.filter(
        patient => patient.customer_request.printing_request.patient_label_printed === true
      ).length === rows.length
  };
};

const groupPatients = rows => {
  const groups = {};
  const orderedGroups = [];

  rows.forEach(value => {
    const printingRequest = value.customer_request.printing_request;

    const groupData = {
      location: printingRequest.location,
      shippingMethod: printingRequest.shipping_method.name,
      shipDate: printingRequest.ship_date,
      assignment: printingRequest.assigned_to,
      courier: printingRequest.courier
    };
    const groupId = value.bundled_shipment_id || `patient-${value.id}`;
    const isRush = value.rx_form !== null && value.rx_form.rush_case;

    if (groups[groupId]) {
      const currentGroup = groups[groupId];
      currentGroup.rows.push(value);
      if (currentGroup.shippingMethod !== groupData.shippingMethod && currentGroup.bundledMethod) {
        currentGroup.shippingMethod = 'Mixed';
        currentGroup.bundledMethod = true;
      }
      currentGroup.hasRushCase = currentGroup.hasRushCase || isRush;
    } else {
      const group = {
        ...groupData,
        bundledMethod: false,
        rows: [value],
        keyValue: groupId,
        hasRushCase: isRush
      };
      orderedGroups.push(group);
      groups[groupId] = group;
    }
  });

  return orderedGroups.map(group => {
    const otherProps = otherGroupProps(group.rows);
    return {
      ...group,
      ...otherProps
    };
  });
};

const ShipmentBundling = ({ rows, ...props }) => {
  const groups = groupPatients(rows);
  return groups.map(groupProps => {
    return <GroupedItems {...props} {...groupProps} />;
  });
};

ShipmentBundling.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.array.isRequired
};

export default ShipmentBundling;
