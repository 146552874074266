import { useEffect } from 'react';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getTokenHeader } from '../../helpers/connection';
import {
  addUserNotificationAction,
  fetchNotificationsAction,
  updateUserNotificationAction
} from '../../reducers/auth';
import { NOTIFICATION_TYPES } from './constants';
import { updateAnnouncementLocally } from '../../reducers/annoucement';
import { DOCTOR_DEFAULT_PAGE, MANAGEMENT_DEFAULT_PAGE } from '../../constants/default';
import { refreshCase } from '../../reducers/caseFeedback';
import getOrganizationRole from '../../selectors/getOrganizationRole';
import getCurrentOrganization from '../../selectors/getCurrentOrganization';
import { isTechnicianOrAdmin } from '../../helpers';

const WSNotificationHandler = ({ user }) => {
  const dispatch = useDispatch();
  const isManagement = useSelector(state => state.auth.isManagement);
  const organizationRole = useSelector(getOrganizationRole);
  const organization = useSelector(getCurrentOrganization);

  useEffect(() => {
    dispatch(fetchNotificationsAction());
  }, [dispatch]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      Pusher.logToConsole = true;
    }

    const options = {
      broadcaster: 'pusher',
      key: process.env.REACT_APP_PUSHER_KEY,
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      forceTLS: true,
      authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
      auth: {
        headers: {
          ...getTokenHeader(),
          accept: 'application/json'
        }
      }
    };

    const echo = new Echo(options);

    if (isTechnicianOrAdmin(user)) {
      echo.private('internal').listen('.announcement.updated', e => {
        dispatch(updateAnnouncementLocally(e.announcement));
      });
    }

    echo.private('general').listen('.announcement.updated', e => {
      dispatch(updateAnnouncementLocally(e.announcement));
    });

    echo.private(`App.User.${user.id}`).notification(data => {
      switch (data.type) {
        case NOTIFICATION_TYPES.PATIENT_SETUP_COMPLETED:
          dispatch(
            addUserNotificationAction(
              data,
              isManagement ? MANAGEMENT_DEFAULT_PAGE : DOCTOR_DEFAULT_PAGE
            )
          );
          if (!isManagement) {
            dispatch(refreshCase());
          }

          break;
        case NOTIFICATION_TYPES.RUSH_CASE:
          dispatch(addUserNotificationAction(data, `/patient-details/${data.patient_id}`));
          break;
        case NOTIFICATION_TYPES.UPDATED_NOTIFICATION:
          dispatch(updateUserNotificationAction(data.data, `/patient-details/${data.patient_id}`));
          break;
        default:
          break;
      }
    });

    if (organization && !isManagement) {
      echo.private(`organization.${organization.id}`).notification(data => {
        switch (data.type) {
          case 'refresh-notifications':
            dispatch(fetchNotificationsAction());
            break;
          default:
            break;
        }
      });
    }

    return () => {
      echo.disconnect();
    };
  }, [user, user.id, dispatch, isManagement, organization, organizationRole]);

  return null;
};
WSNotificationHandler.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.any
};

WSNotificationHandler.defaultProps = {
  user: {}
};

export default WSNotificationHandler;
