import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { produce } from 'immer';
import tags from '../../../../constants/tags';

const IntraoralTypeSwitch = ({ values, onChange, value }) => {
  const [stlType, setStlType] = useState(null);

  const isVisible = useCallback(() => {
    const index = values.findIndex(file => file.file_path === value.file_path);
    return index >= 0 && index <= 1;
  }, [values, value]);

  const updateType = useCallback(
    type => {
      onChange(
        produce(values, draft => {
          const index = draft.findIndex(file => file.file_path === value.file_path);

          if (index !== -1) {
            draft[index].tag = type;
          }

          // set other type to opposite
          const otherIndex = draft.findIndex(
            file => file.file_path !== value.file_path && file.tag
          );

          if (otherIndex !== -1 && draft[otherIndex].tag === type) {
            draft[otherIndex].tag =
              type === tags.STL_MANDIBULAR ? tags.STL_MAXILLARY : tags.STL_MANDIBULAR;
          }
        }),
        value.file_path
      );
    },
    [values, value, onChange]
  );

  useEffect(() => {
    if (stlType !== value.tag && value.tag) {
      setStlType(value.tag);
    }
  }, [stlType, value]);

  const handleChange = event => {
    const type = event.target.checked ? tags.STL_MANDIBULAR : tags.STL_MAXILLARY;
    updateType(type);
    setStlType(type);
  };

  if (!isVisible()) {
    return null;
  }

  return (
    <Typography component="div" style={{ marginRight: '20px' }}>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>Maxillary</Grid>
        <Grid item>
          <Switch onChange={handleChange} checked={stlType === tags.STL_MANDIBULAR} />
        </Grid>
        <Grid item>Mandibular</Grid>
      </Grid>
    </Typography>
  );
};

IntraoralTypeSwitch.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default IntraoralTypeSwitch;
