import { Div } from 'react-images/lib/primitives';
import React, { useContext, useState } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import TrashIcon from '@material-ui/icons/DeleteRounded';
import AddIcon from '@material-ui/icons/AddCircleRounded';
import HandleIcon from '@material-ui/icons/DragHandle';
import { useDrop, useDrag } from 'react-dnd';
import FormControls from './index';
import { ControlContext } from '../../contexts';
import SectionColumn from './SectionColumn';
import {
  EVENT_ADD_COLUMN,
  EVENT_DELETE_COLUMN,
  EVENT_DELETE_LAYOUT_ELEMENT,
  EVENT_ADD_CONTROL,
  EVENT_MOVE_CONTROL,
  EVENT_DELETE_CONTROL,
  TYPE_LAYOUT,
  EVENT_MOVE_LAYOUT,
  CONTROL_EXISTING
} from '../helpers';

const columnHasNativeFields = column => {
  return column.children.filter(control => control.system_type === CONTROL_EXISTING).length > 0;
};
const sectionHasNativeFields = section => {
  return (
    section.children.filter(col => {
      return columnHasNativeFields(col);
    }).length > 0
  );
};

export default function FormSection({ element: section, isPreview }) {
  const { onUpdate, onDelete } = useContext(ControlContext);
  const [disableAddColumn, setDisableAddColumn] = useState(false);
  const cols = Array.isArray(section.children) ? section.children : [];
  const containsNativeFields = sectionHasNativeFields(section);
  const moveSection = params => {
    onUpdate({
      event: EVENT_MOVE_LAYOUT,
      ...params
    });
  };

  // make section draggable
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: TYPE_LAYOUT,
    item: { id: section.id },
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  }));

  // make section droppable
  const [{ isOver }, drop] = useDrop({
    accept: TYPE_LAYOUT,
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return;
      }

      if (item.id !== section.id) {
        moveSection({
          src: {
            id: item.id,
            system_type: item.system_type
          },
          dst: {
            id: section.id
          }
        });
      }
    },
    collect: monitor => ({
      isOver: !!monitor.isOver({ shallow: true })
    })
  });

  const onAddColumn = () => {
    if (cols.length > 3) {
      setDisableAddColumn(true);
      return;
    }

    onUpdate({
      event: EVENT_ADD_COLUMN,
      sectionId: section.id
    });
  };

  const deleteSection = () => {
    if (onDelete) {
      onDelete({
        id: section.id,
        event: EVENT_DELETE_LAYOUT_ELEMENT
      });
    }
  };

  const removeColumn = colId => {
    if (cols.length === 1) {
      return;
    }

    onDelete({
      id: colId,
      event: EVENT_DELETE_COLUMN,
      sectionId: section.id
    });

    setDisableAddColumn(false);
  };

  const addControl = item => {
    onUpdate({
      event: EVENT_ADD_CONTROL,
      ...item,
      sectionId: section.id
    });
  };

  const moveControl = params => {
    onUpdate({
      event: EVENT_MOVE_CONTROL,
      ...params
    });
  };

  const removeControl = params => {
    onDelete({
      event: EVENT_DELETE_CONTROL,
      ...params
    });
  };

  const classNames = ['fb-builder-section'];
  const style = {};

  if (isDragging) {
    style.opacity = '0.7';
  } else {
    style.opacity = '1';
  }

  if (isOver) {
    classNames.push('highlight');
  }

  return (
    <div ref={node => preview(drop(node))} className={classNames.join(' ')} style={style}>
      {!isPreview && (
        <Div className="fb-section-actions">
          <IconButton
            variant="contained"
            className="fb-add-column"
            onClick={onAddColumn}
            size="small"
            disabled={disableAddColumn}
            title="Add Column"
          >
            <AddIcon color={disableAddColumn ? 'disabled' : 'primary'} />
          </IconButton>

          <IconButton
            variant="contained"
            className="fb-delete-section"
            onClick={deleteSection}
            disabled={containsNativeFields}
            size="small"
            title="Delete Section"
          >
            <TrashIcon color={containsNativeFields ? 'disabled' : 'error'} />
          </IconButton>

          <button ref={drag} type="button" className="fb-sec-drag-handle">
            <HandleIcon />
          </button>
        </Div>
      )}

      <Grid container spacing={5}>
        {cols.map(col => {
          const options = {
            isPreview,
            element: col,
            removeColumn,
            disableRemoveBtn: cols.length === 1 || columnHasNativeFields(col),
            addControl,
            sectionId: section.id,
            moveControl
          };

          return (
            <Grid key={col.id} item xs={parseInt(12 / cols.length)}>
              <SectionColumn {...options}>
                <FormControls
                  elements={col.children}
                  columnId={col.id}
                  sectionId={section.id}
                  isPreview={isPreview}
                  moveControl={moveControl}
                  removeControl={removeControl}
                />

                {!isPreview && <span className="fb-column-title">Drop field here</span>}
              </SectionColumn>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
