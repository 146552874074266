import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  ratingContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: 20
  },
  rating: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer'
  },
  emoji: {
    fontSize: 48
  },
  description: {
    fontSize: 16,
    fontWeight: 500
  }
}));

const Rating = ({ submit }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.ratingContainer}>
        <div className={classes.rating} role="button" tabIndex={0} onClick={() => submit(10)}>
          <span role="img" aria-label="Great" className={classes.emoji}>
            😀
          </span>
          <span className={classes.description}>Great</span>
        </div>
        <div className={classes.rating} role="button" tabIndex={0} onClick={() => submit(5)}>
          <span role="img" aria-label="Ok" className={classes.emoji}>
            😐
          </span>
          <span className={classes.description}>Ok</span>
        </div>
        <div className={classes.rating} role="button" tabIndex={0} onClick={() => submit(1)}>
          <span role="img" aria-label="Bad" className={classes.emoji}>
            🙁
          </span>
          <span className={classes.description}>Bad</span>
        </div>
      </div>
    </>
  );
};

Rating.propTypes = {
  submit: PropTypes.func.isRequired
};

export default Rating;
