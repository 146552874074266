import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardActions, CardHeader, Container } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { connect } from 'react-redux';
import CustomFileInput from './CustomFileInput';
import {
  requestMachineLearningFileDownloadUrl,
  uploadMachineLearningFile
} from '../../../reducers/installers';

const useStyles = makeStyles(() => ({
  fieldWrapper: {
    width: 400
  },
  cardContent: { display: 'flex', justifyContent: 'center' }
}));

const MachineLearning = ({ uploading, upload, downloadUrl, requestDownloadUrl }) => {
  const classes = useStyles();
  const initialValues = {
    file: null
  };
  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .required('Required')
      .test('fileFormat', 'Only accept .7z file', value => {
        if (value) {
          return /^.*\.7z$/.test(value.name.replace(/\s/g, ''));
        }
        return false;
      })
  });

  const handleSubmit = (values, { resetForm }) => {
    upload(values.file).then(resetForm);
  };

  const handleDownload = () => {
    if (!downloadUrl) {
      return;
    }
    document.location.href = downloadUrl;
  };

  useEffect(() => {
    if (downloadUrl) {
      return;
    }
    requestDownloadUrl();
  }, [downloadUrl, requestDownloadUrl]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ values, touched, errors, setFieldValue, handleBlur, isValid }) => {
        return (
          <Form noValidate>
            <Container maxWidth="md">
              <Card>
                <CardHeader title="Machine Learning" titleTypographyProps={{ variant: 'h6' }} />
                <Divider />
                <CardContent className={classes.cardContent}>
                  <div className={classes.fieldWrapper}>
                    <Field
                      name="file"
                      component={CustomFileInput}
                      accept=".7z"
                      title="Upload File"
                      value={values.file ? values.file.name : ''}
                      setFieldValue={setFieldValue}
                      errorMessage={errors.installer_file ? errors.installer_file : undefined}
                      touched={touched.installer_file}
                      onBlur={handleBlur}
                    />
                  </div>
                </CardContent>
                <Divider />
                <CardActions style={{ justifyContent: 'flex-end' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={uploading || !values.file || !isValid}
                  >
                    {uploading ? 'Uploading...' : 'Upload'}
                  </Button>
                  <Button disabled={!downloadUrl} variant="contained" onClick={handleDownload}>
                    Download
                  </Button>
                </CardActions>
              </Card>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

MachineLearning.propTypes = {
  downloadUrl: PropTypes.string,
  uploading: PropTypes.bool.isRequired,
  upload: PropTypes.func.isRequired,
  requestDownloadUrl: PropTypes.func.isRequired
};

MachineLearning.defaultProps = {
  downloadUrl: null
};

export default connect(
  state => {
    return {
      downloadUrl: state.installers.machineLearningFile.downloadUrl,
      uploading: state.installers.machineLearningFile.uploading
    };
  },
  dispatch => {
    return {
      upload: file => dispatch(uploadMachineLearningFile(file)),
      requestDownloadUrl: () => dispatch(requestMachineLearningFileDownloadUrl())
    };
  }
)(MachineLearning);
