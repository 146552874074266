import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import PatientFilesApiService from '../../services/api/patientFiles';
import { enqueueNotification } from '../../reducers/notifications';
import { addPatientFileAction, removePatientFileAction } from '../../reducers/patients';
import UploadFilesDialogBase from '../UploadFilesDialogBase';
import { ACCEPTED_FILE_TYPES, ZIP_EXTRACTABLE_FILE_TYPES, UPLOAD_NOTES } from '../../constants';

const UploadFilesDialog = ({
  open,
  handleClose,
  title,
  patientId,
  patientFileTypeId,
  maxFiles,
  isImageUploader,
  updatePatient,
  preventDuplicate,
  existingFiles,
  nameValidator,
  validateFile
}) => {
  const dispatch = useDispatch();
  const patientFilesService = new PatientFilesApiService();
  const [uploadedFiles, setUploadedFiles] = useState({});

  const getUploadParams = async ({ file, meta: { name, lastModifiedDate } }) => {

    if (validateFile) {
      const errorMessage = await validateFile(file);

      if (errorMessage) {
        dispatch(enqueueNotification('error', errorMessage));
        return false;
      }
    }

    let validName = name;
    if (nameValidator) {
      try {
        validName = nameValidator(name);
      } catch (e) {
        dispatch(enqueueNotification('error', e.message));
        return false;
      }
    }
    if (preventDuplicate) {
      const existingFile = existingFiles.find(
        ({ original_name: originalName, patient_file_type_id: typeId }) =>
          originalName === validName && typeId === patientFileTypeId
      );

      if (existingFile) {
        dispatch(enqueueNotification('error', 'File with such name already exists.'));
        return false;
      }
    }

    try {
      const { url, file_path: filePath } = await patientFilesService.getUploadUrl(
        validName,
        patientId,
        patientFileTypeId,
        false
      );
      return {
        method: 'put',
        body: file,
        url,
        meta: { filePath, name: validName, lastModifiedDate }
      };
    } catch (e) {
      dispatch(enqueueNotification('error', e.message));
      return false;
    }
  };

  const handleChangeStatus = async ({ meta }, status, fileWithMeta) => {
    if (status === 'done') {
      // Check if the file can be stored
      try {
        const { file } = await patientFilesService.storeUploadedFile(
          meta.filePath,
          patientId,
          patientFileTypeId,
          meta.name,
          meta.lastModifiedDate
            ? moment(meta.lastModifiedDate)
                .utc()
                .format('YYYY-MM-DD HH:mm:ss')
            : null
        );
        const files = { ...uploadedFiles, [meta.filePath]: { id: file.id } };
        setUploadedFiles(files);

        if (isImageUploader) {
          const { url } = await patientFilesService.getDownloadUrl(file.id);
          dispatch(addPatientFileAction(patientId, patientFileTypeId, { ...file, url }));
        }

        if (updatePatient && !isImageUploader) {
          dispatch(addPatientFileAction(patientId, patientFileTypeId, file));
        }
        const fileArray = Object.values(fileWithMeta);
        if (
          fileArray.filter(fileItem => fileItem.meta.status === 'done').length === fileArray.length
        ) {
          const message = `The ${
            fileArray.length === 1 ? 'file was' : 'files were'
          } uploaded successfully.`;
          dispatch(enqueueNotification('success', message));
          handleClose(files);
        }
      } catch (e) {
        dispatch(enqueueNotification('error', e.message));
      }
    }

    if (status === 'removed') {
      if (uploadedFiles[meta.filePath]) {
        await patientFilesService.deleteFile(uploadedFiles[meta.filePath].id);
        if (isImageUploader || updatePatient) {
          dispatch(
            removePatientFileAction(patientId, patientFileTypeId, uploadedFiles[meta.filePath].id)
          );
        }
      }
    }
  };

  const handleSubmit = (files, allFiles) => {
    allFiles.forEach(f => f.remove());
  };

  const close = response => {
    const res = response ? uploadedFiles : false;
    handleClose(res);
    setUploadedFiles({});
  };

  return (
    <UploadFilesDialogBase
      handleChangeStatus={handleChangeStatus}
      handleClose={close}
      title={title}
      getUploadParams={getUploadParams}
      open={open}
      handleSubmit={handleSubmit}
      showContentText={isImageUploader}
      maxFiles={maxFiles}
      note={UPLOAD_NOTES[patientFileTypeId] ? UPLOAD_NOTES[patientFileTypeId] : null}
      accept={ACCEPTED_FILE_TYPES[patientFileTypeId] ? ACCEPTED_FILE_TYPES[patientFileTypeId] : ''}
      autoExtractCompressedFiles={ZIP_EXTRACTABLE_FILE_TYPES[patientFileTypeId] || null}
    />
  );
};

UploadFilesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  patientId: PropTypes.number.isRequired,
  patientFileTypeId: PropTypes.number.isRequired,
  isImageUploader: PropTypes.bool,
  maxFiles: PropTypes.number,
  updatePatient: PropTypes.bool,
  preventDuplicate: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  existingFiles: PropTypes.array,
  nameValidator: PropTypes.func,
  validateFile: PropTypes.func
};

UploadFilesDialog.defaultProps = {
  maxFiles: 100,
  isImageUploader: false,
  updatePatient: false,
  preventDuplicate: false,
  existingFiles: [],
  nameValidator: null,
  validateFile: () => null
};

export default UploadFilesDialog;
