import React, { useCallback, useEffect, useRef, useState } from 'react';
import LaunchIcon from '@material-ui/icons/Launch';
import { Button, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { DIBS_OPTIONS } from '../RxForm/constants';
import { openDIBS } from '../../helpers/dibs';
import hasPermission from '../../selectors/hasPermission';
import { fetchRxFormPreference } from '../../reducers/rxFormPreferences';

const OpenWithExternalApp = ({
  patient,
  path,
  params,
  onClick,
  buttonProps,
  isIconButton,
  isDisabled,
  dibsOptions,
  fetchDibsOptions,
  fetchingDibsOptions
}) => {
  const [opening, setOpening] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const anchorRef = useRef();
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenMenu(false);
  };

  const openExternalLink = useCallback(
    app => {
      if (onClick) {
        onClick(app);
      } else {
        dispatch(openDIBS(app, path, params));
      }
      handleClose();
    },
    [dispatch, onClick, path, params]
  );

  const executeOpen = useCallback(() => {
    if (dibsOptions.length === 1) {
      openExternalLink(dibsOptions[0]);
    } else {
      setOpenMenu(true);
    }
  }, [setOpenMenu, dibsOptions, openExternalLink]);

  const handleClick = useCallback(() => {
    if (dibsOptions) {
      executeOpen();
      return;
    }
    if (fetchingDibsOptions) {
      return;
    }
    if (!fetchingDibsOptions && !dibsOptions) {
      setOpening(true);
      fetchDibsOptions();
    }
  }, [executeOpen, dibsOptions, fetchingDibsOptions, fetchDibsOptions, setOpening]);

  const { title, ...restButtonProps } = buttonProps;

  useEffect(() => {
    if (opening && dibsOptions) {
      setOpening(false);
      executeOpen();
    }
  }, [dibsOptions, opening, setOpening, executeOpen]);

  const disabled = isDisabled ? isDisabled(patient) : !!buttonProps.disabled;

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isIconButton && (
        <Tooltip title={disabled ? '' : opening ? 'Opening' : title}>
          <IconButton
            ref={anchorRef}
            onClick={handleClick}
            {...restButtonProps}
            disabled={disabled}
          >
            <LaunchIcon />
          </IconButton>
        </Tooltip>
      )}
      {!isIconButton && (
        <Button ref={anchorRef} onClick={handleClick} {...buttonProps} disabled={disabled}>
          {buttonProps.text || buttonProps.title}
        </Button>
      )}
      <Menu
        id="simple-menu"
        keepMounted
        anchorEl={anchorRef.current}
        open={openMenu}
        onClose={handleClose}
        onMouseUp={e => e.stopPropagation()}
      >
        {dibsOptions && dibsOptions.includes(DIBS_OPTIONS.DIBS_DESKTOP) && (
          <MenuItem onClick={() => openExternalLink(DIBS_OPTIONS.DIBS_DESKTOP)}>
            Open via Desktop
          </MenuItem>
        )}
        {dibsOptions && dibsOptions.includes(DIBS_OPTIONS.DIBS_ONLINE) && (
          <MenuItem onClick={() => openExternalLink(DIBS_OPTIONS.DIBS_ONLINE)}>
            Open via Online
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

OpenWithExternalApp.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired,
  path: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  buttonProps: PropTypes.object,
  isIconButton: PropTypes.bool,
  isDisabled: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  dibsOptions: PropTypes.array,
  fetchDibsOptions: PropTypes.func.isRequired,
  fetchingDibsOptions: PropTypes.bool.isRequired
};

OpenWithExternalApp.defaultProps = {
  path: null,
  params: {},
  onClick: null,
  buttonProps: {},
  isIconButton: true,
  isDisabled: null,
  dibsOptions: null
};

export default connect(
  (state, props) => {
    const { patient, isDisabled } = props;

    const canViewArchived = hasPermission(state, { permissions: ['patients.view-archived'] });
    const isArchived = patient.deleted_at;

    const forceDisabled = isArchived && !canViewArchived;
    const rxFormPreference = state.rxFormPreferences.organizations[patient.organization_id];
    return {
      isDisabled: forceDisabled ? () => true : isDisabled,
      fetchingDibsOptions: rxFormPreference ? rxFormPreference.fetching : false,
      dibsOptions:
        rxFormPreference && rxFormPreference.preference
          ? rxFormPreference.preference.allowed_dibs_options
          : null
    };
  },
  (dispatch, ownProps) => {
    return {
      fetchDibsOptions: () => {
        return dispatch(fetchRxFormPreference(ownProps.patient.organization_id));
      }
    };
  }
)(OpenWithExternalApp);
