import React from 'react';
import OriginalTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

export const AUTO_CAPS_NAME = 'name';

const TextField = ({
  children,
  onChange,
  onBlur,
  autoCaps,
  removeUnnecessaryWhitespaces,
  ...props
}) => {
  const customOnBlur = event => {
    const { value } = event.target;
    onBlur && onBlur(event);
    let updatedValue = value;
    // remove whitespaces from start and end
    // eslint-disable-next-line no-param-reassign
    if (!value) {
      return;
    }
    if (removeUnnecessaryWhitespaces) {
      updatedValue = value.replace(/^\s+/, '').replace(/\s+$/, '');
    }
    if (updatedValue !== value) {
      event.target.value = updatedValue;
      onChange && onChange(event);
    }
  };

  const handleChange = event => {
    const { value } = event.target;
    let updatedValue = value;
    if (autoCaps === AUTO_CAPS_NAME) {
      updatedValue = updatedValue.replace(/(^|\s)\S/g, l => l.toUpperCase());
    }
    event.target.value = updatedValue;
    onChange && onChange(event);
  };
  return (
    <OriginalTextField onBlur={customOnBlur} onChange={handleChange} {...props}>
      {children}
    </OriginalTextField>
  );
};

TextField.propTypes = {
  onChange: PropTypes.func,
  removeUnnecessaryWhitespaces: PropTypes.bool,
  autoCaps: PropTypes.string
};

TextField.defaultProps = {
  onChange: () => {},
  removeUnnecessaryWhitespaces: false,
  autoCaps: null
};

export default TextField;
