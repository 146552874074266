import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { toggleNotificationSetting } from '../../../reducers/userNotificationSettings';

const NotificationSettingsItem = ({ userId, name, title, description, setting }) => {
  const dispatch = useDispatch();
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);

  const handleChange = () => {
    setIsLoadingLocal(true);
    dispatch(toggleNotificationSetting(userId, name)).then(() => {
      setIsLoadingLocal(false);
    });
  };

  return (
    <ListItem>
      <ListItemText primary={title} secondary={description} />
      <ListItemSecondaryAction>
        <FormControlLabel
          edge="end"
          control={<Switch color="primary" />}
          onChange={handleChange}
          label={<LoadingSpinner show={isLoadingLocal} />}
          labelPlacement="start"
          checked={setting.enabled}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

NotificationSettingsItem.propTypes = {
  userId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  setting: PropTypes.object.isRequired
};

export default NotificationSettingsItem;
