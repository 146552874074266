import React from 'react';
import AdminDashboard from './components/AdminDashboard';
import MakeHidden from '../../components/MakeHidden';
import Can from '../../components/Can';

export default function Dashboard() {
  return (
    <>
      <MakeHidden profile="normal">
        <Can
          permissions={['users.view-dashboard', 'users.manage', 'users.manage-limited']}
          yes={() => <AdminDashboard />}
        />
      </MakeHidden>
    </>
  );
}
