import * as Yup from 'yup';
import { ASSIGNMENTS, ATTRIBUTES } from './constants';
import patterns from '../../constants/patterns';
import { REQUEST_ASSIGNMENT_IN_OFFICE } from '../../constants';
import {
  CONTROL_TERMS,
  CONTROL_TEXT
} from '../../pages/Account/components/RxFormCustomization/FormBuilder/helpers';

const schema = {
  first_name: Yup.string()
    .required('Required')
    .matches(
      patterns.name,
      'Name may only contain letters (first letter uppercase), spaces, points, brackets, and apostrophes.'
    )
    .matches(
      patterns.acceptableLanguages,
      "Doesn't support right-to-left writing and ideogram languages."
    ),
  last_name: Yup.string()
    .required('Required')
    .matches(
      patterns.name,
      'Name may only contain letters (first letter uppercase), spaces, points, brackets, and apostrophes.'
    )
    .matches(
      patterns.acceptableLanguages,
      "Doesn't support right-to-left writing and ideogram languages."
    ),
  case_number_text: Yup.string()
    .nullable()
    .matches(
      patterns.case_number,
      'Case number may only contain letters, spaces, points, brackets, and apostrophes.'
    ),
  intraoral_scan_date: Yup.date().required('Required'),
  submission_completed_by: Yup.number().required('Required'),
  rush_case: Yup.boolean(),
  rush_case_authorized_by: Yup.string().when('rush_case', {
    is: val => val === true,
    then: Yup.string()
      .required('Required')
      .matches(
        patterns.name,
        'Name may only contain letters (first letter uppercase), spaces, points, brackets, and apostrophes.'
      )
  }),
  scan_submission_type: Yup.number().required('Required'),
  // nullable doesn't work on number, probably needs to update Yup version
  // scanner_type: Yup.number()
  //   .nullable()
  //   .when('scan_submission_type', {
  //     is: value => value === SCAN_SUBMISSION_METHODS.SCANNER,
  //     then: () => {
  //       return Yup.number().nullable();
  //     }
  //   })
  //   .transform((_, v) => (v === '' ? null : v)),
  jaws_id: Yup.number().required('Required'),
  custom_scanner_type_name: Yup.string().when('scanner_type', {
    is: val => val === 7,
    then: Yup.string().required('Required')
  }),
  stl_files: Yup.array().when('scan_submission_type', {
    is: val => {
      return Number(val) === ASSIGNMENTS.ORTHOSELECT;
    },
    then: Yup.array()
      .of(
        Yup.object().shape({
          file_path: Yup.string().required('Required'),
          name: Yup.string().required('Required')
        })
      )
      .required('Required')
      .min(1),
    otherwise: validationSchema => validationSchema.nullable()
  }),
  arch_wires: Yup.array(),
  rx_intraoral_photos: Yup.array()
    .of(
      Yup.object().shape({
        file_path: Yup.string().required('Required'),
        name: Yup.string().required('Required')
      })
    )
    .required('Required')
    .min(1),
  export_type: Yup.number().required(),
  print_assignment: Yup.number().required(),
  tray_design_assignment: Yup.number().nullable(),
  insert_brackets_assignment: Yup.number().required(),
  location_id: Yup.number()
    .nullable()
    .when('print_assignment', {
      is: val => val !== ASSIGNMENTS.IN_OFFICE,
      then: Yup.number().required()
    }),
  shipping_method: Yup.string()
    .nullable()
    .when('print_assignment', {
      is: val => val === ASSIGNMENTS.ORTHOSELECT,
      then: Yup.string().required()
    }),
  root_integration: Yup.boolean().required(),
  dicom_files: Yup.array()
    .of(
      Yup.object().shape({
        file_path: Yup.string().required('Required'),
        name: Yup.string().required('Required')
      })
    )
    .when('root_integration', {
      is: true,
      then: schema =>
        schema
          .required('Required')
          .min(1)
          .max(1),
      otherwise: validationSchema => validationSchema.nullable()
    }),
  bonding_date: Yup.date().nullable()
};

export const createValidationSchema = (attributes = [], overrideOptions = {}, newControls = []) => {
  const options = { requireBracketLibraries: false, requireDoctor: false, ...overrideOptions };
  const additionalValidation = {};

  if (options.requireBracketLibraries) {
    additionalValidation.bracket_libraries = Yup.array().when(
      ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT,
      {
        is: assignment => assignment === REQUEST_ASSIGNMENT_IN_OFFICE,
        then: validationSchema => validationSchema.nullable(),
        otherwise: validationSchema => validationSchema.required().min(1)
      }
    );
  }

  if (options.requireDoctor) {
    additionalValidation[ATTRIBUTES.DOCTOR] = Yup.number().required('Required');
  }

  if (newControls && newControls.length) {
    for (const field of newControls) {
      let validation = null;

      if (field.rules.required) {
        if (field.system_type === CONTROL_TEXT && field.props.type === 'number') {
          validation = Yup.number().required('Required');
        } else if (field.system_type === CONTROL_TERMS) {
          validation = Yup.number()
            .required()
            .test('is-terms', 'Required', value => parseInt(value) === 1);
        } else {
          validation = Yup.string().required('Required');
        }
      }

      if (validation) {
        additionalValidation[field.system_name] = validation;
      }
    }
  }

  let validationSchema = { ...schema, ...additionalValidation };

  const targetAttributes = [...attributes];
  if (attributes.find(item => item === ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT)) {
    targetAttributes.push(ATTRIBUTES.STL_FILES);
  }
  if (attributes.find(item => item === ATTRIBUTES.ROOT_INTEGRATION)) {
    targetAttributes.push(ATTRIBUTES.DICOM_FILES);
  }

  if (targetAttributes.length) {
    const only = {};

    targetAttributes.forEach(attribute => {
      only[attribute] = validationSchema[attribute];
    });

    validationSchema = { ...only };
  }

  return Yup.object().shape(validationSchema);
};
