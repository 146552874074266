import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import createLoadingSelector from '../../../selectors/loading';
import {
  CREATE_INSTALLER_PREFIX,
  createInstallerAction,
  updateInstallerProgressAction
} from '../../../reducers/installers';
import UploadInstaller from './UploadInstaller';

const AddInstaller = ({ organizationId }) => {
  const dispatch = useDispatch();
  const isCreating = useSelector(state => createLoadingSelector([CREATE_INSTALLER_PREFIX])(state));
  const uploadProgress = useSelector(state => state.installers.uploadProgress);
  const installers = useSelector(state => {
    const { items } = state.installers;
    if (organizationId) {
      return items.filter(item => item.organization_id === organizationId);
    }
    return items;
  });

  const onUploadProgress = progressEvent => {
    const totalLength = progressEvent.lengthComputable
      ? progressEvent.total
      : progressEvent.target.getResponseHeader('content-length') ||
        progressEvent.target.getResponseHeader('x-decompressed-content-length');
    if (totalLength !== null) {
      dispatch(
        updateInstallerProgressAction(Math.round((progressEvent.loaded * 100) / totalLength))
      );
    }
  };

  const onSubmit = (values, { resetForm }) => {
    return dispatch(createInstallerAction(values, onUploadProgress, organizationId)).then(
      response => {
        if (response) {
          resetForm();
        }
      }
    );
  };

  return (
    <Container maxWidth="md">
      <UploadInstaller
        installers={installers}
        onSubmit={onSubmit}
        isSaving={isCreating}
        uploadProgress={uploadProgress}
      />
    </Container>
  );
};

AddInstaller.propTypes = {
  organizationId: PropTypes.number
};

AddInstaller.defaultProps = {
  organizationId: null
};

export default AddInstaller;
