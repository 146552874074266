import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
  holder: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    float: 'left',
    margin: '2px'
  },
  chip: {
    margin: '0px',
    borderRadius: 0,
    fontSize: '0.75rem',
    color: 'white',
    height: '100%',
    justifyContent: 'left',
    fontWeight: 500
  },
  firstChip: {
    borderTopRightRadius: '16px',
    borderTopLeftRadius: '16px',
    fontWeight: 700
  },
  lastChip: {
    borderBottomRightRadius: '16px',
    borderBottomLeftRadius: '16px'
  },
  number: {
    '&.MuiChip-avatar': {
      background: 'transparent',
      color: 'white',
      borderRight: '1px solid #ffffff80',
      borderRadius: 0,
      padding: '0 5px',
      width: '40px',
      height: '100%',
      flexDirection: 'column'
    }
  },
  count: {
    fontSize: '12px'
  },
  percentage: {
    fontSize: '10px',
    marginTop: -6
  }
}));

const ArchBreakdown = ({ chipClassName, onClick, item }) => {
  const classes = useStyles();

  return (
    <div className={classes.holder}>
      {Object.keys(item).map((key, index) => (
        <Chip
          className={`${classes.chip} ${chipClassName} ${index === 0 ? classes.firstChip : ''} ${
            index === Object.keys(item).length - 1 ? classes.lastChip : ''
          }`}
          key={key}
          size="small"
          label={key}
          avatar={
            <Grid container className={classes.number}>
              <Grid item className={classes.count}>
                {item[key].count}
              </Grid>
              <Grid item className={classes.percentage}>
                {item[key].percentage ? `${item[key].percentage}%` : '---'}
              </Grid>
            </Grid>
          }
          onClick={index === 0 ? onClick : null}
        />
      ))}
    </div>
  );
};

ArchBreakdown.propTypes = {
  chipClassName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired
};

export default ArchBreakdown;
