import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  CONTROL_CHECKBOXES,
  CONTROL_DROPDOWN,
  CONTROL_FILE,
  CONTROL_PARAGRAPH,
  CONTROL_RADIOS,
  CONTROL_TERMS,
  CONTROL_TEXT,
  EXISTING_RX_FIELDS
} from '../helpers';

import Text from './Text';
import Radios from './Radios';
import Dropdown from './Dropdown';
import Paragraph from './Paragraph';
import Checkboxes from './Checkboxes';
import FileUpload from './FileUpload';
import Terms from './Terms';

import { ControlContext, MainContext } from '../../contexts';
import FormControlWrapper from './FormControlWrapper';

const renderControls = ({
  elements,
  showSettings,
  moveControl,
  isPreview,
  columnId,
  sectionId,
  removeControl,
  hiddenFields
}) => {
  return elements.map(element => {
    let component = null;
    const isHiddenField = hiddenFields.indexOf(element.system_name) !== -1;

    switch (element.system_type) {
      case CONTROL_DROPDOWN:
        component = <Dropdown preview={isPreview} {...element} />;
        break;
      case CONTROL_TEXT:
        component = <Text preview={isPreview} {...element} />;
        break;
      case CONTROL_PARAGRAPH:
        component = <Paragraph preview={isPreview} {...element} />;
        break;
      case CONTROL_RADIOS:
        component = <Radios preview={isPreview} {...element} />;
        break;
      case CONTROL_CHECKBOXES:
        component = <Checkboxes preview={isPreview} {...element} />;
        break;
      case CONTROL_FILE:
        component = <FileUpload preview={isPreview} {...element} />;
        break;
      case CONTROL_TERMS:
        component = <Terms preview={isPreview} {...element} />;
        break;
      default:
        component = (
          <div className={`fb-default-control ${isHiddenField ? 'disabled-in-preference' : ''}`}>
            {EXISTING_RX_FIELDS[element.system_name]}
            {isHiddenField && (
              <div className="disabled-note">
                Will not be displayed in RxForm due to being disabled in preference
              </div>
            )}
          </div>
        );
    }

    return (
      <FormControlWrapper
        key={element.id}
        id={element.id}
        columnId={columnId}
        sectionId={sectionId}
        isPreview={isPreview}
        moveControl={moveControl}
        system_type={element.system_type}
        showSettings={() => showSettings(element)}
        removeControl={removeControl}
      >
        {component}
      </FormControlWrapper>
    );
  });
};

export default function FormControls({
  elements,
  columnId,
  sectionId,
  isPreview,
  moveControl,
  removeControl
}) {
  const { openSettings } = useContext(ControlContext);
  const { hiddenFields = [] } = useContext(MainContext);

  const showSettings = element => {
    openSettings({ ...element, columnId, sectionId });
  };

  const controls = renderControls({
    elements,
    showSettings,
    isPreview,
    columnId,
    sectionId,
    moveControl,
    removeControl,
    hiddenFields
  });

  return <>{controls}</>;
}

FormControls.propTypes = {
  elements: PropTypes.array.isRequired
};
