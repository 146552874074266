import { Button, Dialog, DialogActions, DialogContent, FormLabel } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PropsPropType, RulesPropType } from '../propTypes';
import { createLabel } from './helpers';

const FileUpload = ({ id, preview, props, rules }) => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <FormLabel>{createLabel(props, rules, 'File Upload')}</FormLabel> <br />
      <Button disabled={preview} variant="contained" onClick={() => setOpen(true)}>
        Upload
      </Button>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>This will be replaced by file picker</DialogContent>

        <DialogActions>
          <Button>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

FileUpload.defaultProps = {
  preview: false
};

FileUpload.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  system_type: PropTypes.string.isRequired,
  props: PropsPropType,
  rules: RulesPropType,
  preview: PropTypes.bool
};

export default FileUpload;
