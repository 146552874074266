import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { Chip, Box } from '@material-ui/core';
import FormDialog from '../../../../components/FormDialog';

const UsersDialog = ({ open, setOpen, name, users, value, onArchWireUpdate }) => {
  const [archWireUsers, setArchWireUsers] = useState(value);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const selectedUserIds = archWireUsers.map(option => option.id);
    setOptions(
      users
        .filter(user => !selectedUserIds.includes(user.id))
        .map(user => ({ label: user.full_name, id: user.id }))
    );
  }, [users, archWireUsers]);

  useEffect(() => {
    setArchWireUsers(value);
  }, [value]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
      setArchWireUsers([]);
    }
  }, [open]);

  const handleClose = response => {
    if (!response) {
      setOpen(false);
    } else {
      onArchWireUpdate(archWireUsers.map(option => option.id));
    }
  };

  return (
    <FormDialog
      setOpen={setOpen}
      open={open}
      titleText={name}
      acceptButtonText="Save"
      handleClose={handleClose}
      fullWidth
    >
      <Box flexDirection="column" width={1}>
        <Autocomplete
          multiple
          freeSolo
          options={options || []}
          value={archWireUsers}
          renderTags={(val, getTagProps) =>
            val.map((option, index) => (
              <Chip disabled variant="outlined" label={option.label} {...getTagProps({ index })} />
            ))
          }
          getOptionLabel={option => option.label || ''}
          onChange={(event, archWireValue) => {
            const invalid =
              Array.isArray(archWireValue) && archWireValue.find(val => typeof val !== 'object');
            if (!invalid) {
              setArchWireUsers(archWireValue);
            }
          }}
          fullWidth
          renderInput={params => (
            <TextField
              size="small"
              variant="outlined"
              multiline
              {...params}
              placeholder="Add User"
              label="Visible to"
            />
          )}
        />
      </Box>
    </FormDialog>
  );
};

UsersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  users: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.array.isRequired,
  onArchWireUpdate: PropTypes.func.isRequired
};

export default UsersDialog;
