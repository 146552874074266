import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import RequestsApiService from '../services/api/requests';
import FlawedTraysApiService from '../services/api/flawedTrays';
import ShippingMethodsApiService from '../services/api/shippingMethods';
import PrintingRequestsApiService from '../services/api/printingRequests';
import ServiceManager from '../services/api/serviceManager';
import { RESET_PATIENTS } from './patients';
import TechWorkflowProcessApiService from '../services/api/techWorkflowProcess';
import {
  enqueueUnfinishedProcessNotification,
  formatProcessRequest
} from '../helpers/techWorkflow';
import techWorkflowTypes from '../constants/techWorkflowTypes';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_REQUESTS_PREFIX = 'FETCH_REQUESTS';
export const FETCH_REQUESTS_REQUEST_ACTION = `${FETCH_REQUESTS_PREFIX}_REQUEST_ACTION`;
export const FETCH_REQUESTS_SUCCESS_ACTION = `${FETCH_REQUESTS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_REQUESTS_FAILURE_ACTION = `${FETCH_REQUESTS_PREFIX}_FAILURE_ACTION`;

export const FETCH_REQUEST_PREFIX = 'FETCH_REQUEST';
export const FETCH_REQUEST_REQUEST_ACTION = `${FETCH_REQUEST_PREFIX}_REQUEST_ACTION`;
export const FETCH_REQUEST_SUCCESS_ACTION = `${FETCH_REQUEST_PREFIX}_SUCCESS_ACTION`;
export const FETCH_REQUEST_FAILURE_ACTION = `${FETCH_REQUEST_PREFIX}_FAILURE_ACTION`;

export const UPDATE_REQUEST_PREFIX = 'UPDATE_REQUEST';
export const UPDATE_REQUEST_REQUEST_ACTION = `${UPDATE_REQUEST_PREFIX}_REQUEST_ACTION`;
export const UPDATE_REQUEST_SUCCESS_ACTION = `${UPDATE_REQUEST_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_REQUEST_FAILURE_ACTION = `${UPDATE_REQUEST_PREFIX}_FAILURE_ACTION`;

export const CANCEL_REQUEST_PREFIX = 'CANCEL_REQUEST';
export const CANCEL_REQUEST_REQUEST_ACTION = `${CANCEL_REQUEST_PREFIX}_REQUEST_ACTION`;
export const CANCEL_REQUEST_SUCCESS_ACTION = `${CANCEL_REQUEST_PREFIX}_SUCCESS_ACTION`;
export const CANCEL_REQUEST_FAILURE_ACTION = `${CANCEL_REQUEST_PREFIX}_FAILURE_ACTION`;

export const COMPLETE_REQUEST_PREFIX = 'COMPLETE_REQUEST';
export const COMPLETE_REQUEST_REQUEST_ACTION = `${COMPLETE_REQUEST_PREFIX}_REQUEST_ACTION`;
export const COMPLETE_REQUEST_SUCCESS_ACTION = `${COMPLETE_REQUEST_PREFIX}_SUCCESS_ACTION`;
export const COMPLETE_REQUEST_FAILURE_ACTION = `${COMPLETE_REQUEST_PREFIX}_FAILURE_ACTION`;

export const CORRECT_FLAWED_TRAY_FROM_REQUEST_PREFIX = 'CORRECT_FLAWED_TRAY_FROM_REQUEST';
export const CORRECT_FLAWED_TRAY_FROM_REQUEST_REQUEST_ACTION = `${CORRECT_FLAWED_TRAY_FROM_REQUEST_PREFIX}_REQUEST_ACTION`;
export const CORRECT_FLAWED_TRAY_FROM_REQUEST_SUCCESS_ACTION = `${CORRECT_FLAWED_TRAY_FROM_REQUEST_PREFIX}_SUCCESS_ACTION`;
export const CORRECT_FLAWED_TRAY_FROM_REQUEST_FAILURE_ACTION = `${CORRECT_FLAWED_TRAY_FROM_REQUEST_PREFIX}_FAILURE_ACTION`;

export const UPDATE_FLAWED_TRAY_FROM_REQUEST_PREFIX = 'UPDATE_FLAWED_TRAY_FROM_REQUEST';
export const UPDATE_FLAWED_TRAY_FROM_REQUEST_REQUEST_ACTION = `${UPDATE_FLAWED_TRAY_FROM_REQUEST_PREFIX}_REQUEST_ACTION`;
export const UPDATE_FLAWED_TRAY_FROM_REQUEST_SUCCESS_ACTION = `${UPDATE_FLAWED_TRAY_FROM_REQUEST_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_FLAWED_TRAY_FROM_REQUEST_FAILURE_ACTION = `${UPDATE_FLAWED_TRAY_FROM_REQUEST_PREFIX}_FAILURE_ACTION`;

export const UPDATE_SHIPPING_FROM_REQUEST_PREFIX = 'UPDATE_SHIPPING_FROM_REQUEST';
export const UPDATE_SHIPPING_FROM_REQUEST_REQUEST_ACTION = `${UPDATE_SHIPPING_FROM_REQUEST_PREFIX}_REQUEST_ACTION`;
export const UPDATE_SHIPPING_FROM_REQUEST_SUCCESS_ACTION = `${UPDATE_SHIPPING_FROM_REQUEST_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_SHIPPING_FROM_REQUEST_FAILURE_ACTION = `${UPDATE_SHIPPING_FROM_REQUEST_PREFIX}_FAILURE_ACTION`;

export const UPDATE_PRINTING_REQUEST_ITEM_FROM_REQUEST_PREFIX =
  'UPDATE_PRINTING_REQUEST_ITEM_FROM_REQUEST';
export const UPDATE_PRINTING_REQUEST_ITEM_FROM_REQUEST_REQUEST_ACTION = `${UPDATE_PRINTING_REQUEST_ITEM_FROM_REQUEST_PREFIX}_REQUEST_ACTION`;
export const UPDATE_PRINTING_REQUEST_ITEM_FROM_REQUEST_SUCCESS_ACTION = `${UPDATE_PRINTING_REQUEST_ITEM_FROM_REQUEST_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_PRINTING_REQUEST_ITEM_FROM_REQUEST_FAILURE_ACTION = `${UPDATE_PRINTING_REQUEST_ITEM_FROM_REQUEST_PREFIX}_FAILURE_ACTION`;

export const ASSIGN_PRINTING_REQUEST_PREFIX = 'ASSIGN_PRINTING_REQUEST';
export const ASSIGN_PRINTING_REQUEST_REQUEST_ACTION = `${ASSIGN_PRINTING_REQUEST_PREFIX}_REQUEST_ACTION`;
export const ASSIGN_PRINTING_REQUEST_SUCCESS_ACTION = `${ASSIGN_PRINTING_REQUEST_PREFIX}_SUCCESS_ACTION`;
export const ASSIGN_PRINTING_REQUEST_FAILURE_ACTION = `${ASSIGN_PRINTING_REQUEST_PREFIX}_FAILURE_ACTION`;

export const REMOVE_PRINTING_REQUEST_ITEM_FROM_REQUEST_PREFIX =
  'REMOVE_PRINTING_REQUEST_ITEM_FROM_REQUEST';
export const REMOVE_PRINTING_REQUEST_ITEM_FROM_REQUEST_REQUEST_ACTION = `${REMOVE_PRINTING_REQUEST_ITEM_FROM_REQUEST_PREFIX}_REQUEST_ACTION`;
export const REMOVE_PRINTING_REQUEST_ITEM_FROM_REQUEST_SUCCESS_ACTION = `${REMOVE_PRINTING_REQUEST_ITEM_FROM_REQUEST_PREFIX}_SUCCESS_ACTION`;
export const REMOVE_PRINTING_REQUEST_ITEM_FROM_REQUEST_FAILURE_ACTION = `${REMOVE_PRINTING_REQUEST_ITEM_FROM_REQUEST_PREFIX}_FAILURE_ACTION`;

export const REMOVE_FLAWED_TRAY_FROM_REQUEST_PREFIX = 'REMOVE_FLAWED_TRAY_FROM_REQUEST';
export const REMOVE_FLAWED_TRAY_FROM_REQUEST_REQUEST_ACTION = `${REMOVE_FLAWED_TRAY_FROM_REQUEST_PREFIX}_REQUEST_ACTION`;
export const REMOVE_FLAWED_TRAY_FROM_REQUEST_SUCCESS_ACTION = `${REMOVE_FLAWED_TRAY_FROM_REQUEST_PREFIX}_SUCCESS_ACTION`;
export const REMOVE_FLAWED_TRAY_FROM_REQUEST_FAILURE_ACTION = `${REMOVE_FLAWED_TRAY_FROM_REQUEST_PREFIX}_FAILURE_ACTION`;

export const UPDATE_REQUEST_MANAGEMENT_DATA_PREFIX = 'UPDATE_REQUEST_MANAGEMENT_DATA';
export const UPDATE_REQUEST_MANAGEMENT_DATA_REQUEST_ACTION = `${UPDATE_REQUEST_MANAGEMENT_DATA_PREFIX}_REQUEST_ACTION`;
export const UPDATE_REQUEST_MANAGEMENT_DATA_SUCCESS_ACTION = `${UPDATE_REQUEST_MANAGEMENT_DATA_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_REQUEST_MANAGEMENT_DATA_FAILURE_ACTION = `${UPDATE_REQUEST_MANAGEMENT_DATA_PREFIX}_FAILURE_ACTION`;

export const RESET_REQUESTS = 'RESET_REQUESTS';

export const MARKING_SHIPPING_LABEL_AS_PRINTED = 'MARKING_SHIPPING_LABEL_AS_PRINTED';
export const MARKING_SHIPPING_LABEL_AS_PRINTED_FAILED = 'MARKING_SHIPPING_LABEL_AS_PRINTED_FAILED';
export const MARKED_SHIPPING_LABEL_AS_PRINTED = 'MARKED_SHIPPING_LABEL_AS_PRINTED';
export const BUNDLING_SHIPMENT = 'BUNDLING_SHIPMENT';
export const BUNDLING_SHIPMENT_FAILED = 'BUNDLING_SHIPMENT_FAILED';
export const SHIPMENT_BUNDLED = 'SHIPMENT_BUNDLED';

export const UPDATE_TECH_WORKFLOW_REQUEST_PROCESS_PREFIX = 'UPDATE_TECH_WORKFLOW_REQUEST_PROCESS';
export const UPDATE_TECH_WORKFLOW_REQUEST_PROCESS_REQUEST_ACTION = `${UPDATE_TECH_WORKFLOW_REQUEST_PROCESS_PREFIX}_REQUEST_ACTION`;
export const UPDATE_TECH_WORKFLOW_REQUEST_PROCESS_SUCCESS_ACTION = `${UPDATE_TECH_WORKFLOW_REQUEST_PROCESS_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_TECH_WORKFLOW_REQUEST_PROCESS_FAILURE_ACTION = `${UPDATE_TECH_WORKFLOW_REQUEST_PROCESS_PREFIX}_FAILURE_ACTION`;

export const START_TECH_WORKFLOW_REQUEST_PROCESS_PREFIX = 'START_TECH_WORKFLOW_REQUEST_PROCESS';
export const START_TECH_WORKFLOW_REQUEST_PROCESS_REQUEST_ACTION = `${START_TECH_WORKFLOW_REQUEST_PROCESS_PREFIX}_REQUEST_ACTION`;
export const START_TECH_WORKFLOW_REQUEST_PROCESS_SUCCESS_ACTION = `${START_TECH_WORKFLOW_REQUEST_PROCESS_PREFIX}_SUCCESS_ACTION`;
export const START_TECH_WORKFLOW_REQUEST_PROCESS_FAILURE_ACTION = `${START_TECH_WORKFLOW_REQUEST_PROCESS_PREFIX}_FAILURE_ACTION`;

export const FINISH_TECH_WORKFLOW_REQUEST_PROCESS_PREFIX = 'FINISH_TECH_WORKFLOW_REQUEST_PROCESS';
export const FINISH_TECH_WORKFLOW_REQUEST_PROCESS_REQUEST_ACTION = `${FINISH_TECH_WORKFLOW_REQUEST_PROCESS_PREFIX}_REQUEST_ACTION`;
export const FINISH_TECH_WORKFLOW_REQUEST_PROCESS_SUCCESS_ACTION = `${FINISH_TECH_WORKFLOW_REQUEST_PROCESS_PREFIX}_SUCCESS_ACTION`;
export const FINISH_TECH_WORKFLOW_REQUEST_PROCESS_FAILURE_ACTION = `${FINISH_TECH_WORKFLOW_REQUEST_PROCESS_PREFIX}_FAILURE_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  items: [],
  total: 0,
  actions: {
    markingShippingLabelInBulk: false,
    bundlingShipment: false
  },
  globalParams: {
    statusId: '',
    order: 'desc',
    orderBy: 'updated_at',
    page: 1,
    perPage: 25,
    searchFullNameQuery: '',
    searchDoctorFullNameQuery: '',
    locations: []
  }
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REQUESTS_SUCCESS_ACTION:
      return {
        ...state,
        items: action.payload.customer_requests.data,
        total: action.payload.customer_requests.total,
        globalParams: action.payload.params
      };
    case FETCH_REQUEST_SUCCESS_ACTION:
      return produce(state, draft => {
        const printingRequestIndex = state.items.findIndex(item => item.id === action.payload.id);
        if (printingRequestIndex > -1) {
          draft.items[state.items.findIndex(item => item.id === action.payload.id)] =
            action.payload;
        } else {
          draft.items.push(action.payload);
        }
      });
    case UPDATE_REQUEST_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items[state.items.findIndex(item => item.id === action.payload.id)] = action.payload;
      });
    case CANCEL_REQUEST_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items[state.items.findIndex(item => item.id === action.payload.id)] = action.payload;
      });
    case COMPLETE_REQUEST_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items[state.items.findIndex(item => item.id === action.payload.id)] = action.payload;
      });
    case UPDATE_FLAWED_TRAY_FROM_REQUEST_SUCCESS_ACTION:
      return produce(state, draft => {
        const requestIndex = state.items.findIndex(item => item.id === action.payload.requestId);
        const flawedTrayIndex = state.items[requestIndex].flawed_trays.findIndex(
          item => item.id === action.payload.flawedTray.id
        );

        draft.items[requestIndex].flawed_trays[flawedTrayIndex] = action.payload.flawedTray;

        if (action.payload.printingRequest === 'deleted') {
          draft.items[requestIndex].printing_request = null;
          return;
        }

        if (action.payload.printingRequest) {
          draft.items[requestIndex].printing_request = action.payload.printingRequest;
        }
      });
    case CORRECT_FLAWED_TRAY_FROM_REQUEST_SUCCESS_ACTION:
      return produce(state, draft => {
        const requestIndex = state.items.findIndex(item => item.id === action.payload.requestId);
        const flawedTrayIndex = state.items[requestIndex].flawed_trays.findIndex(
          item => item.id === action.payload.flawedTray.id
        );

        draft.items[requestIndex].flawed_trays[flawedTrayIndex] = action.payload.flawedTray;
      });
    case UPDATE_PRINTING_REQUEST_ITEM_FROM_REQUEST_SUCCESS_ACTION:
      return produce(state, draft => {
        const requestIndex = state.items.findIndex(item => item.id === action.payload.requestId);
        const printingRequestItemIndex = state.items[
          requestIndex
        ].printing_request.printing_request_items.findIndex(
          item => item.id === action.payload.printingRequestItemId
        );
        draft.items[requestIndex].printing_request.printing_request_items[
          printingRequestItemIndex
        ] = action.payload.printingRequestItem;
      });
    case REMOVE_PRINTING_REQUEST_ITEM_FROM_REQUEST_SUCCESS_ACTION:
      return produce(state, draft => {
        const requestIndex = state.items.findIndex(item => item.id === action.payload.requestId);
        draft.items[requestIndex] = action.payload.request;
      });
    case UPDATE_SHIPPING_FROM_REQUEST_SUCCESS_ACTION:
      return produce(state, draft => {
        const requestIndex = state.items.findIndex(item => item.id === action.payload.requestId);
        draft.items[requestIndex].printing_request = action.payload.printingRequest;
      });
    case REMOVE_FLAWED_TRAY_FROM_REQUEST_SUCCESS_ACTION:
      return produce(state, draft => {
        const requestIndex = state.items.findIndex(item => item.id === action.payload.requestId);
        draft.items[requestIndex] = action.payload.request;
      });
    case ASSIGN_PRINTING_REQUEST_SUCCESS_ACTION:
      return produce(state, draft => {
        const requestIndex = state.items.findIndex(item => item.id === action.payload.requestId);
        draft.items[requestIndex].printing_request.assigned_to = action.payload.assignToId;
      });
    case UPDATE_TECH_WORKFLOW_REQUEST_PROCESS_SUCCESS_ACTION:
    case START_TECH_WORKFLOW_REQUEST_PROCESS_SUCCESS_ACTION:
    case FINISH_TECH_WORKFLOW_REQUEST_PROCESS_SUCCESS_ACTION:
    case START_TECH_WORKFLOW_REQUEST_PROCESS_FAILURE_ACTION:
    case FINISH_TECH_WORKFLOW_REQUEST_PROCESS_FAILURE_ACTION:
      return produce(state, draft => {
        const requestIndex = state.items.findIndex(item => item.id === action.payload.requestId);
        let processIndex = -1;
        switch (action.payload.techWorkflowType) {
          case techWorkflowTypes.PRINTING_REQUEST:
            processIndex = draft.items[
              requestIndex
            ].printing_request.tech_workflow_printing_request.processes.findIndex(
              item => +item.id === +action.payload.techWorkflowProcessId
            );

            if (action.payload.processes) {
              draft.items[requestIndex].printing_request.tech_workflow_printing_request.processes =
                action.payload.processes;
            } else {
              draft.items[requestIndex].printing_request.tech_workflow_printing_request.processes[
                processIndex
              ] = action.payload.process;
            }

            break;
          case techWorkflowTypes.FLAWED_TRAY:
            processIndex = draft.items[requestIndex].tech_workflow_flawed_tray.processes.findIndex(
              item => +item.id === +action.payload.techWorkflowProcessId
            );

            if (action.payload.processes) {
              draft.items[requestIndex].tech_workflow_flawed_tray.processes =
                action.payload.processes;
            } else {
              draft.items[requestIndex].tech_workflow_flawed_tray.processes[processIndex] =
                action.payload.process;
            }

            break;
          default:
            break;
        }
      });
    case RESET_REQUESTS: {
      return initialState;
    }
    case MARKING_SHIPPING_LABEL_AS_PRINTED:
      return produce(state, draft => {
        draft.actions.markingShippingLabelInBulk = true;
      });
    case MARKED_SHIPPING_LABEL_AS_PRINTED:
    case MARKING_SHIPPING_LABEL_AS_PRINTED_FAILED:
      return produce(state, draft => {
        draft.actions.markingShippingLabelInBulk = false;
      });
    case BUNDLING_SHIPMENT:
      return produce(state, draft => {
        draft.actions.bundlingShipment = true;
      });
    case BUNDLING_SHIPMENT_FAILED:
    case SHIPMENT_BUNDLED:
      return produce(state, draft => {
        draft.actions.markingShippingLabelInBulk = false;
      });
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchRequestsAction(params = {}) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_REQUESTS_REQUEST_ACTION });
    const requestsApiService = new ServiceManager(getState).getRequestServiceInstance();
    return requestsApiService
      .get(params)
      .then(response => {
        dispatch({
          type: FETCH_REQUESTS_SUCCESS_ACTION,
          payload: { customer_requests: response.customer_requests, params }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_REQUESTS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchRequestAction(id) {
  return dispatch => {
    dispatch({ type: FETCH_REQUEST_REQUEST_ACTION });
    const requestsApiService = new RequestsApiService();
    return requestsApiService
      .show(id)
      .then(response => {
        dispatch({
          type: FETCH_REQUEST_SUCCESS_ACTION,
          payload: response.customer_request
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_REQUEST_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateRequestAction(id, params) {
  return dispatch => {
    dispatch({ type: UPDATE_REQUEST_REQUEST_ACTION });
    const requestsApiService = new RequestsApiService();
    return requestsApiService
      .update(id, params)
      .then(response => {
        dispatch({
          type: UPDATE_REQUEST_SUCCESS_ACTION,
          payload: response.customer_request
        });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: UPDATE_REQUEST_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function cancelRequestAction(id, params = {}) {
  return dispatch => {
    dispatch({ type: CANCEL_REQUEST_REQUEST_ACTION });
    const requestsApiService = new RequestsApiService();
    return requestsApiService
      .cancel(id, params)
      .then(response => {
        dispatch({
          type: CANCEL_REQUEST_SUCCESS_ACTION,
          payload: response.customer_request
        });
        dispatch(enqueueNotification('success', response.message));
        return response.customer_request;
      })
      .catch(error => {
        dispatch({ type: CANCEL_REQUEST_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function completeRequestAction(id, params) {
  return dispatch => {
    dispatch({ type: COMPLETE_REQUEST_REQUEST_ACTION });
    const requestsApiService = new RequestsApiService();
    return requestsApiService
      .complete(id, params)
      .then(response => {
        dispatch({
          type: COMPLETE_REQUEST_SUCCESS_ACTION,
          payload: response.customer_request
        });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: COMPLETE_REQUEST_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function correctFlawedTrayFromRequestAction(id, fileId, requestId) {
  return dispatch => {
    dispatch({ type: CORRECT_FLAWED_TRAY_FROM_REQUEST_REQUEST_ACTION });
    const flawedTraysApiService = new FlawedTraysApiService();
    return flawedTraysApiService
      .correctFlawedTray(id, fileId)
      .then(response => {
        dispatch({
          type: CORRECT_FLAWED_TRAY_FROM_REQUEST_SUCCESS_ACTION,
          payload: { id, flawedTray: response.flawed_tray, requestId }
        });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: CORRECT_FLAWED_TRAY_FROM_REQUEST_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateFlawedTrayFromRequestAction(id, params, requestId) {
  return dispatch => {
    dispatch({ type: UPDATE_FLAWED_TRAY_FROM_REQUEST_REQUEST_ACTION });
    const flawedTraysApiService = new FlawedTraysApiService();
    return flawedTraysApiService
      .update(id, params)
      .then(response => {
        dispatch({
          type: UPDATE_FLAWED_TRAY_FROM_REQUEST_SUCCESS_ACTION,
          payload: {
            flawedTray: response.flawed_tray,
            requestId,
            printingRequest: response.printing_request
          }
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: UPDATE_FLAWED_TRAY_FROM_REQUEST_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateShippingFromRequestAction(requestId, printingRequestId, params) {
  return dispatch => {
    dispatch({ type: UPDATE_SHIPPING_FROM_REQUEST_REQUEST_ACTION });
    const shippingMethodsApiService = new ShippingMethodsApiService();
    return shippingMethodsApiService
      .updateShippingMethodFromPrintRequest(printingRequestId, params)
      .then(response => {
        dispatch({
          type: UPDATE_SHIPPING_FROM_REQUEST_SUCCESS_ACTION,
          payload: { printingRequest: response.printing_request, printingRequestId, requestId }
        });
        dispatch(enqueueNotification('success', response.message));
        return response.printing_request;
      })
      .catch(error => {
        dispatch({ type: UPDATE_SHIPPING_FROM_REQUEST_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updatePrintingRequestItemFromRequestAction(
  requestId,
  printingRequestItemId,
  params
) {
  return dispatch => {
    dispatch({ type: UPDATE_PRINTING_REQUEST_ITEM_FROM_REQUEST_REQUEST_ACTION });
    const printingRequestsApiService = new PrintingRequestsApiService();
    return printingRequestsApiService
      .updatePrintingRequestItem(printingRequestItemId, params)
      .then(response => {
        dispatch({
          type: UPDATE_PRINTING_REQUEST_ITEM_FROM_REQUEST_SUCCESS_ACTION,
          payload: {
            printingRequestItem: response.printing_request_item,
            printingRequestItemId,
            requestId
          }
        });
        dispatch(enqueueNotification('success', response.message));
        return response.printing_request_item;
      })
      .catch(error => {
        dispatch({ type: UPDATE_PRINTING_REQUEST_ITEM_FROM_REQUEST_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function removePrintingRequestItemFromRequestAction(
  requestId,
  printingRequestItemId,
  flawedTrayId = null
) {
  return dispatch => {
    dispatch({ type: REMOVE_PRINTING_REQUEST_ITEM_FROM_REQUEST_REQUEST_ACTION });
    const printingRequestsApiService = new PrintingRequestsApiService();
    return printingRequestsApiService
      .removePrintingRequestItem(printingRequestItemId)
      .then(response => {
        dispatch({
          type: REMOVE_PRINTING_REQUEST_ITEM_FROM_REQUEST_SUCCESS_ACTION,
          payload: {
            printingRequestItemId,
            requestId,
            flawedTrayId,
            request: response.customer_request
          }
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: REMOVE_PRINTING_REQUEST_ITEM_FROM_REQUEST_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function removeFlawedTrayFromRequestAction(requestId, flawedTrayId = null) {
  return dispatch => {
    dispatch({ type: REMOVE_FLAWED_TRAY_FROM_REQUEST_REQUEST_ACTION });
    const flawedTraysApiService = new FlawedTraysApiService();
    return flawedTraysApiService
      .remove(flawedTrayId)
      .then(response => {
        dispatch({
          type: REMOVE_FLAWED_TRAY_FROM_REQUEST_SUCCESS_ACTION,
          payload: {
            requestId,
            flawedTrayId,
            request: response.customer_request
          }
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: REMOVE_FLAWED_TRAY_FROM_REQUEST_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateRequestManagementDataAction(requestId, params) {
  return dispatch => {
    dispatch({ type: UPDATE_REQUEST_MANAGEMENT_DATA_REQUEST_ACTION });
    const requestsApiService = new RequestsApiService();
    return requestsApiService
      .updateManagementData(requestId, params)
      .then(response => {
        dispatch({
          type: UPDATE_REQUEST_MANAGEMENT_DATA_SUCCESS_ACTION,
          payload: {
            requestId,
            requestManagementData: response.customer_request_management_data
          }
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: UPDATE_REQUEST_MANAGEMENT_DATA_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function assignPrintingRequestAction(printingRequestId, assignToId, requestId) {
  return dispatch => {
    dispatch({ type: ASSIGN_PRINTING_REQUEST_REQUEST_ACTION });
    const printingRequestsApiService = new PrintingRequestsApiService();
    return printingRequestsApiService
      .assign(printingRequestId, assignToId)
      .then(response => {
        dispatch({
          type: ASSIGN_PRINTING_REQUEST_SUCCESS_ACTION,
          payload: {
            printingRequestId,
            assignToId,
            requestId
          }
        });
        dispatch(enqueueNotification('success', response.message));
        return response;
      })
      .catch(error => {
        dispatch({ type: ASSIGN_PRINTING_REQUEST_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function resetRequests() {
  return dispatch => {
    dispatch({
      type: RESET_PATIENTS
    });
  };
}

export function requestFlawedTrayCorrection(organizationId, data) {
  return dispatch => {
    const apiService = new FlawedTraysApiService();
    return apiService
      .requestForCorrection(organizationId, data)
      .then(response => {
        dispatch(enqueueNotification('success', response.message));
        return response;
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function markPatientLabelAsPrinted(id) {
  return dispatch => {
    const requestsApiService = new RequestsApiService();
    return requestsApiService
      .markPatientLabelAsPrinted(id)
      .then(response => {
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: COMPLETE_REQUEST_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function printShippingLabel(bundledShipmentId) {
  return dispatch => {
    dispatch({ type: MARKING_SHIPPING_LABEL_AS_PRINTED });
    const requestsApiService = new RequestsApiService();
    return requestsApiService
      .printShippingLabel(bundledShipmentId)
      .then(response => {
        dispatch({ type: MARKED_SHIPPING_LABEL_AS_PRINTED });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: MARKING_SHIPPING_LABEL_AS_PRINTED_FAILED });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function completeAsBundle(
  ids,
  trackingNumber,
  customShippingFee = null,
  internalShippingCost = null
) {
  return dispatch => {
    dispatch({ type: BUNDLING_SHIPMENT });
    const requestsApiService = new RequestsApiService();
    return requestsApiService
      .completeInBundle(ids, trackingNumber, customShippingFee, internalShippingCost)
      .then(response => {
        dispatch({ type: SHIPMENT_BUNDLED });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: BUNDLING_SHIPMENT_FAILED });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateTechWorkflowProcessAction(
  requestId,
  techWorkflowType,
  techWorkflowProcessId,
  data
) {
  return dispatch => {
    dispatch({ type: UPDATE_TECH_WORKFLOW_REQUEST_PROCESS_REQUEST_ACTION });
    const techWorkflowProcessApiService = new TechWorkflowProcessApiService();

    return new Promise((resolve, reject) => {
      techWorkflowProcessApiService
        .update(techWorkflowProcessId, formatProcessRequest(data))
        .then(response => {
          dispatch({
            type: UPDATE_TECH_WORKFLOW_REQUEST_PROCESS_SUCCESS_ACTION,
            payload: {
              requestId,
              techWorkflowProcessId,
              techWorkflowType,
              process: response.process,
              processes: response.processes
            }
          });
          dispatch(enqueueNotification('success', response.message));
          resolve(true);
          return true;
        })
        .catch(error => {
          dispatch({ type: UPDATE_TECH_WORKFLOW_REQUEST_PROCESS_FAILURE_ACTION });
          dispatch(enqueueNotification('error', error.message));
          reject(error);
        });
    });
  };
}
export function startTechWorkflowProcessAction(requestId, techWorkflowType, techWorkflowProcessId) {
  return dispatch => {
    dispatch({ type: START_TECH_WORKFLOW_REQUEST_PROCESS_REQUEST_ACTION });
    const techWorkflowProcessApiService = new TechWorkflowProcessApiService();

    return new Promise((resolve, reject) => {
      techWorkflowProcessApiService
        .start(techWorkflowProcessId)
        .then(response => {
          dispatch({
            type: START_TECH_WORKFLOW_REQUEST_PROCESS_SUCCESS_ACTION,
            payload: {
              requestId,
              techWorkflowType,
              techWorkflowProcessId,
              process: response.process,
              processes: response.processes
            }
          });
          dispatch(enqueueNotification('success', response.message));
          resolve(true);
          return true;
        })
        .catch(error => {
          if (error.responseData && error.responseData.process) {
            dispatch({
              type: START_TECH_WORKFLOW_REQUEST_PROCESS_FAILURE_ACTION,
              payload: {
                requestId,
                techWorkflowType,
                techWorkflowProcessId,
                process: error.responseData.process,
                processes: error.responseData.processes
              }
            });
          }

          dispatch(enqueueUnfinishedProcessNotification(error));
          reject(error);
        });
    });
  };
}

export function finishTechWorkflowProcessAction(
  requestId,
  techWorkflowType,
  techWorkflowProcessId
) {
  return dispatch => {
    dispatch({ type: FINISH_TECH_WORKFLOW_REQUEST_PROCESS_REQUEST_ACTION });
    const techWorkflowProcessApiService = new TechWorkflowProcessApiService();

    return new Promise((resolve, reject) => {
      techWorkflowProcessApiService
        .finish(techWorkflowProcessId)
        .then(response => {
          dispatch({
            type: FINISH_TECH_WORKFLOW_REQUEST_PROCESS_SUCCESS_ACTION,
            payload: {
              requestId,
              techWorkflowType,
              techWorkflowProcessId,
              process: response.process,
              processes: response.processes
            }
          });
          dispatch(enqueueNotification('success', response.message));
          resolve(true);
          return true;
        })
        .catch(error => {
          if (error.responseData && error.responseData.process) {
            dispatch({
              type: FINISH_TECH_WORKFLOW_REQUEST_PROCESS_FAILURE_ACTION,
              payload: {
                requestId,
                techWorkflowType,
                techWorkflowProcessId,
                process: error.responseData.process,
                processes: error.responseData.processes
              }
            });
          }
          dispatch(enqueueNotification('error', error.message));
          reject(error);
        });
    });
  };
}
