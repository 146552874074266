import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UploadFilesDialogBase from '../../../../components/UploadFilesDialogBase';
import { enqueueNotification } from '../../../../reducers/notifications';
import FormatSelectionField from './FormatSelectionField';

const UploadDialog = ({
  uploading,
  handleSave,
  retrieveDownloadUrl,
  handleClose,
  isFileAlreadyUploaded,
  ...props
}) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [uploadingCount, setUploadingCount] = useState(0);
  const [format, setFormat] = useState(1);

  const addFile = file => {
    setFiles([...files, file]);
  };

  const removeFile = id => {
    setFiles(files.filter(file => file.id !== id));
  };

  const handleCancel = () => {
    handleClose();
    setFiles([]);
  };

  const handleUpload = () => {
    handleSave(files.map(file => ({ ...file, format })));
    setFiles([]);
  };

  const fileExists = fileName => {
    return files.filter(item => item.file_name === fileName).length > 0;
  };

  const handleChange = async (fileWithMeta, status) => {
    const { meta } = fileWithMeta;
    switch (status) {
      case 'ready':
        if (isFileAlreadyUploaded(meta.name)) {
          dispatch(
            enqueueNotification(
              'error',
              `A similar bracket library named "${meta.name}" already exists.`
            )
          );
          fileWithMeta.remove();
          fileWithMeta.cancel();
        } else if (fileExists(meta.name)) {
          dispatch(enqueueNotification('error', `"${meta.name}" file is already on the list.`));
          fileWithMeta.remove();
          fileWithMeta.cancel();
        } else {
          fileWithMeta.restart();
        }
        break;
      case 'uploading':
        setUploadingCount(uploadingCount + 1);
        break;
      case 'aborted':
        setUploadingCount(uploadingCount - 1);
        break;
      case 'done':
        setUploadingCount(uploadingCount - 1);

        addFile({
          id: meta.id,
          file_name: meta.name
        });

        break;
      case 'removed':
        removeFile(meta.id);
        break;
      default:
        break;
    }
  };

  const getUploadParams = async ({ file, meta: { name, lastModifiedDate } }) => {
    try {
      const { url, id } = await retrieveDownloadUrl(name);
      return { method: 'put', body: file, url, meta: { id, name, lastModifiedDate } };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
      dispatch(
        enqueueNotification('error', 'Failed to upload, please try again or contact support team.')
      );
      return null;
    }
  };

  const actions = [
    {
      label: uploading ? 'Saving' : 'Save',
      disabled: !!uploadingCount || uploading || files.length === 0,
      color: 'primary',
      variant: 'contained',
      onClick: handleUpload
    },
    {
      label: 'Cancel',
      variant: 'contained',
      color: 'secondary',
      disabled: !!uploadingCount || uploading,
      onClick: handleCancel
    }
  ];

  const handleChangeFormat = value => {
    setFormat(value);
  };

  return (
    <div>
      <UploadFilesDialogBase
        handleChangeStatus={handleChange}
        title="Upload Bracket Libraries"
        getUploadParams={getUploadParams}
        handleSubmit={() => {}}
        maxFiles={10}
        handleClose={handleCancel}
        customActions={actions}
        disabled={uploading}
        autoUpload={false}
        postDropzoneComponent={
          <FormatSelectionField value={format} onChange={handleChangeFormat} />
        }
        {...props}
      />
    </div>
  );
};

UploadDialog.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  retrieveDownloadUrl: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  isFileAlreadyUploaded: PropTypes.func.isRequired
};

export default UploadDialog;
