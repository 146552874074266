import axios from 'axios';
import { getToken, getTokenHeader } from '../../helpers/connection';

const REQUEST_ABORTED = 'REQUEST_ABORTED';

export default class BaseApiService {
  constructor() {
    this.cancelToken = null;
    this.baseUrl = process.env.REACT_APP_API_URL;
  }

  static handleResponse(response) {
    return response.data;
  }

  getCancelRequestFn() {
    this.cancelToken = axios.CancelToken.source();
    return () => {
      this.cancelToken.cancel(REQUEST_ABORTED);
    };
  }

  static handleError(error) {
    const isAborted = error.message === REQUEST_ABORTED;

    if (error.code === 'ECONNABORTED') {
      throw new Error('Something went wrong. Please check your internet connection and try again.');
    }

    if (
      error.response &&
      error.response.status === 401 &&
      error.response.config &&
      error.response.config.url !== '/login'
    ) {
      localStorage.removeItem('access_token');
      window.location = '/login';
    }
    let errorResponse =
      (error.response && error.response.data && error.response.data.error) ||
      'Something went wrong, please try again.';

    if (typeof errorResponse === 'object') {
      errorResponse = Object.keys(errorResponse).map(item => errorResponse[item].join(' '));
    }

    const errorObject = new Error(errorResponse);
    errorObject.code = error.response && error.response.data && error.response.data.code;
    errorObject.requestParams = error.config && error.config.data;
    if (isAborted) {
      errorObject.name = 'request aborted';
    }
    errorObject.responseData = error.response && error.response.data;

    throw errorObject;
  }

  request({
    method,
    url,
    params,
    data = {},
    customHeaders,
    handleResponse = BaseApiService.handleResponse,
    additionalHeaders = {},
    ...rest
  }) {
    const baseURL = this.baseUrl;
    let tokenHeader = {};
    const token = getToken();
    if (token) {
      tokenHeader = getTokenHeader(token);
    }
    const headers = {
      ...tokenHeader,
      'Content-Type': 'application/json',
      ...additionalHeaders
    };

    return axios({
      baseURL,
      timeout: process.env.REACT_APP_REQUEST_TIMEOUT * 1000,
      maxContentLength: 5000,
      method,
      url,
      params,
      data,
      cancelToken: this.cancelToken && this.cancelToken.token,
      headers: customHeaders || headers,
      ...rest
    }).then(handleResponse, BaseApiService.handleError);
  }
}
