import _ from 'lodash';

export const calculateTaxRate = (settings, state) => {
  return _.toLower(settings.tax.state) === _.toLower(state)
    ? parseFloat(settings.tax.rate) / 100
    : 0;
};

export const getCreditCardFeeRate = (primaryPaymentMethod, settings) => {
  if (_.isNil(primaryPaymentMethod)) return 0;

  const paymentMethodType = _.get(primaryPaymentMethod, 'payment_method_type', null);

  const isCreditCard = paymentMethodType === 'card';

  return isCreditCard ? parseFloat(settings.credit_card.fee) / 100 : 0;
};

export const addDiscountToOrder = (currentOrganization, order, promotionCode, settings) => {
  const { state } = currentOrganization.payment_info.billing_address;

  const taxRate = calculateTaxRate(settings, state);

  let { subtotal, order_items: orderItems } = order;
  let discount = promotionCode.value;
  if (!promotionCode.is_fixed) {
    discount = (subtotal * promotionCode.value) / 100;
  }

  const positivePromoValue = -discount;
  const shouldWaverStartFee = positivePromoValue > subtotal;

  if (shouldWaverStartFee) {
    subtotal = positivePromoValue;
    orderItems = orderItems.map(orderItem => ({
      ...orderItem,
      value: 0
    }));
  } else if (discount > subtotal) {
    subtotal = 0;
  } else {
    subtotal -= discount;
  }

  const tax = !shouldWaverStartFee ? taxRate * subtotal : 0;
  const primaryPaymentMethod = currentOrganization.payment_info.payment_methods.find(
    paymentMethod => {
      return paymentMethod.is_primary;
    }
  );

  const creditCardFeeRate = getCreditCardFeeRate(primaryPaymentMethod, settings);

  const creditCardFee = !shouldWaverStartFee ? creditCardFeeRate * (tax + subtotal) : 0;

  return {
    ...order,
    credit_card_fee: creditCardFee,
    discounts: [
      {
        id: 1,
        promotion_code: promotionCode,
        value: discount,
        custom_description: promotionCode.label
      }
    ],
    order_items: orderItems,
    subtotal,
    tax,
    shouldWaverStartFee
  };
};

export const redirectToOnboardingCoordination = () => {
  window.location = process.env.REACT_APP_ONBOARDING_URL;
  return true;
};

export const getOrderItems = product => {
  return [
    {
      product,
      quantity: 1,
      id: 1,
      product_id: product.id,
      value: product.unit_price
    }
  ];
};

export const extractPrimaryPaymentMethod = organization => {
  const paymentMethods = _.get(organization, 'payment_info.payment_methods', null);

  if (_.isNil(paymentMethods)) return null;

  return paymentMethods.find(paymentMethod => paymentMethod.is_primary);
};

export const calculateCreditCardFees = (currentOrganization, product, settings) => {
  const state = _.get(currentOrganization, 'payment_info.billing_address.state', null);

  const taxRate = calculateTaxRate(settings, state);

  const primaryPaymentMethod = extractPrimaryPaymentMethod(currentOrganization);

  const creditCardFeeRate = getCreditCardFeeRate(primaryPaymentMethod, settings);

  const credits = Number(
    currentOrganization.credits > product.unit_price
      ? product.unit_price
      : currentOrganization.credits
  );

  const subtotal = Number(product.unit_price - credits);

  const tax = taxRate * Number(subtotal);

  const creditCardFee = creditCardFeeRate * (tax + subtotal);

  const orderItems = getOrderItems(product);

  return {
    tax,
    credits,
    subtotal,
    credit_card_fee: creditCardFee,
    order_items: orderItems
  };
};
