import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import WifiTetheringIcon from '@material-ui/icons/WifiTethering';
import { makeStyles } from '@material-ui/core/styles';
import Charts from '../Charts';
import Statuses, { ArchiveStatusKey } from '../../../GlobalSearch/Data/Statuses';
import hasPermission from '../../../../selectors/hasPermission';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  root: {
    paddingLeft: '0',
    paddingTop: '0'
  }
}));

const Board = ({ statistics }) => {
  const classes = useStyles();

  const doctorActiveTotal =
    statistics.onboarding_doctors.live_data +
    statistics.new_doctors.live_data +
    statistics.active_doctors.live_data;

  const canViewArchived = useSelector(state =>
    hasPermission(state, { permissions: ['organization.view-archived'] })
  );

  return (
    <>
      <CardHeader
        title="Users"
        classes={{
          root: classes.root,
          content: classes.content
        }}
        titleTypographyProps={{ variant: 'h6', color: 'primary' }}
        subheader={<WifiTetheringIcon color="error" />}
      />
      <Charts
        numbers={[
          statistics.registering_doctors.live_data,
          statistics.onboarding_doctors.live_data,
          statistics.new_doctors.live_data,
          statistics.active_doctors.live_data,
          statistics.inactive_doctors.live_data,
          statistics.archived_doctors.live_data
        ]}
        totals={[
          statistics.total_doctors.live_data,
          doctorActiveTotal,
          doctorActiveTotal,
          doctorActiveTotal,
          statistics.total_doctors.live_data,
          statistics.total_doctors.live_data
        ]}
        titles={['Leads', 'Onboarding', 'New', 'Active', 'Passive', 'Archived']}
        subtitles={['Doctors', 'Doctors', 'Doctors', 'Doctors', 'Doctors', 'Doctors']}
        filters={Object.keys(Statuses)}
        disabledFilters={canViewArchived ? [] : [ArchiveStatusKey]}
        paramString="filter[roles]=doctor&filter[status]="
        clickable={false}
      />
    </>
  );
};

Board.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  statistics: PropTypes.object.isRequired
};

export default Board;
