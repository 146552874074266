import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  chip: {
    marginLeft: theme.spacing(1),
    fontSize: '11px',
    fontWeight: 'bold',
    color: 'white'
  }
}));

const NoteStatus = ({ hasDoctorNotes, hasInternalNotes, hasManagementNotes, hasOnHoldNotes }) => {
  const classes = useStyles();

  let count = 0;
  let title = '';
  if (hasDoctorNotes) {
    title = 'D. Notes';
    count += 1;
  }
  if (hasInternalNotes) {
    title = 'I. Notes';
    count += 1;
  }
  if (hasManagementNotes) {
    title = 'T. Notes';
    count += 1;
  }
  if (hasOnHoldNotes) {
    title = 'H. Notes';
    count += 1;
  }
  if (count > 1) {
    title = 'M. Notes';
  }

  return count ? (
    <Chip color="primary" size="small" className={classes.chip} label={title} />
  ) : null;
};

NoteStatus.propTypes = {
  hasDoctorNotes: PropTypes.bool.isRequired,
  hasInternalNotes: PropTypes.bool.isRequired,
  hasManagementNotes: PropTypes.bool.isRequired,
  hasOnHoldNotes: PropTypes.bool.isRequired
};

export default NoteStatus;
