import React from 'react';
import { useDrag } from 'react-dnd';
import { TYPE_CONTROL } from '../helpers';

export default function Element({el, onClick }) {
    const [collected, drag, dragPreview] = useDrag(() => ({
        type: el.draggableType || TYPE_CONTROL,
        item: {
            id: el.id,
            name: el.name,
            isNew: el.isNew,
            system_type: el.system_type,
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        })
    }));

    const onAddElement = () => {
        onClick(el.system_type);
    }

    return (
        <div
            ref={collected.isDragging ? dragPreview : drag}
            className="fb-field"
            onClick={onAddElement}>
            <span className='fbf-icon'>{el.icon}</span> {el.name}
        </div>
    )
}