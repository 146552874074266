import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  return {
    container: {
      '& .MuiDialog-paper': {
        padding: theme.spacing(1)
      }
    }
  };
});

const LabelDialog = ({
  title,
  children,
  onPrinted,
  onClose,
  printed,
  markingAsPrinted,
  open,
  labels,
  ...props
}) => {
  const classes = useStyles();
  const contentRef = useRef();

  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    const content = contentRef.current;
    await window.navigator.clipboard.writeText(content.outerText);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Dialog onClose={onClose} open={open} {...props} className={classes.container}>
      {open && (
        <>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText ref={contentRef}>{children}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={onClose}>
              Close
            </Button>
            <Button
              variant={copied ? 'outlined' : 'contained'}
              onClick={handleCopy}
              color="primary"
            >
              {copied ? `Copied!` : 'Copy'}
            </Button>
            <Button
              disabled={markingAsPrinted || printed}
              variant="contained"
              onClick={onPrinted}
              color="primary"
            >
              {printed ? 'Printed' : labels.printButton || 'Mark as Printed'}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

LabelDialog.propTypes = {
  title: PropTypes.string.isRequired,
  onPrinted: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  children: PropTypes.node.isRequired,
  printed: PropTypes.bool,
  markingAsPrinted: PropTypes.bool,
  labels: PropTypes.shape({
    printButton: PropTypes.string
  })
};

LabelDialog.defaultProps = {
  markingAsPrinted: false,
  open: false,
  printed: false,
  labels: {
    printButton: 'Mark as Printed'
  }
};

export default LabelDialog;
