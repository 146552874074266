// eslint-disable-next-line import/prefer-default-export
export const stepsMap = {
  1: {
    title: 'Welcome to DIBS AI sign-up!',
    subtitle: "Let's get started.",
    inputs: ['first_name', 'last_name', 'email']
  },
  2: {
    title: 'Almost there!',
    inputs: ['password', 'password_confirmation']
  }
};
