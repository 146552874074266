import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { DOCTOR_DEFAULT_PAGE, MANAGEMENT_DEFAULT_PAGE } from '../../constants/default';

const PublicRoute = props => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const isManagement = useSelector(state => state.auth.isManagement);
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      history.push(isManagement ? MANAGEMENT_DEFAULT_PAGE : DOCTOR_DEFAULT_PAGE);
    }
  }, [history, isAuthenticated, isManagement]);

  if (isAuthenticated) {
    return null;
  }

  return <Route {...props} />;
};

PublicRoute.propTypes = {};

PublicRoute.defaultProps = {};

export default PublicRoute;
