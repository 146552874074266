import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import PatientNote from '../PatientNote';
import { UTCDateTimeToLocalDateTimeReadable } from '../../helpers';

const OnHoldNotes = props => {
  const { notes, label, disabled } = props;

  const onHoldMessage = note => {
    if (!note.is_on_hold_reason) {
      return '';
    }

    return note.is_unhold ? 'unheld this case.' : 'placed this case on hold.';
  };

  const formattedNote = useMemo(() => {
    return notes.reduce((allNotes, note) => {
      const dateTime = UTCDateTimeToLocalDateTimeReadable(note.created_at);

      return `${allNotes ? `${allNotes}\n\n` : ''} ${dateTime} - ${note.name} ${onHoldMessage(
        note
      )} \n ${note.note}`;
    }, '');
  }, [notes]);

  return (
    <Box flexDirection="column" width={1}>
      <PatientNote
        {...props}
        note={formattedNote}
        disabled
        label={disabled ? label : `Saved ${label}`}
        noteIdentifier="on_hold_notes"
      />
      {!disabled && (
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <PatientNote
            {...props}
            note=""
            label={`New ${label}`}
            noteIdentifier="on_hold_note"
            relatedIdentifiers={['on_hold_notes']}
            emptyOnSave
          />
        </Box>
      )}
    </Box>
  );
};

OnHoldNotes.propTypes = {
  patientId: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  notes: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  rows: PropTypes.number,
  disabled: PropTypes.bool
};

OnHoldNotes.defaultProps = {
  rows: 4,
  disabled: false
};

export default OnHoldNotes;
