import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { createLabel, createMoreInfoLink, getInjectedFields } from './helpers';
import { PropsPropType, RulesPropType } from '../propTypes';

const Terms = ({ id, preview, field, props, rules, isLive, form }) => {
  const description = get(props, 'description');
  const { name, value, onChange } = getInjectedFields(field);

  const onCheck = e => {
    const checked = Number(e.target.checked).toString();
    onChange({ target: { name, value: checked } });
  };

  return (
    <div style={{ textAlign: 'left' }}>
      <Typography variant="body1">
        {get(props, 'description')}
        {createMoreInfoLink(props)}
      </Typography>

      <FormControlLabel
        control={
          <Checkbox disabled={preview} color="primary" onChange={onCheck} checked={value === '1'} />
        }
        label={createLabel(props, rules, 'I Agree')}
      />

      {description && <p className="caption">{description}</p>}
    </div>
  );
};

Terms.defaultProps = {
  preview: false
};

Terms.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  system_type: PropTypes.string.isRequired,
  props: PropsPropType,
  rules: RulesPropType,
  preview: PropTypes.bool
};

export default Terms;
