import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardContent, Divider, List } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import NotificationSettingsItem from './NotificationSettingsItem';
import { isTechnicianOrAdmin } from '../../../helpers';
import { fetchNotificationSettings } from '../../../reducers/userNotificationSettings';

const NOTIFICATIONS = {
  rush_case: {
    label: 'Rush Case',
    description: 'This notification will be sent when a new rush case is submitted by a doctor.'
  }
};

const NotificationSettings = ({ user }) => {
  const dispatch = useDispatch();
  const settings = useSelector(state => {
    return state.userNotificationSettings.users[user.id];
  });

  useEffect(() => {
    dispatch(fetchNotificationSettings(user.id));
  }, [dispatch, user]);

  if (!isTechnicianOrAdmin(user)) {
    return null;
  }

  if (!settings) {
    return null;
  }

  return (
    <Card>
      <CardHeader title="Notification Settings" />
      <Divider />
      <CardContent>
        <List>
          {settings.map(setting => {
            const { label, description } = NOTIFICATIONS[setting.name];
            return (
              <NotificationSettingsItem
                userId={user.id}
                name={setting.name}
                title={label}
                description={description}
                key={setting.name}
                setting={setting}
              />
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
};

NotificationSettings.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired
};

export default NotificationSettings;
