import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { NOTIFICATION_TYPES } from '../WSNotificationHandler/constants';
import { markNotificationAsRead, removeNotificationAlertAction } from '../../reducers/auth';
import RushCase from './Types/RushCase';
import LowBracketInventory from './Types/LowBracketInventory';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    height: '100%'
  },
  alert_container: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  }
}));

const contentWrappers = {
  [NOTIFICATION_TYPES.RUSH_CASE]: RushCase,
  [NOTIFICATION_TYPES.LOW_BRACKET_INVENTORY]: LowBracketInventory
};
const customTitle = {
  [NOTIFICATION_TYPES.RUSH_CASE]: 'Rush Case'
};

const Item = ({ id, category, onClose, title, content, ContentWrapper = null, data }) => {
  return (
    <Alert severity={category} key={id} onClose={onClose}>
      <AlertTitle>{title}</AlertTitle>
      {ContentWrapper ? <ContentWrapper data={data} /> : content}
    </Alert>
  );
};

Item.defaultProps = {
  content: null,
  ContentWrapper: null
};

Item.propTypes = {
  id: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  ContentWrapper: PropTypes.elementType,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired
};

const AlertContainer = () => {
  const classes = useStyles();
  const userNotifications = useSelector(state => state.auth.userNotificationAlerts);
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();

  const onClose = id => {
    dispatch(removeNotificationAlertAction(id));
    dispatch(markNotificationAsRead(id));
  };

  useEffect(() => {
    setNotifications(userNotifications);
  }, [userNotifications]);

  if (notifications.length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className={classes.alert_container}>
            {notifications.map(notification => {
              const type = notification.type || '';
              const ContentWrapper = contentWrappers[type] || null;
              let { title = '' } = notification;
              const { message, category = 'info' } = notification;

              if (customTitle[type]) {
                title = customTitle[type];
              }

              return (
                <Item
                  id={notification.id}
                  key={notification.id}
                  category={category}
                  onClose={() => onClose(notification.id)}
                  title={title}
                  content={message}
                  ContentWrapper={ContentWrapper}
                  data={notification}
                />
              );
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AlertContainer;
