import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connectRefinementList } from 'react-instantsearch-dom';
import { updateRefinements } from '../../../reducers/globalSearch';

const RefinementList = ({
  items,
  currentRefinement,
  options,
  attribute,
  onClick,
  disabledOptions
}) => {
  const dispatch = useDispatch();

  const isDisabled = filter => disabledOptions.includes(filter);

  const toggleRefine = (e, option) => {
    e.preventDefault();

    if (isDisabled(option.filter)) {
      return;
    }

    const removeOption = currentRefinement.find(refinement => refinement === option.filter);

    const updatedRefinements = removeOption
      ? currentRefinement.filter(refinement => refinement !== option.filter)
      : [...currentRefinement, option.filter];

    onClick(updatedRefinements);
    dispatch(updateRefinements(attribute, updatedRefinements));
  };

  const getItemClassName = (option, isRefined) => {
    let className = isRefined ? 'ais-RefinementList-item--selected' : 'ais-RefinementList-item';

    if (disabledOptions.includes(option.filter)) {
      className = 'ais-RefinementList-item ais-RefinementList-item--disabled';
    }

    return className;
  };

  return (
    <ul className="ais-RefinementList-list">
      {_.map(options, (option, key) => {
        const item = _.find(items, i => i.label === key);

        /* The item isn't fetched from the search facets */
        if (!item)
          return (
            <li
              className={getItemClassName(option, false)}
              key={option.filter}
              onClick={e => toggleRefine(e, option)}
            >
              <label className="ais-RefinementList-label">
                <input
                  disabled={isDisabled(option.filter)}
                  className="ais-RefinementList-checkbox"
                  type="checkbox"
                />
                <span className="ais-RefinementList-labelText">{option.label}</span>
              </label>
            </li>
          );

        return (
          <li
            className={getItemClassName(option, item.isRefined)}
            key={item.label}
            onClick={e => toggleRefine(e, option)}
          >
            <label className="ais-RefinementList-label">
              <input
                disabled={isDisabled(option.filter)}
                className="ais-RefinementList-checkbox"
                type="checkbox"
              />
              <span className="ais-RefinementList-labelText">{option.label}</span>
            </label>
          </li>
        );
      })}
    </ul>
  );
};

const CustomRefinementList = connectRefinementList(RefinementList);

RefinementList.propTypes = {
  attribute: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  options: PropTypes.objectOf(
    PropTypes.shape({
      filter: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  currentRefinement: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  disabledOptions: PropTypes.array
};

RefinementList.defaultProps = {
  options: {},
  onClick: () => {},
  disabledOptions: []
};

export default CustomRefinementList;
