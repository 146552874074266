import { produce } from 'immer';
import UsersApiService from '../services/api/users';
import { enqueueNotification } from './notifications';

export const FETCH_USER_NOTIFICATION_SETTINGS_PREFIX = 'FETCH_USER_NOTIFICATION_SETTINGS';
export const FETCH_USER_NOTIFICATION_SETTINGS_REQUEST_ACTION = `${FETCH_USER_NOTIFICATION_SETTINGS_PREFIX}_REQUEST_ACTION`;
export const FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS_ACTION = `${FETCH_USER_NOTIFICATION_SETTINGS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_USER_NOTIFICATION_SETTINGS_FAILURE_ACTION = `${FETCH_USER_NOTIFICATION_SETTINGS_PREFIX}_FAILURE_ACTION`;

export const TOGGLE_USER_NOTIFICATION_SETTINGS_PREFIX = 'TOGGLE_USER_NOTIFICATION_SETTINGS';
export const TOGGLE_USER_NOTIFICATION_SETTINGS_REQUEST_ACTION = `${TOGGLE_USER_NOTIFICATION_SETTINGS_PREFIX}_REQUEST_ACTION`;
export const TOGGLE_USER_NOTIFICATION_SETTINGS_SUCCESS_ACTION = `${TOGGLE_USER_NOTIFICATION_SETTINGS_PREFIX}_SUCCESS_ACTION`;
export const TOGGLE_USER_NOTIFICATION_SETTINGS_FAILURE_ACTION = `${TOGGLE_USER_NOTIFICATION_SETTINGS_PREFIX}_FAILURE_ACTION`;

const initialState = {
  users: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.users = {
          ...draft.users,
          [action.payload.user_id]: action.payload.settings
        };
      });
    case TOGGLE_USER_NOTIFICATION_SETTINGS_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.users = {
          ...draft.users,
          [action.payload.user_id]: action.payload.settings
        };
      });
    default:
      return state;
  }
};

export function fetchNotificationSettings(id) {
  return dispatch => {
    dispatch({ type: FETCH_USER_NOTIFICATION_SETTINGS_REQUEST_ACTION });
    const UsersService = new UsersApiService();
    return UsersService.getNotificationSettings(id)
      .then(({ data: settings }) => {
        dispatch({
          type: FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS_ACTION,
          payload: { user_id: id, settings }
        });
        return settings;
      })
      .catch(error => {
        dispatch({ type: FETCH_USER_NOTIFICATION_SETTINGS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function toggleNotificationSetting(id, name) {
  return dispatch => {
    dispatch({ type: TOGGLE_USER_NOTIFICATION_SETTINGS_REQUEST_ACTION });
    const UsersService = new UsersApiService();
    return UsersService.toggleNotificationSetting(id, name)
      .then(({ message, settings }) => {
        dispatch(enqueueNotification('success', message));
        dispatch({
          type: TOGGLE_USER_NOTIFICATION_SETTINGS_SUCCESS_ACTION,
          payload: { user_id: id, settings }
        });
        return true;
      })
      .catch(error => {
        dispatch({ type: TOGGLE_USER_NOTIFICATION_SETTINGS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
