import { map } from 'lodash';
import moment from 'moment';
import { enqueueNotification } from '../reducers/notifications';

// eslint-disable-next-line import/prefer-default-export
export const formatProcesses = processes => {
  return map(processes, item => {
    const { started_at: startedAt, finished_at: finishedAt } = item;

    const timeStart =
      startedAt && moment(startedAt).isValid()
        ? moment
            .utc(startedAt)
            .local()
            .format('YYYY-MM-DD HH:mm:ss')
        : null;
    const timeEnd =
      finishedAt && moment(finishedAt).isValid()
        ? moment
            .utc(finishedAt)
            .local()
            .format('YYYY-MM-DD HH:mm:ss')
        : null;

    return {
      ...item,
      started_at: timeStart,
      finished_at: timeEnd
    };
  });
};

export const formatProcessRequest = process => {
  let { started_at: startedAt, finished_at: finishedAt } = process;
  const { id, user_id: userId } = process;

  if (startedAt && moment(startedAt).isValid()) {
    startedAt = moment(startedAt)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
  }

  if (finishedAt && moment(finishedAt).isValid()) {
    finishedAt = moment(finishedAt)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
  }

  return {
    id,
    user_id: userId,
    started_at: startedAt,
    finished_at: finishedAt
  };
};

export const formatProcessesRequest = processes => {
  return map(processes, formatProcessRequest);
};

export const enqueueUnfinishedProcessNotification = error => {
  if (
    error.responseData &&
    error.responseData.patient &&
    error.message === 'User has another pending task.'
  ) {
    return enqueueNotification(
      'error',
      'You have an unfinished task.',
      {},
      {
        link: `/patient-workflow/${error.responseData.patient.id}`,
        text: 'GO TO PATIENT WORKFLOW',
        target: '_blank'
      }
    );
  }

  return enqueueNotification('error', error.message);
};

export const formatProductionTime = seconds => {
  let minutes = seconds / 60;

  if (minutes < 1 && minutes > 0) {
    minutes = 1;
  }

  minutes = Math.ceil(minutes);

  if (!minutes) {
    return '';
  }

  let hours = 0;
  if (minutes > 60) {
    hours = minutes / 60;
  }

  if (hours) {
    minutes = (hours - parseInt(hours, 10)) * 60;
  }

  const time = [];

  hours = Math.floor(hours);
  minutes = Math.floor(minutes);

  if (hours) {
    time.push(`${hours}h`);
  }

  if (minutes) {
    time.push(`${minutes}m`);
  }

  return time.join(' ');
};
