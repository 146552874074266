import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import SimpleMenu from '../../../components/SimpleMenu';
import { useRxForm, useRxFormPreference } from '../../../components/RxForm/hooks';
import { assignmentOptions } from '../../../components/RxForm/helpers';

const AssignmentChanger = ({ onSave, value, serviceName }) => {
  const { rxForm, modificationRules } = useRxForm();
  const {
    third_party_partner: { name: thirdPartyPartnerName }
  } = useRxFormPreference();

  const availableOptions =
    modificationRules[serviceName] && modificationRules[serviceName].allowed_values;

  const save = selectedValue => {
    if (selectedValue === value) {
      return;
    }
    onSave(selectedValue);
  };

  if (
    rxForm[serviceName] !== value ||
    !availableOptions ||
    (availableOptions.length === 1 && availableOptions[0] === value)
  ) {
    const labels = assignmentOptions({
      assignments: [value],
      thirdPartyName: thirdPartyPartnerName
    });
    return `Assigned to: ${labels[0].label}`;
  }

  const options = assignmentOptions({ assignments: availableOptions, thirdPartyPartnerName });
  const selected = options.find(el => el.value === value);

  return (
    <>
      Assigned to: {selected.label}
      <Box mx={2} component="span">
        <SimpleMenu
          buttonTitle="Change"
          onOptionSelected={({ value: selectedValue }) => save(selectedValue)}
          isLoading={false}
          options={options}
        />
      </Box>
    </>
  );
};

AssignmentChanger.propTypes = {
  onSave: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  serviceName: PropTypes.string.isRequired
};

export default AssignmentChanger;
