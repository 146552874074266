import React, { useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Autocomplete } from '@material-ui/lab';
import Chip from '@material-ui/core/Chip';
import { Divider, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import SubSectionWrapper, { SubSectionActionWrapper } from './SubSectionWrapper';
import LoadingButton from '../../../components/LoadingButton';
import OrganizationsApiService from '../../../services/api/organizations';
import { enqueueNotification } from '../../../reducers/notifications';
import LoadingIndicator from '../../../components/LoadingIndicator';

const NOTIFICATIONS = {
  low_bracket_inventory: {
    label: 'Low Brackets Inventory',
    helper:
      "This notification will be sent to the account owner(s) when there's a bracket with low inventory."
  },
  initial_case_setup_request: {
    label: 'Initial Case Setup Request',
    helper:
      'This notification will be sent to the owner and manager when an initial case setup is assigned to the 3PP.'
  },
  initial_case_setup_completed: {
    label: 'Initial Case Setup Completed',
    helper: 'This notification will be sent to the doctor who initiated the request.'
  },
  print_tray_request: {
    label: 'Print Tray Request',
    helper:
      'This notification will be sent to the owner and manager when a print tray request is assigned to the 3PP.'
  },
  flawed_tray_correction_request: {
    label: 'Flawed Tray Correction Request',
    helper:
      'Important Note! This is only intended for future use. No email will be sent from this notification for the time being.'
  },
  print_tray_completed: {
    label: 'Print Tray Completed',
    helper: 'This notification will be sent to the doctor who requested for printing tray.'
  },
  flawed_tray_corrected: {
    label: 'Flawed Tray Corrected',
    helper: 'This notification will be sent to the doctor who requested for flawed tray correction.'
  },
  monthly_invoice: {
    label: 'Monthly Invoice',
    helper:
      "This notification will be sent to the organization's owners when an invoice becomes available."
  },
  payment_failed: {
    label: 'Payment Failed',
    helper: "This notification will be sent to the organization's owners when a payment is failed."
  },
  pending_case_approval_reminder: {
    label: 'Pending Case Approval Reminder',
    helper:
      "This notification will be sent weekly to the organization's owners when there are pending approval cases."
  },
  case_on_hold: {
    label: 'Putting Case On Hold',
    helper: 'This notification will be sent to the listed emails when a case is put on hold.'
  }
};

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold'
  },
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  content: {
    padding: theme.spacing(2),
    borderBottom: '1px solid #e0e0e0',
    marginBottom: theme.spacing(2)
  },
  configItem: {
    alignItems: 'center'
  }
}));

const NotificationSection = ({ title, helper, data, onChange, metadata }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item sm={12}>
        <Grid container>
          <Grid item sm={12}>
            <div className={classes.header}>
              <div>
                <Typography variant="subtitle1" className={classes.title}>
                  {title}
                </Typography>
                <Typography variant="caption" display="block">
                  {helper}
                </Typography>
              </div>
              <div>
                <Switch
                  checked={data.enabled}
                  onChange={event => {
                    onChange({ ...data, enabled: event.target.checked });
                  }}
                />
              </div>
            </div>
          </Grid>
          {data.enabled && (
            <Grid item sm={12} className={classes.content}>
              <Grid container spacing={2} className={classes.configItem}>
                <Grid item sm={3} className={classes.configItemHeader}>
                  <Typography variant="body1">Additional Emails</Typography>
                </Grid>
                <Grid item sm={9}>
                  <Autocomplete
                    disabled={!data.enabled}
                    multiple
                    freeSolo
                    options={(metadata && metadata.email_suggestions) || []}
                    value={data.additional_emails}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          disabled
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    onChange={(event, value) => {
                      onChange({
                        ...data,
                        additional_emails: value,
                        is_disable_default_recipients:
                          value.length === 0 ? false : data.is_disable_default_recipients
                      });
                    }}
                    fullWidth
                    renderInput={params => (
                      <TextField
                        size="small"
                        variant="outlined"
                        multiline
                        {...params}
                        onKeyDownCapture={e => {
                          if (e.key === 'Enter') {
                            const currentValue = e.target.value;
                            if (
                              currentValue.match(
                                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                              ) === null
                            ) {
                              e.stopPropagation();
                              e.preventDefault();
                            }
                          }
                        }}
                        placeholder="Add Email"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.configItem}>
                <Grid item sm={3} className={classes.configItemHeader}>
                  <Typography variant="body1">Disable Default Email Recipients</Typography>
                </Grid>
                <Grid item sm={9}>
                  <Switch
                    checked={data.is_disable_default_recipients || false}
                    disabled={data.additional_emails.length === 0}
                    onChange={event => {
                      onChange({ ...data, is_disable_default_recipients: event.target.checked });
                    }}
                  />
                </Grid>
              </Grid>

              {data.phone_numbers && (
                <Grid container spacing={2} className={classes.configItem}>
                  <Grid item sm={3} className={classes.configItemHeader}>
                    <Typography variant="body1">Phone Numbers</Typography>
                  </Grid>
                  <Grid item sm={9}>
                    <Autocomplete
                      disabled={!data.enabled}
                      multiple
                      freeSolo
                      options={[]}
                      value={data.phone_numbers}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            disabled
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      onChange={(event, value) => {
                        onChange({ ...data, phone_numbers: value });
                      }}
                      fullWidth
                      renderInput={params => (
                        <TextField
                          size="small"
                          variant="outlined"
                          multiline
                          {...params}
                          onKeyDownCapture={e => {
                            if (e.key === 'Enter') {
                              const currentValue = e.target.value;
                              if (
                                currentValue.match(
                                  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
                                ) === null
                              ) {
                                e.stopPropagation();
                                e.preventDefault();
                              }
                            }
                          }}
                          placeholder="Add Phone Number"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Divider />
    </Grid>
  );
};

NotificationSection.propTypes = {
  title: PropTypes.string.isRequired,
  helper: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    additional_emails: PropTypes.arrayOf(PropTypes.string).isRequired,
    phone_numbers: PropTypes.arrayOf(PropTypes.string).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    is_disable_default_recipients: PropTypes.any
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  metadata: PropTypes.shape({
    email_suggestions: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
};

const Notifications = ({ data, onChange, onSave, metadata }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        {Object.keys(data).map(name => {
          const { label, helper } = NOTIFICATIONS[name];
          return (
            <NotificationSection
              metadata={metadata}
              key={name}
              title={label}
              helper={helper}
              data={data[name]}
              onChange={newData => {
                onChange({ ...data, [name]: newData });
              }}
            />
          );
        })}
      </Grid>
      <Grid item xs={12}>
        <SubSectionActionWrapper>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={false}
            onClick={onSave}
          >
            Save
          </LoadingButton>
        </SubSectionActionWrapper>
      </Grid>
    </Grid>
  );
};

Notifications.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  metadata: PropTypes.object.isRequired
};

const Wrapper = ({ organizationId }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const mounted = useRef();

  useEffect(() => {
    mounted.current = true;
    if (data === null) {
      const OrganizationsService = new OrganizationsApiService();
      OrganizationsService.getNotificationSettings(organizationId).then(response => {
        if (!mounted.current) {
          return;
        }
        setData(response.data);
        setMetadata(response.metadata);
      });
    }
    return () => {
      mounted.current = false;
    };
  }, [organizationId, data]);

  const handleChange = newData => {
    setData(newData);
  };

  const onSave = () => {
    const OrganizationsService = new OrganizationsApiService();
    OrganizationsService.updateNotificationSettings(organizationId, data)
      .then(() => {
        dispatch(enqueueNotification('success', 'Notification settings successfully updated.'));
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
        throw new Error(error.message);
      });
  };

  if (data === null || metadata === null) {
    return <LoadingIndicator />;
  }

  return (
    <Notifications data={data || {}} onChange={handleChange} metadata={metadata} onSave={onSave} />
  );
};

Wrapper.propTypes = {
  organizationId: PropTypes.number.isRequired
};

export default props => {
  return (
    <SubSectionWrapper title="Email and Text Notifications">
      <Wrapper {...props} />
    </SubSectionWrapper>
  );
};
