import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Field, Form, Formik } from 'formik';
import { Backdrop, Divider, TextField as MuiTextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Autocomplete } from '@material-ui/lab';
import LoadingIndicator from '../../../../../components/LoadingIndicator';
import { enqueueNotification } from '../../../../../reducers/notifications';
import BracketLibrariesApiService from '../../../../../services/api/bracketLibraries';
import Teeth from './Teeth';

const useStyles = makeStyles(() => ({
  formContainer: {
    position: 'relative',
    overflowY: 'hidden'
  },
  backdrop: {
    position: 'absolute',
    zIndex: '9999'
  },
  dialogTitle: {
    textAlign: 'center'
  }
}));

const InventoryDialog = ({ open, setOpen, organization, bracket, readOnly }) => {
  const [selectedBracket, setSelectedBracket] = useState(bracket || null);
  const [inventory, setInventory] = useState({});
  const [fetching, setFetching] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [updateCounter, setUpdateCounter] = useState(0);

  const api = new BracketLibrariesApiService();

  const { items = [] } = useSelector(state => {
    return state.bracketLibraries.organizations[organization.id] || {};
  });

  const formatInitialData = data => {
    let stocks = [];

    if (!isEmpty(data.stocks)) {
      stocks = Object.keys(data.stocks).map(key => {
        const stock = data.stocks[key];

        return {
          increment: 0,
          ...stock
        };
      });
    }

    return {
      time: Date.now(),
      ...data,
      stocks
    };
  };

  const formatFormData = values => {
    const { time, ...filtered } = values;

    return {
      ...filtered
    };
  };

  const fetchBracketInventory = useCallback(() => {
    if (fetching || !organization || !selectedBracket) {
      return;
    }

    setFetching(true);
    api
      .getBracketInventory(organization.id, selectedBracket.id)
      .then(({ data }) => {
        setInventory(formatInitialData(data));
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      })
      .finally(() => {
        setFetching(false);
      });
  }, [api, dispatch, fetching, organization, selectedBracket]);

  useEffect(() => {
    if (isEmpty(inventory) && !fetching && selectedBracket && organization) {
      fetchBracketInventory();
    }
  }, [inventory, fetchBracketInventory, fetching, selectedBracket, organization]);

  const handleBracketChanged = (event, value, isDirty) => {
    if (value === null) {
      setSelectedBracket(null);
      setInventory({});
      return;
    }

    const index = items.findIndex(item => item.id === value.id);

    if (index === -1) {
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('There are unsaved changes. Do you want to proceed?')) {
      return;
    }

    const item = items[index];
    setSelectedBracket(item);
    setInventory({});
  };

  const handleSubmit = (values, resetForm, setSubmitting) => {
    api
      .updateBracketInventory(organization.id, selectedBracket.id, formatFormData(values))
      .then(({ data }) => {
        setInventory(formatInitialData(data));
        setUpdateCounter(prev => prev + 1);
        dispatch(
          enqueueNotification('success', 'The bracket inventory has been successfully updated.')
        );
        resetForm();
      })
      .catch(error => {
        setSubmitting(false);
        dispatch(enqueueNotification('error', error.message));
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        stocks: inventory.stocks || [],
        low_stock_threshold: inventory.low_stock_threshold || 0,
        time: inventory.time || Date.now()
      }}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        handleSubmit(values, resetForm, setSubmitting);
      }}
    >
      {({ values, isSubmitting, setFieldValue, setFieldTouched, dirty, resetForm }) => (
        <Dialog
          fullWidth
          open={open}
          maxWidth={false}
          onClose={() => {}}
          aria-labelledby="form-dialog-title"
        >
          <Form>
            <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
              Bracket Inventory
            </DialogTitle>
            <DialogContent className={classes.formContainer}>
              <Backdrop className={classes.backdrop} open={fetching || isSubmitting} invisible>
                <LoadingIndicator size={60} />
              </Backdrop>
              <Grid container spacing={3}>
                <Grid
                  item
                  container
                  spacing={2}
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={8}>
                    <Autocomplete
                      id="bracket-library-id"
                      style={{ width: '100%' }}
                      value={selectedBracket}
                      options={items}
                      autoHighlight
                      getOptionLabel={option => option.label}
                      renderInput={params => (
                        <MuiTextField {...params} label="Name of Bracket Set" variant="outlined" />
                      )}
                      onChange={(event, value) =>
                        handleBracketChanged(event, value, dirty, resetForm)
                      }
                      disabled={isSubmitting || fetching}
                    />
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={4}
                    spacing={2}
                  >
                    <Grid item>
                      <Field name="low_stock_threshold">
                        {({ field }) => (
                          <MuiTextField
                            variant="outlined"
                            id="low_stock_threshold"
                            label="Low Stock Threshold"
                            value={field.value}
                            onInput={e => {
                              let { value } = e.target;

                              if (value === '') {
                                value = 0;
                              }

                              const regex = /^[0-9]*$/;
                              if (!regex.test(value)) {
                                return;
                              }

                              field.onChange(e);
                            }}
                            disabled={
                              isSubmitting || fetching || selectedBracket === null || readOnly
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                  <Teeth
                    key={`teeth_${values.time}`}
                    stocks={values.stocks}
                    onChange={stocks => {
                      setFieldValue('stocks', stocks);
                      setFieldTouched('stocks', true, false);
                    }}
                    disabled={isSubmitting || fetching || selectedBracket === null}
                    readOnly={readOnly}
                    lowStockThreshold={+values.low_stock_threshold || 0}
                  />
                </Grid>
                <Divider />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                disabled={isSubmitting}
                onClick={e => {
                  e.preventDefault();

                  if (dirty) {
                    resetForm();
                    setInventory(prev => {
                      return {
                        ...prev,
                        time: Date.now()
                      };
                    });
                  } else {
                    setOpen(updateCounter);
                  }
                }}
              >
                {dirty ? 'Discard' : 'Close'}
              </Button>
              {!readOnly && (
                <Button
                  type="submit"
                  color="primary"
                  disabled={!dirty || isSubmitting || selectedBracket === null}
                >
                  {isSubmitting ? 'Saving' : 'Save'}
                </Button>
              )}
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

InventoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  bracket: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

InventoryDialog.defaultProps = {
  readOnly: false
};

export default InventoryDialog;
