import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import StatisticsCard from './StatisticsCard';
import LoadingSnackbar from '../../../components/LoadingSnackbar';
import Board from './Board';
import {
  formatInitialSetupData,
  formatSubmissionData,
  includeDescriptionOfTotal
} from '../../../helpers/dashboardDataFormatter';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: 'auto',
    margin: theme.spacing(-0.5)
  },
  card: {
    width: '100%',
    padding: '16px 16px 8px',
    margin: theme.spacing(0.5),
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText
  }
}));

function Content({ data, isLoading }) {
  const classes = useStyles();

  let submissions;
  let completedCases;
  let shipped;
  let initialSetups;
  let printRequests;
  let insertBrackets;
  let flawedTrays;
  if (data.filtered_statistics) {
    submissions = formatSubmissionData(data.filtered_statistics.submissions);
    completedCases = includeDescriptionOfTotal(
      data.filtered_statistics.completed_cases,
      data.filtered_statistics.submissions.total,
      'of All Submissions'
    );
    shipped = data.filtered_statistics.shipped;
    initialSetups = formatInitialSetupData(
      data.filtered_statistics.initial_setups,
      data.filtered_statistics.submissions
    );
    printRequests = includeDescriptionOfTotal(
      data.filtered_statistics.print_requests,
      data.filtered_statistics.initial_setups.total,
      'of Initial Setups'
    );
    insertBrackets = includeDescriptionOfTotal(
      data.filtered_statistics.insert_brackets,
      data.filtered_statistics.print_requests.total,
      'of Print Requests'
    );
    flawedTrays = includeDescriptionOfTotal(
      data.filtered_statistics.flawed_trays,
      data.filtered_statistics.submissions.total,
      'of All Submissions'
    );
  }

  return (
    <>
      <LoadingSnackbar show={isLoading} />
      <Grid container>
        <Grid container justifyContent="flex-start" className={classes.wrapper}>
          {submissions && (
            <StatisticsCard value={submissions} title={submissions.title} icon="submissions" />
          )}
          {completedCases && (
            <StatisticsCard
              value={completedCases}
              title={completedCases.title}
              icon="completed_cases"
            />
          )}
          {submissions && <StatisticsCard value={shipped} title={shipped.title} icon="shipped" />}
          {submissions && (
            <StatisticsCard
              value={initialSetups}
              title={initialSetups.title}
              icon="initial_setups"
            />
          )}
          {submissions && (
            <StatisticsCard
              value={printRequests}
              title={printRequests.title}
              icon="print_requests"
            />
          )}
          {submissions && (
            <StatisticsCard
              value={insertBrackets}
              title={insertBrackets.title}
              icon="insert_brackets"
            />
          )}
          <Grid container item xs={12} md={8}>
            <Grid container item xs={12} direction="column" className={classes.card}>
              <Board statistics={data.statistics} />
            </Grid>
          </Grid>
          {flawedTrays && (
            <StatisticsCard value={flawedTrays} title={flawedTrays.title} icon="flawed_trays" />
          )}
        </Grid>
      </Grid>
    </>
  );
}

Content.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool
};

Content.defaultProps = {
  isLoading: false
};

export default Content;
