import React from 'react';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const RushCase = ({ data: { patient_id: patientId, patient_name: patientName } }) => {
  const history = useHistory();
  const handleLinkClick = e => {
    e.preventDefault();
    history.push(`/patient-workflow/${patientId}`);
  };

  return (
    <Link href="src/components/AlertContainer#" onClick={handleLinkClick}>
      Patient: {patientName}
    </Link>
  );
};

RushCase.propTypes = {
  data: PropTypes.shape({
    patient_id: PropTypes.number.isRequired,
    patient_name: PropTypes.string.isRequired
  }).isRequired
};

export default RushCase;
