import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import ArchWiresApiService from '../services/api/archWires';

const FETCHING_ARCH_WIRES_FOR_USER = 'FETCHING_ARCH_WIRES_FOR_USER';
const FETCHED_ARCH_WIRES_FOR_USER = 'FETCHED_ARCH_WIRES_FOR_USER';

const FETCHING_BUILT_IN_ARCH_WIRES = 'FETCHING_BUILT_IN_ARCH_WIRES';
const FETCHED_BUILT_IN_ARCH_WIRES = 'FETCHED_BUILT_IN_ARCH_WIRES';

export const FETCHING_ORGANIZATION_ARCH_WIRES = 'FETCHING_ORGANIZATION_ARCH_WIRES';
export const FETCHED_ORGANIZATION_ARCH_WIRES = 'FETCHED_ORGANIZATION_ARCH_WIRES';
export const FETCHING_ORGANIZATION_ARCH_WIRES_ASSIGNABLE_MEMBERS =
  'FETCHING_ORGANIZATION_ARCH_WIRES_ASSIGNABLE_MEMBERS';
export const FETCHED_ORGANIZATION_ARCH_WIRES_ASSIGNABLE_MEMBERS =
  'FETCHED_ORGANIZATION_ARCH_WIRES_ASSIGNABLE_MEMBERS';
export const UPLOADING_ORGANIZATION_ARCH_WIRE = 'UPLOADING_ORGANIZATION_ARCH_WIRE';
export const UPLOADED_ORGANIZATION_ARCH_WIRE = 'UPLOADED_ORGANIZATION_ARCH_WIRE';
export const UPDATING_ORGANIZATION_ARCH_WIRE = 'UPDATING_ORGANIZATION_ARCH_WIRE';
export const UPDATED_ORGANIZATION_ARCH_WIRE = 'UPDATED_ORGANIZATION_ARCH_WIRE';
export const REMOVING_ORGANIZATION_ARCH_WIRE = 'REMOVING_ORGANIZATION_ARCH_WIRE';
export const REMOVED_ORGANIZATION_ARCH_WIRE = 'REMOVED_ORGANIZATION_ARCH_WIRE';

const initialState = {
  builtIn: {
    fetching: false,
    items: null
  },
  user: {
    fetching: false,
    items: null,
    organizationId: null,
    userId: null
  },
  organizations: {}
};

const sortByFileName = items => {
  items.sort((item1, item2) => (item1.file_name < item2.file_name ? -1 : 1));
};

export default (state = initialState, { type, payload = null }) => {
  switch (type) {
    case FETCHING_ARCH_WIRES_FOR_USER:
      return produce(state, draft => {
        draft.user = {
          items: null,
          organizationId: payload.organization_id,
          userId: payload.user_id,
          fetching: true
        };
      });
    case FETCHED_ARCH_WIRES_FOR_USER:
      return produce(state, draft => {
        draft.user = {
          fetching: false,
          organizationId: payload.organization_id,
          userId: payload.user_id,
          items: payload.items
        };
      });
    case FETCHING_BUILT_IN_ARCH_WIRES:
      return produce(state, draft => {
        draft.builtIn = {
          items: null,
          fetching: true
        };
      });
    case FETCHED_BUILT_IN_ARCH_WIRES:
      return produce(state, draft => {
        draft.builtIn = {
          fetching: false,
          items: payload.items
        };
      });
    case FETCHING_ORGANIZATION_ARCH_WIRES:
      return produce(state, draft => {
        if (!draft.organizations[payload.organization_id]) {
          draft.organizations[payload.organization_id] = {
            fetched: false,
            items: [],
            assignableMembers: [],
            uploading: [],
            fetching: false,
            removing: [],
            updating: false,
            fetchedAssignableMembers: false,
            fetchingAssignableMembers: false
          };
        }
        draft.organizations[payload.organization_id].fetching = true;
      });
    case FETCHING_ORGANIZATION_ARCH_WIRES_ASSIGNABLE_MEMBERS:
      return produce(state, draft => {
        if (!draft.organizations[payload.organization_id]) {
          draft.organizations[payload.organization_id] = {
            fetched: false,
            items: [],
            assignableMembers: [],
            uploading: [],
            fetching: false,
            removing: [],
            updating: false,
            fetchedAssignableMembers: false,
            fetchingAssignableMembers: false
          };
        }
        draft.organizations[payload.organization_id].fetchingAssignableMembers = true;
      });

    case FETCHED_ORGANIZATION_ARCH_WIRES:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].fetching = false;
        draft.organizations[payload.organization_id].fetched = true;
        draft.organizations[payload.organization_id].items = payload.items;
        sortByFileName(draft.organizations[payload.organization_id].items);
      });

    case FETCHED_ORGANIZATION_ARCH_WIRES_ASSIGNABLE_MEMBERS:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].fetchingAssignableMembers = false;
        draft.organizations[payload.organization_id].fetchedAssignableMembers = true;
        draft.organizations[payload.organization_id].assignableMembers = payload.items;
      });

    case UPLOADING_ORGANIZATION_ARCH_WIRE:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].uploading.push(payload.file);
      });

    case UPLOADED_ORGANIZATION_ARCH_WIRE:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].items.push(payload.item);
        sortByFileName(draft.organizations[payload.organization_id].items);
        const { uploading } = state.organizations[payload.organization_id];
        draft.organizations[payload.organization_id].uploading = uploading.filter(
          item => item !== payload.file
        );
      });

    case UPDATING_ORGANIZATION_ARCH_WIRE:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].updating = true;
      });

    case UPDATED_ORGANIZATION_ARCH_WIRE:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].updating = false;
        draft.organizations[payload.organization_id].items = draft.organizations[
          payload.organization_id
        ].items.map(item => {
          if (item.id === payload.item.id) {
            return payload.item;
          }
          return item;
        });
      });

    case REMOVING_ORGANIZATION_ARCH_WIRE:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].removing.push(payload.item_id);
      });

    case REMOVED_ORGANIZATION_ARCH_WIRE:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].items = state.organizations[
          payload.organization_id
        ].items.filter(item => item.id !== payload.item_id);
        draft.organizations[payload.organization_id].removing = state.organizations[
          payload.organization_id
        ].removing.filter(item => item.id !== payload.item_id);
      });

    default:
      return state;
  }
};

export function fetchArchWiresForUser(organizationId, userId) {
  return dispatch => {
    dispatch({
      type: FETCHING_ARCH_WIRES_FOR_USER,
      payload: { organization_id: organizationId, user_id: userId }
    });
    const api = new ArchWiresApiService();
    return api.getUserArchWires(organizationId, userId).then(({ data }) => {
      dispatch({
        type: FETCHED_ARCH_WIRES_FOR_USER,
        payload: {
          organization_id: organizationId,
          user_id: userId,
          items: data
        }
      });
    });
  };
}

export function fetchBuiltInArchWires() {
  return (dispatch, getState) => {
    const state = getState();
    if (state.archWires.builtIn.fetching) {
      return Promise.resolve();
    }
    dispatch({
      type: FETCHING_BUILT_IN_ARCH_WIRES
    });
    const api = new ArchWiresApiService();
    return api.getBuiltInArchWires().then(({ data }) => {
      dispatch({
        type: FETCHED_BUILT_IN_ARCH_WIRES,
        payload: {
          items: data
        }
      });
    });
  };
}

export function fetchOrganizationArchWires(organizationId) {
  return dispatch => {
    dispatch({
      type: FETCHING_ORGANIZATION_ARCH_WIRES,
      payload: { organization_id: organizationId }
    });
    const api = new ArchWiresApiService();
    return api
      .getOrganizationArchWires(organizationId)
      .then(({ data }) => {
        dispatch({
          type: FETCHED_ORGANIZATION_ARCH_WIRES,
          payload: {
            organization_id: organizationId,
            items: data
          }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchOrganizationArchWiresAssignableMembers(organizationId) {
  return (dispatch, getState) => {
    const state = getState();
    if (
      state.archWires.organizations[organizationId] &&
      state.archWires.organizations[organizationId].fetchingAssignableMembers
    ) {
      return;
    }
    dispatch({
      type: FETCHING_ORGANIZATION_ARCH_WIRES_ASSIGNABLE_MEMBERS,
      payload: { organization_id: organizationId }
    });
    const api = new ArchWiresApiService();
    return api
      .getOrganizationMembersAssignableForArchWires(organizationId)
      .then(data => {
        return dispatch({
          type: FETCHED_ORGANIZATION_ARCH_WIRES_ASSIGNABLE_MEMBERS,
          payload: {
            organization_id: organizationId,
            items: data
          }
        });
      })
      .catch(error => {
        return dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function deleteOrganizationArchWire(organizationId, id) {
  return dispatch => {
    dispatch({
      type: REMOVING_ORGANIZATION_ARCH_WIRE,
      payload: { organization_id: organizationId, item_id: id }
    });
    const api = new ArchWiresApiService();
    return api
      .deleteOrganizationArchWire(organizationId, id)
      .then(() => {
        dispatch({
          type: REMOVED_ORGANIZATION_ARCH_WIRE,
          payload: {
            organization_id: organizationId,
            item_id: id
          }
        });
        dispatch(
          enqueueNotification('success', `Arch-wire library has been successfully removed.`)
        );
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function uploadOrganizationArchWire(organizationId, file) {
  return dispatch => {
    dispatch({
      type: UPLOADING_ORGANIZATION_ARCH_WIRE,
      payload: { organization_id: organizationId, file }
    });
    const api = new ArchWiresApiService();
    return api
      .uploadOrganizationArchWire(organizationId, file)
      .then(({ data }) => {
        dispatch({
          type: UPLOADED_ORGANIZATION_ARCH_WIRE,
          payload: {
            organization_id: organizationId,
            item: data,
            file
          }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateOrganizationArchWire(organizationId, id, userIds) {
  return dispatch => {
    dispatch({
      type: UPDATING_ORGANIZATION_ARCH_WIRE,
      payload: { organization_id: organizationId }
    });
    const api = new ArchWiresApiService();
    return api
      .updateOrganizationArchWire(organizationId, id, { userIds })
      .then(({ data }) => {
        dispatch({
          type: UPDATED_ORGANIZATION_ARCH_WIRE,
          payload: {
            organization_id: organizationId,
            item: data
          }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
