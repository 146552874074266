import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { List } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { includes } from 'lodash';
import { FETCH_CUSTOM_PERMISSIONS_PREFIX, fetchCustomPermissions } from '../../../reducers/auth';
import createLoadingSelector from '../../../selectors/loading';
import LoadingIndicator from '../../../components/LoadingIndicator';
import PermissionListItem from './PermissionListItem';

const CustomUserPermissionManager = ({ user }) => {
  const dispatch = useDispatch();
  const customPermissions = useSelector(state => state.auth.customPermissions);
  const isLoading = useSelector(state =>
    createLoadingSelector([FETCH_CUSTOM_PERMISSIONS_PREFIX])(state)
  );

  useEffect(() => {
    dispatch(fetchCustomPermissions(user.id));
  }, [dispatch, user]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!customPermissions) {
    return null;
  }

  return (
    <List>
      {customPermissions.map(permission => (
        <PermissionListItem
          userId={user.id}
          key={permission.permission}
          permission={permission}
          isEnabled={includes(user.all_permissions, permission.permission)}
        />
      ))}
      {!customPermissions.length && <p>No custom permissions found.</p>}
    </List>
  );
};

CustomUserPermissionManager.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired
};

CustomUserPermissionManager.defaultProps = {};

export default CustomUserPermissionManager;
