import { produce } from 'immer';
import React from 'react';
import Button from '@material-ui/core/Button';
import { AuthApiService } from '../services';
import { enqueueNotification } from './notifications';
import LicensesApiService from '../services/api/licenses';
import PaymentsApiService from '../services/api/payments';
import NotificationApiService from '../services/api/notifications';
import { isManagementProfile } from '../helpers';
import UsersApiService from '../services/api/users';
import OrganizationsApiService from '../services/api/organizations';
import { NOTIFICATION_ALERTS } from '../components/WSNotificationHandler/constants';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const LOGIN_PREFIX = 'LOGIN';
export const LOGIN_REQUEST_ACTION = `${LOGIN_PREFIX}_REQUEST_ACTION`;
export const LOGIN_SUCCESS_ACTION = `${LOGIN_PREFIX}_SUCCESS_ACTION`;
export const LOGIN_FAILURE_ACTION = `${LOGIN_PREFIX}_FAILURE_ACTION`;

export const SIGN_UP_PREFIX = 'SIGN_UP';
export const SIGN_UP_REQUEST_ACTION = `${SIGN_UP_PREFIX}_REQUEST_ACTION`;
export const SIGN_UP_SUCCESS_ACTION = `${SIGN_UP_PREFIX}_SUCCESS_ACTION`;
export const SIGN_UP_FAILURE_ACTION = `${SIGN_UP_PREFIX}_FAILURE_ACTION`;

export const CREATE_ORGANIZATION_USER_PREFIX = 'CREATE_ORGANIZATION_USER';
export const CREATE_ORGANIZATION_USER_REQUEST_ACTION = `${CREATE_ORGANIZATION_USER_PREFIX}_REQUEST_ACTION`;
export const CREATE_ORGANIZATION_USER_SUCCESS_ACTION = `${CREATE_ORGANIZATION_USER_PREFIX}_SUCCESS_ACTION`;
export const CREATE_ORGANIZATION_USER_FAILURE_ACTION = `${CREATE_ORGANIZATION_USER_PREFIX}_FAILURE_ACTION`;

export const VERIFY_EMAIL_PREFIX = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_REQUEST_ACTION = `${VERIFY_EMAIL_PREFIX}_REQUEST_ACTION`;
export const VERIFY_EMAIL_SUCCESS_ACTION = `${VERIFY_EMAIL_PREFIX}_SUCCESS_ACTION`;
export const VERIFY_EMAIL_FAILURE_ACTION = `${VERIFY_EMAIL_PREFIX}_FAILURE_ACTION`;

export const SEND_RESET_PASSWORD_EMAIL_PREFIX = 'SEND_RESET_PASSWORD_EMAIL';
export const SEND_RESET_PASSWORD_EMAIL_REQUEST_ACTION = `${SEND_RESET_PASSWORD_EMAIL_PREFIX}_REQUEST_ACTION`;
export const SEND_RESET_PASSWORD_EMAIL_SUCCESS_ACTION = `${SEND_RESET_PASSWORD_EMAIL_PREFIX}_SUCCESS_ACTION`;
export const SEND_RESET_PASSWORD_EMAIL_FAILURE_ACTION = `${SEND_RESET_PASSWORD_EMAIL_PREFIX}_FAILURE_ACTION`;

export const RESEND_VERIFICATION_EMAIL_PREFIX = 'RESEND_VERIFICATION_EMAIL';
export const RESEND_VERIFICATION_EMAIL_REQUEST_ACTION = `${RESEND_VERIFICATION_EMAIL_PREFIX}_REQUEST_ACTION`;
export const RESEND_VERIFICATION_EMAIL_SUCCESS_ACTION = `${RESEND_VERIFICATION_EMAIL_PREFIX}_SUCCESS_ACTION`;
export const RESEND_VERIFICATION_EMAIL_FAILURE_ACTION = `${RESEND_VERIFICATION_EMAIL_PREFIX}_FAILURE_ACTION`;

export const RESET_PASSWORD_PREFIX = 'RESET_PASSWORD';
export const RESET_PASSWORD_REQUEST_ACTION = `${RESET_PASSWORD_PREFIX}_REQUEST_ACTION`;
export const RESET_PASSWORD_SUCCESS_ACTION = `${RESET_PASSWORD_PREFIX}_SUCCESS_ACTION`;
export const RESET_PASSWORD_FAILURE_ACTION = `${RESET_PASSWORD_PREFIX}_FAILURE_ACTION`;

export const UPDATE_PROFILE_PREFIX = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_REQUEST_ACTION = `${UPDATE_PROFILE_PREFIX}_REQUEST_ACTION`;
export const UPDATE_PROFILE_SUCCESS_ACTION = `${UPDATE_PROFILE_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_PROFILE_FAILURE_ACTION = `${UPDATE_PROFILE_PREFIX}_FAILURE_ACTION`;

export const DELETE_OWN_DEVICE_PREFIX = 'DELETE_OWN_DEVICE';
export const DELETE_OWN_DEVICE_REQUEST_ACTION = `${DELETE_OWN_DEVICE_PREFIX}_REQUEST_ACTION`;
export const DELETE_OWN_DEVICE_SUCCESS_ACTION = `${DELETE_OWN_DEVICE_PREFIX}_SUCCESS_ACTION`;
export const DELETE_OWN_DEVICE_FAILURE_ACTION = `${DELETE_OWN_DEVICE_PREFIX}_FAILURE_ACTION`;

export const UPDATE_OWN_DEVICE_PREFIX = 'UPDATE_OWN_DEVICE';
export const UPDATE_OWN_DEVICE_REQUEST_ACTION = `${UPDATE_OWN_DEVICE_PREFIX}_REQUEST_ACTION`;
export const UPDATE_OWN_DEVICE_SUCCESS_ACTION = `${UPDATE_OWN_DEVICE_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_OWN_DEVICE_FAILURE_ACTION = `${UPDATE_OWN_DEVICE_PREFIX}_FAILURE_ACTION`;

export const LOGOUT_PREFIX = 'LOGOUT';
export const LOGOUT_REQUEST_ACTION = `${LOGOUT_PREFIX}_REQUEST_ACTION`;
export const LOGOUT_SUCCESS_ACTION = `${LOGOUT_PREFIX}_SUCCESS_ACTION`;
export const LOGOUT_FAILURE_ACTION = `${LOGOUT_PREFIX}_FAILURE_ACTION`;

export const FETCH_CURRENT_USER_PREFIX = 'FETCH_CURRENT_USER';
export const FETCH_CURRENT_USER_REQUEST_ACTION = `${FETCH_CURRENT_USER_PREFIX}_REQUEST_ACTION`;
export const FETCH_CURRENT_USER_SUCCESS_ACTION = `${FETCH_CURRENT_USER_PREFIX}_SUCCESS_ACTION`;
export const FETCH_CURRENT_USER_FAILURE_ACTION = `${FETCH_CURRENT_USER_PREFIX}_FAILURE_ACTION`;

export const CREATE_FATTMERCHANT_CUSTOMER_PREFIX = 'CREATE_FATTMERCHANT_CUSTOMER';
export const CREATE_FATTMERCHANT_CUSTOMER_REQUEST_ACTION = `${CREATE_FATTMERCHANT_CUSTOMER_PREFIX}_REQUEST_ACTION`;
export const CREATE_FATTMERCHANT_CUSTOMER_SUCCESS_ACTION = `${CREATE_FATTMERCHANT_CUSTOMER_PREFIX}_SUCCESS_ACTION`;
export const CREATE_FATTMERCHANT_CUSTOMER_FAILURE_ACTION = `${CREATE_FATTMERCHANT_CUSTOMER_PREFIX}_FAILURE_ACTION`;

export const CREATE_FATTMERCHANT_PAYMENT_METHOD_PREFIX = 'CREATE_FATTMERCHANT_PAYMENT_METHOD';
export const CREATE_FATTMERCHANT_PAYMENT_METHOD_REQUEST_ACTION = `${CREATE_FATTMERCHANT_PAYMENT_METHOD_PREFIX}_REQUEST_ACTION`;
export const CREATE_FATTMERCHANT_PAYMENT_METHOD_SUCCESS_ACTION = `${CREATE_FATTMERCHANT_PAYMENT_METHOD_PREFIX}_SUCCESS_ACTION`;
export const CREATE_FATTMERCHANT_PAYMENT_METHOD_FAILURE_ACTION = `${CREATE_FATTMERCHANT_PAYMENT_METHOD_PREFIX}_FAILURE_ACTION`;

export const DELETE_MY_PAYMENT_METHOD_PREFIX = 'DELETE_MY_PAYMENT_METHOD';
export const DELETE_MY_PAYMENT_METHOD_REQUEST_ACTION = `${DELETE_MY_PAYMENT_METHOD_PREFIX}_REQUEST_ACTION`;
export const DELETE_MY_PAYMENT_METHOD_SUCCESS_ACTION = `${DELETE_MY_PAYMENT_METHOD_PREFIX}_SUCCESS_ACTION`;
export const DELETE_MY_PAYMENT_METHOD_FAILURE_ACTION = `${DELETE_MY_PAYMENT_METHOD_PREFIX}_FAILURE_ACTION`;

export const UPDATE_MY_PAYMENT_METHOD_PREFIX = 'UPDATE_MY_PAYMENT_METHOD';
export const UPDATE_MY_PAYMENT_METHOD_REQUEST_ACTION = `${UPDATE_MY_PAYMENT_METHOD_PREFIX}_REQUEST_ACTION`;
export const UPDATE_MY_PAYMENT_METHOD_SUCCESS_ACTION = `${UPDATE_MY_PAYMENT_METHOD_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_MY_PAYMENT_METHOD_FAILURE_ACTION = `${UPDATE_MY_PAYMENT_METHOD_PREFIX}_FAILURE_ACTION`;

export const CALCULATE_INITIAL_ORDER_PREFIX = 'CALCULATE_INITIAL_ORDER';
export const CALCULATE_INITIAL_ORDER_REQUEST_ACTION = `${CALCULATE_INITIAL_ORDER_PREFIX}_REQUEST_ACTION`;
export const CALCULATE_INITIAL_ORDER_SUCCESS_ACTION = `${CALCULATE_INITIAL_ORDER_PREFIX}_SUCCESS_ACTION`;
export const CALCULATE_INITIAL_ORDER_FAILURE_ACTION = `${CALCULATE_INITIAL_ORDER_PREFIX}_FAILURE_ACTION`;

export const INITIAL_PURCHASE_PREFIX = 'INITIAL_PURCHASE';
export const INITIAL_PURCHASE_REQUEST_ACTION = `${INITIAL_PURCHASE_PREFIX}_REQUEST_ACTION`;
export const INITIAL_PURCHASE_SUCCESS_ACTION = `${INITIAL_PURCHASE_PREFIX}_SUCCESS_ACTION`;
export const INITIAL_PURCHASE_FAILURE_ACTION = `${INITIAL_PURCHASE_PREFIX}_FAILURE_ACTION`;

export const DELETE_ADDITIONAL_EMAIL_PREFIX = 'DELETE_ADDITIONAL_EMAIL';
export const DELETE_ADDITIONAL_EMAIL_REQUEST_ACTION = `${DELETE_ADDITIONAL_EMAIL_PREFIX}_REQUEST_ACTION`;
export const DELETE_ADDITIONAL_EMAIL_SUCCESS_ACTION = `${DELETE_ADDITIONAL_EMAIL_PREFIX}_SUCCESS_ACTION`;
export const DELETE_ADDITIONAL_EMAIL_FAILURE_ACTION = `${DELETE_ADDITIONAL_EMAIL_PREFIX}_FAILURE_ACTION`;

export const CREATE_ADDITIONAL_EMAIL_PREFIX = 'CREATE_ADDITIONAL_EMAIL';
export const CREATE_ADDITIONAL_EMAIL_REQUEST_ACTION = `${CREATE_ADDITIONAL_EMAIL_PREFIX}_REQUEST_ACTION`;
export const CREATE_ADDITIONAL_EMAIL_SUCCESS_ACTION = `${CREATE_ADDITIONAL_EMAIL_PREFIX}_SUCCESS_ACTION`;
export const CREATE_ADDITIONAL_EMAIL_FAILURE_ACTION = `${CREATE_ADDITIONAL_EMAIL_PREFIX}_FAILURE_ACTION`;

export const CONFIRM_INVITATION_PREFIX = 'CONFIRM_INVITATION';
export const CONFIRM_INVITATION_REQUEST_ACTION = `${CONFIRM_INVITATION_PREFIX}_REQUEST_ACTION`;
export const CONFIRM_INVITATION_SUCCESS_ACTION = `${CONFIRM_INVITATION_PREFIX}_SUCCESS_ACTION`;
export const CONFIRM_INVITATION_FAILURE_ACTION = `${CONFIRM_INVITATION_PREFIX}_FAILURE_ACTION`;

export const FETCH_CURRENT_ORGANIZATION_ORDERS_PREFIX = 'FETCH_CURRENT_ORGANIZATION_ORDERS';
export const FETCH_CURRENT_ORGANIZATION_ORDERS_REQUEST_ACTION = `${FETCH_CURRENT_ORGANIZATION_ORDERS_PREFIX}_REQUEST_ACTION`;
export const FETCH_CURRENT_ORGANIZATION_ORDERS_SUCCESS_ACTION = `${FETCH_CURRENT_ORGANIZATION_ORDERS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_CURRENT_ORGANIZATION_ORDERS_FAILURE_ACTION = `${FETCH_CURRENT_ORGANIZATION_ORDERS_PREFIX}_FAILURE_ACTION`;

export const UPDATE_CURRENT_ORGANIZATION_BILLING_ADDRESS_PREFIX =
  'UPDATE_CURRENT_ORGANIZATION_BILLING_ADDRESS';
export const UPDATE_CURRENT_ORGANIZATION_BILLING_ADDRESS_REQUEST_ACTION = `${UPDATE_CURRENT_ORGANIZATION_BILLING_ADDRESS_PREFIX}_REQUEST_ACTION`;
export const UPDATE_CURRENT_ORGANIZATION_BILLING_ADDRESS_SUCCESS_ACTION = `${UPDATE_CURRENT_ORGANIZATION_BILLING_ADDRESS_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_CURRENT_ORGANIZATION_BILLING_ADDRESS_FAILURE_ACTION = `${UPDATE_CURRENT_ORGANIZATION_BILLING_ADDRESS_PREFIX}_FAILURE_ACTION`;

export const FETCH_CUSTOM_PERMISSIONS_PREFIX = 'FETCH_CUSTOM_PERMISSIONS';
export const FETCH_CUSTOM_PERMISSIONS_REQUEST_ACTION = `${FETCH_CUSTOM_PERMISSIONS_PREFIX}_REQUEST_ACTION`;
export const FETCH_CUSTOM_PERMISSIONS_SUCCESS_ACTION = `${FETCH_CUSTOM_PERMISSIONS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_CUSTOM_PERMISSIONS_FAILURE_ACTION = `${FETCH_CUSTOM_PERMISSIONS_PREFIX}_FAILURE_ACTION`;

export const FETCH_ORGANIZATION_USER_PERMISSIONS_PREFIX = 'FETCH_ORGANIZATION_USER_PERMISSIONS';
export const FETCH_ORGANIZATION_USER_PERMISSIONS_REQUEST_ACTION = `${FETCH_ORGANIZATION_USER_PERMISSIONS_PREFIX}_REQUEST_ACTION`;
export const FETCH_ORGANIZATION_USER_PERMISSIONS_SUCCESS_ACTION = `${FETCH_ORGANIZATION_USER_PERMISSIONS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_ORGANIZATION_USER_PERMISSIONS_FAILURE_ACTION = `${FETCH_ORGANIZATION_USER_PERMISSIONS_PREFIX}_FAILURE_ACTION`;

export const ADD_ORGANIZATION_TO_GLOBAL_FILTER = 'ADD_ORGANIZATION_TO_GLOBAL_FILTER';
export const REMOVE_ORGANIZATION_FROM_GLOBAL_FILTER = 'REMOVE_ORGANIZATION_FROM_GLOBAL_FILTER';
export const SET_ORGANIZATIONS_TO_GLOBAL_FILTER = 'SET_ORGANIZATIONS_TO_GLOBAL_FILTER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION';
export const SET_IS_OPEN_ORGANIZATION_TOP_BAR = 'SET_IS_OPEN_ORGANIZATION_TOP_BAR';
export const SET_IS_CURRENT_ORGANIZATION_UPDATING = 'SET_IS_CURRENT_ORGANIZATION_UPDATING';
export const ADD_USER_NOTIFICATION = 'ADD_USER_NOTIFICATION';
export const UPDATE_USER_NOTIFICATION = 'UPDATE_USER_NOTIFICATION';
export const MARK_ALL_USER_NOTIFICATIONS_AS_READ = 'MARK_ALL_USER_NOTIFICATIONS_AS_READ';
export const CLEAR_USER_NOTIFICATIONS = 'CLEAR_USER_NOTIFICATIONS';
export const RESET_AUTH = 'RESET_AUTH';
export const RESET_APP = 'RESET_APP';

export const FETCH_NOTIFICATIONS_PREFIX = 'FETCH_NOTIFICATIONS_PREFIX';
export const FETCH_NOTIFICATIONS_REQUEST_ACTION = `${FETCH_NOTIFICATIONS_PREFIX}_REQUEST_ACTION`;
export const FETCH_NOTIFICATIONS_SUCCESS_ACTION = `${FETCH_NOTIFICATIONS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_NOTIFICATIONS_FAILURE_ACTION = `${FETCH_NOTIFICATIONS_PREFIX}_FAILURE_ACTION`;

export const REMOVE_NOTIFICATION_ALERT_ACTION = 'REMOVE_NOTIFICATION_ALERT_ACTION';

export const MARK_NOTIFICATION_AS_READ_PREFIX = 'MARK_NOTIFICATION_AS_READ_PREFIX';
export const MARK_NOTIFICATION_AS_READ_REQUEST_ACTION = `${MARK_NOTIFICATION_AS_READ_PREFIX}_REQUEST_ACTION`;
export const MARK_NOTIFICATION_AS_READ_SUCCESS_ACTION = `${MARK_NOTIFICATION_AS_READ_PREFIX}_SUCCESS_ACTION`;
export const MARK_NOTIFICATION_AS_READ_FAILURE_ACTION = `${MARK_NOTIFICATION_AS_READ_PREFIX}_FAILURE_ACTION`;

export const UPDATE_LAST_ACTIVITY_TIME_PREFIX = 'UPDATE_LAST_ACTIVITY_TIME_PREFIX';
export const UPDATE_LAST_ACTIVITY_TIME_REQUEST_ACTION = `${UPDATE_LAST_ACTIVITY_TIME_PREFIX}_REQUEST_ACTION`;
export const UPDATE_LAST_ACTIVITY_TIME_SUCCESS_ACTION = `${UPDATE_LAST_ACTIVITY_TIME_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_LAST_ACTIVITY_TIME_FAILURE_ACTION = `${UPDATE_LAST_ACTIVITY_TIME_PREFIX}_FAILURE_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  user: null,
  isAuthenticated: !!localStorage.getItem('access_token'),
  isVerified: false,
  isInvalidToken: false,
  isOrganizationTopBarOpen: false,
  isCurrentOrganizationUpdating: false,
  userNotifications: [],
  userNotificationAlerts: [],
  availablePlans: [],
  customPermissions: [],
  currentOrganization: null,
  isManagement: isManagementProfile(),
  successfulSignup: false,
  management: {
    selectedOrganizations: [],
    organizationsPermissions: {}
  }
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP_SUCCESS_ACTION: {
      return {
        ...state,
        successfulSignup: true
      };
    }
    case LOGIN_REQUEST_ACTION:
      return {
        ...state,
        isAuthenticated: false
      };
    case LOGIN_SUCCESS_ACTION:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        currentOrganization: action.payload.management ? null : action.payload.defaultOrganization,
        isManagement: action.payload.management,
        showPromotion: action.payload.showPromotion
      };
    case VERIFY_EMAIL_SUCCESS_ACTION:
      return {
        ...state,
        isVerified: true,
        isInvalidToken: false
      };
    case VERIFY_EMAIL_FAILURE_ACTION:
      return {
        ...state,
        isVerified: true,
        isInvalidToken: true
      };
    case RESET_PASSWORD_SUCCESS_ACTION:
      return {
        ...state,
        isVerified: true
      };
    case FETCH_CURRENT_USER_SUCCESS_ACTION: {
      let currentOrganization = null;
      const organizationsPermissions = [];
      if (!state.isManagement) {
        [currentOrganization] = action.payload.user.organizations;
        if (action.payload.currentOrganizationId) {
          currentOrganization = action.payload.user.organizations.find(
            item => item.id === action.payload.currentOrganizationId
          );
        }
      } else {
        action.payload.user.organizations.forEach(organization => {
          organizationsPermissions[organization.id] = organization.pivot.all_permissions;
        });
      }

      return {
        ...state,
        user: action.payload.user,
        currentOrganization,
        showPromotion: action.payload.showPromotion,
        management: {
          ...state.management,
          organizationsPermissions
        }
      };
    }
    case UPDATE_PROFILE_SUCCESS_ACTION:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      };
    case DELETE_OWN_DEVICE_SUCCESS_ACTION:
      return produce(state, draft => {
        const licenseIndex = state.currentOrganization.licenses.findIndex(
          license => license.id === action.payload.licenseId
        );
        draft.currentOrganization.licenses[licenseIndex].devices.splice(
          state.currentOrganization.licenses[licenseIndex].devices.findIndex(
            device => device.id === action.payload.id
          ),
          1
        );
        draft.currentOrganization.licenses[licenseIndex].available_seats =
          state.currentOrganization.licenses[licenseIndex].available_seats + 1;
      });
    case UPDATE_OWN_DEVICE_SUCCESS_ACTION:
      return produce(state, draft => {
        const licenseIndex = state.currentOrganization.licenses.findIndex(
          license => license.id === action.payload.licenseId
        );
        const deviceIndex = state.currentOrganization.licenses[licenseIndex].devices.findIndex(
          device => device.id === action.payload.id
        );
        draft.currentOrganization.licenses[licenseIndex].devices[deviceIndex] =
          action.payload.device;
      });
    case FETCH_NOTIFICATIONS_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.userNotificationAlerts = [];
        draft.userNotifications = [];
        action.payload.forEach(notification => {
          const n = {
            id: notification.id,
            read_at: notification.read_at,
            created_at: notification.created_at,
            ...notification.data
          };

          if (NOTIFICATION_ALERTS.includes(n.type)) {
            draft.userNotificationAlerts.push(n);
          } else {
            draft.userNotifications.push(n);
          }
        });
      });
    case ADD_USER_NOTIFICATION:
      return produce(state, draft => {
        const type = action.payload && action.payload.type ? action.payload.type : '';
        if (NOTIFICATION_ALERTS.includes(type)) {
          draft.userNotificationAlerts.push(action.payload);
        } else {
          draft.userNotifications.push(action.payload);
        }
      });
    case UPDATE_USER_NOTIFICATION:
      return produce(state, draft => {
        const type = action.payload && action.payload.type ? action.payload.type : '';
        const key = NOTIFICATION_ALERTS.includes(type)
          ? 'userNotificationAlerts'
          : 'userNotifications';
        const index = state[key].findIndex(item => item.id === action.payload.id);
        draft[key][index] = action.payload;
      });
    case MARK_ALL_USER_NOTIFICATIONS_AS_READ:
      return produce(state, draft => {
        draft.userNotifications.forEach(item => {
          // eslint-disable-next-line no-param-reassign
          item.read_at = true;
        });
      });
    case CLEAR_USER_NOTIFICATIONS:
      return produce(state, draft => {
        draft.userNotifications = [];
      });
    case REMOVE_NOTIFICATION_ALERT_ACTION:
      return produce(state, draft => {
        draft.userNotificationAlerts.splice(
          state.userNotificationAlerts.findIndex(item => item.id === action.payload.id),
          1
        );
      });
    case CREATE_FATTMERCHANT_CUSTOMER_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.currentOrganization.payment_info = action.payload;
      });
    case CREATE_FATTMERCHANT_PAYMENT_METHOD_SUCCESS_ACTION:
      return produce(state, draft => {
        if (action.payload.is_primary) {
          state.currentOrganization.payment_info.payment_methods.forEach((value, key) => {
            draft.currentOrganization.payment_info.payment_methods[key].is_primary = false;
          });
        }
        draft.currentOrganization.payment_info.payment_methods.push(action.payload);
      });
    case DELETE_MY_PAYMENT_METHOD_SUCCESS_ACTION:
      return produce(state, draft => {
        const paymentMethodIndex = state.currentOrganization.payment_info.payment_methods.findIndex(
          paymentMethod => paymentMethod.id === action.payload.id
        );
        draft.currentOrganization.payment_info.payment_methods.splice(paymentMethodIndex, 1);
      });
    case UPDATE_MY_PAYMENT_METHOD_SUCCESS_ACTION:
      return produce(state, draft => {
        const paymentMethodIndex = state.currentOrganization.payment_info.payment_methods.findIndex(
          paymentMethod => paymentMethod.id === action.payload.id
        );
        if (action.payload.is_primary) {
          state.currentOrganization.payment_info.payment_methods.forEach((value, key) => {
            if (key !== paymentMethodIndex) {
              draft.currentOrganization.payment_info.payment_methods[key].is_primary = false;
            }
          });
        }
        draft.currentOrganization.payment_info.payment_methods[paymentMethodIndex] = action.payload;
      });
    case FETCH_CUSTOM_PERMISSIONS_SUCCESS_ACTION:
      return {
        ...state,
        customPermissions: action.payload
      };
    case CALCULATE_INITIAL_ORDER_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.currentOrganization.initial_order = { ...action.payload };
      });
    case INITIAL_PURCHASE_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.currentOrganization.licenses = [action.payload];
      });
    case RESET_AUTH:
      return {
        ...initialState,
        isAuthenticated: false
      };
    case CREATE_ADDITIONAL_EMAIL_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.user.additional_emails.push(action.payload.email);
      });
    case DELETE_ADDITIONAL_EMAIL_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.user.additional_emails.splice(
          state.user.additional_emails.findIndex(item => item.id === action.payload.id),
          1
        );
      });
    case SET_CURRENT_ORGANIZATION:
      return {
        ...state,
        currentOrganization: action.payload
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload
      };
    case ADD_ORGANIZATION_TO_GLOBAL_FILTER:
      return produce(state, draft => {
        draft.management.selectedOrganizations.push(action.payload);
      });
    case REMOVE_ORGANIZATION_FROM_GLOBAL_FILTER:
      return produce(state, draft => {
        const index = draft.management.selectedOrganizations.findIndex(
          organization => organization.id === action.payload.id
        );
        draft.management.selectedOrganizations.splice(index, 1);
      });
    case SET_ORGANIZATIONS_TO_GLOBAL_FILTER:
      return produce(state, draft => {
        draft.management.selectedOrganizations = action.payload;
      });
    case FETCH_CURRENT_ORGANIZATION_ORDERS_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.currentOrganization.orders = action.payload.orders;
      });
    case CONFIRM_INVITATION_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.user.organizations.push(action.payload);
      });
    case SET_IS_OPEN_ORGANIZATION_TOP_BAR:
      return produce(state, draft => {
        draft.isOrganizationTopBarOpen = action.payload;
      });
    case SET_IS_CURRENT_ORGANIZATION_UPDATING:
      return produce(state, draft => {
        draft.isCurrentOrganizationUpdating = action.payload;
      });
    case UPDATE_CURRENT_ORGANIZATION_BILLING_ADDRESS_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.currentOrganization.payment_info.billing_address = action.payload.billingAddress;
      });
    case FETCH_ORGANIZATION_USER_PERMISSIONS_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.management.organizationsPermissions[action.payload.organizationId] =
          action.payload.permissions;
      });
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function resendVerificationEmailAction(email) {
  return dispatch => {
    dispatch({ type: RESEND_VERIFICATION_EMAIL_REQUEST_ACTION });
    const authService = new AuthApiService();
    return authService
      .resendVerificationEmail(email)
      .then(({ message }) => {
        dispatch({ type: RESEND_VERIFICATION_EMAIL_SUCCESS_ACTION });
        dispatch(enqueueNotification('success', message, { autoHideDuration: null }));
      })
      .catch(error => {
        dispatch({ type: RESEND_VERIFICATION_EMAIL_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function loginAction(params, management) {
  return dispatch => {
    dispatch({ type: LOGIN_REQUEST_ACTION });
    const authService = new AuthApiService();
    return authService
      .login(params, management)
      .then(response => {
        if (params.remember_me) {
          localStorage.setItem('access_token', response.access_token);
          localStorage.setItem('is_management', management);
        }
        sessionStorage.setItem('access_token', response.access_token);
        sessionStorage.setItem('is_management', management);
        dispatch({
          type: LOGIN_SUCCESS_ACTION,
          payload: {
            user: response.user,
            management,
            showPromotion: response.metadata.show_promotion,
            defaultOrganization: response.metadata.default_organization
          }
        });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: LOGIN_FAILURE_ACTION });
        let { message } = error;
        if (error.code === 2200) {
          const email = error.requestParams && JSON.parse(error.requestParams).email;
          const resendAction = resendVerificationEmailAction(email);
          message = (
            <div>
              {message}{' '}
              <Button color="primary" variant="contained" onClick={() => dispatch(resendAction)}>
                Resend Email
              </Button>
            </div>
          );
        }
        dispatch(enqueueNotification('error', message));
      });
  };
}

export function signUpAction(params) {
  return dispatch => {
    dispatch({ type: SIGN_UP_REQUEST_ACTION });
    const authService = new AuthApiService();
    return authService
      .signUp(params)
      .then(() => {
        dispatch({ type: SIGN_UP_SUCCESS_ACTION });
      })
      .catch(error => {
        dispatch({ type: SIGN_UP_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function createOrganizationUserAction(params) {
  return dispatch => {
    dispatch({ type: CREATE_ORGANIZATION_USER_REQUEST_ACTION });
    const authService = new AuthApiService();
    return authService
      .createOrganizationUser(params)
      .then(({ message }) => {
        dispatch({ type: CREATE_ORGANIZATION_USER_SUCCESS_ACTION });
        dispatch(enqueueNotification('success', message));
        return true;
      })
      .catch(error => {
        dispatch({ type: CREATE_ORGANIZATION_USER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function sendResetPasswordEmailAction(params) {
  return dispatch => {
    dispatch({ type: SEND_RESET_PASSWORD_EMAIL_REQUEST_ACTION });
    const authService = new AuthApiService();
    return authService
      .sendResetPasswordEmail(params)
      .then(({ message }) => {
        dispatch({ type: SEND_RESET_PASSWORD_EMAIL_SUCCESS_ACTION });
        dispatch(enqueueNotification('success', message, { autoHideDuration: null }));
      })
      .catch(error => {
        dispatch({ type: SEND_RESET_PASSWORD_EMAIL_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function verifyEmailAction(verificationCode) {
  return dispatch => {
    dispatch({ type: VERIFY_EMAIL_REQUEST_ACTION });
    const authService = new AuthApiService();
    return authService
      .verifyEmail(verificationCode)
      .then(response => {
        dispatch({ type: VERIFY_EMAIL_SUCCESS_ACTION });

        localStorage.setItem('access_token', response.login_info.access_token);
        localStorage.setItem('is_management', false);

        sessionStorage.setItem('access_token', response.login_info.access_token);
        sessionStorage.setItem('is_false', false);

        dispatch({
          type: LOGIN_SUCCESS_ACTION,
          payload: { user: response.login_info.user, management: false }
        });
      })
      .catch(() => {
        dispatch({ type: VERIFY_EMAIL_FAILURE_ACTION });
      });
  };
}

export function confirmInvitationAction(verificationCode) {
  return dispatch => {
    dispatch({ type: CONFIRM_INVITATION_REQUEST_ACTION });
    const authService = new AuthApiService();
    return authService
      .confirmInvitation(verificationCode)
      .then(({ message, organization }) => {
        dispatch({ type: CONFIRM_INVITATION_SUCCESS_ACTION, payload: organization });
        dispatch(enqueueNotification('success', message));
        return true;
      })
      .catch(error => {
        dispatch({ type: CONFIRM_INVITATION_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function resetPasswordAction(data) {
  return dispatch => {
    dispatch({ type: RESET_PASSWORD_REQUEST_ACTION });
    const authService = new AuthApiService();
    return authService
      .resetPassword(data)
      .then(({ message }) => {
        dispatch({ type: RESET_PASSWORD_SUCCESS_ACTION });
        dispatch(enqueueNotification('success', message));
        return true;
      })
      .catch(error => {
        dispatch({ type: RESET_PASSWORD_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function resetAuthAction() {
  return dispatch => {
    dispatch({ type: RESET_AUTH });
  };
}

export function logoutAction() {
  return dispatch => {
    dispatch({ type: LOGOUT_REQUEST_ACTION });
    const authService = new AuthApiService();
    const isManagement = isManagementProfile();

    setTimeout(() => {
      window.location = isManagement ? '/management' : '/login';
      localStorage.removeItem('access_token');
      localStorage.removeItem('is_management');
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('is_management');
    }, 0);

    return authService
      .logout()
      .then(({ message }) => {
        dispatch({ type: LOGOUT_SUCCESS_ACTION });
        dispatch(enqueueNotification('success', message));
      })
      .catch(error => {
        dispatch({ type: LOGOUT_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchCurrentUserAction(currentOrganizationId) {
  return dispatch => {
    dispatch({ type: FETCH_CURRENT_USER_REQUEST_ACTION });
    const authService = new AuthApiService();
    return authService
      .getCurrentUser(currentOrganizationId)
      .then(response => {
        dispatch({
          type: FETCH_CURRENT_USER_SUCCESS_ACTION,
          payload: {
            user: response.user,
            showPromotion: response.metadata.show_promotion,
            currentOrganizationId
          }
        });
        return response.user;
      })
      .catch(error => {
        dispatch({ type: FETCH_CURRENT_USER_FAILURE_ACTION, payload: { message: error.message } });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateProfileAction(params) {
  return dispatch => {
    dispatch({ type: UPDATE_PROFILE_REQUEST_ACTION });
    const authService = new AuthApiService();
    return authService
      .updateProfile(params)
      .then(({ message, user }) => {
        dispatch({ type: UPDATE_PROFILE_SUCCESS_ACTION, payload: user });
        dispatch(enqueueNotification('success', message));
      })
      .catch(error => {
        dispatch({ type: UPDATE_PROFILE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function addUserNotificationAction(data, link) {
  return dispatch => {
    dispatch({
      type: ADD_USER_NOTIFICATION,
      payload: { ...data, read_at: null, created_at: new Date(), link }
    });
  };
}

export function updateUserNotificationAction(data, link) {
  return dispatch => {
    dispatch({
      type: UPDATE_USER_NOTIFICATION,
      payload: { read_at: null, created_at: new Date(), ...data, link }
    });
  };
}

export function markAllUserNotificationsAsReadAction() {
  return dispatch => {
    dispatch({ type: MARK_ALL_USER_NOTIFICATIONS_AS_READ });
  };
}

export function clearAllUserNotificationsAction() {
  return dispatch => {
    dispatch({ type: CLEAR_USER_NOTIFICATIONS });
  };
}

export function deleteOwnDeviceAction(id, licenseId) {
  return dispatch => {
    dispatch({ type: DELETE_OWN_DEVICE_REQUEST_ACTION });
    const licensesService = new LicensesApiService();
    return licensesService
      .deleteDevice(id)
      .then(response => {
        dispatch({ type: DELETE_OWN_DEVICE_SUCCESS_ACTION, payload: { id, licenseId } });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: DELETE_OWN_DEVICE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateOwnDeviceAction(id, licenseId, params) {
  return dispatch => {
    dispatch({ type: UPDATE_OWN_DEVICE_REQUEST_ACTION });
    const licensesService = new LicensesApiService();
    return licensesService
      .updateDevice(id, params)
      .then(({ device, message }) => {
        dispatch({ type: UPDATE_OWN_DEVICE_SUCCESS_ACTION, payload: { id, device, licenseId } });
        dispatch(enqueueNotification('success', message));
      })
      .catch(error => {
        dispatch({ type: UPDATE_OWN_DEVICE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function createFattmerchantCustomerAction(organizationId) {
  return dispatch => {
    dispatch({ type: CREATE_FATTMERCHANT_CUSTOMER_REQUEST_ACTION });
    const paymentService = new PaymentsApiService();
    return paymentService
      .createFattmerchantCustomer(organizationId)
      .then(response => {
        dispatch({
          type: CREATE_FATTMERCHANT_CUSTOMER_SUCCESS_ACTION,
          payload: response.payment_info
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: CREATE_FATTMERCHANT_CUSTOMER_FAILURE_ACTION,
          payload: { message: error.message }
        });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function createFattmerchantPaymentMethodAction(id, organizationId) {
  return dispatch => {
    dispatch({ type: CREATE_FATTMERCHANT_PAYMENT_METHOD_REQUEST_ACTION });
    const paymentService = new PaymentsApiService();
    return paymentService
      .createFattmerchantPaymentMethod(id, organizationId)
      .then(response => {
        dispatch({
          type: CREATE_FATTMERCHANT_PAYMENT_METHOD_SUCCESS_ACTION,
          payload: response.payment_method
        });
        dispatch(enqueueNotification('success', response.message));
        return response;
      })
      .catch(error => {
        dispatch({
          type: CREATE_FATTMERCHANT_PAYMENT_METHOD_FAILURE_ACTION,
          payload: { message: error.message }
        });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function deleteMyPaymentMethodAction(id) {
  return dispatch => {
    dispatch({ type: DELETE_MY_PAYMENT_METHOD_REQUEST_ACTION });
    const paymentService = new PaymentsApiService();
    return paymentService
      .deleteMyPaymentMethod(id)
      .then(response => {
        dispatch({
          type: DELETE_MY_PAYMENT_METHOD_SUCCESS_ACTION,
          payload: { id }
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: DELETE_MY_PAYMENT_METHOD_FAILURE_ACTION,
          payload: { message: error.message }
        });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateMyPaymentMethodAction(id, params) {
  return dispatch => {
    dispatch({ type: UPDATE_MY_PAYMENT_METHOD_REQUEST_ACTION });
    const paymentService = new PaymentsApiService();
    return paymentService
      .updateMyPaymentMethod(id, params)
      .then(response => {
        dispatch({
          type: UPDATE_MY_PAYMENT_METHOD_SUCCESS_ACTION,
          payload: response.payment_method
        });
        dispatch(enqueueNotification('success', response.message));
        return response;
      })
      .catch(error => {
        dispatch({
          type: UPDATE_MY_PAYMENT_METHOD_FAILURE_ACTION,
          payload: { message: error.message }
        });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateMyPaymentMethodExpirationDateAction(id, params) {
  return dispatch => {
    dispatch({ type: UPDATE_MY_PAYMENT_METHOD_REQUEST_ACTION });
    const paymentService = new PaymentsApiService();
    return paymentService
      .updatePaymentMethodExpirationDate(id, params)
      .then(response => {
        dispatch({
          type: UPDATE_MY_PAYMENT_METHOD_SUCCESS_ACTION,
          payload: response.payment_method
        });
        dispatch(enqueueNotification('success', response.message));
        return response;
      })
      .catch(error => {
        dispatch({
          type: UPDATE_MY_PAYMENT_METHOD_FAILURE_ACTION,
          payload: { message: error.message }
        });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchCustomPermissions(userId) {
  return dispatch => {
    dispatch({ type: FETCH_CUSTOM_PERMISSIONS_REQUEST_ACTION });
    const authService = new AuthApiService();
    return authService
      .getCustomPermissions(userId)
      .then(response => {
        dispatch({
          type: FETCH_CUSTOM_PERMISSIONS_SUCCESS_ACTION,
          payload: response.custom_permissions
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_CUSTOM_PERMISSIONS_FAILURE_ACTION,
          payload: { message: error.message }
        });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function calculateInitialOrderAction(organizationId, promoCode) {
  return dispatch => {
    dispatch({ type: CALCULATE_INITIAL_ORDER_REQUEST_ACTION });
    const paymentsService = new PaymentsApiService();
    return paymentsService
      .calculateInitialOrder(organizationId, promoCode)
      .then(({ order }) => {
        dispatch({ type: CALCULATE_INITIAL_ORDER_SUCCESS_ACTION, payload: order });
      })
      .catch(error => {
        dispatch({
          type: CALCULATE_INITIAL_ORDER_FAILURE_ACTION,
          payload: { message: error.message }
        });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function initialPurchaseAction(promotionCodeId, organizationId) {
  return dispatch => {
    dispatch({ type: INITIAL_PURCHASE_REQUEST_ACTION });
    const paymentsService = new PaymentsApiService();
    return paymentsService
      .initialPurchase(promotionCodeId, organizationId)
      .then(({ license, message }) => {
        dispatch({ type: INITIAL_PURCHASE_SUCCESS_ACTION, payload: license });
        dispatch(enqueueNotification('success', message));
        return license;
      })
      .catch(error => {
        dispatch({
          type: INITIAL_PURCHASE_FAILURE_ACTION,
          payload: { message: error.message }
        });
        dispatch(enqueueNotification('error', error.message));
        throw error;
      });
  };
}

export function createAdditionalEmailAction(userId, data) {
  return dispatch => {
    dispatch({ type: CREATE_ADDITIONAL_EMAIL_REQUEST_ACTION });
    const UsersService = new UsersApiService();
    return UsersService.addAdditionalEmail(userId, data)
      .then(response => {
        dispatch({
          type: CREATE_ADDITIONAL_EMAIL_SUCCESS_ACTION,
          payload: { user_id: userId, email: response.email }
        });
        dispatch(enqueueNotification('success', response.message));
        return response.email;
      })
      .catch(error => {
        dispatch({ type: CREATE_ADDITIONAL_EMAIL_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function deleteAdditionalEmailAction(id) {
  return dispatch => {
    dispatch({ type: DELETE_ADDITIONAL_EMAIL_REQUEST_ACTION });
    const UsersService = new UsersApiService();
    return UsersService.deleteAdditionalEmail(id)
      .then(response => {
        dispatch({ type: DELETE_ADDITIONAL_EMAIL_SUCCESS_ACTION, payload: { id } });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({ type: DELETE_ADDITIONAL_EMAIL_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchCurrentOrganizationsOrdersAction(id) {
  return dispatch => {
    dispatch({ type: FETCH_CURRENT_ORGANIZATION_ORDERS_REQUEST_ACTION });
    const OrganizationsService = new OrganizationsApiService();
    return OrganizationsService.getOrganizationOrders(id)
      .then(({ orders }) => {
        dispatch({
          type: FETCH_CURRENT_ORGANIZATION_ORDERS_SUCCESS_ACTION,
          payload: { id, orders }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_CURRENT_ORGANIZATION_ORDERS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateCurrentOrganizationBillingAddress(id, params) {
  return dispatch => {
    dispatch({ type: UPDATE_CURRENT_ORGANIZATION_BILLING_ADDRESS_REQUEST_ACTION });
    const OrganizationsService = new OrganizationsApiService();
    return OrganizationsService.updateBillingAddress(id, params)
      .then(({ billing_address: billingAddress, message }) => {
        dispatch({
          type: UPDATE_CURRENT_ORGANIZATION_BILLING_ADDRESS_SUCCESS_ACTION,
          payload: { id, billingAddress }
        });
        dispatch(enqueueNotification('success', message));
      })
      .catch(error => {
        dispatch({ type: UPDATE_CURRENT_ORGANIZATION_BILLING_ADDRESS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function setCurrentOrganizationAction(organization) {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_ORGANIZATION,
      payload: organization
    });
  };
}

export function addOrganizationToGlobalFilterAction(organization) {
  return dispatch => {
    dispatch({
      type: ADD_ORGANIZATION_TO_GLOBAL_FILTER,
      payload: organization
    });
  };
}

export function removeOrganizationFromGlobalFilterAction(organization) {
  return dispatch => {
    dispatch({
      type: REMOVE_ORGANIZATION_FROM_GLOBAL_FILTER,
      payload: organization
    });
  };
}

export function setOrganizationsToGlobalFilterAction(organizations) {
  return dispatch => {
    dispatch({
      type: SET_ORGANIZATIONS_TO_GLOBAL_FILTER,
      payload: organizations
    });
  };
}

export function setIsOpenOrganizationTopBar(open) {
  return dispatch => {
    dispatch({
      type: SET_IS_OPEN_ORGANIZATION_TOP_BAR,
      payload: open
    });
  };
}

export function setIsCurrentOrganizationUpdating(isUpdating) {
  return dispatch => {
    dispatch({
      type: SET_IS_CURRENT_ORGANIZATION_UPDATING,
      payload: isUpdating
    });
  };
}

export function updateCurrentOrganization() {
  return (dispatch, getState) => {
    const { currentOrganization } = getState().auth;
    if (currentOrganization) {
      return dispatch(fetchCurrentUserAction(currentOrganization.id));
    }

    return new Promise(resolve => resolve(true));
  };
}

export function fetchOrganizationUserPermissions(organizationId) {
  return dispatch => {
    dispatch({ type: FETCH_ORGANIZATION_USER_PERMISSIONS_REQUEST_ACTION });
    const OrganizationsService = new OrganizationsApiService();
    return OrganizationsService.getOrganizationUserPermissions(organizationId)
      .then(({ permissions }) => {
        dispatch({
          type: FETCH_ORGANIZATION_USER_PERMISSIONS_SUCCESS_ACTION,
          payload: { organizationId, permissions }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_ORGANIZATION_USER_PERMISSIONS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function checkEmail(email) {
  return dispatch => {
    const authService = new AuthApiService();

    return authService
      .checkEmail(email)
      .then(() => {
        return true;
      })
      .catch(e => {
        dispatch(enqueueNotification('error', e.message));

        return false;
      });
  };
}

export function fetchNotificationsAction() {
  return dispatch => {
    dispatch({ type: FETCH_NOTIFICATIONS_REQUEST_ACTION });
    const service = new NotificationApiService();
    return service
      .get()
      .then(response => {
        dispatch({
          type: FETCH_NOTIFICATIONS_SUCCESS_ACTION,
          payload: response
        });
        return response;
      })
      .catch(error => {
        dispatch({ type: FETCH_NOTIFICATIONS_FAILURE_ACTION, payload: { message: error.message } });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function removeNotificationAlertAction(id) {
  return dispatch => {
    dispatch({
      type: REMOVE_NOTIFICATION_ALERT_ACTION,
      payload: { id }
    });
  };
}

export function markNotificationAsRead(id) {
  return dispatch => {
    dispatch({ type: MARK_NOTIFICATION_AS_READ_REQUEST_ACTION });
    const service = new NotificationApiService();
    return service
      .markAsRead(id)
      .then(response => {
        dispatch({
          type: MARK_NOTIFICATION_AS_READ_SUCCESS_ACTION,
          payload: { id }
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: MARK_NOTIFICATION_AS_READ_FAILURE_ACTION,
          payload: { message: error.message }
        });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateLastActivityTime() {
  return dispatch => {
    dispatch({ type: UPDATE_LAST_ACTIVITY_TIME_REQUEST_ACTION });
    const service = new AuthApiService();
    return service
      .updateLastActivityTime()
      .then(response => {
        dispatch({
          type: UPDATE_LAST_ACTIVITY_TIME_SUCCESS_ACTION
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: UPDATE_LAST_ACTIVITY_TIME_FAILURE_ACTION,
          payload: { message: error.message }
        });
        // dispatch(enqueueNotification('error', error.message));
      });
  };
}
