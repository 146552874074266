import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { Select } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Checkbox, ListItemText } from '@material-ui/core';
import { connect } from 'react-redux';
import unionBy from 'lodash/unionBy';
import { QUESTIONS } from '../constants';
import InputLabel from '../../Form/InputLabel';
import { fetchArchWiresForUser } from '../../../reducers/archWires';

const ArchWiresField = ({ name, items, fetching, fetch, doctorId }) => {
  const {
    values: { [name]: value, chosen_arch_wires: chosenArchWires }
  } = useFormikContext();

  const [currentDoctor, setCurrentDoctor] = useState(null);

  const archWires = useMemo(() => {
    return [...unionBy(items, chosenArchWires || [], 'id')];
  }, [chosenArchWires, items]);

  useEffect(() => {
    if (doctorId && (doctorId !== currentDoctor || !items.length) && !fetching) {
      setCurrentDoctor(doctorId);
      fetch();
    }
  }, [items, fetch, fetching, doctorId, currentDoctor]);

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id={`${name}-label`}>{QUESTIONS[name]}</InputLabel>
      <Field
        disabled={fetching}
        labelId={`${name}-label`}
        name={name}
        component={Select}
        multiple
        renderValue={selected => {
          const selectedItems = archWires
            .filter(item => selected.indexOf(item.id) !== -1)
            .map(item => item.label || item.name);
          return selectedItems.join(', ');
        }}
        labelWidth={65}
      >
        {archWires.map(item => {
          return (
            <MenuItem key={item.id} value={item.id}>
              <Checkbox checked={value.indexOf(item.id) > -1} />
              <ListItemText primary={item.label || item.name} />
            </MenuItem>
          );
        })}
      </Field>
    </FormControl>
  );
};

ArchWiresField.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array,
  fetch: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  doctorId: PropTypes.number.isRequired
};

ArchWiresField.defaultProps = {
  items: null
};

export default connect(
  (state, props) => {
    const { doctorId } = props;
    let archWires = state.archWires.user || {};
    if (doctorId && archWires.userId !== doctorId) {
      archWires = {};
    }
    return {
      items: archWires.items || [],
      fetching: archWires.fetching === true
    };
  },
  (dispatch, props) => {
    return {
      fetch: () => {
        dispatch(fetchArchWiresForUser(props.organizationId, props.doctorId));
      }
    };
  }
)(ArchWiresField);
