import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { RX_FORMS } from '../../../constants';
import { SCAN_SUBMISSION_METHODS } from '../../../components/RxForm/constants';

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(1, 0),
    fontSize: theme.typography.pxToRem(12)
  }
}));

const ScannerType = ({ rxForm }) => {
  const classes = useStyles();

  const scanner = useMemo(() => {
    switch (get(rxForm, 'scan_submission_type')) {
      case SCAN_SUBMISSION_METHODS.SCANNER:
        if (rxForm.scanner_type) {
          return rxForm.scanner_type !== 7
            ? RX_FORMS.scanner_type[rxForm.scanner_type].label
            : rxForm.custom_scanner_type_name;
        }
        return null;
      case SCAN_SUBMISSION_METHODS.UPLOAD:
        return 'Direct Upload';
      default:
        return null;
    }
  }, [rxForm]);

  if (!scanner) {
    return null;
  }

  return (
    <div className={classes.container}>
      <strong>Scanner Type: </strong> {scanner}
    </div>
  );
};

ScannerType.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rxForm: PropTypes.object
};

ScannerType.defaultProps = {
  rxForm: null
};

export default ScannerType;
