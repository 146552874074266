import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';

function CustomInput({ id, label, required, type, show, xs, md, component, ...rest }) {
  if (!show) return null;

  return (
    <Grid item xs={xs} md={md}>
      <Field
        autoComplete="off"
        id={id}
        name={id}
        label={label}
        variant="outlined"
        required={required}
        component={component}
        type={type}
        fullWidth
        {...rest}
      />
    </Grid>
  );
}

CustomInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  xs: PropTypes.number,
  md: PropTypes.number,
  type: PropTypes.string,
  required: PropTypes.bool,
  show: PropTypes.bool,
  component: PropTypes.elementType
};

CustomInput.defaultProps = {
  required: false,
  show: false,
  xs: 12,
  md: 12,
  type: 'text',
  component: TextField
};

export default CustomInput;
