import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import StatusTag from '../../../components/StatusTag';
import { REQUEST } from '../../../constants';
import {
  getColorByStatus,
  getDateDifferenceFromNow,
  removeUnderscoreAndCapitalizeFirstLetter
} from '../../../helpers';
import { ASSIGNMENT_SHORT_LABELS, ASSIGNMENTS } from '../../../components/RxForm/constants';
import Tooltip from './Tooltip';

const useStyles = makeStyles(theme => {
  return {
    container: {
      display: 'flex',
      gap: theme.spacing(1)
    },
    collapsed: {
      justifyContent: 'space-evenly'
    },
    collapsedItem: {
      flexDirection: 'column'
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.typography.pxToRem(12),
      columnGap: theme.spacing(0.5)
    },
    assignment: {
      fontWeight: 'bolder',
      fontSize: '14px !important',
      backgroundColor: 'white',
      color: 'black'
    },
    status: {
      fontWeight: 'bolder'
    },
    age: {},
    tag: {
      marginLeft: 0,
      marginBottom: theme.spacing(0.25)
    },
    printBadge: {
      marginRight: theme.spacing(1),
      '& .MuiBadge-badge': {
        color: 'white',
        fontSize: 9,
        padding: 3,
        height: 'auto',
        backgroundColor: theme.palette.success.main
      }
    }
  };
});

const RequestsColumn = ({ collapsed, workflowData, requests, assignments }) => {
  const classes = useStyles();
  const size = 'small';

  const onlyAssignments = assignments.length > 0 ? assignments : Object.values(ASSIGNMENTS);
  const onlyRequests = requests.length > 0 ? requests : Object.values(REQUEST);

  if (isEmpty(workflowData)) {
    return '';
  }

  const data = onlyRequests
    .filter(request => {
      return (
        !isEmpty(workflowData[request]) &&
        onlyAssignments.find(assignment => assignment === workflowData[request].assigned_to) !==
          undefined
      );
    })
    .map(request => {
      const requestWorkflowData = workflowData[request];
      let { status } = requestWorkflowData;
      if (
        request === REQUEST.INITIAL_CASE_SETUP &&
        workflowData.print &&
        !isEmpty(workflowData.print)
      ) {
        status = 'Approved';
      }
      return {
        assignment: ASSIGNMENT_SHORT_LABELS[requestWorkflowData.assigned_to],
        label: request.charAt(0).toUpperCase() + request.slice(1),
        date: requestWorkflowData.status_updated_at
          ? moment.utc(requestWorkflowData.status_updated_at).format('YYYY-MMM-DD')
          : 'N/A',
        age: requestWorkflowData.status_updated_at
          ? getDateDifferenceFromNow(requestWorkflowData.status_updated_at)
          : 'N/A',
        status,
        nextStep: requestWorkflowData.next_step ? requestWorkflowData.next_step.process : '',
        technician: requestWorkflowData.next_step ? requestWorkflowData.next_step.tech_name : '',
        color: getColorByStatus(requestWorkflowData.status)
      };
    });

  const itemClasses = [classes.item];
  const containerClasses = [classes.container];
  if (collapsed) {
    itemClasses.push(classes.collapsedItem);
    containerClasses.push(classes.collapsed);
  }

  return (
    <div className={containerClasses.join(' ')}>
      {data.map(({ assignment, label, age, color, status, nextStep, date }) => {
        return (
          <Tooltip
            disabled={collapsed}
            key={label}
            title={removeUnderscoreAndCapitalizeFirstLetter(status)}
            disableHoverListener={collapsed}
            placement="top"
          >
            <div className={itemClasses.join(' ')}>
              <StatusTag
                className={classes.tag}
                color={color}
                label={label}
                size={size}
                avatar={<Avatar className={classes.assignment}>{assignment}</Avatar>}
              />
              {collapsed && (
                <>
                  <div className={classes.status}>
                    {removeUnderscoreAndCapitalizeFirstLetter(status)}
                  </div>
                  <div className={classes.step}>{nextStep}</div>
                  <div className={classes.date}>{date}</div>
                </>
              )}

              {!collapsed && onlyRequests.length === 1 && (
                <>
                  <div className={classes.step}>{nextStep}</div>
                  <div className={classes.age}> ({age})</div>
                </>
              )}
              {!collapsed && onlyRequests.length > 1 && <div className={classes.age}>{age}</div>}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

RequestsColumn.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  workflowData: PropTypes.object,
  assignments: PropTypes.arrayOf(PropTypes.number).isRequired,
  requests: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default RequestsColumn;
