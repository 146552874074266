import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import RadioGroupField from './RadioGroupField';
import { EXPORT_LABELS } from '../constants';

const ExportTypeField = ({ name, isSubmitting, options }) => {
  const { values } = useFormikContext();
  const optionWithLabels = options.map(option => ({
    value: option,
    label: EXPORT_LABELS[option]
  }));

  return (
    <RadioGroupField
      isSubmitting={isSubmitting}
      name={name}
      value={values[name]}
      options={optionWithLabels}
    />
  );
};

ExportTypeField.propTypes = {
  name: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default ExportTypeField;
