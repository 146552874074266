import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { fetchCurrentUserAction, setCurrentOrganizationAction } from '../../reducers/auth';
import TabPanel from '../../components/TabPanel';
import AccountInformation from './components/AccountInformation';
import AccountPayment from './components/AccountPayment';
import Orders from '../../components/Orders';
import Installer from './components/Installer';
import OrganizationInformation from './components/OrganizationInformation';
import hasPermission from '../../selectors/hasPermission';
import Devices from '../Devices';
import { setWebhookEnable } from '../../reducers/organizations';
import Webhooks from './components/Webhooks';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));

const Account = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentUser = useSelector(state => state.auth.user);
  const isManagement = useSelector(state => state.auth.isManagement);
  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const canViewOrganizationDetails = useSelector(state =>
    hasPermission(state, { permissions: ['organization.view'] })
  );
  const canViewOrder = useSelector(state =>
    hasPermission(state, { permissions: ['organization.get-current-order'] })
  );
  const canUpdatePaymentMethod = useSelector(state =>
    hasPermission(state, { permissions: ['organization.f_m_payment_methods.update'] })
  );
  const canManageWebhooks = useSelector(state =>
    hasPermission(state, { permissions: ['organization.webhooks.manage'] })
  );
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleWebhookSetEnable = useCallback(
    value => {
      dispatch(setWebhookEnable(currentOrganization.id, value));
      dispatch(setCurrentOrganizationAction({ ...currentOrganization, webhooks_enabled: value }));
    },
    [currentOrganization, dispatch]
  );

  useEffect(() => {
    const queryStringParams = parse(location.search);
    if (queryStringParams.tab) {
      setValue(parseInt(queryStringParams.tab, 10));
    }
    dispatch(fetchCurrentUserAction(get(currentOrganization, 'id', null)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location]);

  const showWebhook =
    !isManagement &&
    canManageWebhooks &&
    currentOrganization &&
    currentOrganization.webhooks_enabled !== null;

  if (!get(currentOrganization, 'id', null) && value !== 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="User" value={0} />
          {!isManagement && canViewOrganizationDetails && <Tab label="Organization" value={6} />}
          {!isManagement && canUpdatePaymentMethod && !currentOrganization.disabled_at && (
            <Tab label="Payment" value={2} />
          )}
          {!isManagement &&
            get(currentUser, 'contract.license.license_type.rules.allow_buy_seats', false) && (
              <Tab label="Extra" value={3} />
            )}
          {!isManagement && canViewOrder && <Tab label="Invoices" value={4} />}
          {!isManagement && <Tab label="Installer" value={5} />}
          {!isManagement && <Tab label="Devices" value={7} />}
          {showWebhook && <Tab label="Webhooks" value={8} />}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <AccountInformation />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AccountPayment />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Orders />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Installer />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <OrganizationInformation />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Devices />
      </TabPanel>
      {showWebhook && (
        <TabPanel value={value} index={8}>
          <Webhooks
            enabled={currentOrganization.webhooks_enabled}
            setEnable={handleWebhookSetEnable}
          />
        </TabPanel>
      )}
    </div>
  );
};

export default Account;
