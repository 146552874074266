import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Typography from '@material-ui/core/Typography';
import { UTCDateTimeToLocalTime } from '../../../helpers';
import ManagePaymentMethods from '../../../components/SavePaymentInformation/ManagePaymentMethods';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(5)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  managePaymentMethods: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

const AccountPayment = () => {
  const classes = useStyles();
  const currentOrganization = useSelector(state => state.auth.currentOrganization);

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <AccountBalanceIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Payment Information
      </Typography>
      <Box m={3} className={classes.managePaymentMethods}>
        <ManagePaymentMethods />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        <Typography component="h2" variant="subtitle1">
          Next Payment Date
        </Typography>
      </Box>
      <Box>{UTCDateTimeToLocalTime(currentOrganization.contract.next_payment_date)}</Box>
    </div>
  );
};

export default AccountPayment;
