import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dropzone from 'react-dropzone-uploader';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import JSZip from 'jszip';

const useStyles = makeStyles(() => ({
  root: {
    '& .dzu-previewFileName': { width: '65%', overflowWrap: 'break-word' }
  },
  placeholder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  note: {
    color: '#FF3231',
    fontWeight: 400
  }
}));

const UploadFilesDialogBase = ({
  open,
  handleClose,
  title,
  showContentText,
  getUploadParams,
  handleChangeStatus,
  handleSubmit,
  maxFiles,
  customActions,
  autoExtractCompressedFiles,
  note,
  preDropzoneComponent,
  postDropzoneComponent,
  ...props
}) => {
  const classes = useStyles();
  const actions = customActions || [
    {
      label: 'Close',
      onClick: () => handleClose(true),
      color: 'primary'
    }
  ];

  const onClose = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    handleClose(false);
  };

  const processZipFiles = async event => {
    const files = [];
    const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    for (let i = 0; i < fileList.length; i += 1) {
      const file = fileList.item(i);
      if (file.type.includes('zip')) {
        const jsZip = new JSZip();
        // eslint-disable-next-line no-await-in-loop
        const zip = await jsZip.loadAsync(file);
        if (zip && typeof zip.files === 'object') {
          const keys = Object.keys(zip.files).filter(
            key =>
              !key.match(/^__MACOSX\//) &&
              !!(autoExtractCompressedFiles || []).find(ext => key.endsWith(ext))
          );
          for (let j = 0; j < keys.length; j += 1) {
            // eslint-disable-next-line no-await-in-loop
            const newBlob = await zip.files[keys[j]].async('blob');
            if (newBlob.size > 0) {
              files.push(new File([newBlob], keys[j]));
            }
          }
        }
      } else if (file.size > 0) {
        files.push(file);
      }
    }
    return files;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {showContentText && (
          <DialogContentText id="alert-dialog-description">Upload Files</DialogContentText>
        )}
        {preDropzoneComponent}
        <Dropzone
          getFilesFromEvent={autoExtractCompressedFiles ? processZipFiles : null}
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          onSubmit={handleSubmit}
          maxFiles={maxFiles}
          SubmitButtonComponent={null}
          inputContent={
            note ? (
              <div className={classes.placeholder}>
                <span>Drag Files or Click to Browse</span>
                <span className={classes.note}>{`(Note: ${note})`}</span>
              </div>
            ) : (
              undefined
            )
          }
          styles={{
            dropzone: { minHeight: 200, maxHeight: 250 },
            inputLabelWithFiles: { display: 'none' }
          }}
          {...props}
        />
        {postDropzoneComponent}
      </DialogContent>
      <DialogActions>
        {actions.map(({ label, ...actionProps }) => (
          <Button key={label} {...actionProps}>
            {label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

UploadFilesDialogBase.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  maxFiles: PropTypes.number,
  showContentText: PropTypes.bool,
  getUploadParams: PropTypes.func.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  customActions: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  autoExtractCompressedFiles: PropTypes.array,
  note: PropTypes.string
};

UploadFilesDialogBase.defaultProps = {
  maxFiles: 100,
  showContentText: false,
  customActions: null,
  autoExtractCompressedFiles: null,
  note: null
};

export default UploadFilesDialogBase;
