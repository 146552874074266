import React from 'react';
import PropTypes from 'prop-types';
import HitCardDetails from '../Common/HitCardDetails';
import HitCardHeader from '../Common/HitCardHeader';
import HitCardActions from '../Common/HitCardActions';

const visibleAttributes = [
  'account_number',
  'email',
  'phone_number',
  'street_address',
  'city',
  'state',
  'country',
  'zip_code',
  'number_of_patients',
  'locations',
  'joined_at',
  'first_case_submission',
  'newest_case_submission',
  'last_login_at',
  'last_activity_at',
  'average_monthly_submissions',
  'average_monthly_submissions_diff_percentage'
];

const attributeTitles = {
  account_number: 'User Account Number',
  average_monthly_submissions_diff_percentage: 'Average Monthly Submissions Diff Percentage'
};

const customAttributeRenderers = {
  average_monthly_submissions_diff_percentage: value => {
    const percentage = Number(value);
    const color = percentage > 0 ? 'green' : 'red';
    const symbol = percentage > 0 ? '↑' : '↓';
    return (
      <>
        {Math.abs(percentage)}% {percentage !== 0 && <span style={{ color }}>{symbol}</span>}
      </>
    );
  }
};

const HitCard = ({ hit }) => {
  const [expanded, setExpanded] = React.useState(false);

  let isArchived = false;

  if (hit.model === 'user' && hit.is_archived) {
    isArchived = true;
  }

  return (
    <>
      <HitCardHeader hit={hit} expanded={expanded} setExpanded={setExpanded} />

      {!isArchived && (
        <HitCardActions
          hit={hit}
          actions={[
            {
              label: 'Subscription',
              url: `/subscriptions/${hit.contract_id}`
            },
            {
              label: 'Locations',
              url: () => {
                return hit.organization_id
                  ? `/organizations/${hit.organization_id}/locations`
                  : null;
              }
            },
            {
              label: 'Patients',
              url: `/patients-page?queryUser=${hit.model_id}&queryName=${hit.full_name}`
            }
          ]}
        />
      )}

      {isArchived && (
        <HitCardActions
          hit={hit}
          actions={[
            {
              label: 'Patients',
              url: `/patients-page?queryUser=${hit.model_id}&queryName=${hit.full_name}`
            }
          ]}
        />
      )}

      <HitCardDetails
        hit={hit}
        visibleAttributes={visibleAttributes}
        attributeTitles={attributeTitles}
        expanded={expanded}
        customAttributeRenderers={customAttributeRenderers}
      />
    </>
  );
};

HitCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hit: PropTypes.object.isRequired
};

HitCard.defaultProps = {};

export default HitCard;
