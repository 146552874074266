import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SubSectionWrapper, { SubSectionActionWrapper } from '../SubSectionWrapper';
import {
  deleteBracketLibrary,
  fetchBracketLibraries,
  fetchBracketLibraryAssignableMembers,
  uploadBracketLibrary,
  updateBracketLibrary,
  resetBracketLibraries
} from '../../../../reducers/bracketLibraries';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import EditDialog from './EditDialog';
import Can from '../../../../components/Can';
import UploadDialog from './UploadDialog';
import FileList from './FileList';
import InventoryDialog from './InventoryDialog';
import BracketLibrariesApiService from '../../../../services/api/bracketLibraries';
import hasPermission from '../../../../selectors/hasPermission';

const useStyles = makeStyles(theme => ({
  refreshButtonContainer: {
    paddingBottom: theme.spacing(1)
  },
  subSectionWrapper: {
    '& .MuiAccordionDetails-root': {
      paddingTop: theme.spacing(1)
    }
  }
}));

const Main = ({ organization }) => {
  const classes = useStyles();
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [selectedBracket, setSelectedBracket] = useState(null);
  const [selectedBracketInventory, setSelectedBracketInventory] = useState(null);
  const {
    fetched = false,
    fetching = false,
    items = [],
    assignableMembers = [],
    uploading = [],
    removing = [],
    updating = false
  } = useSelector(state => {
    return state.bracketLibraries.organizations[organization.id] || {};
  });
  const dispatch = useDispatch();
  const canManageInventory = useSelector(state =>
    hasPermission(state, {
      permissions: ['bracket-libraries.manage-inventory', 'bracket-libraries.manage']
    })
  );

  const bracketLibrariesApiService = new BracketLibrariesApiService();

  const handleSave = files => {
    const promises = files.map(file => {
      return dispatch(uploadBracketLibrary(organization.id, file));
    });
    Promise.all(promises).then(() => {
      setShowUploadPopup(false);
    });
  };

  const handleBracketUpdate = (id, data) => {
    dispatch(updateBracketLibrary(organization.id, id, data)).then(() => {
      setSelectedBracket(null);
    });
  };

  const handleDelete = id => {
    dispatch(deleteBracketLibrary(organization.id, id));
  };

  const handleDownload = id => {
    bracketLibrariesApiService.getBracketLibraryDownloadUrl(organization.id, id).then(({ url }) => {
      window.open(url);
    });
  };

  const isFileAlreadyUploaded = fileName => {
    return items.length > 0 && items.filter(item => item.file_name === fileName).length > 0;
  };

  const handleRefresh = e => {
    e.preventDefault();
    dispatch(resetBracketLibraries(organization.id));
  };

  useEffect(() => {
    if (fetched || fetching) {
      return;
    }
    dispatch(fetchBracketLibraries(organization.id));
    dispatch(fetchBracketLibraryAssignableMembers(organization.id));
  }, [fetched, organization, dispatch, fetching]);

  return (
    <>
      <UploadDialog
        uploading={uploading.length > 0}
        handleSave={handleSave}
        retrieveDownloadUrl={name => {
          const api = new BracketLibrariesApiService();
          return api.getUploadUrlForBracketLibraries(organization.id, name);
        }}
        organization={organization}
        open={showUploadPopup}
        handleClose={() => setShowUploadPopup(false)}
        isFileAlreadyUploaded={isFileAlreadyUploaded}
      />
      {selectedBracket && (
        <EditDialog
          open={!!selectedBracket}
          setOpen={() => setSelectedBracket(null)}
          name={selectedBracket ? selectedBracket.label : ''}
          bracket={selectedBracket}
          users={assignableMembers || []}
          onBracketUpdate={data => handleBracketUpdate(selectedBracket.id, data)}
        />
      )}
      {organization && selectedBracketInventory && (
        <InventoryDialog
          organization={organization}
          bracket={selectedBracketInventory}
          open={!!selectedBracketInventory}
          readOnly={!canManageInventory}
          setOpen={count => {
            setSelectedBracketInventory(null);
            if (count > 0) {
              dispatch(resetBracketLibraries(organization.id));
            }
          }}
        />
      )}
      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end" className={classes.refreshButtonContainer}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleRefresh}
              disabled={fetching || updating}
            >
              Refresh
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {(fetching || updating) && <LoadingIndicator />}
        {items && (
          <Grid item xs={12}>
            <FileList
              data={items}
              onDelete={handleDelete}
              onDownload={handleDownload}
              onEdit={bracket => setSelectedBracket(bracket)}
              removingItems={removing}
              organizationId={organization.id}
              onOpenInventory={bracket => {
                setSelectedBracketInventory(bracket);
              }}
            />
          </Grid>
        )}
        <Can
          permissions={['bracket-libraries.manage']}
          organizationId={organization.id}
          yes={() => (
            <Grid item xs={12}>
              <SubSectionActionWrapper>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => setShowUploadPopup(true)}
                  disabled={fetching || updating}
                >
                  Add Library (Zip File)
                </Button>
              </SubSectionActionWrapper>
            </Grid>
          )}
        />
      </Grid>
    </>
  );
};

Main.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object.isRequired
};

Main.defaultProps = {};

export default props => {
  const classes = useStyles();
  return (
    <SubSectionWrapper title="Bracket Libraries" className={classes.subSectionWrapper}>
      <Main {...props} />
    </SubSectionWrapper>
  );
};
