import React, { useEffect } from 'react';
import Carousel, { ModalGateway, Modal } from 'react-images';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import CustomFullScreenImagesView from '../../../components/CustomFullScreenImagesView';
import { fetchPatientFilesDownloadUrlsAction } from '../../../reducers/patients';
import { isImage } from '../../../helpers';

const modalCustomStyles = {
  blanket: base => ({
    ...base,
    zIndex: 1200
  }),
  positioner: base => ({
    ...base,
    zIndex: 1201
  })
};

const PatientFilesFullScreenGalary = ({ patient, isOpen, setOpen, fileTypeId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchPatientFilesDownloadUrlsAction(patient.id, fileTypeId));
    }
  }, [isOpen, dispatch, patient.id, fileTypeId]);

  const loadedIDBImageFiles = get(patient, `files[${fileTypeId}]`, []).filter(file =>
    isImage(file.name)
  );

  return (
    <ModalGateway>
      {isOpen && loadedIDBImageFiles.length > 0 ? (
        <Modal onClose={() => setOpen(false)} styles={modalCustomStyles}>
          <Carousel
            currentIndex={0}
            views={loadedIDBImageFiles.map(image => ({
              src: image.url
            }))}
            components={{ View: CustomFullScreenImagesView }}
          />
        </Modal>
      ) : null}
    </ModalGateway>
  );
};

PatientFilesFullScreenGalary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  fileTypeId: PropTypes.number.isRequired
};

export default PatientFilesFullScreenGalary;
