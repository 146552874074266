import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import PropTypes from 'prop-types';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles(theme => ({
  toothContainer: {
    width: 'auto',
    height: '100px',
    position: 'relative',
    textAlign: 'center',
    '& > span': {
      position: 'absolute'
    }
  },
  tooth: {
    height: '100%'
  },
  toothGrey: {
    '& path': {
      fill: grey[400]
    }
  },
  toothRed: {
    '& path': {
      fill: theme.palette.error.light
    }
  },
  toothContainerGrey: {
    color: '#fff'
  }
}));

const Tooth = ({ image: Image, onChange, mode: value, disabled, label, onClick }) => {
  const classes = useStyles();

  const [mode, setMode] = useState(value);

  useEffect(() => {
    if (mode !== value) {
      setMode(value);
    }
  }, [value, mode]);

  const modeToothClass = useMemo(() => {
    switch (mode) {
      case 1:
        return classes.toothGrey;
      case 2:
        return classes.toothRed;
      default:
        return '';
    }
  }, [mode, classes]);

  const modeToothContainerClass = useMemo(() => {
    switch (mode) {
      case 1:
      case 2:
        return classes.toothContainerGrey;
      default:
        return '';
    }
  }, [mode, classes]);

  const handleOnClick = e => {
    e.preventDefault();
    setMode(prev => {
      let m = +prev + 1;

      if (m > 1) {
        m = 0;
      }

      onChange(m);

      return m;
    });
  };

  return (
    <ButtonBase
      disabled={disabled}
      className={`${classes.toothContainer} ${modeToothContainerClass}`}
      onClick={onClick !== null ? onClick : handleOnClick}
    >
      <Image className={`${classes.tooth} ${modeToothClass}`} />
      {label && <span>{label}</span>}
    </ButtonBase>
  );
};

Tooth.propTypes = {
  mode: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func
};

Tooth.defaultProps = {
  mode: 0,
  onChange: () => {},
  disabled: false,
  label: '',
  onClick: null
};

export default Tooth;
