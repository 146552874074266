import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Container } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import {
  deleteInstallerAction,
  fetchAutoUpdateSettingAction,
  fetchInstallersAction,
  organizationInitialState,
  setAutoUpdateSettingAction
} from '../../../reducers/installers';
import AddInstaller from './AddInstaller';
import EditReleaseNotesForm from './EditReleaseNotesForm';
import InstallersApiService from '../../../services/api/installers';
import { enqueueNotification } from '../../../reducers/notifications';
import LoadingIndicator from '../../../components/LoadingIndicator';
import hasPermission from '../../../selectors/hasPermission';
import MachineLearning from './MachineLearning';

const useStyles = makeStyles(() => ({
  listItem: {
    width: '100%'
  }
}));

const InstallersManagement = ({
  organizationId,
  fetched,
  fetching,
  fetchInstallers,
  installers,
  deleteInstaller,
  fetchedAutoUpdateSetting,
  fetchingAutoUpdateSetting,
  fetchAutoUpdateSetting,
  setAutoUpdateSetting,
  autoUpdate,
  permissions: { canSetAutoUpdate, canAddInstaller }
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const installersApiService = new InstallersApiService();

  useEffect(() => {
    if (!fetched && !fetching) {
      fetchInstallers();
    }
  }, [fetched, fetching, fetchInstallers]);

  useEffect(() => {
    if (!fetchedAutoUpdateSetting && !fetchingAutoUpdateSetting) {
      fetchAutoUpdateSetting();
    }
  }, [fetchedAutoUpdateSetting, fetchingAutoUpdateSetting, fetchAutoUpdateSetting]);

  const deleteInstallerHandler = (event, id) => {
    event.stopPropagation();
    deleteInstaller(id);
  };

  const downloadFile = async (event, fileId) => {
    event.stopPropagation();
    try {
      const { url } = await installersApiService.getDownloadLink(fileId);
      window.open(url);
    } catch (error) {
      dispatch(enqueueNotification('error', error.message));
    }
  };

  const setAutoUpdate = event => {
    const value = event.currentTarget.checked;
    setAutoUpdateSetting(value);
  };

  if (fetching) {
    return <LoadingIndicator />;
  }

  return (
    <Box my={1} width={1}>
      <Container maxWidth="md" style={{ marginBottom: 16 }}>
        <Paper>
          <List>
            <ListItem>
              <ListItemText primary="Auto-Update" />
              <ListItemSecondaryAction>
                <Switch
                  disabled={!canSetAutoUpdate}
                  checked={autoUpdate}
                  onChange={setAutoUpdate}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Paper>
      </Container>
      {canAddInstaller && <AddInstaller organizationId={organizationId} />}
      <Box my={2}>
        <Container maxWidth="md">
          {installers.length > 0 ? (
            <List>
              {installers.map(item => (
                <Accordion key={item.id}>
                  <AccordionSummary expandIcon={<EditIcon />} id={`installer_${item.id}`}>
                    <Box className={classes.listItem}>
                      <ListItem
                        onClick={event => event.stopPropagation()}
                        onFocus={event => event.stopPropagation()}
                      >
                        <ListItemIcon>
                          <AttachmentIcon />
                        </ListItemIcon>
                        <ListItemText primary={item.version} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="comments"
                            onClick={e => downloadFile(e, item.id)}
                          >
                            <CloudDownloadIcon />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="comments"
                            onClick={e => deleteInstallerHandler(e, item.id)}
                          >
                            <DeleteIcon color="secondary" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <EditReleaseNotesForm installer={item} organizationId={organizationId} />
                  </AccordionDetails>
                </Accordion>
              ))}
            </List>
          ) : (
            'No installers'
          )}
        </Container>
      </Box>
      {!organizationId && <MachineLearning />}
    </Box>
  );
};

InstallersManagement.propTypes = {
  organizationId: PropTypes.number,
  fetched: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  fetchInstallers: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  installers: PropTypes.array.isRequired,
  deleteInstaller: PropTypes.func.isRequired,
  fetchedAutoUpdateSetting: PropTypes.bool.isRequired,
  fetchingAutoUpdateSetting: PropTypes.bool.isRequired,
  fetchAutoUpdateSetting: PropTypes.func.isRequired,
  setAutoUpdateSetting: PropTypes.func.isRequired,
  autoUpdate: PropTypes.bool.isRequired,
  permissions: PropTypes.shape({
    canSetAutoUpdate: PropTypes.bool.isRequired,
    canAddInstaller: PropTypes.bool.isRequired
  }).isRequired
};

InstallersManagement.defaultProps = {
  organizationId: null
};

export default connect(
  (state, { organizationId = null }) => {
    let installerState = state.installers;
    if (organizationId) {
      installerState = state.installers.organizations[organizationId] || organizationInitialState;
    }

    return {
      fetched: installerState.fetched,
      fetching: installerState.fetching,
      installers: organizationId
        ? installerState.items.filter(item => item.organization_id)
        : installerState.items,
      fetchingAutoUpdateSetting: installerState.fetchingAutoUpdateSetting,
      fetchedAutoUpdateSetting: installerState.fetchedAutoUpdateSetting,
      autoUpdate: installerState.autoUpdate,
      permissions: {
        canSetAutoUpdate: hasPermission(state, { permissions: ['installers.set-auto-update'] }),
        canAddInstaller: hasPermission(state, { permissions: ['installers.manage'] })
      }
    };
  },
  (dispatch, { organizationId = null }) => {
    return {
      fetchInstallers: () => dispatch(fetchInstallersAction({}, organizationId)),
      deleteInstaller: id => dispatch(deleteInstallerAction(id, organizationId)),
      fetchAutoUpdateSetting: () => dispatch(fetchAutoUpdateSettingAction(organizationId)),
      setAutoUpdateSetting: value => dispatch(setAutoUpdateSettingAction(value, organizationId))
    };
  }
)(InstallersManagement);
