import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import SubSectionWrapper, { SubSectionActionWrapper } from '../SubSectionWrapper';
import {
  deleteOrganizationArchWire,
  fetchOrganizationArchWires,
  fetchOrganizationArchWiresAssignableMembers,
  uploadOrganizationArchWire,
  updateOrganizationArchWire
} from '../../../../reducers/archWires';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import UsersDialog from './UsersDialog';
import Can from '../../../../components/Can';
import UploadDialog from './UploadDialog';
import FileList from './FileList';
import ArchWiresApiService from '../../../../services/api/archWires';

const ArchWireLibraries = ({ organization }) => {
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [selectedArchWire, setSelectedArchWire] = useState(null);
  const {
    fetched = false,
    fetching = false,
    items = [],
    assignableMembers = [],
    uploading = [],
    removing = [],
    updating = false
  } = useSelector(state => {
    return state.archWires.organizations[organization.id] || {};
  });
  const dispatch = useDispatch();

  const archWiresApi = new ArchWiresApiService();

  const handleSave = files => {
    const promises = files.map(file => {
      return dispatch(uploadOrganizationArchWire(organization.id, file));
    });
    Promise.all(promises).then(() => {
      setShowUploadPopup(false);
      dispatch(fetchOrganizationArchWires(organization.id));
    });
  };

  const handleArchWireUpdate = (id, userIds) => {
    dispatch(updateOrganizationArchWire(organization.id, id, userIds)).then(() => {
      setSelectedArchWire(null);
      dispatch(fetchOrganizationArchWires(organization.id));
    });
  };

  const handleDelete = id => {
    dispatch(deleteOrganizationArchWire(organization.id, id)).then(() => {
      dispatch(fetchOrganizationArchWires(organization.id));
    });
  };

  const handleDownload = id => {
    archWiresApi.getOrganizationArchWireDownloadUrl(organization.id, id).then(({ url }) => {
      window.open(url);
    });
  };

  const isFileAlreadyUploaded = fileName => {
    return items.length > 0 && items.filter(item => item.file_name === fileName).length > 0;
  };

  useEffect(() => {
    if (fetched) {
      return;
    }
    dispatch(fetchOrganizationArchWires(organization.id));
    dispatch(fetchOrganizationArchWiresAssignableMembers(organization.id));
  }, [fetched, organization, dispatch]);

  if (fetching || updating) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <UploadDialog
        uploading={uploading.length > 0}
        handleSave={handleSave}
        retrieveDownloadUrl={name => {
          const api = new ArchWiresApiService();
          return api.getOrganizationArchWireUploadUrl(organization.id, name);
        }}
        organization={organization}
        open={showUploadPopup}
        handleClose={() => setShowUploadPopup(false)}
        isFileAlreadyUploaded={isFileAlreadyUploaded}
      />
      <UsersDialog
        open={!!selectedArchWire}
        setOpen={() => setSelectedArchWire(null)}
        name={selectedArchWire ? selectedArchWire.label : ''}
        value={
          selectedArchWire && selectedArchWire.users
            ? selectedArchWire.users.map(user => ({ id: user.id, label: user.full_name }))
            : []
        }
        users={assignableMembers || []}
        onArchWireUpdate={userIds => handleArchWireUpdate(selectedArchWire.id, userIds)}
      />
      <Grid container>
        {items && (
          <Grid item xs={12}>
            <FileList
              data={items}
              onDelete={handleDelete}
              onDownload={handleDownload}
              onUserSelect={archWire => setSelectedArchWire(archWire)}
              removingItems={removing}
              organizationId={organization.id}
            />
          </Grid>
        )}
        <Can
          permissions={['arch-wires.create', 'arch-wires.manage']}
          yes={() => (
            <Grid item xs={12}>
              <SubSectionActionWrapper>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => setShowUploadPopup(true)}
                >
                  Add Library (Zip File)
                </Button>
              </SubSectionActionWrapper>
            </Grid>
          )}
        />
      </Grid>
    </>
  );
};

ArchWireLibraries.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object.isRequired
};

export default props => {
  return (
    <SubSectionWrapper title="Archwire Libraries">
      <ArchWireLibraries {...props} />
    </SubSectionWrapper>
  );
};
