import _ from 'lodash';
import { initialState } from '../../../reducers/patients';

const CONFIG = {
  onlyOnhold: {
    name: 'filter[on_hold]'
  },
  userId: {
    name: 'queryUser'
  },
  organizationId: {
    name: 'queryOrganization'
  },
  createdDate: {
    name: 'filter[created_at_between]'
  },
  searchDoctorFullNameOrOrganizationQuery: {
    name: 'queryName'
  },
  shippedDate: {
    name: 'filter[shipped_between]'
  },
  completedCaseDate: {
    name: 'filter[completed_case_between]'
  },
  requestTypeStatus: {
    type: 'array'
  },
  requestAssignments: {
    type: 'array',
    parse: value => value.map(v => +v)
  },
  sort: {
    name: 'sort',
    parseReturn: value => {
      switch (value) {
        case 'newest':
          return {
            orderBy: 'created_at',
            order: 'desc'
          };
        case 'oldest':
          return {
            orderBy: 'created_at',
            order: 'asc'
          };
        default:
          return {};
      }
    }
  }
};

const getValue = (queryParams, name) => {
  // Date Filter - detects property with `From` postfix
  if (name.slice(-4) === 'From') {
    const configName = name.substring(0, name.length - 4);

    if (!CONFIG[configName] || !queryParams.has(CONFIG[configName].name)) {
      return {};
    }

    const queryName = CONFIG[configName].name;
    const value = queryParams.get(queryName).split(',');
    const from = `${configName}From`;
    const to = `${configName}To`;
    return {
      [from]: value.length === 2 ? value[0] : null,
      [to]: value.length === 2 ? value[1] : null
    };
  }
  // Ignore - already handled above
  if (name.slice(-2) === 'To') {
    return {};
  }

  // Actual query name is specified on the config
  let queryName;
  if (CONFIG[name] && CONFIG[name].name) {
    queryName = CONFIG[name].name;
  } else {
    // auto detection
    queryName = `filter[${_.snakeCase(name)}]`;
  }

  if (!queryParams.has(queryName)) {
    return {};
  }

  let value = queryParams.get(queryName);
  const dataType = CONFIG[name] && CONFIG[name].type;
  switch (dataType) {
    case 'array':
      value = value.split(',');
      break;
    default:
      if (['true', 'false'].indexOf(value) !== -1) {
        value = value === 'true';
      }
  }

  if (CONFIG[name] && CONFIG[name].parse) {
    value = CONFIG[name].parse(value);
  }

  if (CONFIG[name] && CONFIG[name].parseReturn) {
    return CONFIG[name].parseReturn(value);
  }

  return { [name]: value };
};

export default urlQueryParams => {
  if (!urlQueryParams) {
    return {};
  }
  const queryParams = new URLSearchParams(urlQueryParams);
  let overrideParams = {};
  Object.keys({ ...initialState.globalParams, sort: null }).forEach(name => {
    const value = getValue(queryParams, name);
    overrideParams = {
      ...overrideParams,
      ...value
    };
  });

  return overrideParams;
};
