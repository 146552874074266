import { createSelector } from 'reselect';

const currentUser = state => state.auth.user;
const currentOrganization = state => state.auth.currentOrganization;

export default createSelector([currentUser, currentOrganization], (user, organization) => {
  if (!organization) {
    return null;
  }
  const foundOrganization =
    user.organizations && user.organizations.find(org => org.id === organization.id);
  if (!foundOrganization || !foundOrganization.pivot || !foundOrganization.pivot.role) {
    return null;
  }
  return foundOrganization.pivot.role.name;
});
