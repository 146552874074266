import CardHeader from '@material-ui/core/CardHeader';
import { Divider } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import React from 'react';
import PropTypes from 'prop-types';
import { UTCDateTimeToLocalTime } from '../../helpers';

const ListPaymentAttempts = ({ paymentAttempts }) => {
  return (
    <Card>
      <CardHeader title="Payment Attempts" titleTypographyProps={{ variant: 'h6' }} />
      <Divider />
      <CardContent>
        {paymentAttempts.length === 0 && (
          <Typography variant="body2">No payment attempt yet.</Typography>
        )}
        <List>
          {paymentAttempts.map(attempt => (
            <ListItem key={attempt.id}>
              <ListItemText primary={attempt.status} secondary={attempt.message} />
              <Typography variant="body2">{UTCDateTimeToLocalTime(attempt.created_at)}</Typography>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

ListPaymentAttempts.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  paymentAttempts: PropTypes.array.isRequired
};

ListPaymentAttempts.defaultProps = {};

export default ListPaymentAttempts;
