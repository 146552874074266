import { Grid, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import './styles.css';
import { Div } from 'react-images/lib/primitives';
import { get, remove } from 'lodash';
import {
  FORM_ELEMENTS,
  LAYOUT_DIVIDER,
  LAYOUT_SECTION,
  TYPE_CONTROL,
  TYPE_LAYOUT,
  randomId,
  EVENT_ADD_LAYOUT_ELEMENT,
  EVENT_ADD_COLUMN,
  LAYOUT_COLUMN,
  EVENT_DELETE_LAYOUT_ELEMENT,
  EVENT_DELETE_COLUMN,
  EVENT_ADD_CONTROL,
  EVENT_DELETE_CONTROL,
  EVENT_UPDATE_CONTROL,
  EVENT_MOVE_CONTROL,
  // EXISTING_RX_FIELDS,
  CONTROL_EXISTING,
  EXISTING_RX_FIELDS,
  EVENT_MOVE_LAYOUT
} from './helpers';
import Element from './components/Element';
import BuilderPage from './components/BuilderPage';
import FormSection from './controls/Section';
import Divider from './controls/Divider';
import { ControlContext } from '../contexts';
import ControlSettingsDialog from './components/ControlSettingsDialog';

export const FormBuilderLayoutFactory = ({ element, onDelete, onUpdate, isPreview = false }) => {
  const options = {
    element,
    onDelete,
    onUpdate,
    isPreview
  };

  if (element.system_type === LAYOUT_SECTION) {
    return <FormSection key={element.id} {...options} />;
  }
  if (element.system_type === LAYOUT_DIVIDER) {
    return <Divider key={element.id} {...options} />;
  }
  return (
    <div key={element.id} className="fb-element">
      {element.system_type}
    </div>
  );
};

const renderDraggableElements = ({ onElClick, existingFields }) => {
  return (
    <Div className="fb-fields">
      <Typography variant="h6" className="fb-title">
        Elements
      </Typography>
      {FORM_ELEMENTS[TYPE_CONTROL].map((el, index) => {
        return <Element onClick={onElClick} key={index} el={{ ...el, isNew: true }} />;
      })}

      <Typography variant="h6" className="fb-title">
        Layout
      </Typography>
      {FORM_ELEMENTS[TYPE_LAYOUT].map((el, index) => {
        return <Element onClick={onElClick} key={index} el={{ ...el, isNew: true }} />;
      })}

      <Typography variant="h6" className="fb-title">
        Original Fields
      </Typography>
      {existingFields.map(el => {
        const options = {
          id: el.id,
          isNew: true,
          name: EXISTING_RX_FIELDS[el.system_name],
          draggableType: TYPE_CONTROL,
          system_type: CONTROL_EXISTING
        };

        return <Element onClick={onElClick} key={el.system_name} el={options} />;
      })}
    </Div>
  );
};

const getControlsInLayout = layout => {
  const controls = [];

  for (const section of layout) {
    for (const column of section.children) {
      for (const control of column.children) {
        controls.push(control);
      }
    }
  }

  return controls;
};

const retrieveUnusedNativeFields = (formData, defaultControls) => {
  const controls = getControlsInLayout(formData);
  return defaultControls.filter(control => {
    return control && !controls.find(c => c.system_name === control.system_name);
  });
};

const FormBuilderForm = ({ onChange, layout, defaultControls }) => {
  const [formData, setFormData] = useState(layout);
  const [showSettings, setShowSettings] = useState(false);
  const [settingsElement, setSettingsElement] = useState(null);

  useEffect(() => {
    onChange(formData);
  }, [formData, onChange]);

  const deleteLayoutElement = options => {
    setFormData(current => current.filter(item => item.id !== options.id));
  };

  const deleteColumn = options => {
    setFormData(current => {
      return current.map(item => {
        if (item.id === options.sectionId) {
          const newChildren = item.children.filter(col => col.id !== options.id);
          return { ...item, children: newChildren };
        }

        return item;
      });
    });
  };

  const deleteControl = options => {
    setFormData(current => {
      return current.map(item => {
        if (item.id === options.sectionId) {
          const newChildren = item.children.map(col => {
            if (col.id === options.columnId) {
              const newControls = col.children.filter(control => control.id !== options.id);
              return { ...col, children: newControls };
            }

            return col;
          });

          return { ...item, children: newChildren };
        }

        return item;
      });
    });
  };

  const onDelete = options => {
    if (options.event === EVENT_DELETE_LAYOUT_ELEMENT) {
      deleteLayoutElement(options);
    } else if (options.event === EVENT_DELETE_COLUMN) {
      deleteColumn(options);
    } else if (options.event === EVENT_DELETE_CONTROL) {
      deleteControl(options);
    }
  };

  /**
   * Method to add elements starts here
   */
  const addLayoutElement = useCallback(({ system_type }) => {
    const id = randomId(system_type);

    setFormData(current => [...current, { system_type, id, children: [] }]);

    if (system_type === LAYOUT_SECTION) {
      addSectionColumn({ sectionId: id });
    }
  }, []);

  const addSectionColumn = ({ sectionId }) => {
    setFormData(current => {
      return current.map(item => {
        if (item.id === sectionId) {
          const newChildren = [
            ...item.children,
            { system_type: LAYOUT_COLUMN, id: randomId(LAYOUT_COLUMN), children: [] }
          ];

          return { ...item, children: newChildren };
        }

        return item;
      });
    });
  };

  const addControl = options => {
    const { sectionId, columnId, system_type, id } = options;

    let formControl = {
      id: id || randomId(system_type),
      system_type,
      sectionId,
      columnId,
      temporary: true
    };

    const fields = retrieveUnusedNativeFields(formData, defaultControls).filter(
      con => con.system_type === CONTROL_EXISTING
    );

    if (system_type === CONTROL_EXISTING) {
      formControl = {
        ...fields.find(field => field.id === id)
      };
    }
    setFormData(current => {
      return current.map(item => {
        if (item.id === sectionId) {
          const newChildren = item.children.map(col => {
            if (col.id === columnId) {
              const newControls = [...col.children, formControl];

              return { ...col, children: newControls };
            }

            return col;
          });

          return { ...item, children: newChildren };
        }

        return item;
      });
    });

    openSettings(formControl);
  };

  const updateControl = ({ sectionId, columnId, id, payload }) => {
    setFormData(current => {
      return current.map(item => {
        if (item.id === sectionId) {
          const newChildren = item.children.map(col => {
            if (col.id === columnId) {
              const newControls = col.children.map(control => {
                if (control.id === id) {
                  return { ...control, ...payload };
                }

                return control;
              });

              return { ...col, children: newControls };
            }

            return col;
          });

          return { ...item, children: newChildren };
        }

        return item;
      });
    });
  };

  const moveControl = options => {
    let controlData = null;

    const { src, dst } = options;
    setFormData(current => {
      let newForm = [];

      if (src.isNew) {
        newForm = [...current];

        if (src.system_type === CONTROL_EXISTING) {
          controlData = { ...defaultControls.find(control => control.id === src.itemId) };
        } else {
          controlData = {
            id: src.itemId || randomId(src.system_type),
            system_type: src.system_type,
            sectionId: dst.sectionId,
            columnId: dst.columnId,
            temporary: !src.itemId
          };

          if (src.label) {
            controlData.props = { label: src.label };
          }
        }
      } else {
        newForm = current.map(sectionData => {
          if (sectionData.id === src.sectionId) {
            const newChildren = sectionData.children.map(columnData => {
              if (columnData.id === src.columnId) {
                const newControls = columnData.children.filter(control => {
                  if (control.id === src.itemId) {
                    controlData = { ...control };

                    return false;
                  }

                  return true;
                });

                return { ...columnData, children: newControls };
              }

              return columnData;
            });

            return { ...sectionData, children: newChildren };
          }

          return sectionData;
        });
      }

      newForm = newForm.map(sectionData => {
        if (sectionData.id === dst.sectionId) {
          const newChildren = sectionData.children.map(columnData => {
            if (columnData.id === dst.columnId) {
              let newControls = [];

              if (dst.itemId === null) {
                newControls = [...columnData.children, controlData];
              } else {
                // find index of the control in dst.itemId
                const index = columnData.children.findIndex(control => control.id === dst.itemId);
                // insert controlData at index
                newControls = [
                  ...columnData.children.slice(0, index),
                  controlData,
                  ...columnData.children.slice(index)
                ];
              }

              return { ...columnData, children: newControls };
            }

            return columnData;
          });

          return { ...sectionData, children: newChildren };
        }

        return sectionData;
      });

      return newForm;
    });

    if (src.isNew) {
      openSettings(controlData);
    }
  };

  const moveSection = options => {
    setFormData(current => {
      let section = null;
      let newForm = [...current];
      if (!options.src.id) {
        section = {
          system_type: options.src.system_type,
          id: randomId(options.src.system_type),
          children: []
        };

        // add column to section
        if (section.system_type === LAYOUT_SECTION) {
          section.children = [
            { system_type: LAYOUT_COLUMN, id: randomId(LAYOUT_COLUMN), children: [] }
          ];
        }
      } else {
        [section] = remove(newForm, item => item.id === options.src.id);
      }

      if (options.dst) {
        const index = newForm.findIndex(item => item.id === options.dst.id);
        newForm = [...newForm.slice(0, index), section, ...newForm.slice(index)];
      } else {
        newForm.push(section);
      }
      return newForm;
    });
  };

  const onUpdate = options => {
    if (options.event === EVENT_ADD_LAYOUT_ELEMENT) {
      addLayoutElement(options);
    } else if (options.event === EVENT_ADD_COLUMN) {
      addSectionColumn(options);
    } else if (options.event === EVENT_ADD_CONTROL) {
      addControl(options);
    } else if (options.event === EVENT_UPDATE_CONTROL) {
      updateControl(options);
    } else if (options.event === EVENT_MOVE_CONTROL) {
      moveControl(options);
    } else if (options.event === EVENT_MOVE_LAYOUT) {
      moveSection(options);
    }
  };

  const openSettings = element => {
    if (get(element, 'system_type') === CONTROL_EXISTING) return;

    setSettingsElement(curr => element);
    setShowSettings(curr => !!element);
  };

  const onElClick = system_type => {
    if (system_type === LAYOUT_SECTION || system_type === LAYOUT_DIVIDER) {
      addLayoutElement({ system_type });
    }
  };

  return (
    <>
      <ControlContext.Provider value={{ onDelete, onUpdate, openSettings }}>
        <Grid container spacing={0} className="fb-main">
          <Grid item xs={3}>
            {renderDraggableElements({
              onElClick,
              existingFields: retrieveUnusedNativeFields(formData, defaultControls)
            })}
          </Grid>

          <Grid item xs={9}>
            <Div className="fb-builder">
              <Typography variant="h6" className="fb-title">
                Drag items onto this area
              </Typography>

              <div>
                <BuilderPage
                  moveSection={moveSection}
                  addLayout={({ system_type }) =>
                    onUpdate({
                      event: EVENT_ADD_LAYOUT_ELEMENT,
                      system_type
                    })
                  }
                >
                  {formData.length === 0 ? (
                    <div className="is-empty">Drop layout items here</div>
                  ) : (
                    formData.map(element => {
                      const props = { element, onDelete, onUpdate };

                      return <FormBuilderLayoutFactory key={element.id} {...props} />;
                    })
                  )}
                </BuilderPage>
              </div>
            </Div>
          </Grid>
        </Grid>

        <ControlSettingsDialog
          open={showSettings}
          element={settingsElement}
          onUpdate={onUpdate}
          onDelete={onDelete}
          onClose={() => openSettings(null)}
        />
      </ControlContext.Provider>
    </>
  );
};

export default FormBuilderForm;
