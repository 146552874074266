import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import InstallersApiService from '../services/api/installers';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_INSTALLERS_PREFIX = 'FETCH_INSTALLERS';
export const FETCH_INSTALLERS_REQUEST_ACTION = `${FETCH_INSTALLERS_PREFIX}_REQUEST_ACTION`;
export const FETCH_INSTALLERS_SUCCESS_ACTION = `${FETCH_INSTALLERS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_INSTALLERS_FAILURE_ACTION = `${FETCH_INSTALLERS_PREFIX}_FAILURE_ACTION`;

export const CREATE_INSTALLER_PREFIX = 'CREATE_INSTALLER';
export const CREATE_INSTALLER_REQUEST_ACTION = `${CREATE_INSTALLER_PREFIX}_REQUEST_ACTION`;
export const CREATE_INSTALLER_SUCCESS_ACTION = `${CREATE_INSTALLER_PREFIX}_SUCCESS_ACTION`;
export const CREATE_INSTALLER_FAILURE_ACTION = `${CREATE_INSTALLER_PREFIX}_FAILURE_ACTION`;

export const UPDATE_INSTALLER_PREFIX = 'UPDATE_INSTALLER';
export const UPDATE_INSTALLER_REQUEST_ACTION = `${UPDATE_INSTALLER_PREFIX}_REQUEST_ACTION`;
export const UPDATE_INSTALLER_SUCCESS_ACTION = `${UPDATE_INSTALLER_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_INSTALLER_FAILURE_ACTION = `${UPDATE_INSTALLER_PREFIX}_FAILURE_ACTION`;

export const DELETE_INSTALLER_PREFIX = 'DELETE_INSTALLER';
export const DELETE_INSTALLER_REQUEST_ACTION = `${DELETE_INSTALLER_PREFIX}_REQUEST_ACTION`;
export const DELETE_INSTALLER_SUCCESS_ACTION = `${DELETE_INSTALLER_PREFIX}_SUCCESS_ACTION`;
export const DELETE_INSTALLER_FAILURE_ACTION = `${DELETE_INSTALLER_PREFIX}_FAILURE_ACTION`;

export const FETCH_INSTALLER_PREFIX = 'FETCH_INSTALLER';
export const FETCH_INSTALLER_REQUEST_ACTION = `${FETCH_INSTALLER_PREFIX}_REQUEST_ACTION`;
export const FETCH_INSTALLER_SUCCESS_ACTION = `${FETCH_INSTALLER_PREFIX}_SUCCESS_ACTION`;
export const FETCH_INSTALLER_FAILURE_ACTION = `${FETCH_INSTALLER_PREFIX}_FAILURE_ACTION`;

export const UPDATE_INSTALLERS_PROGRESS_ACTION = 'UPDATE_INSTALLERS_PROGRESS_ACTION';

export const FETCH_AUTO_UPDATE_SETTING = 'FETCH_AUTO_UPDATE_SETTING';
export const SET_AUTO_UPDATE_SETTING = 'SET_AUTO_UPDATE';

export const MACHINE_LEARNING_FILE_PREFIX = 'MACHINE_LEARNING_FILE';
export const MACHINE_LEARNING_FILE_UPLOADING = `${MACHINE_LEARNING_FILE_PREFIX}_UPLOADING`;
export const MACHINE_LEARNING_FILE_UPLOADED = `${MACHINE_LEARNING_FILE_PREFIX}_UPLOADED`;
export const MACHINE_LEARNING_FILE_REQUESTING_DOWNLOAD_URL = `${MACHINE_LEARNING_FILE_PREFIX}_REQUEST_DOWNLOAD_URL`;
export const MACHINE_LEARNING_FILE_REQUESTED_DOWNLOAD_URL = `${MACHINE_LEARNING_FILE_PREFIX}_REQUESTED_DOWNLOAD_URL`;

// INITIAL STATE ///////////////////////////////////////////////////////////////

export const organizationInitialState = {
  fetchingAutoUpdateSetting: false,
  fetchedAutoUpdateSetting: false,
  autoUpdate: true,
  items: [],
  uploadProgress: 0,
  fetching: false,
  fetched: false
};

const initialState = {
  fetchingAutoUpdateSetting: false,
  fetchedAutoUpdateSetting: false,
  autoUpdate: true,
  items: [],
  uploadProgress: 0,
  fetching: false,
  fetched: false,
  machineLearningFile: {
    uploading: false,
    downloadUrl: null
  },
  organizations: {}
};

const updateState = (state, newState, organizationId = null) => {
  if (organizationId) {
    const organizationState = state.organizations[organizationId] || organizationInitialState;
    return {
      ...state,
      organizations: {
        ...state.organizations,
        [organizationId]: {
          ...organizationState,
          ...newState
        }
      }
    };
  }
  return {
    ...state,
    ...newState
  };
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  const { organizationId } = action;
  const targetState = organizationId
    ? state.organizations[organizationId] || organizationInitialState
    : state;

  switch (action.type) {
    case UPDATE_INSTALLERS_PROGRESS_ACTION:
      return updateState(state, { uploadProgress: action.payload.uploadProgress }, organizationId);
    case FETCH_AUTO_UPDATE_SETTING:
      return updateState(
        state,
        { fetchingAutoUpdateSetting: true, fetchedAutoUpdateSetting: false },
        organizationId
      );
    case SET_AUTO_UPDATE_SETTING: {
      return updateState(
        state,
        {
          autoUpdate: action.payload,
          fetchingAutoUpdateSetting: false,
          fetchedAutoUpdateSetting: true
        },
        organizationId
      );
    }
    case FETCH_INSTALLERS_REQUEST_ACTION:
      return updateState(state, { fetching: true, fetched: false }, organizationId);
    case FETCH_INSTALLERS_SUCCESS_ACTION:
      return updateState(
        state,
        {
          items: action.payload.installers,
          fetched: true,
          fetching: false
        },
        organizationId
      );
    case CREATE_INSTALLER_SUCCESS_ACTION:
      return updateState(
        state,
        {
          items: [...targetState.items, action.payload]
        },
        organizationId
      );
    case UPDATE_INSTALLER_SUCCESS_ACTION:
      return updateState(
        state,
        {
          items: targetState.items.map(item => {
            if (item.id === action.payload.id) {
              return action.payload;
            }
            return item;
          })
        },
        organizationId
      );

    case DELETE_INSTALLER_SUCCESS_ACTION: {
      return updateState(
        state,
        {
          items: targetState.items.filter(item => item.id !== action.payload.id)
        },
        organizationId
      );
    }
    case MACHINE_LEARNING_FILE_UPLOADING: {
      return produce(state, draft => {
        draft.machineLearningFile.uploading = true;
        draft.machineLearningFile.downloadUrl = null;
      });
    }
    case MACHINE_LEARNING_FILE_UPLOADED: {
      return produce(state, draft => {
        draft.machineLearningFile.uploading = false;
        draft.machineLearningFile.downloadUrl = action.payload.url;
      });
    }
    case MACHINE_LEARNING_FILE_REQUESTING_DOWNLOAD_URL: {
      return produce(state, draft => {
        draft.machineLearningFile.downloadUrl = null;
      });
    }
    case MACHINE_LEARNING_FILE_REQUESTED_DOWNLOAD_URL: {
      return produce(state, draft => {
        draft.machineLearningFile.downloadUrl = action.payload.download_url;
      });
    }
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchAutoUpdateSettingAction(organizationId = null) {
  return dispatch => {
    const installersApiService = new InstallersApiService();
    dispatch({
      type: FETCH_AUTO_UPDATE_SETTING,
      organizationId
    });
    return installersApiService
      .fetchAutoUpdate(organizationId)
      .then(response => {
        dispatch({
          type: SET_AUTO_UPDATE_SETTING,
          payload: response.data.value,
          organizationId
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function setAutoUpdateSettingAction(value, organizationId = null) {
  return dispatch => {
    const installersApiService = new InstallersApiService();
    return installersApiService
      .setAutoUpdate(value, organizationId)
      .then(response => {
        dispatch({
          type: SET_AUTO_UPDATE_SETTING,
          payload: value,
          organizationId
        });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchInstallersAction(params = {}, organizationId = null) {
  return dispatch => {
    dispatch({ type: FETCH_INSTALLERS_REQUEST_ACTION, organizationId });
    const installersApiService = new InstallersApiService();
    return installersApiService
      .get(params, organizationId)
      .then(response => {
        dispatch({
          type: FETCH_INSTALLERS_SUCCESS_ACTION,
          payload: {
            installers: response.installers
          },
          organizationId
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_INSTALLERS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
export function createInstallerAction(params, onUploadProgress, organizationId = null) {
  return dispatch => {
    dispatch({ type: CREATE_INSTALLER_REQUEST_ACTION });
    const installersApiService = new InstallersApiService();
    return installersApiService
      .create(params, onUploadProgress, organizationId)
      .then(response => {
        dispatch({
          type: CREATE_INSTALLER_SUCCESS_ACTION,
          payload: response.installer,
          organizationId
        });
        dispatch(enqueueNotification('success', response.message));
        return response.installer;
      })
      .catch(error => {
        dispatch({ type: CREATE_INSTALLER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      })
      .finally(() => {
        dispatch({
          type: UPDATE_INSTALLERS_PROGRESS_ACTION,
          payload: {
            uploadProgress: 0
          }
        });
      });
  };
}

export function updateInstallerAction(id, params) {
  return dispatch => {
    dispatch({ type: UPDATE_INSTALLER_REQUEST_ACTION });
    const installersApiService = new InstallersApiService();
    return installersApiService
      .updateReleaseNotes(id, params)
      .then(response => {
        dispatch({
          type: UPDATE_INSTALLER_SUCCESS_ACTION,
          payload: response.installer
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: UPDATE_INSTALLER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
        return false;
      });
  };
}

export function deleteInstallerAction(id, organizationId = null) {
  return dispatch => {
    dispatch({ type: DELETE_INSTALLER_REQUEST_ACTION });
    const installersApiService = new InstallersApiService();
    return installersApiService
      .delete(id)
      .then(response => {
        dispatch({
          type: DELETE_INSTALLER_SUCCESS_ACTION,
          payload: { id },
          organizationId
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: DELETE_INSTALLER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateInstallerProgressAction(uploadProgress) {
  return dispatch => {
    dispatch({
      type: UPDATE_INSTALLERS_PROGRESS_ACTION,
      payload: {
        uploadProgress
      }
    });
  };
}

export function uploadMachineLearningFile(file) {
  return dispatch => {
    dispatch({ type: MACHINE_LEARNING_FILE_UPLOADING });
    const installersApiService = new InstallersApiService();
    return installersApiService
      .uploadMachineLearningFile(file)
      .then(response => {
        dispatch({
          type: MACHINE_LEARNING_FILE_UPLOADED,
          payload: response
        });
        dispatch(enqueueNotification('success', 'File successfully uploaded.'));
        return response;
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function requestMachineLearningFileDownloadUrl() {
  return dispatch => {
    dispatch({ type: MACHINE_LEARNING_FILE_REQUESTING_DOWNLOAD_URL });
    const installersApiService = new InstallersApiService();
    return installersApiService
      .requestDownloadUrl()
      .then(response => {
        dispatch({
          type: MACHINE_LEARNING_FILE_REQUESTED_DOWNLOAD_URL,
          payload: response
        });
        return response;
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
