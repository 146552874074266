import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import _get from 'lodash/get';
import { useDispatch } from 'react-redux';
import Can from '../../../components/Can';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import { PatientFilesApiService } from '../../../services';
import { enqueueNotification } from '../../../reducers/notifications';
import {
  closeGlobalBackdropAction,
  openGlobalBackdropAction
} from '../../../reducers/globalBackdrop';

const ReprocessJobAction = ({ patientFile, onReprocessed }) => {
  const dispatch = useDispatch();
  const api = new PatientFilesApiService();

  const status = _get(patientFile, 'metadata.relu_request.status', null);
  const tag = _get(patientFile, 'tag', null);

  let confirmationText = 'Are you sure you want re-process this file?';

  if (status === 'success') {
    confirmationText = `${confirmationText} Generated output files will be deleted.`;
  }

  const handleConfirm = () => {
    dispatch(openGlobalBackdropAction());
    api
      .reprocessReluJob(patientFile.id)
      .then(({ message, data }) => {
        dispatch(enqueueNotification('success', message));
        onReprocessed(data);
      })
      .catch(error => {
        dispatch(enqueueNotification('error', _get(error, 'responseData.message')));
      })
      .finally(() => {
        dispatch(closeGlobalBackdropAction());
      });
  };

  if (['flagged', 'pending'].includes(status) || tag === 'relu') {
    return null;
  }

  return (
    <Can
      permissions={['patient-files.relu.reprocess-job']}
      yes={() => (
        <>
          <ConfirmationDialogOnClickWrapper
            confirmationBody={confirmationText}
            confirmationTitle="Re-process Job"
          >
            <IconButton onClick={handleConfirm}>
              <RefreshIcon />
            </IconButton>
          </ConfirmationDialogOnClickWrapper>
        </>
      )}
    />
  );
};

ReprocessJobAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patientFile: PropTypes.object.isRequired,
  onReprocessed: PropTypes.func
};

ReprocessJobAction.defaultProps = {
  onReprocessed: () => {}
};

export default ReprocessJobAction;
