import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { get } from 'lodash';
import { PropsPropType, RulesPropType } from '../propTypes';
import { createLabel, getInjectedFields } from './helpers';

const Dropdown = ({ id, field, props, preview, rules, isLive, form }) => {
  const { name, onChange, value } = getInjectedFields(field);

  if (!props) return <></>;

  const labelId = `${name}-label`;

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel style={{ background: 'white', padding: '0 10px' }} id={labelId}>
        {createLabel(props, rules)}
      </InputLabel>
      <Select labelId={labelId} name={name} onChange={onChange} disabled={preview} value={value}>
        {get(props, 'options', []).map((option, index) => (
          <MenuItem value={option.id} key={index}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

Dropdown.defaultProps = {
  preview: false
};

Dropdown.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  props: PropsPropType,
  rules: RulesPropType,
  preview: PropTypes.bool
};

export default Dropdown;
