import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

// ACTION_TYPES ////////////////////////////////////////////////////////////////
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  items: []
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        items: [
          ...state.items,
          {
            key: action.key,
            ...action.notification
          }
        ]
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        items: state.items.map(notification =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        items: state.items.filter(notification => notification.key !== action.key)
      };

    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export const enqueueSnackbar = notification => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random()
    }
  };
};

export const closeSnackbar = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key
});

export const enqueueNotification = (variant, message, options = {}, action = {}) => {
  return dispatch => {
    let otherOptions = {};

    if (!isEmpty(action)) {
      const { text, link, target, ...otherProp } = {
        text: 'Action',
        link: '#',
        target: '_self',
        ...action
      };
      otherOptions = {
        action: snackbarKey => {
          return (
            <>
              <Button
                target={target}
                href={link}
                size="small"
                variant="contained"
                color="primary"
                {...otherProp}
              >
                {text}
              </Button>
              <IconButton onClick={() => dispatch(closeSnackbar(snackbarKey))}>
                <CloseIcon />
              </IconButton>
            </>
          );
        },
        autoHideDuration: 15000
      };
    }

    return dispatch(
      enqueueSnackbar({
        message,
        options: {
          variant,
          ...otherOptions,
          ...options
        }
      })
    );
  };
};

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key
});
