import {
  ASSIGNMENT_LABELS,
  ASSIGNMENTS,
  DIBS_OPTIONS,
  DIBS_OPTIONS_LABELS
} from '../../../../components/RxForm/constants';

const { ORTHOSELECT, THIRD_PARTY, IN_OFFICE } = ASSIGNMENTS;

export const generateAssignmentOptions = thirdPartyName => {
  const options = [
    {
      value: [ORTHOSELECT],
      label: `Always done by ${ASSIGNMENT_LABELS[ORTHOSELECT]}`
    },
    { value: [THIRD_PARTY], label: `Always done by ${thirdPartyName}` },
    {
      value: [ORTHOSELECT, THIRD_PARTY],
      label: `Choose ${ASSIGNMENT_LABELS[ORTHOSELECT]} or ${thirdPartyName}`
    },
    {
      value: [ORTHOSELECT, IN_OFFICE],
      label: `Choose ${ASSIGNMENT_LABELS[ORTHOSELECT]} or ${ASSIGNMENT_LABELS[IN_OFFICE]}`
    },
    {
      value: [IN_OFFICE, THIRD_PARTY],
      label: `Choose ${thirdPartyName} or ${ASSIGNMENT_LABELS[IN_OFFICE]}`
    },
    {
      value: [ORTHOSELECT, IN_OFFICE, THIRD_PARTY],
      label: `Choose ${ASSIGNMENT_LABELS[ORTHOSELECT]}, ${thirdPartyName} or ${ASSIGNMENT_LABELS[IN_OFFICE]}`
    }
  ];
  if (!thirdPartyName) {
    return options.filter(({ value }) => value.indexOf(3) === -1);
  }
  return options;
};

const { DIBS_DESKTOP, DIBS_ONLINE } = DIBS_OPTIONS;

export const generateAllowedDIBSOptions = () => {
  return [
    {
      value: [DIBS_ONLINE],
      label: DIBS_OPTIONS_LABELS[DIBS_ONLINE]
    },
    {
      value: [DIBS_DESKTOP],
      label: DIBS_OPTIONS_LABELS[DIBS_DESKTOP]
    },
    {
      value: [DIBS_DESKTOP, DIBS_ONLINE],
      label: 'All the options'
    }
  ];
};
