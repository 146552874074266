import moment from 'moment';
import { chunk, intersection, map, get, isPlainObject } from 'lodash';
import { PATIENT_FILES_IDS, PATIENT_STATUSES_VALUE } from '../constants';

export const replaceNullToEmpty = (object, except = []) => {
  const clonedObject = { ...object };
  Object.keys(clonedObject).map(key => {
    if (clonedObject[key] === null && except.indexOf(key) === -1) {
      clonedObject[key] = '';
    }
    return clonedObject[key];
  });

  return clonedObject;
};

/* eslint-disable */
export function debounce(a, b, c) {
  var d, e;
  return function() {
    function h() {
      (d = null), c || (e = a.apply(f, g));
    }

    var f = this,
      g = arguments;
    return clearTimeout(d), (d = setTimeout(h, b)), c && !d && (e = a.apply(f, g)), e;
  };
}

/* eslint-enable */

export const UTCDateTimeToLocalTime = UTCDateTime => {
  if (!UTCDateTime) {
    return '-';
  }

  return moment(`${UTCDateTime}Z`).format('YYYY-MM-DD HH:mm:ss');
};

export const UTCDateTimeToLocalDateTimeReadable = UTCDateTime => {
  if (!UTCDateTime) {
    return '-';
  }

  return moment(`${UTCDateTime}Z`).format('LLL');
};

export const UTCDateTimeToLocalDate = UTCDateTime => {
  if (!UTCDateTime) {
    return '-';
  }

  return moment(`${UTCDateTime}Z`).format('YYYY-MM-DD');
};

export const UTCDateTimeToLocalTimeOnly = UTCDateTime => {
  if (!UTCDateTime) {
    return '-';
  }

  return moment(`${UTCDateTime}Z`).format('HH:mm:ss');
};

export const dateTimeToUTC = localTime => {
  return moment(localTime).utc();
};

export const localDateTimeToUTC = localTime => {
  return moment(localTime)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss');
};

export const getDateDifferenceFromNow = date => {
  return moment
    .duration(moment(moment.now()).diff(moment(UTCDateTimeToLocalTime(date))))
    .humanize();
};

export const formatAccountNumber = accountNumber => {
  if (!accountNumber) {
    return '';
  }

  return chunk(accountNumber, 3)
    .map(item => item.join(''))
    .join('-');
};

export const replaceUnderscoreWithSpace = value => {
  if (!value) {
    return '';
  }

  return value.replace('_', ' ');
};

export const formatCurrency = value => {
  return `$${Number(value).toFixed(2)}`;
};

export const sortByDate = (myArray, dateKey = 'date') => {
  return myArray.slice().sort((a, b) => {
    return new Date(b[dateKey]) - new Date(a[dateKey]);
  });
};

export const findRoles = (requiredRoles, userRoles) => {
  return intersection(requiredRoles, map(userRoles, 'name')).length !== 0;
};

export const findRolesInUser = (requiredRoles, currentUser) => {
  return findRoles(requiredRoles, currentUser.roles);
};

export const findRolesInOrganization = (requiredRoles, organization) => {
  return findRoles(requiredRoles, get(organization, 'pivot.roles', []));
};

export const isSuperAdmin = user => findRolesInUser(['super_admin'], user);
export const isAdmin = user => findRolesInUser(['admin'], user);
export const isTechnician = user => findRolesInUser(['tech_support'], user);
export const isDoctor = user => findRolesInUser(['doctor'], user);
export const isLabPartner = user => findRolesInUser(['lab_partner'], user);
export const isTechnicianOrAdmin = user =>
  findRolesInUser(['tech_support', 'supervisor', 'admin', 'super_admin'], user);
export const isSuperAdminOrAdmin = user => findRolesInUser(['admin', 'super_admin'], user);

export const removeUnderscoreAndCapitalizeFirstLetter = value =>
  value
    .toLowerCase()
    .split('_')
    .map(it => it.charAt(0).toUpperCase() + it.slice(1))
    .join(' ');

export const getColorByStatus = status => {
  if (!status) {
    return 'green';
  }

  const lowerCaseStatus = status
    .toString()
    .toLowerCase()
    .replace(/ /g, '_');

  switch (true) {
    case lowerCaseStatus === 'diy':
      return 'orange';
    case lowerCaseStatus === 'duplicated':
      return 'red';
    case lowerCaseStatus === 'new':
      return 'grey';
    case lowerCaseStatus === 'pending':
      return 'purple';
    case lowerCaseStatus === 'in_progress':
      return 'blue';
    case lowerCaseStatus === 'canceled':
      return 'darkRed';
    case lowerCaseStatus === 'completed':
    default:
      return 'green';
  }
};

export const filterFilesByType = (patientFiles, fileTypeId) => {
  if (!patientFiles) {
    return [];
  }

  return patientFiles.filter(patientFile => patientFile.patient_file_type_id === fileTypeId);
};

export const encodeURIComponentArray = data => {
  return data.map(item => encodeURIComponent(item));
};

// eslint-disable-next-line camelcase
export const extractPatientLastFile = ({ patient_files }) => {
  // eslint-disable-next-line camelcase
  if (!patient_files) {
    return null;
  }

  return sortByDate(
    patient_files.filter(file => file.patient_file_type_id === PATIENT_FILES_IDS.ios),
    'updated_at'
  )[0];
};

export const getDateRange = selectedValue => {
  let from;
  let to;
  switch (selectedValue) {
    case 'today':
      from = moment().startOf('day');
      to = moment().endOf('day');
      break;

    case 'yesterday':
      from = moment()
        .startOf('day')
        .subtract('1', 'days');
      to = moment()
        .endOf('day')
        .subtract('1', 'days');
      break;

    case 'this_week':
      from = moment().startOf('isoWeek');
      to = moment().endOf('isoWeek');
      break;

    case 'last_week':
      from = moment()
        .startOf('isoWeek')
        .subtract('1', 'weeks');

      to = moment()
        .endOf('isoWeek')
        .subtract('1', 'weeks');
      break;

    case 'this_month':
      from = moment().startOf('month');
      to = moment().endOf('month');
      break;

    case 'last_month':
      from = moment()
        .startOf('month')
        .subtract('1', 'month');
      to = moment()
        .subtract('1', 'month')
        .endOf('month');
      break;

    case 'day_to_month':
      from = moment()
        .startOf('day')
        .subtract('1', 'month');
      to = moment().endOf('day');
      break;

    // All Time Statistics
    case 'all_time':
    default:
      from = '';
      to = '';
      break;
  }

  return [from, to];
};

export const generateDateRanges = () => {
  return {
    [`${getDateRange('today').join('~')}`]: 'today',
    [`${getDateRange('yesterday').join('~')}`]: 'yesterday',
    [`${getDateRange('this_week').join('~')}`]: 'this_week',
    [`${getDateRange('last_week').join('~')}`]: 'last_week',
    [`${getDateRange('this_month').join('~')}`]: 'this_month',
    [`${getDateRange('last_month').join('~')}`]: 'last_month',
    [`${getDateRange('day_to_month').join('~')}`]: 'day_to_month',
    [`${getDateRange('all_time').join('~')}`]: 'all_time',
    [`${getDateRange('custom').join('~')}`]: 'custom'
  };
};

export const getRangeTextFromDates = dates => {
  if (!dates || dates.length < 2) return 'all_time';
  const from = dates[0].startOf('day');
  const to = dates[1].endOf('day');
  const range = [from, to].join('~');
  const dateRanges = generateDateRanges();
  return dateRanges[range] ? dateRanges[range] : 'custom';
};

export const serializeDate = (date, dateFormat = 'YYYY-MM-DD HH:mm:ss') => {
  if (!date) return '';

  return moment.isMoment(date) ? date.format(dateFormat) : date;
};

export const getShowPatientRedirectURL = (user, patientId) => {
  return isTechnicianOrAdmin(user)
    ? `/patient-workflow/${patientId}`
    : `/patient-details/${patientId}`;
};

export const getPatientsHomeRedirectURL = user => {
  return isTechnicianOrAdmin(user) ? '/patients-page' : '/patients';
};

export const isManagementProfile = () => {
  return localStorage.getItem('is_management') === 'true';
};

export const generateUniqueKey = (salt = 1) => {
  return `${Date.now() * Math.floor(Math.random() * 100) * salt}`;
};

export const camelCaseToSnakeCase = text => {
  return text
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
};

export const convertArrayToObject = (items, key, value) => {
  return items.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item[value]
    };
  }, {});
};

export const objectHasProperty = (object, property) => {
  if (!isPlainObject(object)) return false;

  return Object.prototype.hasOwnProperty.call(object, property);
};

export const isCompletedPatient = patient => {
  return (
    patient &&
    (patient.patient_status_id === PATIENT_STATUSES_VALUE.COMPLETED ||
      (patient.patient_status && patient.patient_status.id === PATIENT_STATUSES_VALUE.COMPLETED))
  );
};

export const isImage = name => {
  return !!name.match(/\.(jpg|jpeg|png|gif)$/i);
};
