import { produce } from 'immer';
import OrganizationsApiService from '../services/api/organizations';
import { enqueueNotification } from './notifications';

const FETCHING_RXFORM_PREFERENCE = 'FETCHING_RXFORM_PREFERENCE';
const FETCHED_RXFORM_PREFERENCE = 'FETCHED_RXFORM_PREFERENCE';
const UPDATING_RXFORM_PREFERENCE = 'UPDATING_RXFORM_PREFERENCE';
const UPDATED_RXFORM_PREFERENCE = 'UPDATED_RXFORM_PREFERENCE';

const ACTIONS = [
  FETCHING_RXFORM_PREFERENCE,
  FETCHED_RXFORM_PREFERENCE,
  UPDATING_RXFORM_PREFERENCE,
  UPDATED_RXFORM_PREFERENCE
];

const initialState = {
  organizations: {}
};

export default (state = initialState, { type, payload }) => {
  if (ACTIONS.indexOf(type) === -1) {
    return state;
  }

  const organizationId = payload.organization_id;
  const organizationState = state.organizations[organizationId] || {
    fetching: false,
    preference: null,
    third_party_partner: null,
    shipping_methods: [],
    hidden_fields: [],
    metadata: null,
    update: false
  };

  switch (type) {
    case FETCHING_RXFORM_PREFERENCE:
      return produce(state, draft => {
        draft.organizations[organizationId] = {
          ...organizationState,
          fetching: true,
          preference: null,
          third_party_partner: null,
          shipping_methods: [],
          hidden_fields: [],
          metadata: null
        };
      });
    case FETCHED_RXFORM_PREFERENCE:
      return produce(state, draft => {
        draft.organizations[organizationId] = {
          ...organizationState,
          fetching: false,
          ...payload
        };
      });
    case UPDATING_RXFORM_PREFERENCE:
      return produce(state, draft => {
        draft.organizations[organizationId] = {
          ...organizationState,
          updating: true
        };
      });
    case UPDATED_RXFORM_PREFERENCE:
      return produce(state, draft => {
        draft.organizations[organizationId] = {
          ...organizationState,
          updating: false,
          ...payload
        };
      });
    default:
      return state;
  }
};

const responseToPayload = response => {
  const { data: preference, metadata } = response;
  const {
    third_party_partner: thirdPartyPartner,
    shipping_methods: shippingMethods,
    hidden_fields: hiddenFields
  } = metadata;
  return {
    preference,
    third_party_partner: thirdPartyPartner,
    shipping_methods: shippingMethods,
    hidden_fields: hiddenFields,
    metadata
  };
};

export function fetchRxFormPreference(organizationId) {
  return (dispatch, getState) => {
    const state = getState();
    if (
      state.rxFormPreferences.organizations[organizationId] &&
      state.rxFormPreferences.organizations[organizationId].fetching
    ) {
      return Promise.resolve();
    }
    dispatch({ type: FETCHING_RXFORM_PREFERENCE, payload: { organization_id: organizationId } });
    const api = new OrganizationsApiService();
    return api.getRxFormPreference(organizationId).then(response => {
      dispatch({
        type: FETCHED_RXFORM_PREFERENCE,
        payload: {
          organization_id: organizationId,
          ...responseToPayload(response)
        }
      });
    });
  };
}

export function updateRxFormPreference(organizationId, data) {
  return dispatch => {
    dispatch({ type: UPDATING_RXFORM_PREFERENCE, payload: { organization_id: organizationId } });
    const api = new OrganizationsApiService();
    return api
      .updateRxFormPreference(organizationId, data)
      .then(response => {
        dispatch(enqueueNotification('success', 'RxForm preference successfully saved.'));
        dispatch({
          type: UPDATED_RXFORM_PREFERENCE,
          payload: {
            organization_id: organizationId,
            ...responseToPayload(response)
          }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
