import PropTypes from 'prop-types';

export const FormPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired,
    structure: PropTypes.array.isRequired,
});

export const PropsPropType = PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    options: PropTypes.array,
    description: PropTypes.string,
    default: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    info_link: PropTypes.string,
    info_link_text: PropTypes.string,
});

export const RulesPropType = PropTypes.oneOfType([
    PropTypes.shape({
        required: PropTypes.bool,
    }),
    PropTypes.array
]);

export const ControlSettingsFormPropTypes = PropTypes.shape({
    props: PropsPropType.isRequired,
    rules: RulesPropType.isRequired,
});

export const ControlPropTypes = PropTypes.shape({
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    system_type: PropTypes.string.isRequired,
    props: PropsPropType,
    rules: RulesPropType,
});
