import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import { get, includes } from 'lodash';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useDispatch } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { Badge, Box, Divider, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreOptions from '../MoreOptions';
import AddDiscountToOrder from './AddDiscountToOrder';
import ConfirmationDialogOnClickWrapper from '../ConfirmationDialogOnClickWrapper';
import { removeDiscountFromOrderAction, removeChargeFromOrderAction } from '../../reducers/orders';
import Can from '../Can';
import MoneyFormat from '../MoneyFormat';
import OrdersApiService from '../../services/api/orders';
import { enqueueNotification } from '../../reducers/notifications';
import Template from './Template';

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: theme.spacing(1, 0)
  },
  listItemDiscountsCharges: {
    padding: theme.spacing(0, 0),
    '&> .MuiListItemText-multiline': {
      margin: theme.spacing(0, 0)
    }
  },
  total: {
    fontWeight: 700
  },
  listItemSubtitle: {
    fontWeight: 700,
    padding: theme.spacing(1, 0),
    fontSize: 'large'
  },
  options: {
    flexDirection: 'row'
  }
}));

const findStartupFee = order => {
  if (!order || !order.order_items) {
    return null;
  }
  return order.order_items.find(order => order.product && order.product.name === 'Start-up Fee');
};

const OrderSummary = ({
  order,
  title,
  displayDetailsOption,
  showMoreOptions,
  showRemoveDiscount,
  onReloadOrderAction,
  showDownload
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [detailed, setDetailed] = useState(false);
  const [openAddDiscountForm, setOpenAddDiscountForm] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const { monthly_fee: monthlyFee = null } = order.template_data || {};
  const { patients = [] } = order.template_data || {};
  const startupFee = findStartupFee(order);
  const hasCharges = get(order, 'charges', []).length > 0;

  const removeDiscount = (id, isPeriodic) => {
    if (isPeriodic) return;

    dispatch(removeDiscountFromOrderAction(order.id, id)).then(response => {
      if (response) {
        onReloadOrderAction();
      }
    });
  };

  const removeCharge = (id, isPeriodic) => {
    if (isPeriodic) return;

    dispatch(removeChargeFromOrderAction(order.id, id)).then(response => {
      if (response) {
        onReloadOrderAction();
      }
    });
  };

  const getFormattedDiscountOrChargeItems = (currentOrder, isDiscount) => {
    const path = isDiscount ? 'discounts' : 'charges';
    let formattedDiscountOrChargeItems = get(currentOrder, path, []);

    formattedDiscountOrChargeItems = formattedDiscountOrChargeItems
      .reduce((acc, item) => {
        if (!item.product) {
          acc.push({ ...item, number: 1 });
          return acc;
        }

        const itemFoundIndex = acc.findIndex(
          accItem =>
            accItem.product &&
            accItem.product.id === item.product.id &&
            (!accItem.promotion_code || accItem.promotion_code.id === item.promotion_code.id)
        );
        if (itemFoundIndex === -1) {
          acc.push({ ...item, number: 1 });
        } else {
          // eslint-disable-next-line no-param-reassign
          acc[itemFoundIndex].number += 1;
        }
        return acc;
      }, [])
      .map(item => {
        // eslint-disable-next-line camelcase
        const {
          product,
          id,
          number,
          value,
          promotion_code: promotionCode,
          is_periodic: isPeriodic
        } = item;
        let primary = promotionCode ? promotionCode.description : '';
        let secondary = '';
        if (product) {
          primary = `${product.name} ${number > 1 ? `x${number}` : ''}`;
          secondary = promotionCode ? promotionCode.description : '';
        }

        if (item.custom_description) {
          primary = item.custom_description;
        }

        return {
          primary,
          secondary,
          isPeriodic,
          value: <MoneyFormat value={value * number * (isDiscount ? -1 : 1)} />,
          showRemoveButton: promotionCode
            ? !includes(['FIRSTMONTHFREE', 'TWOMONTHSFREE'], promotionCode.code)
            : true,
          id
        };
      });

    return formattedDiscountOrChargeItems;
  };

  const OPTIONS = [{ id: 1, label: 'Add or Discount' }];

  const onOptionSelected = () => {
    setOpenAddDiscountForm(true);
  };

  const handleOnDownloadInvoice = async (id, type) => {
    setDownloading(true);
    const ordersApiService = new OrdersApiService();
    try {
      const res = await ordersApiService.downloadInvoice(id, type);
      const filename = res.headers['content-disposition'].split('filename=')[1];
      const blob = new Blob([res.data], {
        type: 'application/pdf'
      });
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      dispatch(enqueueNotification('error', e.message));
      return false;
    } finally {
      setDownloading(false);
    }
    return true;
  };

  if (!order) {
    return null;
  }

  return (
    <>
      <Grid container justifyContent="space-between" direction="row">
        <Grid item>
          <Typography variant="h6" gutterBottom>
            {title || 'Order'}
          </Typography>
        </Grid>
        <Grid item>
          <FormGroup className={classes.options}>
            {showDownload && (
              <Box mr={2}>
                {order.invoice_id && (
                  <IconButton
                    disabled={downloading}
                    onClick={() => handleOnDownloadInvoice(order.id, 'pdf')}
                  >
                    <Badge badgeContent="PDF">
                      <CloudDownloadIcon
                        title="Download Invoice"
                        color={downloading ? 'disabled' : 'primary'}
                      />
                    </Badge>
                  </IconButton>
                )}

                <IconButton
                  disabled={downloading}
                  onClick={() => handleOnDownloadInvoice(order.id, 'csv')}
                >
                  <Badge badgeContent="CSV">
                    <CloudDownloadIcon
                      title="Download Invoice"
                      color={downloading ? 'disabled' : 'primary'}
                    />
                  </Badge>
                </IconButton>
              </Box>
            )}
            {displayDetailsOption && (
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    color="primary"
                    checked={detailed}
                    onChange={() => setDetailed(!detailed)}
                  />
                }
                label="Show Details"
              />
            )}
            {showMoreOptions && <MoreOptions onChooseItem={onOptionSelected} options={OPTIONS} />}
          </FormGroup>
        </Grid>
      </Grid>
      <List disablePadding>
        {!!startupFee && (
          <>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Start-up Fee" />
              {hasCharges && (
                <Typography variant="subtitle1" style={{ textDecoration: 'line-through' }}>
                  <MoneyFormat value={startupFee.details.replaced_by_promo_code.original_value} />
                </Typography>
              )}
              {!hasCharges && (
                <Typography variant="subtitle1">
                  <MoneyFormat value={startupFee.value} />
                </Typography>
              )}
            </ListItem>
            <Divider />
          </>
        )}

        <Template order={order} detailed={detailed} />
        {order.discounts && order.discounts.length > 0 && (
          <>
            <Divider />
            <ListItem className={classes.listItem}>
              <Typography variant="subtitle1" className={classes.listItemSubtitle}>
                Add-ons, Promos & Discounts
              </Typography>
            </ListItem>
            {getFormattedDiscountOrChargeItems(order, true).map(
              ({ primary, secondary, id, value, showRemoveButton, isPeriodic }) => (
                <ListItem className={classes.listItemDiscountsCharges} key={id}>
                  <ListItemText primary={primary} secondary={secondary} />
                  <Typography variant="body2">
                    {value}
                    {showRemoveDiscount && showRemoveButton && (
                      <Can
                        permissions={['orders.manage']}
                        yes={() => (
                          <ConfirmationDialogOnClickWrapper
                            confirmationBody="Are you sure you want to delete this item?"
                            confirmationTitle="Delete Confirmation"
                          >
                            <IconButton
                              onClick={() => removeDiscount(id, isPeriodic)}
                              disabled={isPeriodic}
                            >
                              <DeleteIcon color={isPeriodic ? 'disabled' : 'secondary'} />
                            </IconButton>
                          </ConfirmationDialogOnClickWrapper>
                        )}
                      />
                    )}
                  </Typography>
                </ListItem>
              )
            )}
          </>
        )}
        {order.charges && order.charges.length > 0 && (
          <>
            {getFormattedDiscountOrChargeItems(order, false).map(
              ({ primary, secondary, id, value, showRemoveButton, isPeriodic }) => (
                <ListItem className={classes.listItemDiscountsCharges} key={id}>
                  <ListItemText primary={primary} secondary={secondary} />
                  <Typography variant="body2">
                    {value}
                    {showRemoveDiscount && showRemoveButton && (
                      <Can
                        permissions={['orders.manage']}
                        yes={() => (
                          <ConfirmationDialogOnClickWrapper
                            confirmationBody="Are you sure you want to delete this item?"
                            confirmationTitle="Delete Confirmation"
                          >
                            <IconButton
                              onClick={() => removeCharge(id, isPeriodic)}
                              disabled={isPeriodic}
                            >
                              <DeleteIcon color={isPeriodic ? 'disabled' : 'secondary'} />
                            </IconButton>
                          </ConfirmationDialogOnClickWrapper>
                        )}
                      />
                    )}
                  </Typography>
                </ListItem>
              )
            )}
          </>
        )}
        {!detailed && <Divider />}

        {!patients.length && (
          <>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Sub Total" />
              <Typography variant="subtitle1" className={classes.total}>
                <MoneyFormat value={order.subtotal} />
              </Typography>
            </ListItem>
            <Divider />
          </>
        )}

        {monthlyFee && (
          <>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Monthly Fee" />
              <Typography variant="subtitle1" className={classes.total}>
                <MoneyFormat value={monthlyFee} />
              </Typography>
            </ListItem>
            <Divider />
          </>
        )}

        {order.credits > 0 && (
          <>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Credits" />
              <Typography variant="subtitle1" className={classes.total}>
                <MoneyFormat value={order.credits * -1} />
              </Typography>
            </ListItem>
            <Divider />
          </>
        )}
        <ListItem className={classes.listItem}>
          <ListItemText primary="Tax" />
          <Typography variant="subtitle1" className={classes.total}>
            <MoneyFormat value={order.tax} />
          </Typography>
        </ListItem>
        <Divider />
        {order.credit_card_fee > 0 && (
          <>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Credit Card Fee (1.49%)" />
              <Typography variant="subtitle1" className={classes.total}>
                <MoneyFormat value={order.credit_card_fee} />
              </Typography>
            </ListItem>
            <Divider />
          </>
        )}
        <>
          <ListItem className={classes.listItem}>
            <ListItemText primary={order.shouldWaverStartFee ? 'Credit' : 'Total'} />
            <Typography variant="subtitle1" className={classes.total}>
              <MoneyFormat
                value={Number(order.subtotal) + Number(order.tax) + Number(order.credit_card_fee)}
              />
            </Typography>
          </ListItem>
          <Divider />
        </>
      </List>
      <AddDiscountToOrder
        setOpen={setOpenAddDiscountForm}
        order={order}
        open={openAddDiscountForm}
        onAddedDiscount={onReloadOrderAction}
      />
    </>
  );
};

OrderSummary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.object,
  title: PropTypes.string,
  displayDetailsOption: PropTypes.bool,
  showMoreOptions: PropTypes.bool,
  showRemoveDiscount: PropTypes.bool,
  onReloadOrderAction: PropTypes.func,
  showDownload: PropTypes.bool
};

OrderSummary.defaultProps = {
  order: null,
  title: '',
  displayDetailsOption: true,
  showMoreOptions: false,
  showRemoveDiscount: false,
  onReloadOrderAction: () => {},
  showDownload: true
};

export default OrderSummary;
