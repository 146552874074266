import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab
} from '@material-ui/core';
import { get } from 'lodash';
import { EVENT_DELETE_CONTROL, EVENT_UPDATE_CONTROL } from '../../helpers';
import EditProperties from './EditProperties';
import EditValidations from './EditValidations';
import EditWatchers from './EditWatchers';

const ControlSettingsDialog = ({ open, onClose, element, onUpdate, onDelete }) => {
  const tabs = ['properties', 'validation', 'watchers'];
  const [activeTab, setActiveTab] = useState(0);
  const [form, setForm] = useState({});

  useEffect(() => {
    if (open) {
      setActiveTab(0);
    }
  }, [open]);

  useEffect(() => {
    setForm({
      custom_system_name: get(element, 'custom_system_name', ''),
      fee_enabled: get(element, 'fee_enabled', false),
      props: {
        label: get(element, 'props.label', ''),
        type: get(element, 'props.type', 'text'),
        options: get(element, 'props.options', []),
        description: get(element, 'props.description', ''),
        default: get(element, 'props.default', ''),
        info_link: get(element, 'props.info_link', ''),
        info_link_text: get(element, 'props.info_link_text', '')
      },
      rules: {
        required: get(element, 'rules.required', false)
      },
      temporary: get(element, 'temporary', false)
    });
  }, [element]);

  const onTabChange = (e, newValue) => {
    setActiveTab(newValue);
  };

  const close = () => {
    setForm({});
    onClose();
  };

  const onCancel = () => {
    if (form.temporary) {
      onDelete({
        event: EVENT_DELETE_CONTROL,
        id: element.id,
        columnId: element.columnId,
        sectionId: element.sectionId
      });
    }
    close();
  };

  const deleteElement = () => {
    if (!form.temporary) {
      onDelete({
        event: EVENT_DELETE_CONTROL,
        id: element.id,
        columnId: element.columnId,
        sectionId: element.sectionId
      });
    }
    close();
  };

  const onSave = () => {
    onUpdate({
      event: EVENT_UPDATE_CONTROL,
      id: element.id,
      columnId: element.columnId,
      sectionId: element.sectionId,
      payload: { ...form, props: { ...form.props }, rules: { ...form.rules }, temporary: false }
    });
    setForm(current => {
      return { ...current, temporary: false };
    });
  };

  const onSaveAndClose = () => {
    onSave();
    close();
  };

  if (!element) return <></>;

  return (
    <>
      <Dialog maxWidth="sm" open={open}>
        <DialogTitle>Edit {element.props ? element.props.label : element.system_type}</DialogTitle>

        <DialogContent>
          <Tabs
            color="primary"
            value={activeTab}
            onChange={onTabChange}
            aria-label="Control Settings"
          >
            {tabs.map(tab => (
              <Tab color="primary" key={tab} id={`tab-${tab}`} label={tab} />
            ))}
          </Tabs>

          <div className="mt-2rem" style={{ minHeight: '20vh' }}>
            {activeTab === 0 && (
              <EditProperties
                props={element.props}
                system_type={element.system_type}
                form={form}
                setForm={setForm}
              />
            )}
            {activeTab === 1 && (
              <EditValidations
                props={element.props}
                system_type={element.system_type}
                form={form}
                setForm={setForm}
              />
            )}

            {activeTab === 2 && (
              <EditWatchers
                props={element.props}
                system_type={element.system_type}
                form={form}
                setForm={setForm}
              />
            )}
          </div>
        </DialogContent>

        <DialogActions className="mb-1rem">
          <Button onClick={onCancel}>Cancel</Button>

          <Button variant="contained" color="secondary" onClick={deleteElement}>
            Delete
          </Button>

          <Button variant="contained" color="default" onClick={onSave}>
            Save
          </Button>

          <Button type="submit" variant="contained" color="primary" onClick={onSaveAndClose}>
            Save & Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ControlSettingsDialog;
