import React from 'react';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const LowBracketInventory = ({
  data: {
    bracket_library: { id, label, organization }
  },
  view
}) => {
  const history = useHistory();

  const link =
    view === 'management'
      ? `/organizations/${organization.id}#bracket-libraries`
      : `/organizations/${organization.id}/brackets?id=${id}`;

  const handleLinkClick = e => {
    e.preventDefault();
    history.push(link);
  };

  return (
    <Link href={link} onClick={handleLinkClick}>
      {label}
    </Link>
  );
};

LowBracketInventory.propTypes = {
  data: PropTypes.shape({
    bracket_library: PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      organization: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    })
  }).isRequired,
  view: PropTypes.string.isRequired
};

export default connect(state => ({ view: state.auth.isManagement ? 'management' : 'doctor' }))(
  LowBracketInventory
);
