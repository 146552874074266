import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class ExportReportApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  download({ url, data = {}, filename = 'download.csv' }) {
    return this.request({
      method: 'post',
      url,
      data,
      responseType: 'blob',
      handleResponse: r => r
    }).then(response => {
      let file = null;

      try {
        if (response.headers['content-disposition']) {
          // eslint-disable-next-line prefer-destructuring
          file = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', file || filename);
      document.body.appendChild(link);
      link.click();

      return { message: 'Success' };
    });
  }
}
