import React from 'react';
import { get } from 'lodash';

export const createLabel = (props, rules, defaultValue = 'Field Label') => {
  let label = props && props.label ? props.label : defaultValue;

  if (rules && rules.required) {
    label += ' *';
  }

  return label;
};

export const createMoreInfoLink = props => {
  if (!props || !props.info_link) return null;

  const text = props.info_link_text ? props.info_link_text : 'More Info Here';

  return (
    <a
      style={{ display: 'inline-block', marginLeft: '5px' }}
      href={props.info_link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );
};

export const getInjectedFields = field => {
  const name = get(field, 'name');
  const value = get(field, 'value', '');
  const onBlur = get(field, 'onBlur', () => {});
  const onChange = get(field, 'onChange', e => {
    console.log('field:::onChange', e.target.value);
  });

  return { name, value, onBlur, onChange };
};

export const makeControlName = control_id => {
  return `zz_fb_control_${control_id}`;
};
