import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Box, List } from '@material-ui/core';
import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import { produce } from 'immer';
import UploadFilesDialogGeneral from '../../../components/UploadFilesDialogGeneral';
import RxFormsApiService from '../../../services/api/rxForms';

const UploadRxFormFile = ({
  title,
  onChange,
  maxFiles,
  label,
  values,
  organization,
  fileModifier: FileModifier,
  fileModifierProps,
  defaultFileValue,
  ...props
}) => {
  const [openFileUploader, setOpenFileUploader] = useState(false);
  const rxFormsApiService = new RxFormsApiService();

  const handleClose = response => {
    setOpenFileUploader(false);
    if (response) {
      onChange(
        values.concat(
          produce(response, draft => {
            draft.forEach(file => {
              Object.keys(defaultFileValue).forEach(key => {
                if (file[key] === undefined) {
                  // eslint-disable-next-line no-param-reassign
                  file[key] = defaultFileValue[key];
                }
              });
            });
          })
        )
      );
    }
  };

  const getUploadUrl = name => {
    return rxFormsApiService.getUploadLink(name, organization.id);
  };

  const deleteFile = filePath => {
    onChange(values.filter(file => file.file_path !== filePath));
  };

  return (
    <Box my={1} width={1}>
      {values.length > 0 && (
        <List>
          {values.map(item => (
            <ListItem key={item.file_path}>
              <ListItemIcon>
                <AttachmentIcon />
              </ListItemIcon>
              <ListItemText primary={item.name} />
              <ListItemSecondaryAction>
                <Grid container justifyContent="center" alignItems="center">
                  {FileModifier && (
                    <Grid item>
                      <FileModifier
                        key={item.file_path}
                        values={values}
                        onChange={onChange}
                        value={item}
                        {...fileModifierProps}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <IconButton
                      edge="end"
                      aria-label="comments"
                      onClick={() => deleteFile(item.file_path)}
                    >
                      <DeleteIcon color="secondary" />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
      {values.length < maxFiles && (
        <Button color="primary" variant="contained" onClick={() => setOpenFileUploader(true)}>
          {label}
        </Button>
      )}
      <UploadFilesDialogGeneral
        handleClose={handleClose}
        open={openFileUploader}
        onFinishUpload={() => {}}
        onRemoveUpload={() => {}}
        title={title}
        getUploadUrl={getUploadUrl}
        maxFiles={maxFiles}
        {...props}
      />
    </Box>
  );
};

UploadRxFormFile.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxFiles: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  organization: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fileModifier: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  fileModifierProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  defaultFileValue: PropTypes.object
};

UploadRxFormFile.defaultProps = {
  maxFiles: 100,
  fileModifier: null,
  fileModifierProps: {},
  defaultFileValue: {}
};

export default UploadRxFormFile;
