import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { Select } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Checkbox, Chip, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
import unionBy from 'lodash/unionBy';
import { connect } from 'react-redux';
import { QUESTIONS } from '../constants';
import InputLabel from '../../Form/InputLabel';
import { fetchUserBracketLibraries } from '../../../reducers/bracketLibraries';

const useStyles = makeStyles(() => ({
  lowStockChip: {
    backgroundColor: orange[500],
    color: '#fff'
  }
}));

const BracketLibrariesField = ({
  name,
  doctorId,
  fetched,
  fetching,
  fetch,
  items,
  resetValueOnFetch
}) => {
  const classes = useStyles();
  const {
    values: { [name]: value, chosen_bracket_libraries: chosenBracketLibraries },
    setFieldValue
  } = useFormikContext();

  useEffect(() => {
    if (!fetched && !fetching && doctorId) {
      if (resetValueOnFetch && value && value.length) {
        setFieldValue(name, []);
      }
      fetch();
    }
  }, [fetched, fetching, fetch, doctorId, setFieldValue, name, value, resetValueOnFetch]);

  const bracketLibraries = useMemo(() => {
    return [...unionBy(items, chosenBracketLibraries || [], 'id')];
  }, [chosenBracketLibraries, items]);
  const validate = updatedValue => {
    if (bracketLibraries.length > 0 && (!updatedValue || updatedValue.length === 0)) {
      return 'Required';
    }
    return null;
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id={`${name}-label`}>
        {fetching ? 'Loading Brackets' : QUESTIONS[name]}
      </InputLabel>
      <Field
        disabled={items.length === 0}
        labelId={`${name}-label`}
        required
        name={name}
        component={Select}
        multiple
        validate={validate}
        renderValue={selected => {
          const selectedItems = bracketLibraries
            .filter(item => selected.indexOf(item.id) !== -1)
            .map(item => item.file_name);
          return selectedItems.join(', ');
        }}
        labelWidth={65}
      >
        {bracketLibraries.map(item => {
          return (
            <MenuItem key={item.id} value={item.id}>
              <Checkbox checked={value.indexOf(item.id) > -1} />
              <ListItemText primary={item.label} />
              {item.low_stock && (
                <Chip size="small" label="Low Inventory" className={classes.lowStockChip} />
              )}
            </MenuItem>
          );
        })}
      </Field>
    </FormControl>
  );
};

BracketLibrariesField.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array,
  doctorId: PropTypes.number,
  fetched: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  fetch: PropTypes.func.isRequired,
  resetValueOnFetch: PropTypes.bool.isRequired
};

BracketLibrariesField.defaultProps = {
  items: [],
  doctorId: null
};

export default connect(
  (state, { organizationId, doctorId }) => {
    const { bracketLibraries } = state;
    const userState = (bracketLibraries.users[doctorId] &&
      bracketLibraries.users[doctorId][organizationId]) || {
      items: [],
      fetched: false,
      fetching: false
    };
    return {
      items: userState.items,
      fetching: userState.fetching,
      fetched: userState.fetched
    };
  },
  (dispatch, { organizationId, doctorId }) => {
    return {
      fetch: () => dispatch(fetchUserBracketLibraries(organizationId, doctorId))
    };
  }
)(BracketLibrariesField);
