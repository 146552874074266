import React from 'react';
import PropTypes from 'prop-types';
import FolderIcon from '@material-ui/icons/InsertDriveFile';
import PeopleIcon from '@material-ui/icons/People';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ConfirmationDialogOnClickWrapper from '../../../../components/ConfirmationDialogOnClickWrapper';
import Can from '../../../../components/Can';

const FileList = ({
  data,
  onDelete,
  onDownload,
  removingItems = [],
  onUserSelect,
  organizationId
}) => {
  if (data === null) {
    return '';
  }

  return (
    <>
      <List>
        {data.map(archWire => {
          const { id, file_name: fileName, label, users } = archWire;
          const removing = removingItems.indexOf(id) !== -1;
          let deletionMessage =
            users.length > 0
              ? `The library is assigned to ${users.length} member${users.length > 1 ? 's' : ''}.`
              : '';
          deletionMessage += ' Are you sure you want to delete this file?';

          return (
            <ListItem key={id}>
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={label} />
              <ListItemSecondaryAction>
                <Can
                  permissions={[
                    'arch-wires.update',
                    'arch-wires.manage',
                    'organization.arch-wires.update'
                  ]}
                  organizationId={organizationId}
                  yes={() => (
                    <IconButton
                      edge="end"
                      aria-label="doctors"
                      onClick={() => onUserSelect(archWire)}
                      title="Select doctors"
                      disabled={removing}
                    >
                      <PeopleIcon />
                    </IconButton>
                  )}
                />
                <Can
                  permissions={['arch-wires.create', 'arch-wires.manage']}
                  yes={() => (
                    <IconButton
                      edge="end"
                      aria-label="download"
                      onClick={() => onDownload(id)}
                      title="Download File"
                      disabled={removing}
                    >
                      <DownloadIcon />
                    </IconButton>
                  )}
                />
                <Can
                  permissions={['arch-wires.list', 'arch-wires.manage']}
                  yes={() => (
                    <ConfirmationDialogOnClickWrapper
                      confirmationTitle={fileName}
                      confirmationBody={deletionMessage}
                    >
                      <IconButton
                        disabled={removing}
                        edge="end"
                        aria-label="delete"
                        onClick={() => onDelete(id)}
                        title="Delete File"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ConfirmationDialogOnClickWrapper>
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

FileList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  removingItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  onUserSelect: PropTypes.func.isRequired,
  organizationId: PropTypes.number.isRequired
};

export default FileList;
