import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import ErrorIcon from '@material-ui/icons/Error';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector } from 'react-redux';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { isTechnicianOrAdmin } from '../../../helpers';

const ReluRequestStatusChip = ({ reluRequest: { status, message, job_id: jobId } }) => {
  const currentUser = useSelector(state => state.auth.user);

  if (!isTechnicianOrAdmin(currentUser)) {
    return null;
  }

  const jobIdText = `Job ID: ${jobId}`;

  switch (status) {
    case 'failed':
      return (
        <Chip
          variant="outlined"
          color="secondary"
          label={`${jobIdText} — Failed`}
          size="small"
          deleteIcon={
            <Tooltip title={message} arrow interactive>
              <ErrorIcon />
            </Tooltip>
          }
          onDelete={() => {}}
        />
      );
    case 'success':
      return (
        <Chip variant="outlined" color="primary" label={`${jobIdText} — Completed`} size="small" />
      );
    case 'pending':
      return (
        <Chip variant="outlined" color="default" label={`${jobIdText} — Pending`} size="small" />
      );
    case 'flagged':
      return (
        <Chip
          variant="outlined"
          color="secondary"
          label={`${jobIdText} — Flagged`}
          size="small"
          deleteIcon={
            <Tooltip title={message} arrow interactive>
              <ReportProblemIcon />
            </Tooltip>
          }
          onDelete={() => {}}
        />
      );
    default:
      return null;
  }
};

ReluRequestStatusChip.propTypes = {
  reluRequest: PropTypes.shape({
    status: PropTypes.string.isRequired,
    message: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    job_id: PropTypes.any
  }).isRequired
};

export default ReluRequestStatusChip;
