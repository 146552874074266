import React from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio, FormLabel } from "@material-ui/core";


const Radios = ({id, name, label, value, options, onChange}) => {
    const onRadioChange = (e) => {
        const { name, value } = e.target;
        onChange({ target: { name, value }});
    };

    return (
        <FormControl size="small">
            <FormLabel id={id}>{label}</FormLabel>
            <RadioGroup row
                aria-labelledby={id}
                name={name}
                value={value}
                size="small"
                onChange={onRadioChange}>
                    {options.map((option) => {
                        return (
                            <FormControlLabel
                                size="small"
                                key={option}
                                value={option}
                                control={<Radio />}
                                label={option.charAt(0).toUpperCase() + option.slice(1)} />
                        );
                    })}
            </RadioGroup>
        </FormControl>
    );
}

export default Radios;