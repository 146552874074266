import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { Select, TextField } from 'formik-material-ui';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import {
  INVITE_ORGANIZATION_MEMBER_PREFIX,
  inviteOrganizationMemberAction
} from '../../reducers/organizations';
import LoadingButton from '../LoadingButton';
import createLoadingSelector from '../../selectors/loading';
import { ORGANIZATION_ROLE_OPTIONS } from '../../constants';
import SubSectionWrapper, {
  SubSectionActionWrapper
} from '../../pages/Account/components/SubSectionWrapper';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  actionButton: {
    marginLeft: 'auto'
  }
}));

const InviteOrganizationMember = ({ organizationId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSaving = useSelector(state =>
    createLoadingSelector([INVITE_ORGANIZATION_MEMBER_PREFIX])(state)
  );

  const handleOnSubmit = (values, { resetForm }) => {
    return dispatch(inviteOrganizationMemberAction(organizationId, values)).then(result => {
      if (result) {
        resetForm();
      }
    });
  };

  return (
    <SubSectionWrapper title="Invite Member">
      <Formik
        initialValues={{
          email: '',
          role: 'organization_member'
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email()
            .required('Required')
        })}
        onSubmit={handleOnSubmit}
      >
        {({ isValid, dirty }) => (
          <Form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Role</InputLabel>
                  <Field required name="role" component={Select} labelWidth={35}>
                    {Object.keys(omit(ORGANIZATION_ROLE_OPTIONS, 'organization_manager')).map(
                      key => {
                        return (
                          <MenuItem key={key} value={key}>
                            {ORGANIZATION_ROLE_OPTIONS[key].label}
                          </MenuItem>
                        );
                      }
                    )}
                  </Field>
                </FormControl>
              </Grid>
            </Grid>
            <SubSectionActionWrapper>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={isSaving}
                disabled={!dirty || !isValid || isSaving}
                className={classes.actionButton}
              >
                Send Invitation
              </LoadingButton>
            </SubSectionActionWrapper>
          </Form>
        )}
      </Formik>
    </SubSectionWrapper>
  );
};

InviteOrganizationMember.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  organizationId: PropTypes.number.isRequired
};

InviteOrganizationMember.defaultProps = {};

export default InviteOrganizationMember;
