import { DIBS_OPTIONS } from '../components/RxForm/constants';
import { createDesktopURI } from './urls';
import DibsOnDemandApiService from '../services/api/dibsOnDemand';
import { closeGlobalBackdropAction, openGlobalBackdropAction } from '../reducers/globalBackdrop';

// eslint-disable-next-line import/prefer-default-export
export const openDIBS = (app, path, params = {}) => {
  return dispatch => {
    if (app === DIBS_OPTIONS.DIBS_DESKTOP) {
      window.location.href = createDesktopURI(path, params);
    } else if (app === DIBS_OPTIONS.DIBS_ONLINE) {
      const desktopURI = createDesktopURI(path, params);

      const api = new DibsOnDemandApiService();

      dispatch(openGlobalBackdropAction());

      api
        .generateStreamingUrl(desktopURI)
        .then(({ streaming_url: url }) => {
          window.open(url, '_blank');
        })
        .finally(() => {
          dispatch(closeGlobalBackdropAction());
        });
    }
  };
};
