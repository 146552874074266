export const ArchiveStatusKey = '6';

export default {
  '1': {
    label: 'Leads',
    filter: '1'
  },
  '2': {
    label: 'Onboarding',
    filter: '2'
  },
  '3': {
    label: 'New',
    filter: '3'
  },
  '4': {
    label: 'Active',
    filter: '4'
  },
  '5': {
    label: 'Passive',
    filter: '5'
  },
  [ArchiveStatusKey]: {
    label: 'Archived',
    filter: '6'
  }
};
