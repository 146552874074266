import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  CONTROL_TEXT,
  CONTROL_CHECKBOXES,
  CONTROL_RADIOS,
  CONTROL_DROPDOWN,
  CONTROL_TERMS,
  CONTROL_EXISTING
} from '../../helpers';

import Radios from './Radios';
import ManageOptions from './ManageOptions';
import { ControlSettingsFormPropTypes } from '../../propTypes';

const EditProperties = ({ system_type, form, setForm }) => {
  const [options, setOptions] = useState([]);
  const feeEnabled = get(form, 'fee_enabled', false);

  useEffect(() => {
    const options = form && form.props && form.props.options ? form.props.options : [];
    setOptions(options);
  }, [form]);

  const addOption = option => {
    const { name } = option;

    const exists = options.find(opt => opt.name === name);

    if (exists) return;

    const newForm = { ...form };
    newForm.props.options = [...options, option];

    setForm(curr => newForm);
  };

  const removeOption = option => {
    const newForm = { ...form };
    newForm.props.options = options.filter(opt => opt.name !== option.name);
    setForm(curr => newForm);
  };

  const onChange = e => {
    const { name, value } = e.target;
    const newForm = { ...form };
    newForm.props[name] = value;
    setForm(curr => newForm);
  };

  const onFeeChange = e => {
    const { checked } = e.target;
    setForm(curr => {
      return {
        ...curr,
        fee_enabled: checked
      };
    });
  };

  const onNameChange = e => {
    const currentValue = e.target.value;
    setForm(curr => ({ ...curr, custom_system_name: currentValue }));
  };

  const setDefaultValue = value => {
    onChange({ target: { name: 'default', value } });
  };

  const textTypes = ['text', 'number', 'email', 'password', 'date'];

  const hasOptions = [CONTROL_DROPDOWN, CONTROL_RADIOS, CONTROL_CHECKBOXES].includes(system_type);
  const enableFeeAllowed = hasOptions;

  const isTerms = system_type === CONTROL_TERMS;

  return (
    <div className="mb-2rem">
      <div className="mt-2rem">
        <TextField
          label="Label"
          variant="outlined"
          name="label"
          size="small"
          autoFocus
          onChange={onChange}
          value={form.props.label}
          required
          fullWidth
          helperText={isTerms ? `e.g I Agree` : ''}
        />
      </div>

      {system_type === CONTROL_TEXT && (
        <div className="mt-2rem">
          <Radios
            id="type"
            name="type"
            options={textTypes}
            value={form.props.type}
            onChange={onChange}
          />
        </div>
      )}

      {hasOptions && (
        <div className="mt-2rem">
          <ManageOptions
            options={options}
            addOption={addOption}
            removeOption={removeOption}
            defaultValue={form.props.default}
            setDefaultValue={setDefaultValue}
          />
        </div>
      )}

      <div className="mt-2rem">
        <TextField
          label="Description (Helper Text)"
          variant="outlined"
          name="description"
          onChange={onChange}
          value={form.props.description}
          multiline
          size="small"
          fullWidth
          helperText={isTerms ? `Description of the terms` : ''}
        />
      </div>

      {system_type !== CONTROL_EXISTING && (
        <div className="mt-2rem">
          <TextField
            label="Attribute Name"
            variant="outlined"
            name="custom_system_name"
            onChange={onNameChange}
            multiline
            value={form.custom_system_name}
            size="small"
            fullWidth
            helperText="Specify the attribute name for this field"
          />
        </div>
      )}

      {isTerms && (
        <div className="mt-2rem">
          <Typography variant="caption" style={{ marginBottom: '10px' }}>
            More Info Link
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                label="Link"
                variant="outlined"
                name="info_link"
                size="small"
                onChange={onChange}
                value={form.props.info_link}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Text"
                variant="outlined"
                name="info_link_text"
                size="small"
                onChange={onChange}
                value={form.props.info_link_text}
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
      )}

      {enableFeeAllowed && (
        <FormControlLabel
          className="mt-2rem"
          name="fee_enabled"
          onChange={onFeeChange}
          control={<Checkbox checked={feeEnabled} color="primary" />}
          label="Enable Fee"
          color="primary"
        />
      )}
    </div>
  );
};

EditProperties.propTypes = {
  system_type: PropTypes.string.isRequired,
  form: ControlSettingsFormPropTypes.isRequired,
  setForm: PropTypes.func.isRequired
};

export default EditProperties;
