import React, { useState, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import REPORTS, { REPORT_LABELS } from '../../constants/reports';

const ExportReportSelector = ({ value: currentValue, onChange, ...props }) => {
  const currentUser = useSelector(state => state.auth.user);

  const [selectedReport, setSelectedReport] = useState(currentValue);

  const handleSelectedReportChange = event => {
    setSelectedReport(event.target.value);
  };

  const canExport = report => {
    if (!currentUser) {
      return false;
    }

    return _.get(currentUser, 'all_permissions', []).includes(`reports.${report}.export`);
  };

  useEffect(() => {
    onChange(selectedReport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport]);

  return (
    <Select
      {...props}
      value={selectedReport}
      onChange={handleSelectedReportChange}
      displayEmpty
      autoWidth
    >
      <MenuItem value="">
        <em>Choose a report</em>
      </MenuItem>
      {Object.entries(REPORTS)
        .filter(([, value]) => canExport(value))
        .map(([key, value]) => (
          <MenuItem value={value} key={key}>
            {REPORT_LABELS[value]}
          </MenuItem>
        ))}
    </Select>
  );
};

ExportReportSelector.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  onChange: PropTypes.func
};

ExportReportSelector.defaultProps = {
  value: '',
  onChange: () => {}
};

export default ExportReportSelector;
