import React from 'react';
import { get } from 'lodash';
import {
  CONTROL_CHECKBOXES,
  CONTROL_DROPDOWN,
  CONTROL_FILE,
  CONTROL_PARAGRAPH,
  CONTROL_RADIOS,
  CONTROL_TERMS,
  CONTROL_TEXT
} from '../helpers';

import Text from './Text';
import Radios from './Radios';
import Dropdown from './Dropdown';
import Paragraph from './Paragraph';
import Checkboxes from './Checkboxes';
import FileUpload from './FileUpload';
import Terms from './Terms';

const RenderedControl = props => {
  let component = null;
  props = { isLive: true, ...props };

  switch (props.system_type) {
    case CONTROL_TEXT:
      component = <Text {...props} />;
      break;
    case CONTROL_PARAGRAPH:
      component = <Paragraph {...props} />;
      break;
    case CONTROL_DROPDOWN:
      component = <Dropdown {...props} />;
      break;
    case CONTROL_RADIOS:
      component = <Radios {...props} />;
      break;
    case CONTROL_CHECKBOXES:
      component = <Checkboxes {...props} />;
      break;
    case CONTROL_FILE:
      component = <FileUpload {...props} />;
      break;
    case CONTROL_TERMS:
      component = <Terms {...props} />;
      break;
    default:
      component = <div className="fb-default-control">{get(props, 'props.label', '')}</div>;
  }

  return <>{component}</>;
};

export default RenderedControl;
