import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import CustomCircularProgress from '../../../components/CustomCircularProgress';

function Charts({
  numbers,
  totals,
  titles,
  subtitles,
  filters,
  disabledFilters,
  paramString,
  clickable
}) {
  return (
    <Grid container justifyContent="center">
      {_.map(numbers, (number, index) => {
        return (
          <CustomCircularProgress
            key={index}
            number={number}
            total={totals[index]}
            title={titles[index]}
            subtitle={subtitles[index]}
            tooltip="Test"
            disabled={disabledFilters.includes(filters[index])}
            linkTo={`/organizations-users?${paramString}${filters[index]}`}
            clickable={clickable}
          />
        );
      })}
    </Grid>
  );
}

Charts.propTypes = {
  totals: PropTypes.arrayOf(PropTypes.number).isRequired,
  numbers: PropTypes.arrayOf(PropTypes.number).isRequired,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  subtitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  filters: PropTypes.arrayOf(PropTypes.number).isRequired,
  disabledFilters: PropTypes.arrayOf(PropTypes.number),
  paramString: PropTypes.string.isRequired,
  clickable: PropTypes.bool
};

Charts.defaultProps = {
  disabledFilters: [],
  clickable: true
};

export default Charts;
