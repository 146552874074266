import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { parse } from 'query-string';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { Checkbox, TextField } from 'formik-material-ui';
import { LOGIN_PREFIX, loginAction } from '../../reducers/auth';
import LoadingButton from '../../components/LoadingButton';
import createLoadingSelector from '../../selectors/loading';
import dibsLogo from '../../assets/images/logo-220x60.png';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    width: 220
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const Login = ({ management }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const isLoading = useSelector(state => createLoadingSelector([LOGIN_PREFIX])(state));
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      const queryStringParams = parse(location.search);
      let path = '/';
      if (queryStringParams.path) {
        path = queryStringParams.path;
      }

      history.push(path);
    }
  }, [isAuthenticated, history, location.search]);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        remember_me: true
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email()
          .required('Required'),
        password: Yup.string().required('Required')
      })}
      onSubmit={async values => {
        const result = await executeRecaptcha('loginPage');
        if (result) {
          dispatch(loginAction(values, management));
        }
        return true;
      }}
    >
      {({ dirty, isValid }) => (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <img className={classes.avatar} src={dibsLogo} alt="Logo" />
            <Typography component="h1" variant="h5">
              Sign in {management && 'to Admin Dashboard'}
            </Typography>
            <Form className={classes.form} noValidate>
              <Field
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                component={TextField}
              />
              <Field
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                component={TextField}
              />
              <FormControlLabel
                control={<Field name="remember_me" color="primary" component={Checkbox} />}
                label="Remember me"
              />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                loading={isLoading}
                className={classes.submit}
                disabled={!dirty || !isValid || isLoading}
              >
                Sign In
              </LoadingButton>
              <Grid container>
                <Grid item xs>
                  <Link component={RouterLink} to="/forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  {/* <Link component={RouterLink} to="/sign-up" variant="body2">
                    Don't have an account? Sign Up
                  </Link> */}
                </Grid>
              </Grid>
            </Form>
          </div>
        </Container>
      )}
    </Formik>
  );
};

Login.propTypes = {
  management: PropTypes.bool
};

Login.defaultProps = {
  management: false
};

export default Login;
