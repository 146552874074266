import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircleStatic from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    margin: '0.5rem'
  },
  top: {
    animationDuration: '550ms',
    position: 'absolute',
    margin: '0.5rem'
  },
  label: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '500'
  },
  circle: {
    strokeLinecap: 'round'
  }
}));

function CustomCircularProgress({ number, total, title, subtitle, linkTo, disabled, clickable }) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);
  const percentage = parseInt((number / total) * 100, 10);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (progress > percentage) {
        setProgress(progress - 1);
      } else if (progress < percentage) {
        setProgress(progress + 1);
      }
    }, 10);
    return () => {
      clearTimeout(timeout);
    };
  }, [progress, percentage]);

  return (
    <Box position="relative" display="inline-flex" justifyContent="center">
      <CircleStatic
        variant="determinate"
        className={classes.bottom}
        size="7rem"
        thickness={3}
        value={100}
      />
      <CircleStatic
        variant="determinate"
        className={classes.top}
        value={progress}
        size="7rem"
        thickness={3}
        classes={{
          circle: classes.circle
        }}
      />
      <Box className={classes.label}>
        <Typography variant="h6" color="primary">
          {clickable && (
            <Link to={disabled ? '#' : linkTo} disabled={disabled}>
              {number}/{total}
            </Link>
          )}
          {!clickable && (
            <span>
              {number}/{total}
            </span>
          )}
        </Typography>

        <Typography variant="subtitle2">{title}</Typography>

        <Typography variant="caption">{subtitle}</Typography>
      </Box>
    </Box>
  );
}

CustomCircularProgress.propTypes = {
  number: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  clickable: PropTypes.bool
};

CustomCircularProgress.defaultProps = {
  disabled: false,
  clickable: true
};

export default CustomCircularProgress;
