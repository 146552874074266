import React, { useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Typography from '@material-ui/core/Typography';
import { get, isEmpty, size } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import SavePaymentInformation from '../../components/SavePaymentInformation';
import OrganizationInformation from '../../components/OrganizationInformation';
import OrderSummary from '../../components/OrderSummary';
import createGetItemSelector from '../../selectors/getItem';
import { fetchProductAction } from '../../reducers/products';
import {
  CALCULATE_INITIAL_ORDER_PREFIX,
  calculateInitialOrderAction,
  initialPurchaseAction
} from '../../reducers/auth';
import EnterPromotionCode from '../../components/EnterPromotionCode';
import CompletionMessage from './components/CompletionMessage';
import Controls from './components/Controls';
import SubscriptionStartNotice from './components/SubscriptionStartNotice';
import CustomStepper from './components/CustomStepper';
import WelcomeMessage from './components/WelcomeMessage';
import { redirectToOnboardingCoordination } from './helpers';
import createLoadingSelector from '../../selectors/loading';
import LoadingIndicator from '../../components/LoadingIndicator';

const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: '60%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  }
}));

const AccountInitialSetup = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addedPaymentMethod, setAddedPaymentMethod] = useState(null);
  const product = useSelector(state => createGetItemSelector(state.products, 1));
  // const [order, setOrder] = useState({});
  const [promoCode, setPromoCode] = useState(null);
  const [accountInformationReady, setAccountInformationReady] = useState(false);
  const dispatch = useDispatch();
  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const isLoadingOrder = useSelector(state =>
    createLoadingSelector([CALCULATE_INITIAL_ORDER_PREFIX])(state)
  );
  const order = get(currentOrganization, 'initial_order', {});

  const steps = {
    0: {
      key: 'welcome',
      label: 'Welcome',
      showBack: false,
      showNext: true,
      showControls: true,
      disabled: false
    },
    1: {
      key: 'account_details',
      label: 'Account Details',
      showBack: false,
      showNext: true,
      showControls: false,
      disabled: !accountInformationReady
    },
    2: {
      key: 'payment_method',
      label: 'Choose Payment Method',
      showBack: true,
      showNext: true,
      showControls: true,
      disabled:
        !get(currentOrganization, 'payment_info.payment_methods', []).length && !addedPaymentMethod
    },
    3: {
      key: 'review_order',
      label: 'Review your Order',
      showBack: true,
      showNext: true,
      showControls: !isLoadingOrder,
      disabled:
        (!get(currentOrganization, 'payment_info.payment_methods', []).length &&
          !addedPaymentMethod) ||
        isSubmitting ||
        isLoadingOrder
    }
  };

  const lastStep = useMemo(() => size(steps), [steps]);
  const isLastStepCompleted = useMemo(() => activeStep === lastStep, [lastStep, activeStep]);

  const processInitialPurchase = async () => {
    await dispatch(initialPurchaseAction(promoCode, currentOrganization.id))
      .then(() => {
        redirectToOnboardingCoordination();
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const handleNext = () => {
    const nextStep = activeStep + 1;

    if (activeStep === 1) {
      setActiveStep(nextStep);
    }

    switch (nextStep) {
      case 3: {
        if (isEmpty(order)) {
          dispatch(calculateInitialOrderAction(currentOrganization.id, null));
        }

        setActiveStep(nextStep);

        break;
      }
      case 4: {
        setIsSubmitting(true);

        processInitialPurchase();

        break;
      }

      default:
        setActiveStep(nextStep);
        break;
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onPromoCodeApply = promotionCode => {
    dispatch(calculateInitialOrderAction(currentOrganization.id, promotionCode));
    setPromoCode(promotionCode);
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return <WelcomeMessage />;
      case 1:
        return (
          <OrganizationInformation handleNext={handleNext} onSuccess={setAccountInformationReady} />
        );
      case 2:
        return (
          <SavePaymentInformation
            showAlreadyAddedMessage
            handleNext={handleNext}
            onAddedPaymentMethod={paymentMethod => setAddedPaymentMethod(paymentMethod)}
          />
        );
      case 3:
        if (isLoadingOrder) {
          return <LoadingIndicator />;
        }

        return (
          <>
            <OrderSummary order={order} displayDetailsOption={false} showDownload={false} />
            <EnterPromotionCode onApply={onPromoCodeApply} />
          </>
        );
      default:
        throw new Error('Unknown step');
    }
  };

  useEffect(() => {
    dispatch(fetchProductAction(1));
  }, [dispatch]);

  if (!product) {
    return null;
  }

  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" align="center">
          Account Setup
        </Typography>

        <CustomStepper activeStep={activeStep} steps={steps} />

        <CompletionMessage isLastStepCompleted={isLastStepCompleted} />

        {getStepContent(activeStep)}

        <SubscriptionStartNotice activeStep={activeStep} showOnStep={4} />

        <Controls
          lastStep={lastStep}
          activeStep={activeStep}
          handleNext={handleNext}
          handleBack={handleBack}
          steps={steps}
        />
      </Paper>
    </main>
  );
};

export default AccountInitialSetup;
