import { getToken } from './connection';

const getParamString = params => {
  const token = getToken();
  const overrideParams = {
    ...params,
    access_token: token,
    server: process.env.REACT_APP_SERVER_NAME
  };
  const paramsString = Object.keys(overrideParams)
    .map(key => {
      return `${key}=${overrideParams[key]}`;
    })
    .join('&');
  return paramsString;
};

const extractParamsFromPath = path => {
  const pathSplitted = path.split('/');
  const pathParams = {};
  pathSplitted.forEach((value, i) => {
    if (i % 2 === 1) {
      pathParams[pathSplitted[i - 1]] = value;
    }
  });
  return pathParams;
};

export function createDesktopURI(path, params = {}) {
  return `dibs://${path}?${getParamString(params)}`;
}

const createWebUrl = (baseUrl, path, params) => {
  if (baseUrl.endsWith('?')) {
    return `${baseUrl}${getParamString({
      ...extractParamsFromPath(path),
      ...params
    })}`;
  }
  if (baseUrl.endsWith('/')) {
    return `${baseUrl}${path}?${getParamString(params)}`;
  }
  return `${baseUrl}/${path}?${getParamString(params)}`;
};

export function createOnlineFullURI(path, params = {}) {
  return createWebUrl(process.env.REACT_APP_DIBS_ONLINE_FULL_URL, path, params);
}

export function createOnlineLiteURI(path, params = {}) {
  return createWebUrl(process.env.REACT_APP_DIBS_ONLINE_LITE_URL, path, params);
}
