import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import { connect, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PermMedia from '@material-ui/icons/PermMedia';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PrintIcon from '@material-ui/icons/Print';
import InventoryIcon from '@material-ui/icons/ListAlt';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import { CardActions } from '@material-ui/core';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import ShippingDetails from '../../Requests/components/ShippingDetails';
import {
  filterFilesByType,
  getColorByStatus,
  UTCDateTimeToLocalDateTimeReadable,
  isImage
} from '../../../helpers';
import StatusTag from '../../../components/StatusTag';
import {
  JAWS_TYPES,
  PRINT_ASSIGNED_TO_ID,
  PRINT_REQUEST_TABLE_CONFIG,
  PRINTING_REQUESTS_STATUSES,
  PRINTING_REQUESTS_STATUSES_ID,
  FILE_TYPE_IDS
} from '../../../constants';
import { fetchDownloadUrlPrintingFileAction } from '../../../reducers/printingFiles';
import PrintRequestAssignment from './PrintRequestAssignment';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import createLoadingSelector from '../../../selectors/loading';
import {
  CANCEL_REQUEST_PREFIX,
  COMPLETE_REQUEST_PREFIX,
  startTechWorkflowProcessAction,
  updateTechWorkflowProcessAction,
  finishTechWorkflowProcessAction
} from '../../../reducers/requests';
import hasPermission from '../../../selectors/hasPermission';
import techWorkflowTypes from '../../../constants/techWorkflowTypes';
import TechWorkflow from '../../../components/Workflow/TechWorkflow';
import PatientFilesFullScreenGalary from '../../Patients/components/PatientFilesFullScreenGalary';

const useStyles = makeStyles(theme => ({
  requestHeader: {
    display: 'flex',
    padding: theme.spacing(1, 2),
    alignItems: 'baseline'
  },
  requestActionButton: {
    width: '100%',
    maxHeight: theme.spacing(8),
    marginTop: theme.spacing(2)
  },
  bracketLibrariesButton: {
    width: '100%',
    maxHeight: theme.spacing(8),
    minHeight: theme.spacing(8),
    marginTop: theme.spacing(2)
  },
  requestActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 300
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  printRequestLabelContainer: {
    paddingRight: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.25)
  }
}));

const PrintingRequestWorkflowSection = ({
  techWorkflowId,
  printingRequest,
  printRequestWorkflow,
  requestId,
  patient,
  showWorkflowTable,
  disableActionButtons,
  showActionButtons,
  handleEdit,
  handleCancel,
  handleComplete
}) => {
  const printingRequestItems = printingRequest.printing_request_items;
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [lightBoxModalIsOpen, setLightBoxModalIsOpen] = useState(false);

  const isInactive =
    [PRINTING_REQUESTS_STATUSES_ID.completed, PRINTING_REQUESTS_STATUSES_ID.canceled].indexOf(
      printingRequest.printing_request_status_id
    ) !== -1 ||
    disableActionButtons ||
    !showActionButtons;

  const handleDownloadFile = fileId => {
    dispatch(fetchDownloadUrlPrintingFileAction(fileId));
  };

  const idbImageFiles = filterFilesByType(
    get(patient, 'patient_files'),
    FILE_TYPE_IDS.brackets
  ).filter(file => isImage(file.original_name));

  const renderShowIDBTrayImagesButton = () => {
    return (
      <>
        <PatientFilesFullScreenGalary
          patient={patient}
          isOpen={lightBoxModalIsOpen}
          setOpen={setLightBoxModalIsOpen}
          fileTypeId={FILE_TYPE_IDS.brackets}
        />
        <Button
          key="show_idb_tray_images"
          variant="contained"
          onClick={() => setLightBoxModalIsOpen(true)}
          size="small"
          startIcon={<PermMedia />}
          className={classes.requestActionButton}
        >
          SHOW IDB TRAY IMAGES
        </Button>
        <Button
          key="bracket_libraries"
          variant="contained"
          onClick={e => {
            e.preventDefault();
            history.push(`/organizations/${patient.organization_id}#bracket-libraries`);
          }}
          size="small"
          startIcon={<InventoryIcon />}
          className={classes.bracketLibrariesButton}
        >
          BRACKET LIBRARIES
        </Button>
      </>
    );
  };

  const renderPrintDownloadButton = printRequestItem => {
    const { name } = JAWS_TYPES[printRequestItem.arch.jaw_type_id];

    if (!printRequestItem.file_id) {
      return null;
    }

    return (
      <Button
        key={`print_download_${name}`}
        variant="contained"
        onClick={() => handleDownloadFile(printRequestItem.file_id)}
        size="small"
        startIcon={<CloudDownloadIcon />}
        className={classes.requestActionButton}
      >
        DOWNLOAD {name.toUpperCase()}
      </Button>
    );
  };

  const renderPrintRequestLabel = requestItem => {
    const { name } = JAWS_TYPES[requestItem.arch.jaw_type_id];

    return (
      <Grid container alignItems="center" justifyContent="space-between" key={`print_${name}`}>
        <Grid item xs={8}>
          <ListItem alignItems="flex-start" className={classes.printRequestLabelContainer}>
            <ListItemAvatar>
              <Avatar>
                <PrintIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={name}
              disableTypography
              secondary={
                <div>
                  <Typography component="span" variant="body2" color="textPrimary">
                    IDB Tray
                  </Typography>
                </div>
              }
            />
          </ListItem>
        </Grid>
        <Grid item xs={4}>
          <Box m={1}>
            {requestItem.insert_brackets && <StatusTag label="Brackets" color="green" />}
          </Box>
          <Box m={1}>
            {!!requestItem.flawed_tray_id && <StatusTag label="Fix" color="purple" />}
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box mt={2} width="100%">
      <Grid item xs={12}>
        <Card>
          <div className={classes.requestHeader}>
            <Box>
              <Typography component="h1" variant="h5">
                Print
              </Typography>
              <Typography component="div" variant="caption">
                {`Requested at ${UTCDateTimeToLocalDateTimeReadable(printingRequest.created_at)}`}
              </Typography>
              <Typography component="div" variant="caption">
                {`Last Update ${UTCDateTimeToLocalDateTimeReadable(printingRequest.updated_at)}`}
              </Typography>
            </Box>
            <Box mx={10}>
              <StatusTag
                label={PRINTING_REQUESTS_STATUSES[printingRequest.printing_request_status_id]}
                color={getColorByStatus(
                  PRINTING_REQUESTS_STATUSES[printingRequest.printing_request_status_id]
                )}
              />
            </Box>
            <Box mx={2}>
              <Typography component="h1" variant="subtitle2">
                <PrintRequestAssignment
                  printingRequest={printingRequest}
                  requestId={requestId}
                  patient={patient}
                />
              </Typography>
            </Box>
          </div>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                {printingRequestItems &&
                  printingRequestItems.map(printingRequestItem =>
                    renderPrintRequestLabel(printingRequestItem)
                  )}
              </Grid>
              <Grid item xs={8}>
                {showWorkflowTable && techWorkflowId && (
                  <TechWorkflow
                    save={(data, old) =>
                      dispatch(
                        updateTechWorkflowProcessAction(
                          requestId,
                          techWorkflowTypes.PRINTING_REQUEST,
                          old.id,
                          data
                        )
                      )
                    }
                    start={record =>
                      dispatch(
                        startTechWorkflowProcessAction(
                          requestId,
                          techWorkflowTypes.PRINTING_REQUEST,
                          record.id
                        )
                      )
                    }
                    finish={record =>
                      dispatch(
                        finishTechWorkflowProcessAction(
                          requestId,
                          techWorkflowTypes.PRINTING_REQUEST,
                          record.id
                        )
                      )
                    }
                    field="tech_workflow"
                    title="Tech Workflow Table"
                    workflowData={printRequestWorkflow}
                    columnsData={PRINT_REQUEST_TABLE_CONFIG}
                    readOnly={isInactive}
                  />
                )}
              </Grid>
              <Grid item xs={2} className={classes.requestActions}>
                {printingRequestItems && printingRequestItems.map(renderPrintDownloadButton)}
                {idbImageFiles.length > 0 && renderShowIDBTrayImagesButton()}
              </Grid>
            </Grid>
          </CardContent>
          <ShippingDetails
            Wrapper={({ children }) => <>{children}</>}
            printingRequest={printingRequest}
            patient={patient}
            readonly
          />
          <Divider />
          <CardActions className={classes.actions}>
            {showActionButtons && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={disableActionButtons}
                  onClick={handleEdit}
                  className={classes.actionButton}
                >
                  Edit
                </Button>
                <ConfirmationDialogOnClickWrapper
                  confirmationTitle="Cancel Request"
                  confirmationBody="Are you sure you want to cancel this request?"
                >
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    disabled={disableActionButtons}
                    className={classes.actionButton}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </ConfirmationDialogOnClickWrapper>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  disabled={disableActionButtons}
                  className={classes.actionButton}
                  onClick={handleComplete}
                >
                  Complete
                </Button>
              </>
            )}
          </CardActions>
        </Card>
      </Grid>
    </Box>
  );
};

PrintingRequestWorkflowSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  printingRequest: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  printRequestWorkflow: PropTypes.array,
  requestId: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired,
  showWorkflowTable: PropTypes.bool,
  disableActionButtons: PropTypes.bool.isRequired,
  showActionButtons: PropTypes.bool.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleComplete: PropTypes.func.isRequired,
  techWorkflowId: PropTypes.number
};

PrintingRequestWorkflowSection.defaultProps = {
  showWorkflowTable: true,
  techWorkflowId: null,
  printRequestWorkflow: null
};

export default connect((state, { printingRequest, showActionButtons, disableActionButtons }) => {
  const isCompleting = createLoadingSelector([COMPLETE_REQUEST_PREFIX])(state);
  const isCancelling = createLoadingSelector([CANCEL_REQUEST_PREFIX])(state);
  const canCompleteAssignedRequests = hasPermission(state, {
    permissions: ['customer-requests.complete-assigned']
  });
  const canManageCustomRequest = hasPermission(state, {
    permissions: [
      'customer-requests.manage',
      'customer-requests.complete',
      'customer-requests.update-management-data'
    ]
  });
  return {
    disableActionButtons: disableActionButtons || isCompleting || isCancelling,
    showActionButtons:
      showActionButtons &&
      (canManageCustomRequest ||
        (canCompleteAssignedRequests &&
          get(printingRequest, 'assigned_to') === PRINT_ASSIGNED_TO_ID.thirdParty)),
    showWorkflowTable: canManageCustomRequest
  };
})(PrintingRequestWorkflowSection);
