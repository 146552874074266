import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import CollapseIcon from '../SvgIcons/CollapseIcon';
import ExpandIcon from '../SvgIcons/ExpandIcon';

const ExpandCollapseIconButton = ({ onClick, expandTitle, collapseTitle, collapsed }) => {
  const [isCollapse, setIsCollapse] = useState(collapsed);

  const handleOnClick = () => {
    const col = !isCollapse;
    setIsCollapse(col);
    onClick(col);
  };

  return (
    <IconButton title={isCollapse ? expandTitle : collapseTitle} onClick={handleOnClick}>
      {isCollapse ? <ExpandIcon /> : <CollapseIcon />}
    </IconButton>
  );
};

ExpandCollapseIconButton.propTypes = {
  onClick: PropTypes.func,
  expandTitle: PropTypes.string,
  collapseTitle: PropTypes.string,
  collapsed: PropTypes.bool
};

ExpandCollapseIconButton.defaultProps = {
  onClick: () => {},
  expandTitle: 'Expand',
  collapseTitle: 'Collapse',
  collapsed: true
};

export default ExpandCollapseIconButton;
