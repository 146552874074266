import React from 'react';
import PropTypes from 'prop-types';
import { assignmentOptions } from '../helpers';
import RadioGroupField from './RadioGroupField';
import withDependency from './hooks/withDependency';

const TrayDesignAssignmentField = ({ options, ...props }) => {
  const availableAssignments = assignmentOptions({
    assignments: options
  });

  return <RadioGroupField options={availableAssignments} {...props} />;
};

TrayDesignAssignmentField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default withDependency(TrayDesignAssignmentField);
