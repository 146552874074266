import React from 'react';
import RemoveCircleRounded from '@material-ui/icons/RemoveCircleRounded';
import { IconButton } from '@material-ui/core';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { TYPE_CONTROL, TYPE_RENDERED_CONTROL } from '../helpers';

const SectionColumn = ({
  element,
  removeColumn,
  disableRemoveBtn,
  addControl,
  children,
  isPreview,
  moveControl,
  sectionId
}) => {
  const [collectedProps, drop] = useDrop(
    {
      accept: [TYPE_CONTROL, TYPE_RENDERED_CONTROL],
      drop: (item, monitor) => {
        if (monitor.didDrop()) {
          return;
        }

        if (item.action === 'move') {
          if (moveControl) {
            const params = {
              src: {
                itemId: item.id,
                columnId: item.columnId,
                sectionId: item.sectionId
              },
              dst: {
                itemId: null,
                columnId: element.id,
                sectionId
              }
            };

            moveControl(params);
          }
        } else if (addControl) {
          addControl({ ...item, columnId: element.id });
        }
      },
      collect: monitor => ({
        isOver: !!monitor.isOver({ shallow: true })
      })
    },
    [element]
  );

  return (
    <>
      <div className={`fb-builder-column ${collectedProps.isOver ? ' highlight ' : ''}`}>
        {!isPreview && (
          <IconButton
            className="fb-remove-column"
            onClick={() => removeColumn(element.id)}
            size="small"
            title="Remove Last Column"
            disabled={disableRemoveBtn}
          >
            <RemoveCircleRounded color={disableRemoveBtn ? 'disabled' : 'error'} />
          </IconButton>
        )}

        <div ref={drop} className="fb-builder-content-control">
          <div className="fb-builder-content-control-children">{children}</div>
        </div>
      </div>
    </>
  );
};

SectionColumn.propTypes = {
  element: PropTypes.object.isRequired,
  removeColumn: PropTypes.func.isRequired,
  disableRemoveBtn: PropTypes.bool.isRequired
};

export default SectionColumn;
