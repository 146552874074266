import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { enqueueNotification } from '../../reducers/notifications';
import UploadFilesDialogBase from '../UploadFilesDialogBase';

const UploadFilesDialogGeneral = ({
  open,
  handleClose,
  title,
  getUploadUrl,
  onFinishUpload,
  onRemoveUpload,
  maxFiles,
  existingFiles,
  preventDuplicate,
  validateFile,
  ...props
}) => {
  const dispatch = useDispatch();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const currentFileAdditionalData = useRef({});

  useEffect(() => {
    setUploadedFiles([]);
  }, [open]);

  const getUploadParams = async ({ file, meta: { name, lastModifiedDate } }) => {

      const errorMessage = await validateFile(file);

      if (errorMessage) {
        dispatch(enqueueNotification('error', errorMessage));
        return false;
      }

      if (preventDuplicate) {
      const existingFile = existingFiles.find(f => f.name === name);

      if (existingFile) {
        dispatch(enqueueNotification('error', 'File with such name already exists.'));
        return false;
      }
    }
    try {
      const { url, file_path: filePath } = await getUploadUrl(name);
      return { method: 'put', body: file, url, meta: { filePath, name, lastModifiedDate } };
    } catch (e) {
      dispatch(enqueueNotification('error', e.message));
      return false;
    }
  };

  const handleChangeStatus = async ({ meta }, status, fileWithMeta) => {
    if (status === 'done') {
      const files = [
        ...uploadedFiles,
        {
          file_path: meta.filePath,
          name: meta.name
        }
      ];
      setUploadedFiles(files);
      await onFinishUpload(meta.filePath, currentFileAdditionalData.current);
      dispatch(enqueueNotification('success', 'The file was uploaded successfully.'));
      if (fileWithMeta.filter(file => file.meta.status === 'done').length === fileWithMeta.length) {
        handleClose(files);
      }
    }

    if (status === 'removed') {
      setUploadedFiles(
        uploadedFiles.filter(uploadedFile => uploadedFile.file_path !== meta.filePath)
      );
      await onRemoveUpload(meta.filePath, currentFileAdditionalData.current);
    }
  };

  const handleSubmit = (files, allFiles) => {
    allFiles.forEach(f => f.remove());
  };

  return (
    <UploadFilesDialogBase
      handleChangeStatus={handleChangeStatus}
      handleClose={response => (response ? handleClose(uploadedFiles) : handleClose(false))}
      title={title}
      getUploadParams={getUploadParams}
      open={open}
      handleSubmit={handleSubmit}
      maxFiles={maxFiles}
      {...props}
    />
  );
};

UploadFilesDialogGeneral.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  getUploadUrl: PropTypes.func.isRequired,
  onFinishUpload: PropTypes.func.isRequired,
  onRemoveUpload: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  maxFiles: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  existingFiles: PropTypes.array,
  preventDuplicate: PropTypes.bool,
  validateFile: PropTypes.func
};

UploadFilesDialogGeneral.defaultProps = {
  maxFiles: 100,
  existingFiles: [],
  preventDuplicate: false,
  validateFile: () => null
};

export default UploadFilesDialogGeneral;
