import GlobalSearchApiService from '../services/api/globalSearch';
import { enqueueNotification } from './notifications';

// ACTION_TYPES ////////////////////////////////////////////////////////////////
export const UPDATE_ATTRIBUTE_PARAMS = 'UPDATE_ATTRIBUTE_PARAMS';
export const UPDATE_MULTIPLE_PARAMS = 'UPDATE_MULTIPLE_PARAMS';
export const GLOBAL_SEARCH_CONFIG_FETCHED = 'GLOBAL_SEARCH_CONFIG_FETCHED';
export const GLOBAL_SEARCH_ORG_TYPES_FETCHED = 'GLOBAL_SEARCH_ORG_TYPES_FETCHED';
export const RESET_FILTERS = 'RESET_FILTERS';

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  organization_types: null,
  config: {
    indexName: null,
    url: null,
    key: null
  },
  query: '',
  filter: ['doctor', 'organization'],
  onboarding: [],
  payment: [],
  customized_fees: [],
  organization_type: [],
  invoice_version: [],
  status: [],
  page: 1,
  is_archived_only: false,
  has_low_stock_bracket_only: false,
  sort: 'main_index:joined_at_unix:desc',
  joinedDateFromUnix: undefined,
  joinedDateToUnix: undefined
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ATTRIBUTE_PARAMS:
      return {
        ...state,
        [action.payload.attribute]: action.payload.value
      };
    case UPDATE_MULTIPLE_PARAMS:
      return {
        ...state,
        ...action.payload
      };
    case GLOBAL_SEARCH_CONFIG_FETCHED:
      return {
        ...state,
        config: action.payload
      };
    case GLOBAL_SEARCH_ORG_TYPES_FETCHED:
      return {
        ...state,
        organization_types: action.payload
      };
    case RESET_FILTERS:
      return {
        ...initialState,
        config: state.config,
        organization_types: state.organization_types
      };
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////
export function fetchConfig() {
  return dispatch => {
    const globalSearchApiService = new GlobalSearchApiService();
    return globalSearchApiService
      .config()
      .then(response => {
        dispatch({
          type: GLOBAL_SEARCH_CONFIG_FETCHED,
          payload: response
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchOrganizationTypes() {
  return dispatch => {
    const globalSearchApiService = new GlobalSearchApiService();
    return globalSearchApiService
      .organizationTypes()
      .then(response => {
        dispatch({
          type: GLOBAL_SEARCH_ORG_TYPES_FETCHED,
          payload: { ...response.data }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateRefinements(attribute, value) {
  return dispatch => {
    dispatch({
      type: UPDATE_ATTRIBUTE_PARAMS,
      payload: {
        attribute,
        value
      }
    });
  };
}

export function updateMultipleParameters(params) {
  return dispatch => {
    dispatch({
      type: UPDATE_MULTIPLE_PARAMS,
      payload: params
    });
  };
}

export function resetFilters() {
  return dispatch => {
    dispatch({
      type: RESET_FILTERS,
      payload: {}
    });
  };
}
