import { produce } from 'immer';
import UsersApiService from '../services/api/users';
import { enqueueNotification } from './notifications';

export const FETCH_WHITEHAT_INTEGRATION_CONFIG = 'FETCH_WHITEHAT_INTEGRATION_CONFIG';
export const FETCHED_WHITEHAT_INTEGRATION_CONFIG = 'FETCHED_WHITEHAT_INTEGRATION_CONFIG';
export const UPDATE_WHITEHAT_INTEGRATION_CONFIG_REQUEST =
  'UPDATE_WHITEHAT_INTEGRATION_CONFIG_REQUEST';
export const UPDATE_WHITEHAT_INTEGRATION_CONFIG_SUCCESS =
  'UPDATE_WHITEHAT_INTEGRATION_CONFIG_SUCCESS';

export const SET_WHITEHAT_BROWSER_EXTENSION_CONFIG = 'SET_WHITEHAT_BROWSER_EXTENSION_CONFIG';

const initialState = {
  users: {},
  browserExtensionSettings: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WHITEHAT_INTEGRATION_CONFIG:
      return {
        users: {
          ...state.users,
          [action.payload.user_id]: {
            fetching: true,
            data: null
          }
        }
      };
    case FETCHED_WHITEHAT_INTEGRATION_CONFIG:
      return {
        users: {
          ...state.users,
          [action.payload.user_id]: {
            fetching: false,
            data: action.payload.data
          }
        }
      };
    case UPDATE_WHITEHAT_INTEGRATION_CONFIG_REQUEST:
      return produce(state, draft => {
        draft.users[action.payload.user_id].updating = true;
      });
    case UPDATE_WHITEHAT_INTEGRATION_CONFIG_SUCCESS:
      return produce(state, draft => {
        draft.users[action.payload.user_id].updating = false;
        draft.users[action.payload.user_id].data = action.payload.data;
      });
    case SET_WHITEHAT_BROWSER_EXTENSION_CONFIG:
      return {
        ...state,
        browserExtensionSettings: action.payload
      };
    default:
      return state;
  }
};

export function fetchWhitehatUserIntegrationConfig(userId) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_WHITEHAT_INTEGRATION_CONFIG, payload: { user_id: userId } });
    const state = getState();

    const api = new UsersApiService();
    return api
      .getWhitehatIntegrationConfig(userId)
      .then(response => {
        dispatch({
          type: FETCHED_WHITEHAT_INTEGRATION_CONFIG,
          payload: { user_id: userId, data: response.data }
        });
        if (!state.whitehatUserIntegrations.browserExtensionSettings) {
          dispatch({
            type: SET_WHITEHAT_BROWSER_EXTENSION_CONFIG,
            payload: response.metadata.browser_extension_settings
          });
        }
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
export function saveWhitehatUserIntegrationConfig(userId, data) {
  return dispatch => {
    dispatch({ type: UPDATE_WHITEHAT_INTEGRATION_CONFIG_REQUEST, payload: { user_id: userId } });

    const api = new UsersApiService();
    return api
      .saveWhitehatIntegrationConfig(userId, data)
      .then(response => {
        dispatch({
          type: UPDATE_WHITEHAT_INTEGRATION_CONFIG_SUCCESS,
          payload: { user_id: userId, data: response.data }
        });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
