import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Select } from 'antd';
import {
  PATIENT_REQUEST_ASSIGNMENT_FILTER,
  REQUEST_ASSIGNMENT_IN_OFFICE,
  REQUEST_ASSIGNMENT_ORTHOSELECT,
  REQUEST_ASSIGNMENT_THIRD_PARTY
} from '../../constants';
import RequestsStatusFilter from './RequestsStatusFilter';
import RequestsStepsFilter from './RequestsStepsFilter';

const PatientsFilter = ({ handleChange, filterValues }) => {
  const { Option } = Select;
  const handleAssignmentsChange = value => {
    if (value.length) {
      handleChange(value, 'requestAssignments');
    } else {
      handleChange(
        [
          REQUEST_ASSIGNMENT_ORTHOSELECT,
          REQUEST_ASSIGNMENT_IN_OFFICE,
          REQUEST_ASSIGNMENT_THIRD_PARTY
        ],
        'requestAssignments'
      );
    }
  };

  const renderAssignmentsFilter = () => {
    const children = Object.keys(PATIENT_REQUEST_ASSIGNMENT_FILTER).map(key => {
      const { value, label } = PATIENT_REQUEST_ASSIGNMENT_FILTER[key];
      return (
        <Option key={label} value={value}>
          {label}
        </Option>
      );
    });
    return (
      <Select
        mode="multiple"
        placeholder="Please select"
        value={filterValues.requestAssignments}
        onChange={handleAssignmentsChange}
        style={{ width: '100%' }}
      >
        {children}
      </Select>
    );
  };

  return (
    <Box mb={1}>
      <Grid container spacing={3}>
        <RequestsStatusFilter
          value={filterValues.requestTypeStatus}
          onChange={value => handleChange(value, 'requestTypeStatus')}
        />
        <Grid item xs={4}>
          <Typography component="span" variant="subtitle1">
            Assignment Filter
          </Typography>
          {renderAssignmentsFilter()}
        </Grid>

        {filterValues.requestTypeStatus.length > 0 && (
          <Grid item xs={12}>
            <RequestsStepsFilter
              onChange={value => {
                handleChange(value, 'requestStep');
              }}
              value={filterValues.requestStep}
              requestTypeStatusValue={filterValues.requestTypeStatus}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

PatientsFilter.propTypes = {
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filterValues: PropTypes.object
};

PatientsFilter.defaultProps = {
  filterValues: {
    requestTypeStatus: [],
    requestStep: null
  }
};

export default PatientsFilter;
