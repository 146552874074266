import React, { useCallback, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { produce } from 'immer';
import DataTable from '../../components/DataTable';
import SearchInput from '../../components/SearchInput';
import createLoadingSelector from '../../selectors/loading';
import { getColorByStatus } from '../../helpers';
import StatusTag from '../../components/StatusTag';
import { FETCH_REQUESTS_PREFIX, fetchRequestsAction } from '../../reducers/requests';
import { REQUEST_STATUSES } from '../../constants';
import LocationSelectorCurrentOrganizationHandler from '../../components/LocationSelector/LocationSelectorCurrentOrganizationHandler';
import Can from '../../components/Can';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
    width: '100%'
  }
}));

const Requests = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const globalParams = useSelector(state => state.requests.globalParams);
  const isLoading = useSelector(state => createLoadingSelector([FETCH_REQUESTS_PREFIX])(state));
  const isManagement = useSelector(state => state.auth.isManagement);
  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const [additionalParams, setAdditionalParams] = useState({
    params: globalParams,
    resetPage: false
  });

  const rows = useSelector(state => state.requests.items);
  const total = useSelector(state => state.requests.total);
  const [selectedStatus, setSelectedStatus] = React.useState(globalParams.statusId);

  const handleChangeStatus = (event, status) => {
    setSelectedStatus(status);
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params.statusId = status;
        draft.resetPage = true;
      })
    );
  };

  const handleUpdateData = useCallback(
    params => {
      const includes = ['patient.user'];
      dispatch(
        fetchRequestsAction({ ...params, organizationId: currentOrganization.id, includes })
      );
    },
    [dispatch, currentOrganization.id]
  );

  const handleOnChangeSearchInput = debounce((value, additionalParameterKey) => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params[additionalParameterKey] = value;
        draft.resetPage = true;
      })
    );
  }, 500);

  const handleChangeLocations = locations => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params.locations = locations;
        draft.resetPage = true;
      })
    );
  };

  const handleShowItem = id => {
    history.push(`/requests/${id}`);
  };

  const columns = [
    {
      id: 'patient.user.full_name',
      numeric: false,
      disablePadding: false,
      label: 'Doctor',
      disabledSorting: true
    },
    {
      id: 'patient.full_name',
      customSortId: 'patient.first_name',
      numeric: false,
      disablePadding: false,
      disabledSorting: true,
      label: 'Patient'
    },
    {
      id: 'patient.case_number_text',
      numeric: false,
      disablePadding: false,
      label: 'Case #',
      disabledSorting: true,
      formatMethod: value => value || '-'
    },
    {
      id: 'patient.numeric_suffix',
      numeric: false,
      disablePadding: false,
      label: 'Suffix',
      disabledSorting: true,
      formatMethod: value => `${value ? `(${value})` : '-'}`
    },
    {
      id: 'updated_at',
      numeric: false,
      disablePadding: false,
      label: 'Modified Date',
      type: 'datetime'
    },
    {
      id: 'pr_workflow_step',
      numeric: false,
      disablePadding: false,
      label: 'Next Step',
      hide: !isManagement,
      formatMethod: value => {
        if (!value) {
          return `1 Generate Tray`;
        }
        return value;
      }
    },
    {
      id: 'status_id',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      customSortId: 'status_id',
      formatMethod: value => (
        <StatusTag
          label={REQUEST_STATUSES[value]}
          color={getColorByStatus(REQUEST_STATUSES[value])}
        />
      )
    }
  ];

  return (
    <Container component="main">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.toolbar}>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item xs={12} sm={4}>
              <SearchInput
                placeholder="Search by Patient"
                className={classes.searchInput}
                defaultValue={globalParams.searchFullNameQuery}
                onChange={e => handleOnChangeSearchInput(e.target.value, 'searchFullNameQuery')}
              />
            </Grid>
            <Can
              organizationId={currentOrganization.id}
              permissions={['organization.customer-requests.view-any']}
              yes={() => (
                <Grid item xs={12} sm={4}>
                  <SearchInput
                    placeholder="Search by Doctor"
                    className={classes.searchInput}
                    defaultValue={globalParams.searchDoctorFullNameQuery}
                    onChange={e =>
                      handleOnChangeSearchInput(e.target.value, 'searchDoctorFullNameQuery')
                    }
                  />
                </Grid>
              )}
            />
            <Grid item xs={12} sm={4}>
              <LocationSelectorCurrentOrganizationHandler
                onChange={handleChangeLocations}
                defaultValue={globalParams.locations}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3} justifyContent="space-around" direction="row">
                <Grid item>
                  <ToggleButtonGroup value={selectedStatus} exclusive onChange={handleChangeStatus}>
                    {Object.keys(REQUEST_STATUSES).map(item => (
                      <ToggleButton key={item} value={item}>
                        {REQUEST_STATUSES[item]}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <DataTable
          isLoading={isLoading}
          columns={columns}
          rows={rows}
          total={total}
          updateData={handleUpdateData}
          globalParams={globalParams}
          defaultOrderBy={globalParams.orderBy}
          customActions={[
            {
              name: 'show',
              handleOnAction: handleShowItem,
              color: 'primary',
              text: 'Show'
            }
          ]}
          additionalParams={additionalParams}
        />
      </div>
    </Container>
  );
};

Requests.propTypes = {};

Requests.defaultProps = {};

export default Requests;
