import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Main from './Main';

const Wrapper = ({ isManagement, organizationId }) => {
  if (isManagement || !organizationId) {
    return '';
  }

  return <Main organizationId={organizationId} />;
};

Wrapper.propTypes = {
  organizationId: PropTypes.number.isRequired,
  isManagement: PropTypes.bool.isRequired
};

export default connect(state => {
  return {
    isManagement: state.auth.isManagement,
    organizationId: state.auth.currentOrganization && state.auth.currentOrganization.id
  };
})(Wrapper);
