import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Expand } from '../../assets/icons/expand.svg';

const useStyles = makeStyles(() => ({
  icon: {
    '& path': {
      stroke: 'currentColor'
    }
  }
}));

const ExpandIcon = props => {
  const classes = useStyles();

  return (
    <SvgIcon
      {...props}
      className={classes.icon}
      component={Expand}
      viewBox="0.00 0.00 256.00 256.00"
    />
  );
};

export default ExpandIcon;
