import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class DibsOnDemandApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  generateStreamingUrl(dibsLaunchCode) {
    return this.request({
      method: 'post',
      data: { dibs_launch_code: dibsLaunchCode },
      url: `dibs-on-demand/generate-streaming-url`
    });
  }
}
