import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles(() => ({
  hidden: { display: 'none' },
  container: {
    margin: 'auto'
  },
  file_input: {
    width: '90%',
    '& *': {
      cursor: 'pointer !important'
    }
  }
}));

const CustomFileInput = ({ value, errorMessage, title, field, setFieldValue, accept }) => {
  const [fileName, setFileName] = useState('');
  const classes = useStyles();
  const fileUpload = React.createRef();

  const handleClearFile = () => {
    fileUpload.current.value = null;
    setFileName('');
    setFieldValue(field.name, null);
  };

  const showFileUpload = () => {
    if (fileUpload) {
      fileUpload.current.click();
    }
  };

  const handleFileChange = e => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setFieldValue(field.name, file);
    }
  };

  useEffect(() => {
    fileUpload.current.value = null;
    setFileName(value);
  }, [value, fileUpload]);

  return (
    <div className={classes.container}>
      <input
        className={classes.hidden}
        id={field.name}
        name={field.name}
        type="file"
        onChange={handleFileChange}
        ref={fileUpload}
        accept={accept}
      />
      <Grid container alignItems="center">
        <TextField
          label={title}
          variant="outlined"
          fullWidth
          disabled
          value={fileName}
          className={classes.file_input}
          onClick={showFileUpload}
          onChange={() => {}}
          error={!!errorMessage}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Icon style={{ fontSize: 24 }}>folder</Icon>
              </InputAdornment>
            )
          }}
        />
        <InputAdornment
          position="end"
          variant="filled"
          className={fileName ? '' : classes.hidden}
          onClick={handleClearFile}
        >
          <Icon style={{ fontSize: 24 }}>close</Icon>
        </InputAdornment>
      </Grid>
      {errorMessage ? (
        <Typography variant="caption" color="error">
          {errorMessage}
        </Typography>
      ) : null}
    </div>
  );
};

CustomFileInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  field: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  title: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  accept: PropTypes.string
};

CustomFileInput.defaultProps = {
  errorMessage: null,
  value: '',
  accept: null
};

export default CustomFileInput;
