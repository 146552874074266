import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import SubSectionWrapper, { SubSectionActionWrapper } from '../SubSectionWrapper';
import LoadingButton from '../../../../components/LoadingButton';
import { fetchBuiltInArchWires } from '../../../../reducers/archWires';
import {
  fetchRxFormPreference,
  updateRxFormPreference
} from '../../../../reducers/rxFormPreferences';
import Questions from './Questions';
import LoadingIndicator from '../../../../components/LoadingIndicator';

const Main = ({
  fetch,
  preference,
  metadata,
  save,
  builtInArchWires,
  fetchBuiltInArchWiresFn,
  hasUploadedArchWires
}) => {
  const [data, setData] = useState(preference);

  useEffect(() => {
    if (!preference && !data) {
      fetch();
    }
  }, [preference, data, fetch]);

  useEffect(() => {
    if (!builtInArchWires) {
      fetchBuiltInArchWiresFn();
    }
  }, [builtInArchWires, fetchBuiltInArchWiresFn]);

  useEffect(() => {
    if (preference && !data) {
      setData(preference);
    }
  }, [preference, data, setData]);

  const onSave = () => {
    save(data);
  };

  const hasOrganizationArchWires =
    typeof hasUploadedArchWires === 'boolean'
      ? hasUploadedArchWires
      : metadata && metadata.has_uploaded_arch_wires;

  if (!data) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {data && metadata && (
          <Questions
            data={data}
            onChange={setData}
            metadata={{
              ...metadata,
              arch_wires: [...(builtInArchWires || [])],
              has_uploaded_arch_wires: hasOrganizationArchWires
            }}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <SubSectionActionWrapper>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={false}
            onClick={onSave}
          >
            Save
          </LoadingButton>
        </SubSectionActionWrapper>
      </Grid>
    </Grid>
  );
};

Main.propTypes = {
  fetch: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  fetchBuiltInArchWiresFn: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  preference: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  metadata: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  builtInArchWires: PropTypes.array,
  hasUploadedArchWires: PropTypes.bool
};

Main.defaultProps = {
  builtInArchWires: null,
  preference: null,
  metadata: null,
  hasUploadedArchWires: null
};

const Wrapper = props => {
  return (
    <SubSectionWrapper title="RxForm Preference">
      <Main {...props} />
    </SubSectionWrapper>
  );
};

export default connect(
  (state, props) => {
    const { organization } = props;
    const { preference = null, metadata = null } =
      state.rxFormPreferences.organizations[organization.id] || {};
    const organizationArchWires = state.archWires.organizations[organization.id];
    return {
      preference,
      metadata,
      builtInArchWires: state.archWires.builtIn.items,
      fetchingBuiltInArchWires: state.archWires.builtIn.fetching,
      hasUploadedArchWires: organizationArchWires && organizationArchWires.items.length > 0
    };
  },
  (dispatch, props) => {
    return {
      fetch: () => dispatch(fetchRxFormPreference(props.organization.id)),
      save: data => dispatch(updateRxFormPreference(props.organization.id, data)),
      fetchBuiltInArchWiresFn: () => dispatch(fetchBuiltInArchWires())
    };
  }
)(Wrapper);
