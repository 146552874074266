import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { fetchDownloadUrlPrintingFileAction } from '../../../reducers/printingFiles';
import PatientModificationWarning from '../../../components/PatientModificationWarning';
import { isCompletedPatient } from '../../../helpers';
import { openPatientModificationWarning } from '../../../reducers/modals';
import OpenWithExternalApp from '../../../components/OpenWithExternalApp';
import { openDIBS } from '../../../helpers/dibs';

const IOSFileActions = ({ iosFile, patient }) => {
  const dispatch = useDispatch();

  const [selectedApp, setSelectedApp] = useState(null);

  const onPatientModificationWarningClose = answer => {
    if (answer) {
      dispatch(openDIBS(selectedApp, `open_file/${iosFile.id}`));
    }
  };

  const handleOpenFile = (app, file) => {
    setSelectedApp(app);
    if (isCompletedPatient(patient)) {
      dispatch(openPatientModificationWarning('PatientWorkflow-IOSFileActions'));
      return;
    }

    dispatch(openDIBS(app, `open_file/${file.id}`));
  };

  const handleDownloadFile = id => {
    dispatch(fetchDownloadUrlPrintingFileAction(id));
  };

  if (!iosFile || !iosFile.id) {
    return <span>Invalid IOS file provided</span>;
  }

  return (
    <Grid container>
      <OpenWithExternalApp
        patient={patient}
        buttonProps={{
          color: 'inherit',
          title: 'Open DIBS',
          key: 'open'
        }}
        onClick={app => handleOpenFile(app, iosFile)}
      />

      <IconButton
        color="inherit"
        title="Download File"
        key="download"
        onClick={() => handleDownloadFile(iosFile.id)}
      >
        <CloudDownloadIcon />
      </IconButton>

      <PatientModificationWarning
        id="PatientWorkflow-IOSFileActions"
        onClose={onPatientModificationWarningClose}
      />
    </Grid>
  );
};

IOSFileActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  iosFile: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired
};

IOSFileActions.defaultProps = {};

export default IOSFileActions;
