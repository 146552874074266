import List from '@material-ui/core/List';
import {
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/InsertDriveFile';
import IconButton from '@material-ui/core/IconButton';
import InventoryIcon from '@material-ui/icons/ListAlt';
import EditIcon from '@material-ui/icons/Edit';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import ConfirmationDialogOnClickWrapper from '../../../../components/ConfirmationDialogOnClickWrapper';
import Can from '../../../../components/Can';

const useStyles = makeStyles(theme => ({
  lowStockIconColor: {
    color: theme.palette.error.main
  },
  noInventoryColor: {
    color: grey[400]
  },
  withStockIconColor: {
    color: theme.palette.success.main
  },
  vtkIconColor: {
    backgroundColor: 'blueviolet'
  }
}));

const FileList = ({
  data,
  onDelete,
  onDownload,
  onOpenInventory,
  removingItems = [],
  onEdit,
  organizationId
}) => {
  const classes = useStyles();

  if (data === null) {
    return '';
  }

  const inventoryIconClassName = bracket => {
    if (!bracket.has_inventory) {
      return classes.noInventoryColor;
    }

    if (bracket.low_stock) {
      return classes.lowStockIconColor;
    }
    return classes.withStockIconColor;
  };

  return (
    <>
      <List>
        {data.map(bracket => {
          const { id, file_name: fileName, label, users, format } = bracket;
          const removing = removingItems.indexOf(id) !== -1;
          let deletionMessage =
            users.length > 0
              ? `The library is assigned to ${users.length} member${users.length > 1 ? 's' : ''}.`
              : '';
          deletionMessage += ' Are you sure you want to delete this file?';

          return (
            <ListItem key={id}>
              <ListItemAvatar>
                <Tooltip title={format === 1 ? 'Original' : 'VTK'} placement="left">
                  <Avatar className={format === 2 ? classes.vtkIconColor : ''}>
                    <FolderIcon />
                  </Avatar>
                </Tooltip>
              </ListItemAvatar>
              <ListItemText primary={label} />
              <ListItemSecondaryAction>
                <Can
                  permissions={[
                    'bracket-libraries.manage',
                    'organization.bracket-libraries.update',
                    'bracket-libraries.update',
                    'organization.bracket-libraries.view-inventory'
                  ]}
                  organizationId={organizationId}
                  yes={() => (
                    <IconButton
                      edge="end"
                      aria-label="Inventory"
                      onClick={() => onOpenInventory(bracket)}
                      title="Inventory"
                      disabled={removing}
                    >
                      <InventoryIcon className={inventoryIconClassName(bracket)} />
                    </IconButton>
                  )}
                />
                <Can
                  permissions={[
                    'bracket-libraries.manage',
                    'organization.bracket-libraries.update',
                    'bracket-libraries.update'
                  ]}
                  organizationId={organizationId}
                  yes={() => (
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => onEdit(bracket)}
                      title="Edit"
                      disabled={removing}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                />
                <Can
                  permissions={['bracket-libraries.manage', 'bracket-libraries.view']}
                  organizationId={organizationId}
                  yes={() => (
                    <IconButton
                      edge="end"
                      aria-label="download"
                      onClick={() => onDownload(id)}
                      title="Download File"
                      disabled={removing}
                    >
                      <DownloadIcon />
                    </IconButton>
                  )}
                />
                <Can
                  permissions={['bracket-libraries.manage', 'bracket-libraries.delete']}
                  organizationId={organizationId}
                  yes={() => (
                    <ConfirmationDialogOnClickWrapper
                      confirmationTitle={fileName}
                      confirmationBody={deletionMessage}
                    >
                      <IconButton
                        disabled={removing}
                        edge="end"
                        aria-label="delete"
                        onClick={() => onDelete(id)}
                        title="Delete File"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ConfirmationDialogOnClickWrapper>
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

FileList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onOpenInventory: PropTypes.func.isRequired,
  removingItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  onEdit: PropTypes.func.isRequired,
  organizationId: PropTypes.number.isRequired
};

export default FileList;
