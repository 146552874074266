import React from 'react';
import { Card, CardContent, Container } from '@material-ui/core';
import Main from '../../../components/Webhooks';

const Webhooks = props => {
  return (
    <Container maxWidth="md">
      <Card>
        <CardContent>
          <Main {...props} />
        </CardContent>
      </Card>
    </Container>
  );
};

Webhooks.propTypes = {};

export default Webhooks;
