import React, { useMemo, useState } from 'react';
import { produce } from 'immer';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import _find from 'lodash/find';
import PropTypes from 'prop-types';
import IncrementIcon from '@material-ui/icons/Add';
import DecrementIcon from '@material-ui/icons/Remove';
import { ReactComponent as UR7 } from '../../../../../assets/teeth/U-01.svg';
import { ReactComponent as UR6 } from '../../../../../assets/teeth/U-02.svg';
import { ReactComponent as UR5 } from '../../../../../assets/teeth/U-03.svg';
import { ReactComponent as UR4 } from '../../../../../assets/teeth/U-04.svg';
import { ReactComponent as UR3 } from '../../../../../assets/teeth/U-05.svg';
import { ReactComponent as UR2 } from '../../../../../assets/teeth/U-06.svg';
import { ReactComponent as UR1 } from '../../../../../assets/teeth/U-07.svg';
import { ReactComponent as UL1 } from '../../../../../assets/teeth/U-08.svg';
import { ReactComponent as UL2 } from '../../../../../assets/teeth/U-09.svg';
import { ReactComponent as UL3 } from '../../../../../assets/teeth/U-10.svg';
import { ReactComponent as UL4 } from '../../../../../assets/teeth/U-11.svg';
import { ReactComponent as UL5 } from '../../../../../assets/teeth/U-12.svg';
import { ReactComponent as UL6 } from '../../../../../assets/teeth/U-13.svg';
import { ReactComponent as UL7 } from '../../../../../assets/teeth/U-14.svg';
import { ReactComponent as LR7 } from '../../../../../assets/teeth/L-01.svg';
import { ReactComponent as LR6 } from '../../../../../assets/teeth/L-02.svg';
import { ReactComponent as LR5 } from '../../../../../assets/teeth/L-03.svg';
import { ReactComponent as LR4 } from '../../../../../assets/teeth/L-04.svg';
import { ReactComponent as LR3 } from '../../../../../assets/teeth/L-05.svg';
import { ReactComponent as LR2 } from '../../../../../assets/teeth/L-06.svg';
import { ReactComponent as LR1 } from '../../../../../assets/teeth/L-07.svg';
import { ReactComponent as LL1 } from '../../../../../assets/teeth/L-08.svg';
import { ReactComponent as LL2 } from '../../../../../assets/teeth/L-09.svg';
import { ReactComponent as LL3 } from '../../../../../assets/teeth/L-10.svg';
import { ReactComponent as LL4 } from '../../../../../assets/teeth/L-11.svg';
import { ReactComponent as LL5 } from '../../../../../assets/teeth/L-12.svg';
import { ReactComponent as LL6 } from '../../../../../assets/teeth/L-13.svg';
import { ReactComponent as LL7 } from '../../../../../assets/teeth/L-14.svg';
import Tooth from './Tooth';
import InputQty from './InputQty';

const useStyles = makeStyles(() => ({
  toothGrid: {
    // width: '7.14%'
  },
  inputQtyContainer: {
    minHeight: '60px'
  },
  counterButton: {
    borderRadius: '50%',
    padding: '15px',
    margin: '0 2px'
  },
  counterInput: {
    width: '100px'
  },
  counterContainer: {
    paddingBottom: '16px'
  }
}));

const Teeth = ({ stocks, onChange, disabled, readOnly, lowStockThreshold }) => {
  const classes = useStyles();
  const [counter, setCounter] = useState(0);
  const [hoveredTooth, setHoveredTooth] = useState(null);

  const teeth = useMemo(() => {
    const getData = toothNumber => {
      const data = _find(stocks, ['tooth_number', toothNumber]);

      const { available = null, increment = null } = data || {};

      const showTooth = available !== null || increment !== null;

      return {
        available,
        increment,
        showTooth
      };
    };

    return {
      UR8: {
        component: UR7,
        ...getData('UR8'),
        label: 'UR8'
      },
      UR7: {
        component: UR7,
        ...getData('UR7'),
        label: 'UR7'
      },
      UR6: {
        component: UR6,
        ...getData('UR6'),
        label: 'UR6'
      },
      UR5: {
        component: UR5,
        ...getData('UR5'),
        label: 'UR5'
      },
      UR4: {
        component: UR4,
        ...getData('UR4'),
        label: 'UR4'
      },
      UR3: {
        component: UR3,
        ...getData('UR3'),
        label: 'UR3'
      },
      UR2: {
        component: UR2,
        ...getData('UR2'),
        label: 'UR2'
      },
      UR1: {
        component: UR1,
        ...getData('UR1'),
        label: 'UR1'
      },
      UL1: {
        component: UL1,
        ...getData('UL1'),
        label: 'UL1'
      },
      UL2: {
        component: UL2,
        ...getData('UL2'),
        label: 'UL2'
      },
      UL3: {
        component: UL3,
        ...getData('UL3'),
        label: 'UL3'
      },
      UL4: {
        component: UL4,
        ...getData('UL4'),
        label: 'UL4'
      },
      UL5: {
        component: UL5,
        ...getData('UL5'),
        label: 'UL5'
      },
      UL6: {
        component: UL6,
        ...getData('UL6'),
        label: 'UL6'
      },
      UL7: {
        component: UL7,
        ...getData('UL7'),
        label: 'UL7'
      },
      UL8: {
        component: UL7,
        ...getData('UL8'),
        label: 'UL8'
      },
      LR8: {
        component: LR7,
        ...getData('LR8'),
        label: 'LR8'
      },
      LR7: {
        component: LR7,
        ...getData('LR7'),
        label: 'LR7'
      },
      LR6: {
        component: LR6,
        ...getData('LR6'),
        label: 'LR6'
      },
      LR5: {
        component: LR5,
        ...getData('LR5'),
        label: 'LR5'
      },
      LR4: {
        component: LR4,
        ...getData('LR4'),
        label: 'LR4'
      },
      LR3: {
        component: LR3,
        ...getData('LR3'),
        label: 'LR3'
      },
      LR2: {
        component: LR2,
        ...getData('LR2'),
        label: 'LR2'
      },
      LR1: {
        component: LR1,
        ...getData('LR1'),
        label: 'LR1'
      },
      LL1: {
        component: LL1,
        ...getData('LL1'),
        label: 'LL1'
      },
      LL2: {
        component: LL2,
        ...getData('LL2'),
        label: 'LL2'
      },
      LL3: {
        component: LL3,
        ...getData('LL3'),
        label: 'LL3'
      },
      LL4: {
        component: LL4,
        ...getData('LL4'),
        label: 'LL4'
      },
      LL5: {
        component: LL5,
        ...getData('LL5'),
        label: 'LL5'
      },
      LL6: {
        component: LL6,
        ...getData('LL6'),
        label: 'LL6'
      },
      LL7: {
        component: LL7,
        ...getData('LL7'),
        label: 'LL7'
      },
      LL8: {
        component: LL7,
        ...getData('LL8'),
        label: 'LL8'
      }
    };
  }, [stocks]);

  const findStockIndex = toothNumber => {
    return stocks.findIndex(stock => stock.tooth_number === toothNumber);
  };

  const deleteStock = toothNumber => {
    onChange(
      produce(stocks, draft => {
        const index = findStockIndex(toothNumber);
        if (index !== -1) draft.splice(index, 1);
      })
    );
  };

  const addStock = (toothNumber, increment = 0) => {
    onChange(
      produce(stocks, draft => {
        draft.push({
          tooth_number: toothNumber,
          increment
        });
      })
    );
  };

  const updateStock = (toothNumber, increment) => {
    onChange(
      produce(stocks, draft => {
        const index = findStockIndex(toothNumber);

        if (index !== -1) {
          draft[index].increment = increment;
        }
      })
    );
  };

  const handleInputQtyChange = (toothNumber, additional) => {
    const index = findStockIndex(toothNumber);

    if (index !== -1) {
      updateStock(toothNumber, additional);
    } else {
      addStock(toothNumber, additional);
    }
  };

  const handleToothClick = (event, tooth) => {
    event.preventDefault();

    const index = findStockIndex(tooth);

    if (index === -1) {
      handleInputQtyChange(tooth, 0);
      return;
    }

    const stock = stocks[index];

    const total = (stock.available || 0) + stock.increment;

    if (total === 0) {
      // not set
      deleteStock(tooth);
    }
  };

  const toothMode = tooth => {
    const { showTooth, available, increment } = tooth;

    const total = +(available || 0) + +(increment || 0);

    if (showTooth && lowStockThreshold > 0 && total <= lowStockThreshold) {
      return 2;
    }

    return showTooth ? 0 : 1;
  };

  const generateTeeth = part =>
    Object.keys(teeth)
      .filter(t => t.startsWith(part))
      .map(key => {
        const tooth = teeth[key];

        return (
          <Grid
            key={key}
            className={classes.toothGrid}
            item
            onMouseOver={() => {
              if (disabled || readOnly) {
                return;
              }
              setHoveredTooth(key);
            }}
            onMouseLeave={() => setHoveredTooth(null)}
          >
            <Grid container direction="column" alignItems="center" spacing={0}>
              <Grid item className={classes.inputQtyContainer}>
                {(tooth.showTooth || hoveredTooth === key) && (
                  <InputQty
                    key={key}
                    qty={tooth.available}
                    additionalQty={tooth.increment}
                    onChange={({ additionalQty, qty, totalQty }) =>
                      handleInputQtyChange(key, additionalQty, qty, totalQty)
                    }
                    disabled={disabled}
                    readOnly={readOnly}
                  />
                )}
              </Grid>
              <Grid item>
                <Tooth
                  key={key}
                  image={tooth.component}
                  mode={toothMode(tooth)}
                  label={tooth.label || ''}
                  onClick={e => handleToothClick(e, key)}
                  disabled={disabled || readOnly}
                />
              </Grid>
            </Grid>
          </Grid>
        );
      });

  const handleCounterInputChange = e => {
    const regex = /^[0-9]*$/;
    if (!regex.test(e.target.value)) {
      return;
    }
    setCounter(Number(e.target.value || 0));
  };

  const updateStocksIncrements = multiplier => {
    const num = counter * multiplier;

    onChange(
      produce(stocks, draft => {
        for (let i = 0; i < draft.length; i += 1) {
          const stock = draft[i];
          stock.increment += num;
          const available = stock.available || 0;
          const total = available + stock.increment;

          if (total < 0 && available >= 0) {
            stock.increment = available * -1;
          }

          draft[i] = {
            ...stock
          };
        }
      })
    );

    setCounter(0);
  };

  return (
    <Box mt={3} pb={3} pt={3} borderTop={1} borderBottom={1}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        {!readOnly && (
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            className={classes.counterContainer}
          >
            <Grid item>
              <TextField
                value={counter}
                variant="outlined"
                className={classes.counterInput}
                onInput={handleCounterInputChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item>
              <Button
                disabled={counter < 1 || disabled}
                variant="contained"
                color="default"
                className={classes.counterButton}
                onClick={() => updateStocksIncrements(1)}
              >
                <IncrementIcon />
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={counter < 1 || disabled}
                variant="contained"
                color="default"
                className={classes.counterButton}
                onClick={() => updateStocksIncrements(-1)}
              >
                <DecrementIcon />
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid item xs>
          <Typography variant="subtitle1" gutterBottom align="left">
            Upper Right
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="subtitle1" gutterBottom align="center">
            Maxillary
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="subtitle1" gutterBottom align="right">
            Upper Left
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" spacing={0}>
        {generateTeeth('U')}
      </Grid>
      <Grid container justifyContent="center" spacing={0}>
        {generateTeeth('L')}
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs>
          <Typography variant="subtitle1" gutterBottom align="left">
            Lower Right
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="subtitle1" gutterBottom align="center">
            Mandibular
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="subtitle1" gutterBottom align="right">
            Lower Left
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

Teeth.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  stocks: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  lowStockThreshold: PropTypes.number
};

Teeth.defaultProps = {
  onChange: () => {},
  disabled: false,
  readOnly: false,
  lowStockThreshold: 0
};

export default Teeth;
