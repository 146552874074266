import React from 'react';
import OnboardingIcon from '@material-ui/icons/LibraryAddCheck';
import { IconButton, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from '../../reducers/onboardings';
import Can from '../Can';

// THIS BUTTON OPENS THE ONBOARDING MODAL FOR DOCTORS

const OnboardingButton = () => {
  const organization = useSelector(state => state.auth.currentOrganization);
  const isDoctorsView = useSelector(state => !state.auth.isManagement);
  const modalOpened = useSelector(state => state.onboardings.modalOpened);
  const dispatch = useDispatch();
  const onboarding = useSelector(state => state.onboardings.current);

  if (!isDoctorsView || !organization || !organization.onboarding) {
    return '';
  }

  const handleClick = () => {
    if (modalOpened) {
      dispatch(closeModal());
    } else {
      dispatch(openModal());
    }
  };

  return (
    onboarding &&
    onboarding.status !== 'Completed' && (
      <Can
        permissions={['organization.onboarding.view']}
        yes={() => (
          <Tooltip title="Onboarding" arrow open={!modalOpened}>
            <IconButton color="inherit" onClick={handleClick}>
              <OnboardingIcon />
            </IconButton>
          </Tooltip>
        )}
      />
    )
  );
};

export default OnboardingButton;
