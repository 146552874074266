import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { Chip, Box } from '@material-ui/core';
import { uniqBy } from 'lodash';
import FormDialog from '../../../../components/FormDialog';
import FormatSelectionField from './FormatSelectionField';

const extractUsers = users => {
  const items = users.map(user => ({ label: user.full_name, id: user.id }));
  return uniqBy(items, 'id');
};

const EditDialog = ({ open, setOpen, name, users, bracket, onBracketUpdate }) => {
  const [data, setData] = useState({
    users: bracket.users ? extractUsers(bracket.users) : [],
    format: bracket.format
  });

  const [availableUsers, setAvailableUsers] = useState([]);

  useEffect(() => {
    setAvailableUsers(
      users
        .filter(option => data.users.findIndex(user => option.id === user.id) === -1)
        .map(user => ({ label: user.full_name, id: user.id }))
    );
  }, [data, setAvailableUsers, users]);

  const handleClose = response => {
    if (!response) {
      setOpen(false);
    } else {
      onBracketUpdate({ userIds: data.users.map(option => option.id), format: data.format });
    }
  };

  return (
    <FormDialog
      setOpen={setOpen}
      open={open}
      titleText={name}
      acceptButtonText="Save"
      handleClose={handleClose}
      fullWidth
    >
      <Box flexDirection="column" width={1}>
        <Autocomplete
          multiple
          freeSolo
          options={availableUsers}
          value={data.users}
          renderTags={(val, getTagProps) =>
            val.map((option, index) => (
              <Chip disabled variant="outlined" label={option.label} {...getTagProps({ index })} />
            ))
          }
          getOptionLabel={option => option.label || ''}
          onChange={(event, bracketValue) => {
            const invalid =
              Array.isArray(bracketValue) && bracketValue.find(val => typeof val !== 'object');
            if (!invalid) {
              setData({
                ...data,
                users: bracketValue
              });
            }
          }}
          fullWidth
          renderInput={params => (
            <TextField
              size="small"
              variant="outlined"
              multiline
              {...params}
              placeholder="Add User"
              label="Visible to"
            />
          )}
        />
        <FormatSelectionField
          value={data.format}
          onChange={format => setData({ ...data, format })}
        />
      </Box>
    </FormDialog>
  );
};

EditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  users: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  bracket: PropTypes.object.isRequired,
  onBracketUpdate: PropTypes.func.isRequired
};

export default EditDialog;
