import { produce } from 'immer';

export const OPEN_GLOBAL_BACKDROP = 'OPEN_GLOBAL_BACKDROP';
export const CLOSE_GLOBAL_BACKDROP = 'CLOSE_GLOBAL_BACKDROP';

const initialState = {
  open: false,
  options: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_GLOBAL_BACKDROP:
      return produce(state, draft => {
        draft.open = true;
        draft.options = action.options;
      });
    case CLOSE_GLOBAL_BACKDROP:
      return produce(state, draft => {
        draft.open = false;
        draft.options = {};
      });
    default:
      return state;
  }
};

export function openGlobalBackdropAction(options = {}) {
  return dispatch => {
    dispatch({
      type: OPEN_GLOBAL_BACKDROP,
      options
    });
  };
}

export function closeGlobalBackdropAction() {
  return dispatch => {
    dispatch({
      type: CLOSE_GLOBAL_BACKDROP
    });
  };
}
