import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ErrorMessage from '../ErrorMessage';

const CheckAuthorization = ({ roles, profiles, unauthorizedRedirectUrl, children }) => {
  const history = useHistory();
  const user = useSelector(state => state.auth.user);
  const isManagement = useSelector(state => state.auth.isManagement);

  const isAuthorized = (currentUser, requiredRoles) => {
    if (!requiredRoles.length) {
      return true;
    }

    const foundRole = requiredRoles.find(role => {
      return currentUser.roles.findIndex(userRoles => userRoles.name === role) > -1;
    });

    return !!foundRole;
  };

  const isProfileFound = (allProfiles, requiredProfile) => {
    return allProfiles.findIndex(profile => profile === requiredProfile) > -1;
  };

  const isValidProfile = () => {
    let requiredProfile = 'normal';
    if (isManagement) {
      requiredProfile = 'management';
    }

    return isProfileFound(profiles, requiredProfile);
  };

  const hasNoPermission = !isValidProfile() || !isAuthorized(user, roles);

  useEffect(() => {
    if (hasNoPermission && unauthorizedRedirectUrl) {
      history.push(unauthorizedRedirectUrl);
    }
  }, [hasNoPermission, unauthorizedRedirectUrl, history]);

  if (hasNoPermission) {
    return <ErrorMessage message="You don't have permission to access this page." showHomeLink />;
  }

  return children;
};

CheckAuthorization.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  profiles: PropTypes.arrayOf(PropTypes.string),
  unauthorizedRedirectUrl: PropTypes.string
};

CheckAuthorization.defaultProps = {
  profiles: ['normal', 'management'],
  unauthorizedRedirectUrl: null
};

export default CheckAuthorization;
