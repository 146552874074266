import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import AddToPhotos from '@material-ui/icons/AddToPhotos';
import PrintIcon from '@material-ui/icons/Print';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import TuneIcon from '@material-ui/icons/Tune';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import WifiTetheringIcon from '@material-ui/icons/WifiTethering';
import { blue } from '@material-ui/core/colors';
import { Link, useHistory } from 'react-router-dom';
import ArchBreakdown from './ArchBreakdown';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    padding: '16px 16px 8px',
    margin: theme.spacing(0.5),
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText
  },
  cardIcon: {
    fontSize: '4rem',
    opacity: 0.1
  },
  title: {
    fontWeight: 'inherit',
    fontSize: '1.1rem'
  },
  chip: {
    fontSize: '0.75rem',
    margin: '2px',
    color: 'white',
    background: blue[500]
  },
  number: {
    '&.MuiChip-avatar': {
      background: 'transparent',
      color: 'white',
      borderRight: '1px solid #ffffff80',
      borderRadius: 0,
      padding: '0 5px',
      width: 'auto',
      height: 'auto'
    }
  },
  liveChip: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    background: 'white',
    padding: '5px',
    fontSize: '40px',
    transform: 'translate(-50%, -50%)'
  }
}));

function StatisticsCard({ title, value, icon }) {
  const history = useHistory();
  const classes = useStyles();
  const totalValue = _.isObject(value) ? value.total : value;
  const description = _.isObject(value) ? value.description : '';
  const totalDescription = _.isObject(value) ? value.total_description : '';
  const MAPPED_ICONS = {
    shipped: <LocalShippingIcon className={classes.cardIcon} />,
    submissions: <ReceiptIcon className={classes.cardIcon} />,
    flawed_trays: <DeveloperBoardIcon className={classes.cardIcon} />,
    print_requests: <PrintIcon className={classes.cardIcon} />,
    insert_brackets: <AddToPhotos className={classes.cardIcon} />,
    completed_cases: <DoneAllIcon className={classes.cardIcon} />,
    initial_setups: <TuneIcon className={classes.cardIcon} />
  };

  const ORDER_MAPPER = {
    NEW: 1,
    IN_PROGRESS: 2,
    PENDING: 3,
    COMPLETED: 4,
    CANCELED: 5,
    CANCELLED: 5,
    OrthoSelect: 1,
    'In-house': 2,
    '3rd Party': 3,
    ON_HOLD: 6
  };

  const patientPageLink = filters => {
    let params = {};

    if (_.isObject(filters)) {
      params = { ...filters };
    }

    const q = new URLSearchParams(params);

    return `/patients-page?${q.toString()}`;
  };

  const handleTagClick = status => {
    history.push(patientPageLink(_.get(value, `filters.["${value.key}.${status}"]`, {})));
  };

  return (
    <Grid container item xs={12} sm={6} md={4} className={classes.wrapper}>
      <Box className={classes.card}>
        <Typography className={classes.title}>{_.startCase(title)}</Typography>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid
            item
            md={9}
            style={{ display: 'flex', flexDirection: 'column' }}
            justifyContent="start"
            alignItems="start"
          >
            <Grid item md={12} style={{ display: 'flex' }} alignItems="center">
              <Typography variant="h4" color="primary">
                <Link to={patientPageLink(_.get(value, `filters.${value.key}`, {}))}>
                  {totalValue}
                </Link>
              </Typography>
              <Typography style={{ marginLeft: 10 }} variant="h8" color="primary">
                {totalDescription ? `(${totalDescription})` : ''}
              </Typography>
            </Grid>
            <Grid item md={12}>
              {description && (
                <Typography variant="h8" color="primary">
                  {description}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item md={3} style={{ display: 'flex' }} justifyContent="end" alignItems="center">
            {MAPPED_ICONS[icon]}
          </Grid>
        </Grid>

        {_.isObject(value.live_data) && (
          <Box>
            <Box py={4} position="relative">
              <Divider variant="middle" />
              <WifiTetheringIcon color="error" className={classes.liveChip} />
            </Box>
            <Box>
              {_.map(ORDER_MAPPER, (v, key) => {
                // eslint-disable-next-line no-prototype-builtins
                if (!value.live_data.hasOwnProperty(key)) return null;

                const keyString = _.kebabCase(key);
                const chipClass = `${classes.chip}`;
                const item = value.live_data[key];

                if (_.isObject(item)) {
                  return (
                    <ArchBreakdown
                      chipClassName={chipClass}
                      color={blue[500]}
                      key={key}
                      item={item}
                      onClick={
                        ['flawed', 'print', 'insert'].includes(value.key) &&
                        ['CANCELLED', 'COMPLETED'].includes(key)
                          ? null
                          : () => handleTagClick(key)
                      }
                    />
                  );
                }
                return (
                  <Chip
                    className={chipClass}
                    key={key}
                    size="small"
                    color={blue[500]}
                    label={_.startCase(keyString)}
                    avatar={<span className={classes.number}>{item}</span>}
                    onClick={
                      ['flawed', 'print', 'insert'].includes(value.key) &&
                      ['CANCELLED', 'COMPLETED'].includes(key)
                        ? null
                        : () => handleTagClick(key)
                    }
                  />
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  );
}

StatisticsCard.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  icon: PropTypes.string
};

StatisticsCard.defaultProps = {
  icon: 'default'
};
export default StatisticsCard;
