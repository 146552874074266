import React from 'react';
import { DatePicker, Input, TimePicker } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import UserAutoComplete from './UserAutoComplete';
import { findRolesInUser } from '../../helpers';
import { ROLES } from '../../constants';

const useStyles = makeStyles(() => ({
  autoComplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: '1px 45px 1px 0 !important',
      border: '1px solid #d9d9d9 !important'
    },
    '& .MuiAutocomplete-input': {
      padding: '2px !important',
      lineHeight: 1.15,
      fontSize: 12
    },
    '& .MuiAutocomplete-endAdornment': {
      right: '0 !important',
      '& .MuiAutocomplete-clearIndicator': {
        padding: '2px !important'
      },
      '& .MuiAutocomplete-popupIndicator': {
        padding: '2px !important'
      }
    }
  }
}));

const NewEditableCell = ({
  title,
  children,
  dataIndex,
  record,
  editable,
  handleSave,
  type,
  deletable,
  editing,
  ...rest
}) => {
  const classes = useStyles();

  const currentUser = useSelector(state => state.auth.user);

  const save = value => {
    if (type !== 'user') {
      handleSave({ ...record, [dataIndex]: value });
      return;
    }

    const user = {
      [dataIndex]: null,
      user_id: null,
      user_full_name: null,
      user_email: null
    };

    if (value) {
      user[dataIndex] = value.full_name;
      user.user_id = value.id;
      user.user_full_name = value.full_name;
      user.user_email = value.email;
    }

    handleSave({
      ...record,
      ...user
    });
  };

  const getOptions = () => {
    const options = [];

    if (currentUser && findRolesInUser(['tech_support'], currentUser)) {
      options.push({
        id: currentUser.id,
        full_name: currentUser.full_name,
        email: currentUser.email
      });
    }

    if (record[dataIndex] && record.user_id !== (currentUser.id || 0)) {
      options.push({
        id: record.user_id,
        full_name: record[dataIndex],
        email: record.user_email
      });
    }
    return options;
  };

  const focus = e => {
    e.target.focus();
  };

  const renderInput = () => {
    let input = <Input value={record[dataIndex]} onChange={e => save(e.target.value)} />;
    switch (type) {
      case 'datetime':
        input = (
          <DatePicker
            showTime={{ format: 'HH:mm:ss' }}
            format="YYYY-MM-DD HH:mm:ss"
            value={record[dataIndex] ? moment(record[dataIndex]) : null}
            onChange={(_, value) => save(value)}
            onMouseEnter={focus}
          />
        );
        break;
      case 'date':
        input = (
          <DatePicker
            autoFocus
            value={record[dataIndex] ? moment(record[dataIndex]) : null}
            onChange={(_, value) => save(value)}
            format="YYYY-MM-DD"
            onMouseEnter={focus}
          />
        );
        break;
      case 'time':
        input = (
          <TimePicker
            value={record[dataIndex] ? moment(record[dataIndex], 'HH:mm') : null}
            format="HH:mm"
            onChange={(_, value) => save(value)}
            onMouseEnter={focus}
          />
        );
        break;
      case 'user':
        input = (
          <UserAutoComplete
            currentUserOnly={findRolesInUser(['tech_support'], currentUser)}
            className={classes.autoComplete}
            size="small"
            onChange={(e, value) => {
              save(value);
            }}
            value={
              record[dataIndex]
                ? {
                    id: record.user_id,
                    full_name: record[dataIndex],
                    email: record.user_email
                  }
                : null
            }
            options={getOptions()}
            roles={[ROLES.techSupport, ROLES.superAdmin, ROLES.admin]}
            onMouseEnter={focus}
          />
        );
        break;
      default:
        break;
    }

    return input;
  };

  return <td {...rest}>{editing && editable ? renderInput() : children}</td>;
};

NewEditableCell.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.array.isRequired,
  editable: PropTypes.bool,
  dataIndex: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  type: PropTypes.string,
  deletable: PropTypes.bool,
  editing: PropTypes.bool
};

NewEditableCell.defaultProps = {
  type: '',
  editable: false,
  deletable: false,
  editing: false
};

export default NewEditableCell;
