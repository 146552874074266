import { produce } from 'immer';
import PricingApiService from '../services/api/pricing';
import { enqueueNotification } from './notifications';

export const FETCHING_ORGANIZATION_PRICING = 'FETCHING_ORGANIZATION_PRICING';
export const FETCHED_ORGANIZATION_PRICING = 'FETCHED_ORGANIZATION_PRICING';
export const UPDATING_ORGANIZATION_PRICING = 'UPDATING_ORGANIZATION_PRICING';
export const UPDATED_ORGANIZATION_PRICING = 'UPDATED_ORGANIZATION_PRICING';

const initialState = {
  organizations: {}
};

export default (state = initialState, { type, payload = null }) => {
  const organizationId = payload ? payload.organization_id : -1;
  const organizationState = state.organizations[organizationId] || {
    fetching: false,
    updating: false
  };

  switch (type) {
    case FETCHING_ORGANIZATION_PRICING:
      return produce(state, draft => {
        draft.organizations[organizationId] = {
          ...organizationState,
          fetching: true,
          pricing: null
        };
      });

    case FETCHED_ORGANIZATION_PRICING:
      return produce(state, draft => {
        draft.organizations[organizationId] = {
          ...organizationState,
          fetching: false,
          ...payload
        };
      });

    case UPDATING_ORGANIZATION_PRICING:
      return produce(state, draft => {
        draft.organizations[organizationId] = {
          ...organizationState,
          updating: true
        };
      });

    case UPDATED_ORGANIZATION_PRICING:
      return produce(state, draft => {
        draft.organizations[organizationId] = {
          ...organizationState,
          updating: false,
          ...payload
        };
      });
    default:
      return state;
  }
};

export function fetchOrganizationPricing(organizationId) {
  return dispatch => {
    dispatch({ type: FETCHING_ORGANIZATION_PRICING, payload: { organization_id: organizationId } });

    const pricingService = new PricingApiService();
    pricingService.getOrganization(organizationId).then(({ data: pricing }) => {
      dispatch({
        type: FETCHED_ORGANIZATION_PRICING,
        payload: {
          organization_id: organizationId,
          pricing
        }
      });
    });
  };
}

export function saveOrganizationPricing(organizationId, pricing) {
  return dispatch => {
    dispatch({ type: UPDATING_ORGANIZATION_PRICING });

    const pricingService = new PricingApiService();
    return pricingService
      .updateOrganization(organizationId, pricing)
      .then(response => {
        dispatch({
          type: UPDATED_ORGANIZATION_PRICING,
          payload: { organization_id: organizationId, pricing: response.data }
        });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
