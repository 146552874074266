const iosFileNameValidator = (name, existingFiles, patient) => {
  const fileExt = name.includes('.') ? `.${name.split('.').pop()}` : '';
  let sameFileSuffixIndex = 0;
  let alreadyExists;
  let finalFileName;

  // without extension yet
  let baseFileName = `${patient.last_name}, ${patient.first_name}`;
  if (patient.case_number_text) {
    baseFileName = `${baseFileName}, ${patient.case_number_text}`;
  }
  if (patient.numeric_suffix) {
    baseFileName = `${baseFileName} (${patient.numeric_suffix})`;
  }

  if (patient)
    do {
      const sameFileSuffix = sameFileSuffixIndex ? ` (${sameFileSuffixIndex})` : '';
      const fileName = `${baseFileName}${sameFileSuffix}${fileExt}`;
      alreadyExists = !!existingFiles.find(file => file.original_name === fileName);
      if (alreadyExists) {
        sameFileSuffixIndex += 1;
      } else {
        finalFileName = fileName;
      }
    } while (alreadyExists);

  return finalFileName || name;
};

export default iosFileNameValidator;
