import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { CardContent } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import CardActions from '@material-ui/core/CardActions';
import React, { useEffect, useState } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { replaceNullToEmpty } from '../../helpers';
import LoadingButton from '../LoadingButton';
import ConfirmationDialogOnClickWrapper from '../ConfirmationDialogOnClickWrapper';
import Can from '../Can';
import CountryList from '../CountryList';
import OrganizationsApiService from '../../services/api/organizations';
import PhoneNumber from '../PhoneNumber';
import CommonPropTypes from '../../common/PropTypes';
import NameField from '../Formik/NameField';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  actionButton: {
    marginLeft: 'auto'
  }
}));

const LocationForm = ({
  organization,
  onSubmit,
  isSaving,
  location,
  isDeleting,
  onDelete,
  disabledAddressFields
}) => {
  const classes = useStyles();
  const { Option } = Select;
  const [users, setUsers] = useState([]);
  const organizationId = location ? location.organization_id : organization.id;
  useEffect(() => {
    if (!organizationId) {
      return;
    }

    const fetchOrganizationMembers = async () => {
      const organizationApiService = new OrganizationsApiService();
      const { members } = await organizationApiService.getOrganizationMembers(organizationId);
      setUsers(members);
    };
    fetchOrganizationMembers();
  }, [organizationId]);

  const getLocationWithUserIds = () => {
    if (!location.users) {
      return null;
    }

    const userIds = location.users.map(user => user.id);

    return {
      ...location,
      users: userIds
    };
  };

  const initialValues = {
    location_name: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    street_address: '',
    street_address_line_2: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    users: [],
    organization_id: organization.id
  };

  return (
    <Formik
      initialValues={location ? replaceNullToEmpty(getLocationWithUserIds()) : initialValues}
      enableReinitialize
      validationSchema={Yup.object().shape({
        location_name: Yup.string().required('Required'),
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        email: Yup.string()
          .email()
          .required('Required'),
        phone_number: Yup.string()
          .required('Required')
          .matches(/^\+[1-9]\d{6,14}$/, 'Invalid phone number.'),
        street_address: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        state: Yup.string()
          .required('Required')
          .length(2),
        country: Yup.string().required('Required'),
        zip_code: Yup.string().required('Required')
      })}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty, values, setFieldValue }) => (
        <Form className={classes.form} noValidate>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  fullWidth
                  id="location_name"
                  label="Location Name"
                  name="location_name"
                  disabled={disabledAddressFields}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  autoComplete="off"
                  name="first_name"
                  variant="outlined"
                  required
                  fullWidth
                  id="first_name"
                  label="First Name"
                  disabled={disabledAddressFields}
                  component={NameField}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  name="last_name"
                  disabled={disabledAddressFields}
                  component={NameField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  disabled={disabledAddressFields}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  required
                  id="country"
                  label="Country"
                  name="country"
                  disabled={disabledAddressFields}
                  component={CountryList}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="phone_number"
                  label="Phone Number"
                  name="phone_number"
                  component={PhoneNumber}
                  selectedCountry={values.country}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="state"
                  label="State / Province"
                  name="state"
                  disabled={disabledAddressFields}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  name="city"
                  variant="outlined"
                  required
                  fullWidth
                  id="city"
                  label="City"
                  disabled={disabledAddressFields}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="zip_code"
                  label="Postal / Zip Code"
                  name="zip_code"
                  disabled={disabledAddressFields}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  name="street_address"
                  label="Street Address"
                  id="street_address"
                  disabled={disabledAddressFields}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  fullWidth
                  name="street_address_line_2"
                  label="Street Address Line 2"
                  id="street_address_line_2"
                  disabled={disabledAddressFields}
                  component={TextField}
                />
              </Grid>
              {location && (
                <Grid item xs={12}>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend">Users</FormLabel>
                    <Select
                      mode="multiple"
                      placeholder="Please select"
                      defaultValue={values.users}
                      onChange={value => setFieldValue('users', value || [])}
                      style={{ width: '100%' }}
                      optionFilterProp="label"
                    >
                      {users.map(user => {
                        return (
                          <Option key={user.id} value={user.id} label={user.full_name}>
                            {user.full_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Can
              permissions={[
                'locations.manage',
                'locations.create',
                'locations.update',
                'organization.locations.create',
                'organization.locations.update'
              ]}
              organizationId={organization.id}
              yes={() => (
                <>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={isSaving}
                    disabled={!dirty || !isValid || isSaving || isDeleting}
                    className={classes.actionButton}
                  >
                    Save
                  </LoadingButton>
                </>
              )}
            />
            {location && (
              <Can
                permissions={[
                  'locations.manage',
                  'locations.delete',
                  'organization.locations.delete'
                ]}
                organizationId={organization.id}
                yes={() => (
                  <ConfirmationDialogOnClickWrapper
                    confirmationBody="Are you sure you want to delete this location?"
                    confirmationTitle="Delete Location"
                  >
                    <LoadingButton
                      type="button"
                      variant="contained"
                      color="secondary"
                      loading={isDeleting}
                      disabled={isDeleting || isSaving}
                      onClick={onDelete}
                      className={classes.actionButton}
                    >
                      Delete
                    </LoadingButton>
                  </ConfirmationDialogOnClickWrapper>
                )}
              />
            )}
          </CardActions>
        </Form>
      )}
    </Formik>
  );
};

LocationForm.propTypes = {
  organization: CommonPropTypes.organization.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
  disabledAddressFields: PropTypes.bool
};

LocationForm.defaultProps = {
  location: null,
  onDelete: () => {},
  isDeleting: false,
  disabledAddressFields: false
};

export default LocationForm;
