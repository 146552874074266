import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class TechWorkflowProcessApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  update(techWorkflowProcessId, data) {
    return this.request({
      method: 'put',
      url: `/tech-workflow-processes/${techWorkflowProcessId}`,
      data: { ...data }
    });
  }

  start(techWorkflowProcessId) {
    return this.request({
      method: 'patch',
      url: `/tech-workflow-processes/${techWorkflowProcessId}/start`
    });
  }

  finish(techWorkflowProcessId) {
    return this.request({
      method: 'patch',
      url: `/tech-workflow-processes/${techWorkflowProcessId}/finish`
    });
  }
}
