import { ROLE_OPTIONS } from '../../../constants';

export default {
  super_admin: {
    label: ROLE_OPTIONS.super_admin.label,
    filter: ROLE_OPTIONS.super_admin.key
  },
  admin: {
    label: ROLE_OPTIONS.admin.label,
    filter: ROLE_OPTIONS.admin.key
  },
  tech_support: {
    label: ROLE_OPTIONS.tech_support.label,
    filter: ROLE_OPTIONS.tech_support.key
  },
  supervisor: {
    label: ROLE_OPTIONS.supervisor.label,
    filter: ROLE_OPTIONS.supervisor.key
  },
  lab_partner: {
    label: ROLE_OPTIONS.lab_partner.label,
    filter: ROLE_OPTIONS.lab_partner.key
  },
  doctor: {
    label: ROLE_OPTIONS.doctor.label,
    filter: ROLE_OPTIONS.doctor.key
  },
  staff_member: {
    label: 'Staff Member',
    filter: 'staff_member'
  },
  organization: {
    label: 'Organization',
    filter: 'organization'
  },
  location: {
    label: 'Location',
    filter: 'location'
  }
};
