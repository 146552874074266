import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import OrganizationsApiService from '../services/api/organizations';
import { SET_CURRENT_USER, SET_CURRENT_ORGANIZATION } from './auth';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_ORGANIZATIONS_PREFIX = 'FETCH_ORGANIZATIONS';
export const FETCH_ORGANIZATIONS_REQUEST_ACTION = `${FETCH_ORGANIZATIONS_PREFIX}_REQUEST_ACTION`;
export const FETCH_ORGANIZATIONS_SUCCESS_ACTION = `${FETCH_ORGANIZATIONS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_ORGANIZATIONS_FAILURE_ACTION = `${FETCH_ORGANIZATIONS_PREFIX}_FAILURE_ACTION`;

export const CREATE_ORGANIZATION_PREFIX = 'CREATE_ORGANIZATION';
export const CREATE_ORGANIZATION_REQUEST_ACTION = `${CREATE_ORGANIZATION_PREFIX}_REQUEST_ACTION`;
export const CREATE_ORGANIZATION_SUCCESS_ACTION = `${CREATE_ORGANIZATION_PREFIX}_SUCCESS_ACTION`;
export const CREATE_ORGANIZATION_FAILURE_ACTION = `${CREATE_ORGANIZATION_PREFIX}_FAILURE_ACTION`;

export const UPDATE_ORGANIZATION_PREFIX = 'UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATION_REQUEST_ACTION = `${UPDATE_ORGANIZATION_PREFIX}_REQUEST_ACTION`;
export const UPDATE_ORGANIZATION_SUCCESS_ACTION = `${UPDATE_ORGANIZATION_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_ORGANIZATION_FAILURE_ACTION = `${UPDATE_ORGANIZATION_PREFIX}_FAILURE_ACTION`;

export const DELETE_ORGANIZATION_PREFIX = 'DELETE_ORGANIZATION';
export const DELETE_ORGANIZATION_REQUEST_ACTION = `${DELETE_ORGANIZATION_PREFIX}_REQUEST_ACTION`;
export const DELETE_ORGANIZATION_SUCCESS_ACTION = `${DELETE_ORGANIZATION_PREFIX}_SUCCESS_ACTION`;
export const DELETE_ORGANIZATION_FAILURE_ACTION = `${DELETE_ORGANIZATION_PREFIX}_FAILURE_ACTION`;

export const FETCH_ORGANIZATION_PREFIX = 'FETCH_ORGANIZATION';
export const FETCH_ORGANIZATION_REQUEST_ACTION = `${FETCH_ORGANIZATION_PREFIX}_REQUEST_ACTION`;
export const FETCH_ORGANIZATION_SUCCESS_ACTION = `${FETCH_ORGANIZATION_PREFIX}_SUCCESS_ACTION`;
export const FETCH_ORGANIZATION_FAILURE_ACTION = `${FETCH_ORGANIZATION_PREFIX}_FAILURE_ACTION`;

export const FETCH_ORGANIZATION_LOCATIONS_PREFIX = 'FETCH_ORGANIZATION_LOCATIONS';
export const FETCH_ORGANIZATION_LOCATIONS_REQUEST_ACTION = `${FETCH_ORGANIZATION_LOCATIONS_PREFIX}_REQUEST_ACTION`;
export const FETCH_ORGANIZATION_LOCATIONS_SUCCESS_ACTION = `${FETCH_ORGANIZATION_LOCATIONS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_ORGANIZATION_LOCATIONS_FAILURE_ACTION = `${FETCH_ORGANIZATION_LOCATIONS_PREFIX}_FAILURE_ACTION`;

export const INVITE_ORGANIZATION_MEMBER_PREFIX = 'INVITE_ORGANIZATION_MEMBER';
export const INVITE_ORGANIZATION_MEMBER_REQUEST_ACTION = `${INVITE_ORGANIZATION_MEMBER_PREFIX}_REQUEST_ACTION`;
export const INVITE_ORGANIZATION_MEMBER_SUCCESS_ACTION = `${INVITE_ORGANIZATION_MEMBER_PREFIX}_SUCCESS_ACTION`;
export const INVITE_ORGANIZATION_MEMBER_FAILURE_ACTION = `${INVITE_ORGANIZATION_MEMBER_PREFIX}_FAILURE_ACTION`;

export const ADD_ORGANIZATION_MEMBER_PREFIX = 'ADD_ORGANIZATION_MEMBER';
export const ADD_ORGANIZATION_MEMBER_REQUEST_ACTION = `${ADD_ORGANIZATION_MEMBER_PREFIX}_REQUEST_ACTION`;
export const ADD_ORGANIZATION_MEMBER_SUCCESS_ACTION = `${ADD_ORGANIZATION_MEMBER_PREFIX}_SUCCESS_ACTION`;
export const ADD_ORGANIZATION_MEMBER_FAILURE_ACTION = `${ADD_ORGANIZATION_MEMBER_PREFIX}_FAILURE_ACTION`;

export const REMOVE_ORGANIZATION_MEMBER_PREFIX = 'REMOVE_ORGANIZATION_MEMBER';
export const REMOVE_ORGANIZATION_MEMBER_REQUEST_ACTION = `${REMOVE_ORGANIZATION_MEMBER_PREFIX}_REQUEST_ACTION`;
export const REMOVE_ORGANIZATION_MEMBER_SUCCESS_ACTION = `${REMOVE_ORGANIZATION_MEMBER_PREFIX}_SUCCESS_ACTION`;
export const REMOVE_ORGANIZATION_MEMBER_FAILURE_ACTION = `${REMOVE_ORGANIZATION_MEMBER_PREFIX}_FAILURE_ACTION`;

export const CHANGE_ORGANIZATION_MEMBER_ROLE_PREFIX = 'CHANGE_ORGANIZATION_MEMBER_ROLE';
export const CHANGE_ORGANIZATION_MEMBER_ROLE_REQUEST_ACTION = `${CHANGE_ORGANIZATION_MEMBER_ROLE_PREFIX}_REQUEST_ACTION`;
export const CHANGE_ORGANIZATION_MEMBER_ROLE_SUCCESS_ACTION = `${CHANGE_ORGANIZATION_MEMBER_ROLE_PREFIX}_SUCCESS_ACTION`;
export const CHANGE_ORGANIZATION_MEMBER_ROLE_FAILURE_ACTION = `${CHANGE_ORGANIZATION_MEMBER_ROLE_PREFIX}_FAILURE_ACTION`;

export const FETCH_ORGANIZATION_ORDERS_PREFIX = 'FETCH_ORGANIZATION_ORDERS';
export const FETCH_ORGANIZATION_ORDERS_REQUEST_ACTION = `${FETCH_ORGANIZATION_ORDERS_PREFIX}_REQUEST_ACTION`;
export const FETCH_ORGANIZATION_ORDERS_SUCCESS_ACTION = `${FETCH_ORGANIZATION_ORDERS_PREFIX}_SUCCESS_ACTION`;
export const FETCH_ORGANIZATION_ORDERS_FAILURE_ACTION = `${FETCH_ORGANIZATION_ORDERS_PREFIX}_FAILURE_ACTION`;

export const UPDATE_ORGANIZATION_BILLING_ADDRESS_PREFIX = 'UPDATE_ORGANIZATION_BILLING_ADDRESS';
export const UPDATE_ORGANIZATION_BILLING_ADDRESS_REQUEST_ACTION = `${UPDATE_ORGANIZATION_BILLING_ADDRESS_PREFIX}_REQUEST_ACTION`;
export const UPDATE_ORGANIZATION_BILLING_ADDRESS_SUCCESS_ACTION = `${UPDATE_ORGANIZATION_BILLING_ADDRESS_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_ORGANIZATION_BILLING_ADDRESS_FAILURE_ACTION = `${UPDATE_ORGANIZATION_BILLING_ADDRESS_PREFIX}_FAILURE_ACTION`;

export const UPDATE_ORGANIZATION_ACCOUNT_INFO_PREFIX = 'UPDATE_ORGANIZATION_ACCOUNT_INFO';
export const UPDATE_ORGANIZATION_ACCOUNT_INFO_REQUEST_ACTION = `${UPDATE_ORGANIZATION_ACCOUNT_INFO_PREFIX}_REQUEST_ACTION`;
export const UPDATE_ORGANIZATION_ACCOUNT_INFO_SUCCESS_ACTION = `${UPDATE_ORGANIZATION_ACCOUNT_INFO_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_ORGANIZATION_ACCOUNT_INFO_FAILURE_ACTION = `${UPDATE_ORGANIZATION_ACCOUNT_INFO_PREFIX}_FAILURE_ACTION`;

export const ARCHIVE_ORGANIZATION_PREFIX = 'ARCHIVE_ORGANIZATION';
export const ARCHIVE_ORGANIZATION_REQUEST_ACTION = `${ARCHIVE_ORGANIZATION_PREFIX}_REQUEST_ACTION`;
export const ARCHIVE_ORGANIZATION_SUCCESS_ACTION = `${ARCHIVE_ORGANIZATION_PREFIX}_SUCCESS_ACTION`;
export const ARCHIVE_ORGANIZATION_FAILURE_ACTION = `${ARCHIVE_ORGANIZATION_PREFIX}_FAILURE_ACTION`;

export const UN_ARCHIVE_ORGANIZATION_PREFIX = 'UN_ARCHIVE_ORGANIZATION';
export const UN_ARCHIVE_ORGANIZATION_REQUEST_ACTION = `${UN_ARCHIVE_ORGANIZATION_PREFIX}_REQUEST_ACTION`;
export const UN_ARCHIVE_ORGANIZATION_SUCCESS_ACTION = `${UN_ARCHIVE_ORGANIZATION_PREFIX}_SUCCESS_ACTION`;
export const UN_ARCHIVE_ORGANIZATION_FAILURE_ACTION = `${UN_ARCHIVE_ORGANIZATION_PREFIX}_FAILURE_ACTION`;

// WEBHOOKS
export const WEBHOOKS_SET_ENABLE_SUCCESS_ACTION = `${UN_ARCHIVE_ORGANIZATION_PREFIX}_SUCCESS_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  items: [],
  total: 0,
  accountDetailsSuccess: false,
  globalParams: {
    order: 'desc',
    orderBy: 'created_at',
    page: 1,
    perPage: 25,
    searchFullNameQuery: '',
    customerServiceManager: '',
    userId: '',
    searchDoctorFullNameQuery: '',
    patientStatusId: ''
  },
  fetching: {},
  metadata: {}
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORGANIZATIONS_SUCCESS_ACTION:
      return {
        ...state,
        items: action.payload.organizations.data,
        total: action.payload.organizations.total,
        globalParams: action.payload.params
      };
    case FETCH_ORGANIZATION_REQUEST_ACTION:
      return produce(state, draft => {
        draft.fetching[action.payload] = true;
      });
    case FETCH_ORGANIZATION_SUCCESS_ACTION:
      return produce(state, draft => {
        const { organization, metadata } = action.payload;
        const organizationIndex = state.items.findIndex(item => item.id === organization.id);
        if (organizationIndex > -1) {
          draft.items[organizationIndex] = organization;
        } else {
          draft.items.push(organization);
        }
        draft.metadata[organization.id] = metadata;
        draft.fetching[organization.id] = false;
      });
    case CREATE_ORGANIZATION_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items.push(action.payload);
      });
    case UPDATE_ORGANIZATION_SUCCESS_ACTION:
      return produce(state, draft => {
        const organizationIndex = state.items.findIndex(item => item.id === action.payload.id);
        draft.items[organizationIndex === -1 ? 0 : organizationIndex] = action.payload;
      });
    case DELETE_ORGANIZATION_SUCCESS_ACTION: {
      return produce(state, draft => {
        draft.items.splice(
          state.items.findIndex(item => item.id === action.payload.id),
          1
        );
      });
    }
    case REMOVE_ORGANIZATION_MEMBER_SUCCESS_ACTION: {
      return produce(state, draft => {
        const organizationIndex = state.items.findIndex(
          item => item.id === action.payload.organizationId
        );
        const userIndex = state.items[organizationIndex].users.findIndex(
          item => item.id === action.payload.userId
        );
        draft.items[organizationIndex].users.splice(userIndex, 1);
      });
    }
    case CHANGE_ORGANIZATION_MEMBER_ROLE_SUCCESS_ACTION: {
      return produce(state, draft => {
        const organizationIndex = state.items.findIndex(
          item => item.id === action.payload.organizationId
        );
        const userIndex = state.items[organizationIndex].users.findIndex(
          item => item.id === action.payload.userId
        );
        draft.items[organizationIndex].users[userIndex] = action.payload.user;
      });
    }
    case FETCH_ORGANIZATION_ORDERS_SUCCESS_ACTION: {
      return produce(state, draft => {
        const organizationIndex = state.items.findIndex(item => item.id === action.payload.id);
        draft.items[organizationIndex].orders = action.payload.orders;
      });
    }
    case ADD_ORGANIZATION_MEMBER_SUCCESS_ACTION: {
      return produce(state, draft => {
        const organizationIndex = state.items.findIndex(
          item => item.id === action.payload.organizationId
        );
        draft.items[organizationIndex].users = action.payload.users;
      });
    }
    case UPDATE_ORGANIZATION_BILLING_ADDRESS_SUCCESS_ACTION: {
      return produce(state, draft => {
        const organizationIndex = state.items.findIndex(item => item.id === action.payload.id);
        draft.items[organizationIndex].payment_info.billing_address = action.payload.billingAddress;
      });
    }
    case UPDATE_ORGANIZATION_ACCOUNT_INFO_SUCCESS_ACTION: {
      return produce(state, draft => {
        const organizationIndex = state.items.findIndex(item => item.id === action.payload.id);

        draft.items[organizationIndex] = action.payload.organization;

        draft.accountDetailsSuccess = true;
      });
    }
    case FETCH_ORGANIZATION_LOCATIONS_SUCCESS_ACTION: {
      return produce(state, draft => {
        const organizationIndex = state.items.findIndex(item => item.id === action.payload.id);
        if (organizationIndex > -1) {
          draft.items[organizationIndex].locations = action.payload.locations;
        } else {
          draft.items.push(action.payload);
        }
      });
    }
    case ARCHIVE_ORGANIZATION_SUCCESS_ACTION: {
      return produce(state, draft => {
        draft.items.splice(
          state.items.findIndex(item => item.id === action.payload.id),
          1
        );
      });
    }
    case UN_ARCHIVE_ORGANIZATION_SUCCESS_ACTION:
      return produce(state, draft => {
        draft.items.push(action.payload);
      });
    case WEBHOOKS_SET_ENABLE_SUCCESS_ACTION:
      return produce(state, draft => {
        const organizationIndex = state.items.findIndex(item => item.id === action.payload.id);
        draft.items[organizationIndex].webhooks_enabled = action.payload.value;
      });
    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchOrganizationsAction(params = {}) {
  return dispatch => {
    dispatch({ type: FETCH_ORGANIZATIONS_REQUEST_ACTION });
    const organizationsApiService = new OrganizationsApiService();
    return organizationsApiService
      .get(params)
      .then(response => {
        dispatch({
          type: FETCH_ORGANIZATIONS_SUCCESS_ACTION,
          payload: { organizations: response.organizations, params }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_ORGANIZATIONS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchOrganizationAction(id, includes = []) {
  return dispatch => {
    dispatch({ type: FETCH_ORGANIZATION_REQUEST_ACTION, payload: id });
    const organizationsApiService = new OrganizationsApiService();
    return organizationsApiService
      .show(id, includes)
      .then(response => {
        dispatch({
          type: FETCH_ORGANIZATION_SUCCESS_ACTION,
          payload: {
            organization: response.organization,
            metadata: response.metadata
          }
        });
        return response.organization;
      })
      .catch(error => {
        dispatch({ type: FETCH_ORGANIZATION_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
        return false;
      });
  };
}

export function fetchOrganizationLocationsAction(id) {
  return dispatch => {
    dispatch({ type: FETCH_ORGANIZATION_LOCATIONS_REQUEST_ACTION });
    const organizationsApiService = new OrganizationsApiService();
    return organizationsApiService
      .show(id, ['locations'])
      .then(response => {
        dispatch({
          type: FETCH_ORGANIZATION_LOCATIONS_SUCCESS_ACTION,
          payload: { id, locations: response.organization.locations }
        });
        return response.organization.locations;
      })
      .catch(error => {
        dispatch({ type: FETCH_ORGANIZATION_LOCATIONS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
        return false;
      });
  };
}

export function createOrganizationAction(params) {
  return dispatch => {
    dispatch({ type: CREATE_ORGANIZATION_REQUEST_ACTION });
    const organizationsApiService = new OrganizationsApiService();
    return organizationsApiService
      .create(params)
      .then(response => {
        dispatch({
          type: CREATE_ORGANIZATION_SUCCESS_ACTION,
          payload: response.organization
        });
        dispatch(enqueueNotification('success', response.message));
        return response.organization;
      })
      .catch(error => {
        dispatch({ type: CREATE_ORGANIZATION_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateOrganizationAction(params, id) {
  return dispatch => {
    dispatch({ type: UPDATE_ORGANIZATION_REQUEST_ACTION });
    const organizationsApiService = new OrganizationsApiService();
    return organizationsApiService
      .update(params, id)
      .then(response => {
        dispatch({
          type: UPDATE_ORGANIZATION_SUCCESS_ACTION,
          payload: response.organization
        });
        dispatch(enqueueNotification('success', response.message));
        return response.organization;
      })
      .catch(error => {
        dispatch({ type: UPDATE_ORGANIZATION_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
        return null;
      });
  };
}

export function deleteOrganizationAction(id) {
  return dispatch => {
    dispatch({ type: DELETE_ORGANIZATION_REQUEST_ACTION });
    const organizationsApiService = new OrganizationsApiService();
    return organizationsApiService
      .delete(id)
      .then(response => {
        dispatch({
          type: DELETE_ORGANIZATION_SUCCESS_ACTION,
          payload: { id }
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: DELETE_ORGANIZATION_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function inviteOrganizationMemberAction(organizationId, params) {
  return dispatch => {
    dispatch({ type: INVITE_ORGANIZATION_MEMBER_REQUEST_ACTION });
    const organizationsApiService = new OrganizationsApiService();
    return organizationsApiService
      .inviteMember(organizationId, params)
      .then(response => {
        dispatch({
          type: INVITE_ORGANIZATION_MEMBER_SUCCESS_ACTION,
          payload: response.organization
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: INVITE_ORGANIZATION_MEMBER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function addOrganizationMemberAction(organizationId, params) {
  return dispatch => {
    dispatch({ type: ADD_ORGANIZATION_MEMBER_REQUEST_ACTION });
    const organizationsApiService = new OrganizationsApiService();
    return organizationsApiService
      .addMember(organizationId, params)
      .then(response => {
        dispatch({
          type: ADD_ORGANIZATION_MEMBER_SUCCESS_ACTION,
          payload: { organizationId, users: response.users }
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: ADD_ORGANIZATION_MEMBER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function removeOrganizationMemberAction(organizationId, userId) {
  return dispatch => {
    dispatch({ type: REMOVE_ORGANIZATION_MEMBER_REQUEST_ACTION });
    const organizationsApiService = new OrganizationsApiService();
    return organizationsApiService
      .removeMember(organizationId, userId)
      .then(response => {
        dispatch({
          type: REMOVE_ORGANIZATION_MEMBER_SUCCESS_ACTION,
          payload: { organizationId, userId }
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: REMOVE_ORGANIZATION_MEMBER_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function changeOrganizationMemberRoleAction(organizationId, userId, role) {
  return dispatch => {
    dispatch({ type: CHANGE_ORGANIZATION_MEMBER_ROLE_REQUEST_ACTION });
    const organizationsApiService = new OrganizationsApiService();
    return organizationsApiService
      .changeMemberRole(organizationId, userId, role)
      .then(response => {
        dispatch({
          type: CHANGE_ORGANIZATION_MEMBER_ROLE_SUCCESS_ACTION,
          payload: { organizationId, userId, user: response.user }
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: CHANGE_ORGANIZATION_MEMBER_ROLE_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchOrganizationOrdersAction(id) {
  return dispatch => {
    dispatch({ type: FETCH_ORGANIZATION_ORDERS_REQUEST_ACTION });
    const OrganizationsService = new OrganizationsApiService();
    return OrganizationsService.getOrganizationOrders(id)
      .then(({ orders }) => {
        dispatch({
          type: FETCH_ORGANIZATION_ORDERS_SUCCESS_ACTION,
          payload: { id, orders }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_ORGANIZATION_ORDERS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateOrganizationBillingAddress(id, params) {
  return dispatch => {
    dispatch({ type: UPDATE_ORGANIZATION_BILLING_ADDRESS_REQUEST_ACTION });
    const OrganizationsService = new OrganizationsApiService();
    return OrganizationsService.updateBillingAddress(id, params)
      .then(({ billing_address: billingAddress, message }) => {
        dispatch({
          type: UPDATE_ORGANIZATION_BILLING_ADDRESS_SUCCESS_ACTION,
          payload: { id, billingAddress }
        });
        dispatch(enqueueNotification('success', message));
      })
      .catch(error => {
        dispatch({ type: UPDATE_ORGANIZATION_BILLING_ADDRESS_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function saveOrganizationCredits(id, value, validFrom = null, validTo = null) {
  return dispatch => {
    const OrganizationsService = new OrganizationsApiService();

    return OrganizationsService.saveCredits(
      id,
      value,
      !validFrom ? null : validFrom,
      !validTo ? null : validTo
    )
      .then(response => {
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));

        throw new Error(error.message);
      });
  };
}

export function updateOrganizationAccountInfo(id, params) {
  return dispatch => {
    dispatch({ type: UPDATE_ORGANIZATION_ACCOUNT_INFO_REQUEST_ACTION });

    const OrganizationsService = new OrganizationsApiService();

    return OrganizationsService.updateAccountInfo(id, params)
      .then(response => {
        // Update user data in store
        dispatch({
          type: SET_CURRENT_USER,
          payload: response.user
        });

        // Update Organization data in store
        dispatch({
          type: SET_CURRENT_ORGANIZATION,
          payload: {
            ...response.organization,
            credits: response.credits
          }
        });

        dispatch({
          type: UPDATE_ORGANIZATION_ACCOUNT_INFO_SUCCESS_ACTION,
          payload: {
            id,
            organization: response.organization
          }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));

        throw new Error(error.message);
      });
  };
}

export function archiveOrganizationAction(id) {
  return dispatch => {
    dispatch({ type: ARCHIVE_ORGANIZATION_REQUEST_ACTION });
    const organizationsApiService = new OrganizationsApiService();
    return organizationsApiService
      .archive(id)
      .then(response => {
        dispatch({
          type: ARCHIVE_ORGANIZATION_SUCCESS_ACTION,
          payload: { id }
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: ARCHIVE_ORGANIZATION_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function unArchiveOrganizationAction(id) {
  return dispatch => {
    dispatch({ type: UN_ARCHIVE_ORGANIZATION_REQUEST_ACTION });
    const organizationsApiService = new OrganizationsApiService();
    return organizationsApiService
      .unArchive(id)
      .then(response => {
        dispatch({
          type: UN_ARCHIVE_ORGANIZATION_SUCCESS_ACTION,
          payload: response.organization
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch({ type: UN_ARCHIVE_ORGANIZATION_FAILURE_ACTION });
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function setWebhookEnable(id, value) {
  return dispatch => {
    const organizationsApiService = new OrganizationsApiService();
    return organizationsApiService
      .setWebhookEnabled(id, value)
      .then(response => {
        dispatch({
          type: WEBHOOKS_SET_ENABLE_SUCCESS_ACTION,
          payload: {
            id,
            value
          }
        });
        dispatch(enqueueNotification('success', response.message));
        return true;
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}
