import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, IconButton, TextField as MuiTextField } from '@material-ui/core';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import _get from 'lodash/get';
import { Field, Form, Formik } from 'formik';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import Can from '../../../components/Can';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { PatientFilesApiService } from '../../../services';
import { enqueueNotification } from '../../../reducers/notifications';

const useStyles = makeStyles(() => ({
  formContainer: {
    position: 'relative',
    overflowY: 'hidden'
  },
  backdrop: {
    position: 'absolute',
    zIndex: '9999'
  },
  dialogTitle: {
    textAlign: 'center'
  }
}));

const FlagJobAction = ({ patientFile, onFlagged }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const api = new PatientFilesApiService();

  const jobId = _get(patientFile, 'metadata.relu_request.job_id', null);
  const status = _get(patientFile, 'metadata.relu_request.status', null);

  const handleSubmit = (values, resetForm, setSubmitting) => {
    api
      .flatReluJob(patientFile.id, values.remarks)
      .then(({ message, data }) => {
        dispatch(enqueueNotification('success', message));
        resetForm();
        setOpen(false);
        onFlagged(data);
      })
      .catch(error => {
        setSubmitting(false);
        dispatch(enqueueNotification('error', error.message));
      });
  };

  if (!jobId) {
    return null;
  }

  if (['flagged', 'pending'].includes(status)) {
    return null;
  }

  return (
    <Can
      permissions={['patient-files.relu.flag-job']}
      yes={() => (
        <>
          <ConfirmationDialogOnClickWrapper
            confirmationBody="Are you sure you want to report this job to Relu?"
            confirmationTitle="Report an Issue"
          >
            <IconButton
              onClick={() => {
                setOpen(true);
              }}
              color="secondary"
            >
              <ReportProblemIcon />
            </IconButton>
          </ConfirmationDialogOnClickWrapper>
          <Formik
            enableReinitialize
            validationSchema={Yup.object().shape({
              remarks: Yup.string()
                .min(3, 'The remarks must be at least 3 characters')
                .max(1000, 'The remarks must not be more than 1000 characters.')
                .required('Required')
            })}
            initialValues={{
              remarks: '',
              filename: patientFile.original_name
            }}
            onSubmit={(values, { resetForm, setSubmitting }) => {
              handleSubmit(values, resetForm, setSubmitting);
            }}
          >
            {({ isSubmitting, resetForm, errors, touched }) => (
              <Dialog
                open={open}
                onClose={() => {}}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="sm"
              >
                <Form>
                  <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
                    Input your remarks
                  </DialogTitle>
                  <DialogContent className={classes.formContainer}>
                    <Backdrop className={classes.backdrop} open={isSubmitting} invisible>
                      <LoadingIndicator size={60} />
                    </Backdrop>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field name="filename">
                          {({ field }) => (
                            <MuiTextField
                              fullWidth
                              variant="outlined"
                              id="filename"
                              label="File"
                              value={field.value}
                              InputProps={{ readOnly: true }}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="remarks">
                          {({ field }) => (
                            <MuiTextField
                              error={!!errors.remarks && touched.remarks}
                              helperText={errors.remarks}
                              fullWidth
                              variant="outlined"
                              id="remarks"
                              label="Remarks"
                              value={field.value}
                              disabled={isSubmitting}
                              InputLabelProps={{ shrink: true }}
                              multiline
                              minRows={6}
                              onInput={field.onChange}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="secondary"
                      disabled={isSubmitting}
                      onClick={e => {
                        e.preventDefault();
                        resetForm();
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                      {isSubmitting ? 'Submitting' : 'Submit'}
                    </Button>
                  </DialogActions>
                </Form>
              </Dialog>
            )}
          </Formik>
        </>
      )}
    />
  );
};

FlagJobAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patientFile: PropTypes.object.isRequired,
  onFlagged: PropTypes.func
};

FlagJobAction.defaultProps = {
  onFlagged: () => {}
};

export default FlagJobAction;
