import React from 'react';
import TextField from './TextField';
import { AUTO_CAPS_NAME } from '../Form/TextField';

const NameField = ({ children, ...props }) => {
  const updatedProps = {
    ...props,
    removeUnnecessaryWhitespaces: true,
    autoCaps: AUTO_CAPS_NAME
  };
  return <TextField {...updatedProps}>{children}</TextField>;
};

NameField.propTypes = {};

export default NameField;
