import React, { useEffect, useRef } from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import { Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PromotionCodeForm from './components/PromotionCodeForm';
import createLoadingSelector from '../../selectors/loading';
import {
  CREATE_PROMOTION_CODE_PREFIX,
  createPromotionCodeAction
} from '../../reducers/promotionCodes';
import createErrorMessageSelector from '../../selectors/error';

const useStyles = makeStyles(() => ({
  form: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

const AddPromotionCode = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const hasMount = useRef(false);
  const isCreatingPromotionCode = useSelector(state =>
    createLoadingSelector([CREATE_PROMOTION_CODE_PREFIX])(state)
  );
  const errorOnCreating = useSelector(state =>
    createErrorMessageSelector([CREATE_PROMOTION_CODE_PREFIX])(state)
  );

  useEffect(() => {
    if (hasMount.current && !isCreatingPromotionCode && !errorOnCreating) {
      history.push('/promotion-codes');
    } else {
      hasMount.current = true;
    }
  }, [isCreatingPromotionCode, errorOnCreating, history]);

  const createPromotionCode = values => {
    dispatch(createPromotionCodeAction(values));
    return true;
  };

  return (
    <Card className={classes.form}>
      <CardHeader title="Create Promotion Code" />
      <Divider />
      <PromotionCodeForm onSubmit={createPromotionCode} isLoading={isCreatingPromotionCode} />
    </Card>
  );
};

export default AddPromotionCode;
