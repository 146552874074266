import React from 'react';
import PropTypes from 'prop-types';
import { FormBuilderLayoutFactory } from './FormBuilder/Form';

const PreviewForm = ({ layout }) => {
  const onDelete = () => {};
  const onUpdate = () => {};
  const isPreview = true;

  return (
    <div className="is-preview">
      {layout.length === 0 ? (
        <h5>No additional fields exists</h5>
      ) : (
        layout.map(element => {
          const props = {
            element,
            onDelete,
            onUpdate,
            isPreview
          };

          return <FormBuilderLayoutFactory key={element.id} {...props} />;
        })
      )}
    </div>
  );
};

PreviewForm.propTypes = {
  layout: PropTypes.array.isRequired
};

PreviewForm.defaultProps = {
  layout: []
};

export default PreviewForm;
