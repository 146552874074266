import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Field, FieldArray, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch } from 'react-redux';
import LoadingButton from '../LoadingButton';
import LoadingIndicator from '../LoadingIndicator';
import SubSectionWrapper from '../../pages/Account/components/SubSectionWrapper';
import OrganizationsApiService from '../../services/api/organizations';
import { enqueueNotification } from '../../reducers/notifications';
import NameField from '../Formik/NameField';

const useStyles = makeStyles(() => ({
  contactInformation: {
    '& .MuiAccordionDetails-root': {
      flexDirection: 'column'
    }
  }
}));

const ContactInformation = ({ organizationId, ContentWrapper, readOnly }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState({ contacts: null });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const contactsFormRef = useRef();

  useEffect(() => {
    if (initialValues.contacts !== null) {
      return () => {};
    }

    let setResult = response => {
      initialValues.contacts = response.contacts;
      setInitialValues({ ...initialValues });
    };

    let turnOffLoading = () => {
      setIsLoading(false);
    };

    const fetchOrganizationContacts = () => {
      const organizationApiService = new OrganizationsApiService();

      setIsLoading(true);

      organizationApiService.getContacts(organizationId).then(
        response => {
          setResult(response);
          turnOffLoading();
        },
        () => {
          turnOffLoading();
        }
      );
    };

    fetchOrganizationContacts();

    return () => {
      setResult = () => {};
      turnOffLoading = () => {};
    };
  }, [organizationId, initialValues]);

  const uid = () => {
    return (
      Date.now().toString(36) +
      Math.random()
        .toString(36)
        .substr(2)
    );
  };

  const addContact = (formValues, setFormValues) => {
    const contacts = [...formValues.contacts];

    contacts.push({
      id: uid(),
      first_name: '',
      last_name: '',
      title: '',
      email: '',
      phone_number: '',
      notes: '',
      is_new: true
    });

    setFormValues({ ...formValues, contacts });
  };

  const deleteContact = (contact, formValues, setFormValues) => {
    let contacts = [...formValues.contacts];
    contacts = contacts.filter(item => item !== contact);

    setFormValues({ ...formValues, contacts });
  };

  const onSubmit = formValues => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const contactsParam = JSON.parse(JSON.stringify(formValues.contacts));

    // eslint-disable-next-line no-restricted-syntax
    for (const contact of contactsParam) {
      if (contact.is_new) {
        delete contact.id;
        delete contact.is_new;
      }
    }

    const organizationApiService = new OrganizationsApiService();

    organizationApiService.updateContact(organizationId, contactsParam).then(
      response => {
        initialValues.contacts = response.contacts;
        const newFormValue = { ...initialValues };
        setInitialValues(newFormValue);
        contactsFormRef.current.setValues(newFormValue);
        setIsSubmitting(false);
        dispatch(enqueueNotification('success', response.message));
      },
      () => {
        setIsSubmitting(false);
      }
    );
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const validationSchema = Yup.object().shape({
    contacts: Yup.array().of(
      Yup.object().shape({
        first_name: Yup.string().required('First Name is required'),
        last_name: Yup.string(),
        title: Yup.string().required('Title is required'),
        email: Yup.string().email('Email is invalid')
      })
    )
  });

  return (
    <SubSectionWrapper
      title="Additional Office Staff (Contact Information)"
      className={classes.contactInformation}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        innerRef={contactsFormRef}
      >
        {({ values, setValues, dirty }) => (
          <Form id="test-form">
            <FieldArray name="contacts">
              {() =>
                values.contacts.map((contact, i) => {
                  return (
                    <ContentWrapper key={contact.id}>
                      <Typography component="h1" variant="h5" style={{ marginBottom: '5px' }}>
                        {`Contact ${i + 1}`}
                        {!readOnly && (
                          <IconButton
                            edge="end"
                            color="secondary"
                            onClick={() => deleteContact(contact, values, setValues)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Typography>

                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Field
                            variant="outlined"
                            fullWidth
                            label="First Name"
                            name={`contacts.${i}.first_name`}
                            className="form-control"
                            component={NameField}
                            disabled={readOnly || isSubmitting}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            variant="outlined"
                            fullWidth
                            label="Last Name"
                            name={`contacts.${i}.last_name`}
                            className="form-control"
                            component={NameField}
                            disabled={readOnly || isSubmitting}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            variant="outlined"
                            fullWidth
                            label="Title"
                            name={`contacts.${i}.title`}
                            className="form-control"
                            component={TextField}
                            disabled={readOnly || isSubmitting}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            variant="outlined"
                            fullWidth
                            label="Email"
                            name={`contacts.${i}.email`}
                            className="form-control"
                            component={TextField}
                            disabled={readOnly || isSubmitting}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            variant="outlined"
                            fullWidth
                            label="Phone Number"
                            name={`contacts.${i}.phone_number`}
                            className="form-control"
                            component={TextField}
                            disabled={readOnly || isSubmitting}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            variant="outlined"
                            fullWidth
                            label="Notes"
                            name={`contacts.${i}.notes`}
                            className="form-control"
                            component={TextField}
                            disabled={readOnly || isSubmitting}
                          />
                        </Grid>
                      </Grid>
                    </ContentWrapper>
                  );
                })
              }
            </FieldArray>
            {!readOnly && (
              <>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className="form-control"
                  style={{ marginRight: '5px' }}
                  onClick={() => addContact(values, setValues)}
                >
                  Add new contact
                </Button>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  disabled={!dirty}
                >
                  Save
                </LoadingButton>
              </>
            )}
          </Form>
        )}
      </Formik>
    </SubSectionWrapper>
  );
};

ContactInformation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  organizationId: PropTypes.number.isRequired,
  ContentWrapper: PropTypes.elementType,
  readOnly: PropTypes.bool
};

ContactInformation.defaultProps = {
  ContentWrapper: CardContent,
  readOnly: false
};

export default ContactInformation;
