import React from 'react';
import { Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import LoadingIndicator from '../LoadingIndicator';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  }
}));

const GlobalBackdrop = () => {
  const classes = useStyles();

  const { open = false, options = {} } = useSelector(state => state.globalBackdrop || {});

  return (
    <Backdrop className={classes.backdrop} open={open} {...options}>
      <LoadingIndicator size={60} />
    </Backdrop>
  );
};

export default GlobalBackdrop;
