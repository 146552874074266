import PropTypes from 'prop-types';
import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import { Divider, Grid, makeStyles } from '@material-ui/core';
import {
  ASSIGNMENT_LABELS,
  ASSIGNMENTS,
  ATTRIBUTE_VALUE_LABELS,
  ATTRIBUTES,
  EXPORTS,
  QUESTIONS,
  SCAN_SUBMISSION_LABELS,
  SCAN_SUBMISSION_METHODS,
  SCANNER_LABELS,
  VIEW_LABEL
} from './constants';
import Teeth from './Teeth';
import {
  CONTROL_CHECKBOXES,
  CONTROL_FILE,
  CONTROL_TERMS
} from '../../pages/Account/components/RxFormCustomization/FormBuilder/helpers';
import { useRxFormPreference } from './hooks';

const useStyles = makeStyles(theme => ({
  bracketsList: {
    paddingLeft: theme.spacing(2.5)
  },
  teethDivider: {
    marginBottom: theme.spacing(1.5)
  }
}));

const getOrderedFields = formBuilderData => {
  const orderedFields = new Set([
    ATTRIBUTES.DOCTOR,
    ATTRIBUTES.PRINT_ASSIGNMENT,
    ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT
  ]);

  formBuilderData.layout.forEach(section => {
    section.children.forEach(col => {
      col.children.forEach(field => {
        orderedFields.add(field.system_name);
      });
    });
  });

  return [...orderedFields];
};

const customFieldQuestions = form => {
  return form.controls
    .filter(control => {
      return control.system_type !== 'existing' && control.system_type !== CONTROL_FILE;
    })
    .map(control => {
      return {
        custom: true,
        attribute: control.system_name,
        customLabel: control.props.label,
        generateLabel: value => {
          if (control.system_type === CONTROL_TERMS) {
            return value === '1' ? 'Agreed' : 'Disagreed';
          }

          if (!value || !control.props || !control.props.options || !control.props.options.length) {
            return value;
          }

          let options = [];
          if (control.system_type === CONTROL_CHECKBOXES) {
            const values = value.split(',').map(val => Number(val));
            options = control.props.options.filter(item => values.indexOf(item.id) !== -1);
          } else {
            options = control.props.options.filter(item => item.id === Number(value));
          }
          return options.map(option => option.name).join(', ');
        }
      };
    });
};

const View = ({ rxForm }) => {
  const styles = useStyles();
  const { preference } = useRxFormPreference();

  // generate custom fields questions
  // list down attributes order

  const nativeQuestions = [
    {
      attribute: ATTRIBUTES.DOCTOR,
      generateLabel: (value, data) => {
        return data.user.full_name;
      }
    },
    {
      attribute: ATTRIBUTES.INTRAORAL_SCAN_DATE
    },
    {
      attribute: ATTRIBUTES.BONDING_DATE,
      hide: !rxForm.bonding_date
    },
    {
      attribute: ATTRIBUTES.TREATMENT_LOCATION,
      generateLabel: (value, data) => {
        return data.location.location_name;
      }
    },
    {
      attribute: ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT
    },
    {
      attribute: ATTRIBUTES.RUSH_CASE,
      generateLabel: (value, data) => {
        if (!value) {
          return 'No';
        }
        const authorizedName = data[ATTRIBUTES.RUSH_CASE_AUTHORIZED_BY_NAME];
        return `Yes, authorized by "${authorizedName}"`;
      },
      hide: !rxForm.version
    },
    {
      attribute: ATTRIBUTES.BRACKET_LIBRARIES,
      generateLabel: value => {
        return (
          <ol className={styles.bracketsList}>
            {value.map(library => (
              <li key={library.file_name}>{library.file_name}</li>
            ))}
          </ol>
        );
      },
      labelProps: { component: 'div' },
      hide:
        !rxForm.version &&
        Array.isArray(rxForm.bracket_libraries) &&
        rxForm.bracket_libraries.length > 0
    },
    {
      attribute: ATTRIBUTES.ARCH_WIRES,
      generateLabel: value => {
        return (
          <ol className={styles.bracketsList}>
            {value.map(archWire => (
              <li key={archWire.name}>{archWire.name}</li>
            ))}
          </ol>
        );
      },
      labelProps: { component: 'div' },
      hide: !rxForm.version || !Array.isArray(rxForm.arch_wires) || rxForm.arch_wires.length === 0
    },
    {
      attribute: ATTRIBUTES.SCAN_SUBMISSION_METHOD,
      generateLabel: (value, data) => {
        let label = SCAN_SUBMISSION_LABELS[value];
        if (value === SCAN_SUBMISSION_METHODS.UPLOAD) {
          label = SCAN_SUBMISSION_LABELS[value];
        } else {
          const scannerName =
            data[ATTRIBUTES.CUSTOM_SCANNER_NAME] || SCANNER_LABELS[data[ATTRIBUTES.SCANNER]];
          label = `${label} (${scannerName})`;
        }
        return label.replace(
          '{ASSIGNMENT}',
          ASSIGNMENT_LABELS[data[ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT]]
        );
      }
    },
    {
      attribute: ATTRIBUTES.ARCH_TYPE,
      hide: !rxForm.version
    },
    {
      attribute: ATTRIBUTES.EXPORT_TYPE,
      hide: !rxForm.version
    },
    {
      attribute: ATTRIBUTES.PRINT_ASSIGNMENT,
      hide: !rxForm.version
    },
    {
      attribute: ATTRIBUTES.TRAY_DESIGN_ASSIGNMENT,
      hide:
        !rxForm.version ||
        rxForm.print_assignment !== ASSIGNMENTS.IN_OFFICE ||
        rxForm.export_type !== EXPORTS.TRAYS
    },
    {
      attribute: ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT,
      hide: !rxForm.version
    },
    {
      attribute: ATTRIBUTES.SHIPPING_LOCATION,
      generateLabel: (value, data) => {
        return data.shipping_location && data.shipping_location.location_name;
      },
      hide: !rxForm.version
    },
    {
      attribute: ATTRIBUTES.SHIPPING_METHOD,
      hide: !rxForm.version || rxForm.print_assignment === ASSIGNMENTS.IN_OFFICE,
      generateLabel: (value, data) => {
        return data.shipping_method_label;
      }
    },
    {
      attribute: ATTRIBUTES.ROOT_INTEGRATION,
      hide: !rxForm.version
    },
    {
      attribute: 'created_by_user_id',
      hide: rxForm.user_id === rxForm.created_by_user_id,
      generateLabel: (value, data) => {
        return data.created_by_user.full_name;
      }
    },
    {
      attribute: ATTRIBUTES.NOTES
    },
    {
      attribute: ATTRIBUTES.CUSTOM_SETUP,
      generateLabel: value => (value ? 'Yes' : 'No'),
      hide: !rxForm.version || !preference.custom_setup_enabled
    }
  ];

  const questions = [...nativeQuestions, ...customFieldQuestions(rxForm.form_builder_data.form)];

  const orderedFields = getOrderedFields(rxForm.form_builder_data);

  return (
    <Grid container spacing={1}>
      {orderedFields.map(attribute => {
        const questionIndex = questions.findIndex(q => q.attribute === attribute);
        if (questionIndex === -1) {
          return '';
        }
        const question = questions[questionIndex];
        const {
          customLabel,
          generateLabel = null,
          gridSize = 6,
          hide = false,
          labelProps = {},
          custom = false
        } = question;

        let label = rxForm[attribute];
        if (custom) {
          label = rxForm.custom_field_values[attribute];
        }

        if (label === null || label === undefined || hide) {
          if (!customLabel) return '';
        }

        if (generateLabel !== null) {
          label = generateLabel(label, rxForm);
        } else if (ATTRIBUTE_VALUE_LABELS[attribute]) {
          label = ATTRIBUTE_VALUE_LABELS[attribute][label];
        } else if (typeof label === 'boolean') {
          label = label ? 'Yes' : 'No';
        }

        return (
          <Grid key={attribute} item xs={gridSize}>
            <ListItemText
              primary={customLabel || VIEW_LABEL[attribute] || QUESTIONS[attribute]}
              secondary={label}
              secondaryTypographyProps={labelProps}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Divider className={styles.teethDivider} />
        <Teeth data={rxForm.teeth || {}} viewOnly />
      </Grid>
    </Grid>
  );
};

View.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rxForm: PropTypes.object.isRequired
};

export default View;
