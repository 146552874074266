const REPORTS = {
  TECHNICIANS: 'technicians',
  SHIPPING: 'internal-shipping',
  FEEDBACKS: 'feedbacks',
  INVOICES: 'invoices',
  DELETED_CASES: 'deleted-cases'
};

export const REPORT_LABELS = {
  [REPORTS.TECHNICIANS]: 'Technicians Report',
  [REPORTS.SHIPPING]: 'Shipping Report',
  [REPORTS.FEEDBACKS]: 'Feedbacks Report',
  [REPORTS.INVOICES]: 'Invoices Report',
  [REPORTS.DELETED_CASES]: 'Deleted Cases Report'
};

export default REPORTS;
