import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import RadioGroupField from './RadioGroupField';
import withDependency from './hooks/withDependency';

const CustomSetupField = ({ name, currentValue, ...props }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <RadioGroupField
          name={name}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
          {...props}
        />
      </Grid>
    </Grid>
  );
};

CustomSetupField.propTypes = {
  name: PropTypes.string.isRequired,
  currentValue: PropTypes.bool.isRequired
};

export default withDependency(CustomSetupField);
